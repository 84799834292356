import React from 'react'

import {PropTypes} from 'prop-types'
import {FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {
  MainContainerFlex,
  MainContainer
} from '../../pages/step/StepStyles'
import {Margin} from '../../styles/BasicStyles'
import {SecondaryCTA} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import {
  QuestionTitle,
  IntroductionBlock
} from '../jogo_espirais/JogoEspiraisStyles'
import DichotomousQuestion from '../question/DichotomousQuestion'
import Question from './Question'

const SessionEvaluation = ({currentStep, activeLanguage}) => (
  <FormSpy subscription={{values: true}}>
    {() => (
      <>
        {currentStep.order === 1 ? (
          <MainContainer>
            <Margin size={32} tablet={24} mobile={24} />
            {currentStep.descriptions?.length > 0 && (
              <IntroductionBlock bgColor={SecondaryCTA}>
                {currentStep.descriptions.map((description) => (
                  <QuestionText 
                    isBold
                    dangerouslySetInnerHTML={{
                      __html: GetValueLanguage(
                        description.title,
                        activeLanguage?.code
                      )
                    }}
                  />
                ))}
              </IntroductionBlock>
            )}
            {/* {currentStep.title && (
              <>
                <QuestionTitle weight='700'>
                  {GetValueLanguage(
                    currentStep.title,
                    activeLanguage?.code
                  )}
                </QuestionTitle>
              </>
            )} */}
            <FieldArray name={`steps[${currentStep.order - 1}].questions`}>
              {({fields}) =>
                currentStep.questions.map((question, i) => (
                  <div key={i}>
                    <Question
                      fieldName={`${fields.name}[${i}]`}
                      question={question}
                      onChangeValue={() => {}}
                      isFirst={i === 0}
                      title={GetValueLanguage(
                        currentStep.title,
                        activeLanguage?.code
                      )}
                    />
                  </div>
                ))
              }
            </FieldArray>
            <Margin size={24} />
          </MainContainer>
        ) : (
          <MainContainerFlex>
            <Margin size={64} tablet={24} mobile={24} />
            {currentStep.title && (
              <>
                <QuestionTitle weight='700'>
                  {GetValueLanguage(
                    currentStep.title,
                    activeLanguage?.code
                  )}
                </QuestionTitle>
                <Margin size={48} />
              </>
            )}
            <FieldArray name={`steps[${currentStep.order - 1}].questions`}>
              {({fields}) =>
                currentStep.questions.map((question, i) => (
                  <div key={i}>
                    <DichotomousQuestion
                      fieldName={`${fields.name}[${i}]`}
                      question={question}
                      onChangeValue={() => {}}
                      isBig
                      isFour
                    />
                  </div>
                ))
              }
            </FieldArray>
          </MainContainerFlex>
        )}
      </>
    )}
  </FormSpy>
)

SessionEvaluation.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  currentStep: PropTypes.object.isRequired
}

export default withLocalize(SessionEvaluation)

import {Row, Col} from 'antd'
import styled from 'styled-components'

import {
  MenuBorderColor,
  White,
  SecondaryColour
} from '../../styles/Colours'
import {device} from '../../styles/Responsive'
import {Title22} from '../../styles/TextSizes'

export const ChartContainer = styled.div`
  z-index: 1;
  width: 100%;
  margin-top: 24px;
  padding: 24px;
  height: 100%;
  background: ${({bgColor}) => bgColor || White};
  border: 2px solid ${({borderColor}) => borderColor || MenuBorderColor};
  border-radius: 8px;

  @media ${device.tablet} {
    background-image: none !important;
    padding: 16px;
  }
`

export const ChartRow = styled(Row)`
  display: flex !important;
`

export const ChartBox = styled.div`
  width: 100%;
  height: ${({isAuto}) => (isAuto ? 'auto' : '100%')};
  border: 2px solid
    ${({borderColor, isStatic}) =>
      isStatic ? 'transparent' : borderColor || MenuBorderColor};
  border-radius: 8px;
  margin: ${({isStatic, isAuto}) =>
    isAuto ? 'auto auto 24px' : isStatic ? '-40px -20px 10px' : '0px'};
  max-width: ${({isAuto}) => (isAuto ? '80%' : '100%')};
  background: ${({isStatic}) => (isStatic ? 'transparent' : White)};

  @media ${device.tabletMin} {
    padding: 0px;
    margin: 0px;
    border: none;
    max-width: 100%;
  }
`

export const ChartCol = styled(Col)`
  padding: 24px 24px 24px 8px !important;
  display: flex !important;
  flex-direction: column;

  @media ${device.tabletMin} {
    padding: 16px !important;
    flex-direction: column-reverse;
  }
`

export const InfoTop = styled.div`
  display: block;

  @media ${device.tabletMin} {
    display: flex;
    background: ${MenuBorderColor};
    padding: 8px;
    border-radius: 8px;
  }
`

export const ChartBoxInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${White};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  margin-bottom: 8px;
  text-align: center;

  @media ${device.tabletMin} {
    margin-left: ${({isLeft}) => (isLeft ? '0px' : '4px')};
    margin-right: ${({isLeft}) => (isLeft ? '4px' : '0px')};
    margin-bottom: 0px;
  }
`

export const LegendContainer = styled.div`
  width: 100%;
  margin-top: auto;

  @media ${device.tabletMin} {
    display: flex;
    margin-bottom: 16px;
  }
`

export const LegendMeditationContainer = styled.div`
  padding: 0px 24px;

  @media ${device.laptop} {
    padding: 0px 16px;
  }
`

export const LegendLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;

  @media ${device.tabletMin} {
    justify-content: flex-start;
  }
`

export const BoxColor = styled.div`
  border-top: 3px solid ${({color}) => color || SecondaryColour};
  width: 10px;
  margin-right: 8px;
  margin-top: 8px;
  min-width: 10px;
`

export const LineSeparator = styled.div`
  border-top: 2px solid ${({color}) => color || MenuBorderColor};
  width: calc(100% - 48px);
  margin: 24px 24px 0px;
`

export const ChartTitle = styled(Title22)`
  padding: 24px 24px 0px;
  text-align: left;
  margin-bottom: ${({margin}) => margin || 0}px;
`

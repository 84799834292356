import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'

import ChevronRight from '../../assets/icons/chevron-right.svg'
import Complete from '../../assets/icons/complete.svg'
import Flag from '../../assets/icons/flag.svg'
import Lock from '../../assets/icons/lock.svg'
import Sun from '../../assets/icons/sun.svg'
import SunIcon from '../../assets/icons/sun_session.svg'
import {
  GetValueLanguage,
  calcProgress,
  GetStepName
} from '../../infra/utils/CommonFunctions'
import {LockIcon} from '../../pages/dashboard/DashboardStyles'
import {SecondaryColour, Beige} from '../../styles/Colours'
import {
  CompleteIcon,
  SessionContainer,
  SessionTitle,
  CompleteBar,
  CompleteBarColor,
  SessionInner,
  TableCol
} from './SessionStyles'

const SessionPanelHeader = ({
  session,
  last,
  onClick,
  index,
  isOpen,
  activeLanguage,
  id,
  nextSession
}) => {
  const {user, info: {program}} = useSelector((state) => state)
  const isApplicator = user.type === 2
  const isEducator = user.type === 3
  const programIsOpen = isApplicator || isEducator
  const isSession1 = session.order === 1 || false
  // const active = session.enabled || isSession1 || isApplicator
  const active = session.enabled || programIsOpen
  const isLast = index === last
  const progress = calcProgress(session)
  const stepName = GetStepName(program)

  return (
    <Row style={{display: isLast ? 'flex' : 'block'}} id={id}>
      {index === 0 && (
        <Col xs={2}>
          <img alt='sun' src={Sun} />
        </Col>
      )}
      {(stepName === 'step' || (stepName === 'step_educators' && index !== 0)) && (
        <TableCol
          xs={{
            span: 18,
            offset: isLast ? 0 : index % 2 === 0 ? 0 : 6
          }}
          lg={{
            span: 18,
            offset: isLast ? 4 : index % 2 === 0 ? 0 : 6
          }}
          isSession1={isSession1}
        >
          <SessionContainer
            bgColor={Beige}
            active={active}
            onClick={active ? onClick : () => {}}
            noMarginBottom={index > 0}
            isOpen={isOpen}
          >
            {!active ? (
              <LockIcon top='-10px' right='-10px'>
                <img alt='lock' src={Lock} />
              </LockIcon>
            ) : session.completed && !isEducator ? (
              <CompleteIcon top='-10px' right='-10px'>
                <img alt='complete' src={Complete} />
              </CompleteIcon>
            ) : active &&
              !session.completed &&
              !nextSession?.enabled &&
              !programIsOpen ? (
              <div
                style={{
                  width: 33,
                  height: 33,
                  position: 'absolute',
                  top: -35,
                  left: 130
                }}
              >
                <img alt='sun' src={SunIcon} />
              </div>
            ) : null}
            <SessionInner>
              <Col md={stepName === 'step' ? 18 : 23} style={{display: 'flex', textAlign: 'left'}}>
                {!isSession1 && (
                  <img
                    alt='arrow'
                    style={{
                      marginRight: 16,
                      transform: `rotate(${isOpen ? 90 : 0}deg)`
                    }}
                    src={ChevronRight}
                    alt='chevron'
                  />
                )}
                <SessionTitle inactive={!active}>
                  {GetValueLanguage(session.title, activeLanguage?.code)}
                </SessionTitle>
              </Col>
              <Col md={stepName === 'step' ? 6 : 1} style={{textAlign: 'right'}}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}
                >
                  {!isSession1 && active && session.complete !== 100 && stepName === 'step' && (
                    <CompleteBar>
                      <CompleteBarColor
                        width={progress}
                        bgColor={SecondaryColour}
                      />
                      <CompleteBarColor />
                    </CompleteBar>
                  )}
                  {/* <StarIcon src={!active ? StarInactive : Star} />
                  <StarText inactive={!active}>
                    {session.starNumber}
                  </StarText> */}
                </div>
              </Col>
            </SessionInner>
          </SessionContainer>
        </TableCol>
      )}
      <Col xs={2}>
        {isLast && (
          <img
            alt='flag'
            style={{
              marginLeft: 30,
              position: 'absolute',
              bottom: 0,
              left: 0
            }}
            src={Flag}
          />
        )}
      </Col>
    </Row>
  )
}

SessionPanelHeader.propTypes = {
  index: PropTypes.number.isRequired,
  last: PropTypes.number,
  session: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  activeLanguage: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  nextSession: PropTypes.object
}

SessionPanelHeader.defaultProps = {
  onClick: () => {},
  isOpen: false,
  last: undefined,
  nextSession: undefined
}

export default withLocalize(SessionPanelHeader)

import React from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/illustrations/illustration_quiz_activities.svg'
import CompleteBoxComponent from '../../components/dashboard/CompleteBoxComponent'
import {
  GetValueLanguage,
  GetQuizzesFromProgram,
  GetStepName
} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {Beige} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import {DashboardBox} from '../dashboard/DashboardStyles'

const QuizzesComponent = ({translate, activeLanguage, history}) => {
  const {
    user,
    info: {program}
  } = useSelector((state) => state)
  const stepName = GetStepName(program)
  const isEducator = user.type === 3

  const records = GetQuizzesFromProgram(program)

  return (
    <DashboardBox
      column
      bgColor={Beige}
      image={`url(${Illustration})`}
      style={{
        backgroundPosition: 'right bottom',
        backgroundSize: 'auto'
      }}
      tabletRemove
    >
      <TitleH3 weight={700} style={{textAlign: 'left'}}>
        {translate('QUIZZES')}
      </TitleH3>
      <Margin size={21} />
      <Row gutter={[16, 16]}>
        {records.map((record, i) => {
          const title = isEducator && record.description ? GetValueLanguage(record.description, activeLanguage?.code) : GetValueLanguage(record.title, activeLanguage?.code)
          return (
          <Col xs={24} md={8} key={i}>
            <CompleteBoxComponent
              translate={translate}
              inactive={!record.enabled}
              title={title}
              starNumber={4}
              titleBtn='COMEÇAR'
              onClick={() =>
                history.push(
                  `/${stepName}/${
                    record.steps.find((s) => s.type === 'question')._id
                  }`,
                  {
                    next: 'isBack',
                    back: 'isBack',
                    noProgram: true
                  }
                )
              }
              isEducator={isEducator}
            />
          </Col>
        )
      })}
      </Row>
    </DashboardBox>
  )
}

QuizzesComponent.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withLocalize(withRouter(QuizzesComponent))

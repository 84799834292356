import React, {useState, useEffect, useRef} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import {PropTypes} from 'prop-types'
import {Droppable, Draggable} from 'react-beautiful-dnd'
import {FormSpy} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import Close from '../../assets/icons/close.svg'
import {Margin} from '../../styles/BasicStyles'
import {
  White,
  TextColor,
  LightGrey,
  SecondaryColour
} from '../../styles/Colours'
import {Title25, Body, TitleH3} from '../../styles/TextSizes'
import {BaseButton} from '../buttons/BaseButton'
import DateInput from '../inputs/DateInput'
import {
  DayContainer,
  ActivitiesContainer,
  SelectActivitiesContainer,
  TagsContainer,
  Tag,
  TagRemove,
  ValidationContainer,
  MobileTable,
  DaysContainer,
  DaysContainerScroll,
  TableCol
} from './AtividadesLazerStyles'
import TagsComponent from './TagsComponent'

const MobileTableComponent = ({
  days,
  validation,
  validate,
  onClickTag,
  onRemoveTag,
  translate,
  onCloseValidation,
  isSession,
  customFormat,
  week,
  onchangeDate,
  disabledDate
}) => {
  const [activeDay, setActiveDay] = useState(false)
  const refDay = useRef(null)

  useEffect(() => {
    const today = moment.utc().format('YYYY-MM-DD')
    let day = days.find(d => d.date === today) ? today : false
    setActiveDay(day)

    if (!day) {
      day = days[0]?.date ? moment.utc(days[0].date) : moment.utc()
      const weekStart = day
        .clone()
        .startOf('week')
        .format('YYYY-MM-DD')
      const weekEnd = day
        .clone()
        .endOf('week')
        .format('YYYY-MM-DD')
      if (weekStart <= today <= weekEnd) {
        setActiveDay(today)
      } else setActiveDay(weekStart)
    }

    setTimeout(() => {
      if (refDay?.current) {
        refDay.current.scrollIntoView(false, {
          behavior: 'smooth',
          block: 'start'
        })
      }
    }, 300)
  }, [days])

  return (
    <FormSpy subscription={{values: true}}>
      {({values, form}) => (
        <MobileTable>
          {!isSession && (
            <TableCol style={{marginBottom: 16}}>
              <DateInput
                first
                meta={{}}
                picker='week'
                dateFormat={customFormat}
                input={{
                  value: week,
                  onChange: onchangeDate
                }}
                disabledDate={disabledDate}
              />
            </TableCol>
          )}
          <DaysContainer>
            <DaysContainerScroll>
              {days.map((day, i) => (
                <DayContainer
                  key={i}
                  xs={6}
                  active={day.date === activeDay}
                  ref={day.date === activeDay ? refDay : undefined}
                  onClick={() => setActiveDay(day.date)}
                >
                  <Title25>{day.day}</Title25>
                  <Body color={LightGrey}>{day.dayOfWeekSmall}</Body>
                </DayContainer>
              ))}
            </DaysContainerScroll>
          </DaysContainer>
          {validation && (
            <ValidationContainer>
              <Body color={SecondaryColour}>
                {translate('REALIZASTE_A_ATIVIDADE')}
              </Body>
              <Margin size={8} />
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <BaseButton
                    htmlType='button'
                    type='primaryMedium'
                    text={translate('SIM')}
                    onClick={() => validate(form, values)}
                  />
                </Col>
                <Col xs={12}>
                  <BaseButton
                    htmlType='button'
                    type='default'
                    text={translate('NAO')}
                    onClick={onCloseValidation}
                  />
                </Col>
              </Row>
            </ValidationContainer>
          )}
          <ActivitiesContainer>
            {days.map((d, i) => {
              let day = d
              const exist = values.activities?.findIndex(
                f => moment.utc(f.date).format('YYYY-MM-DD') === d.date
              )
              if (exist > -1) day = {...values.activities[exist], ...day}
              const activities =
                exist > -1 ? values.activities[exist]?.activities : []
              return (
                <Droppable droppableId={`mobile_${day.date}`} key={i}>
                  {provided => (
                    <div
                      style={{
                        width: '100%',
                        display:
                          day.date === activeDay
                            ? activities?.length
                              ? 'block'
                              : 'flex'
                            : 'none',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      {...provided.props}
                      ref={provided.innerRef}
                    >
                      <TagsContainer style={{margin: 0}}>
                        {(!activities || !activities?.length) && (
                          <TitleH3
                            color={LightGrey}
                            style={{textAlign: 'center'}}
                          >
                            {translate('ARRASTA_PARA_AQUI_AS_ATIVIDADES')}
                          </TitleH3>
                        )}
                        {activities?.map((tag, i) => (
                          <Draggable
                            isDragDisabled
                            key={i}
                            draggableId={`tag_${day.date}_${i}`}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Tag
                                  key={`tag_${day.date}_${i}`}
                                  onClick={() =>
                                    onClickTag(
                                      tag.title.toLowerCase(),
                                      day.date
                                    )
                                  }
                                  isActive={
                                    validation &&
                                    tag.title.toLowerCase() ===
                                      validation.tag.toLowerCase() &&
                                    day.date === validation.date
                                  }
                                  isValid={tag.completed}
                                >
                                  <Body
                                    color={
                                      tag.completed ? White : TextColor
                                    }
                                  >
                                    {tag.title.toLowerCase()}
                                  </Body>
                                  {!tag.completed && (
                                    <TagRemove
                                      src={Close}
                                      onClick={e => {
                                        e.stopPropagation()
                                        onRemoveTag(
                                          tag.title.toLowerCase(),
                                          day.date,
                                          form,
                                          values
                                        )
                                      }}
                                    />
                                  )}
                                </Tag>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </TagsContainer>
                    </div>
                  )}
                </Droppable>
              )
            })}
          </ActivitiesContainer>
          <div>
            <SelectActivitiesContainer>
              <TagsComponent />
            </SelectActivitiesContainer>
          </div>
        </MobileTable>
      )}
    </FormSpy>
  )
}

MobileTableComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  days: PropTypes.array,
  validation: PropTypes.object,
  validate: PropTypes.func,
  onClickTag: PropTypes.func,
  onRemoveTag: PropTypes.func,
  onCloseValidation: PropTypes.func,
  isSession: PropTypes.bool,
  customFormat: PropTypes.func,
  week: PropTypes.object,
  onchangeDate: PropTypes.func,
  disabledDate: PropTypes.func
}

MobileTableComponent.defaultProps = {
  days: undefined,
  validation: undefined,
  validate: undefined,
  onClickTag: undefined,
  onRemoveTag: undefined,
  onCloseValidation: undefined,
  isSession: false,
  customFormat: undefined,
  week: undefined,
  onchangeDate: undefined,
  disabledDate: undefined
}

export default withLocalize(withRouter(MobileTableComponent))

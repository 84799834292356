import React, {useState} from 'react'

import moment from 'moment'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {Margin} from '../../styles/BasicStyles'
import {Lilac} from '../../styles/Colours'
import DateInput from '../inputs/DateInput'
import ChartMeditationComponent from './ChartMeditationComponent'
import {ChartContainer} from './ChartsStyles'

const ChartMeditation = () => {
  const [week, setWeek] = useState(moment.utc())

  const customFormat = (value) => {
    const dateWeekStart = value.clone().startOf('week')
    const dateWeekEnd = value.clone().endOf('week')
    return `${dateWeekStart.format('ddd, D MMM')} - ${dateWeekEnd.format(
      'ddd, D MMM'
    )}`
  }

  const disabledDate = (current) => {
    const weekEnd = moment
      .utc()
      .clone()
      .endOf('isoWeek')
    return current && current > weekEnd
  }

  const onchangeDate = (date) => {
    if (!date) return
    setWeek(date)
  }

  return (
    <ChartContainer
      bgColor={Lilac}
      borderColor={Lilac}
      style={{height: 'auto', flex: 1}}
    >
      <DateInput
        first
        meta={{}}
        picker='week'
        dateFormat={customFormat}
        input={{
          value: week,
          onChange: onchangeDate
        }}
        disabledDate={disabledDate}
      />
      <Margin size={16} />
      <ChartMeditationComponent week={week} />
    </ChartContainer>
  )
}

export default withLocalize(withRouter(ChartMeditation))

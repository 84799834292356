import styled from 'styled-components'

import {
  TextColor,
  MenuBorderColor,
  SecondaryColour,
  White,
  ErrorColor
} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const MaterialInputSection = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: ${({noMargin}) => (noMargin ? '0px' : '16px')};
  background: transparent;

  @media ${device.tablet} {
    margin-top: ${({noMargin}) => (noMargin ? '0px' : '10px')};
  }
`

export const MaterialLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${({color}) => color || TextColor};
  text-align: left;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 13px;
  }

  @media ${device.tablet} {
    font-size: 12px;
  }
`

export const MaterialErrorLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  top: ${({filled}) => (filled ? '0' : '10px')};
  left: 0;
  transition: all 0.2s ease;
  transition: color 0;
  color: #de8f8f;
  pointer-events: none;
  text-align: left;
`

export const MaterialCheckboxLabel = styled.div`
  display: inline-block;
  color: ${({showError}) => (showError ? ErrorColor : TextColor)};
  text-decoration: ${({clickable}) => (clickable ? 'underline' : 'none')};
`

export const MaterialInput = styled.input`
  display: inline-block;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 7px;
  border: 2px solid
    ${({showError}) => (showError ? 'red' : MenuBorderColor)};
  font-size: 14px;
  color: ${TextColor};
  line-height: 21px;
  font-weight: 400;
  padding: ${({isSmall}) => (isSmall ? '8px 16px' : '14px 16px')};
  background: ${White};
  opacity: ${(p) => (p.tag === 'GUESTS' ? 1 : p.disabled ? 0.6 : 1)};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &::placeholder {
    color: #d7d7d7;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 13px;
  }

  @media ${device.tablet} {
    font-size: 12px;
  }
`

export const MaterialInputBox = styled.div`
  position: relative;
  margin-top: ${({noMargin}) => (noMargin ? '0px' : '8px')};
  align-items: center;
  display: flex;
`

export const MaterialIcon = styled.span`
  position: absolute;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
`

export const QuestionInputBox = styled.div`
  position: relative;
  margin-top: 8px;
  align-items: center;
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  border: 2px solid
    ${({showError}) => (showError ? 'red' : MenuBorderColor)};
  padding: 21px 32px;
  background: transparent;

  @media ${device.tablet} {
    padding: 8px 16px;
  }
`

export const QuestionInput = styled.input`
  display: inline-block;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid
    ${({showError}) => (showError ? 'red' : MenuBorderColor)};
  font-size: 14px;
  color: ${TextColor};
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  background: transparent;
  min-height: 32px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &::placeholder {
    color: #d7d7d7;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const RequiredIcon = styled.span`
  &:after {
    color: ${SecondaryColour}
    content:"*"
  }
`

export const HiddenLabelInputBox = styled.div`
  position: relative;
  margin-top: 8px;
  align-items: center;
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  border: 2px solid
    ${({showError}) => (showError ? 'red' : MenuBorderColor)};
  padding: 8px 32px 32px;
  background: ${White};

  @media ${device.tablet} {
    padding: 8px 16px 16px;
  }
`

export const HiddenLabelQuestionText = styled.div`
  font-family: 'Poppins', sans-serif;
  color: ${(p) => p.color || TextColor};
  font-weight: ${(p) => (p.isBold ? 'bold' : 400)};
  font-size: ${({size}) => (size === 'medium' ? '11px' : '14px')};
  line-height: 16px;
  min-height: 17px;

  @media ${device.tablet} {
    font-size: ${({size}) => (size === 'medium' ? '11px' : '14px')};
    line-height: 16px;
    min-height: 17px;
  }
`

export const HiddenLabelQuestionInput = styled.input`
  display: inline-block;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid
    ${({showError}) => (showError ? 'red' : MenuBorderColor)};
  font-size: 14px;
  color: ${TextColor};
  font-size: ${({size}) => (size === 'medium' ? '20px' : '25px')};
  line-height: 30px;
  font-weight: 400;
  background: transparent;
  min-height: 32px;
  padding-top: 7px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &::placeholder {
    color: ${({size}) => (size === 'medium' ? '#d7d7d7' : TextColor)};
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  @media ${device.tablet} {
    font-size: ${({size}) => (size === 'medium' ? '16px' : '20px')};
    line-height: 20px;
  }
`

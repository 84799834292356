import {SAVE_USER, UPDATE_USER_SCORE} from '../ActionsType'

export const SaveUser = (user) => ({
  type: SAVE_USER,
  user
})

export const DeleteUser = () => ({
  type: SAVE_USER,
  user: {}
})

export const UpdateUserScore = (points) => ({
  type: UPDATE_USER_SCORE,
  points
})

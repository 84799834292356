import React, {useState} from 'react'

import {Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_chart.svg'
import {Margin} from '../../styles/BasicStyles'
import {Title25} from '../../styles/TextSizes'
import {TableCol} from '../atividades_lazer/AtividadesLazerStyles'
import DateInput from '../inputs/DateInput'
import ChartComponent from './ChartComponent'
import {ChartContainer, ChartRow} from './ChartsStyles'

const ChartActivities = ({translate}) => {
  const [week, setWeek] = useState(moment.utc())

  const customFormat = (value) => {
    const dateWeekStart = value
      .utc()
      .clone()
      .startOf('isoWeek')
    const dateWeekEnd = value
      .utc()
      .clone()
      .endOf('isoWeek')
    return `${dateWeekStart.format('ddd, D MMM')} - ${dateWeekEnd.format(
      'ddd, D MMM'
    )}`
  }

  const disabledDate = (current) => {
    const weekEnd = moment
      .utc()
      .clone()
      .endOf('isoWeek')
    return current && current > weekEnd
  }

  const onchangeDate = (date) => {
    if (!date) return
    setWeek(date)
  }

  return (
    <ChartContainer
      style={{
        backgroundImage: `url(${Illustration})`,
        backgroundPosition: 'right bottom',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Title25>{translate('GRAFICO_ATIVIDADES_VS_DIARIO_HUMOR')}</Title25>
      <Margin size={16} />
      <ChartRow gutter={[16, 16]}>
        <TableCol sm={24} md={16}>
          <DateInput
            first
            meta={{}}
            picker='week'
            dateFormat={customFormat}
            input={{
              value: week,
              onChange: onchangeDate
            }}
            disabledDate={disabledDate}
          />
        </TableCol>
        <Col sm={24} md={16}>
          <ChartComponent week={week} />
        </Col>
      </ChartRow>
    </ChartContainer>
  )
}

ChartActivities.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(withRouter(ChartActivities))

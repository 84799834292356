import {
  PrimaryColour,
  SecondaryColour,
  ThirdColour,
  PrimaryCTA,
  SecondaryCTA,
  AlertColour,
  InputBorderColour,
  PrimaryBorderColor,
  SecondaryBorderColor,
  MenuBorderColor
} from './Colours'

export default {
  // colors
  primaryColor: PrimaryColour,
  secondaryColor: SecondaryColour,
  thirdColor: ThirdColour,
  primaryCTA: PrimaryCTA,
  secondaryCTA: SecondaryCTA,

  inputBorderColor: InputBorderColour,
  inputFocusColor: InputBorderColour,
  inputErrorColor: AlertColour,

  primaryBorderColor: PrimaryBorderColor,
  secondaryBorderColor: SecondaryBorderColor,
  menuBorderColor: MenuBorderColor,

  primaryBackgroundColor: '#ffffff',
  secondaryBackgroundColor: '#f3f3f3',

  // text size
  titleSize: '30px',
  sectionSize: '24px',
  textSize: '14px'
}

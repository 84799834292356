import React from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {QuestionText} from '../../styles/TextSizes'
import TextInput from '../inputs/TextInput'
import {OptionsContainer, OptionsSelect} from './QuestionStyles'

const QuestionMultiSelect = ({
  activeLanguage,
  titleColor,
  textColor,
  question,
  translate,
  fieldName
}) => {
  const onSelectOption = (fields, idOption, form, name) => {
    const selected = fields || []
    const exist = selected.findIndex((id) => idOption === id)
    let changes = [...selected]
    if (exist >= 0) {
      changes = fields.filter((id) => idOption !== id)
    } else {
      changes.push(idOption)
    }
    form.change(name, changes)
  }

  const optionsArray = (input) => {
    const {options, form, fields} = input
    const currentOptions = fields.value || []

    return (
      <>
        {options.map((option, index) => (
          <OptionsSelect
            key={index}
            onClick={() =>
              onSelectOption(currentOptions, option._id, form, fields.name)
            }
            isSelected={
              currentOptions.findIndex((a) => option._id === a) >= 0
            }
            color={textColor}
          >
            {GetValueLanguage(option.title, activeLanguage?.code)}
          </OptionsSelect>
        ))}
      </>
    )
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({form}) => (
        <>
          <QuestionText color={titleColor}>
            {GetValueLanguage(question.title, activeLanguage?.code)}
          </QuestionText>
          <Margin size={24} />

          <Row>
            <OptionsContainer>
              <FieldArray
                name={`${fieldName}.options`}
                component={optionsArray}
                options={question.options}
                form={form}
              />
            </OptionsContainer>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} lg={8}>
              <Margin size={24} />
              <Field
                component={TextInput}
                name={`${fieldName}.other`}
                typeText='text'
                color={titleColor}
                label={translate('INTRODUZ_OUTRA_EMOÇAO')}
                placeholder={translate('EMOÇAO_QUE_SENTISTE')}
              />
            </Col>
          </Row>
        </>
      )}
    </FormSpy>
  )
}

QuestionMultiSelect.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  textColor: PropTypes.string,
  titleColor: PropTypes.string,
  question: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired
}

QuestionMultiSelect.defaultProps = {
  textColor: undefined,
  titleColor: undefined
}

export default withLocalize(withRouter(QuestionMultiSelect))

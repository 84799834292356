import {Button} from 'antd'
import styled from 'styled-components'

import {PrimaryColour, FocusColor} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const StyledButton = styled(Button)`
  overflow: hidden;
  float: ${p => (p.align ? p.align : 'left')};

  &.ant-btn-danger {
    background-color: ${p => p.theme.primaryBackgroundColor};
    color: ${p => p.theme.inputErrorColor};

    &:hover {
      background-color: ${p => p.theme.inputErrorColor};
      color: white;
    }
  }

  &.ant-btn-default,
  &.ant-btn-secondary,
  &.ant-btn-primaryLarge,
  &.ant-btn-primaryMedium,
  &.ant-btn-cancelLarge,
  &.ant-btn-cancelMedium {
    background-color: ${p =>
      p.bgcolor === 'primaryColor'
        ? p.theme.primaryColor
        : p.theme.primaryCTA};
    border-radius: 30px;
    width: ${p => (p.auto ? 'auto' : '100%')};
    height: auto;
    padding: 8px 24px;
    border: 1px solid transparent;

    &:hover {
      background-color: ${({theme}) => theme.secondaryCTA};
      color: #ffffff;
      border: 1px solid transparent;

      & span {
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
      }
    }

    &:focus,
    &:active {
      color: #ffffff;
      border: 1px solid transparent;
      background-color: ${FocusColor};

      & span {
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
      }
    }

    &:disabled {
      background-color: #d7d7d7;
      color: white;
      border: 1px solid transparent;

      &:hover,
      &:focus {
        background-color: rgba(215, 215, 215, 0.7);
      }
    }

    & span {
      color: #ffffff;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
    }

    @media ${device.mobileS} {
      padding: 8px 24px;
    }
  }

  &.ant-btn-default {
    background-color: transparent;
    border: 1px solid ${PrimaryColour};
    color: ${PrimaryColour};

    & span {
      color: ${PrimaryColour};
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
    }
  }

  &.ant-btn-primaryMedium,
  &.ant-btn-cancelMedium {
    padding: 8px 24px;

    @media ${device.tablet} {
      padding: 8px 24px;

      & span {
        font-size: 16px;
      }
    }

    @media ${device.mobileL} {
      padding: 8px 24px;

      & span {
        font-size: 16px;
      }
    }

    @media ${device.mobileM} {
      padding: 8px 24px;

      & span {
        font-size: 16px;
      }
    }
  }

  &.ant-btn-primarySmall,
  &.ant-btn-cancelSmall {
    background-color: ${({theme}) => theme.primaryCTA};
    border-radius: 6px;
    width: auto;
    height: auto;
    padding: 8px 12px;

    &:hover,
    &:focus {
      background-color: ${({theme}) => theme.secondaryCTA};
      border: 1px solid transparent;

      & span {
        color: #ffffff;
      }
    }

    & span {
      color: #ffffff;
      font-family: 'Titillium Web', sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      text-align: center;
      transition: color 0.5s linear;
    }

    @media ${device.mobileL} {
      padding: 6px 8px;
    }
  }

  &.ant-btn-cancelLarge,
  &.ant-btn-cancelMedium,
  &.ant-btn-cancelSmall {
    background-color: #ffffff;
    border: 1px solid ${({theme}) => theme.primaryCTA};

    &:hover,
    &:focus {
      background-color: ${({theme}) => theme.secondaryCTA};

      & span {
        color: #ffffff;
      }
    }

    & span {
      color: ${({theme}) => theme.secondaryCTA};
    }
  }
`

export const FloatButton = styled(Button)`
  position: absolute;
  top: 15px;
  right: ${({position}) =>
    position
      ? position === 1
        ? '70px'
        : `${(position - 1) * 55 + 70}px`
      : '15px'};

  &.ant-btn-danger {
    background-color: ${p => p.theme.primaryBackgroundColor};
    color: ${p => p.theme.inputErrorColor};

    &:hover {
      background-color: ${p => p.theme.inputErrorColor};
      color: white;
    }
  }
`

export const TextContainer = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 38px;
  opacity: 0.2;
  width: 168px;
  text-align: right;
  white-space: pre-line;
  margin-right: 35px;
  transition: opacity 0.5s linear;
  z-index: 1;

  @media ${device.laptop} {
    display: none;
  }
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${p => p.theme.primaryCTA};
  transition: background-color 0.5s linear;
  z-index: 1;
`

export const IconText = styled.span`
  display: none;
  font-family: 'Titillium Web', sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: #ffffff;

  @media ${device.laptop} {
    display: block;
  }
`

export const Icon = styled.span`
  width: 24px;
  height: 24px;

  & svg {
    width: 24px;
    height: 24px;

    & #arrowright {
      fill: #ffffff;
    }

    & #plus {
      fill: #ffffff;
    }
  }

  @media ${device.laptop} {
    width: 12px;
    height: 12px;
    line-height: 12px;
    margin-top: 6px;

    & svg {
      width: 12px;
      height: 12px;
    }
  }
`

export const StyledSaveButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0px solid transparent;
  background-color: transparent;
  position: fixed;
  bottom: 15px;
  right: 15px;
  height: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #000000;
  z-index: 999;
  overflow: hidden;

  @media ${device.desktopL} {
    padding: ${p => (p.withBackground ? '5px' : '0px')};
  }

  @media ${device.laptop} {
    padding: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-radius: 35px;

    @media ${device.desktopL} {
      background-color: ${p =>
        p.withBackground ? 'rgba(255, 255, 255, 0.9)' : 'transparent'};
    }

    @media ${device.laptop} {
      background-color: transparent;
    }
  }

  &:hover {
    ${TextContainer} {
      opacity: 0.4;
    }

    ${IconContainer} {
      background-color: ${p => p.theme.secondaryCTA};
    }
  }
`

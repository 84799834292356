import React, {useRef, useState} from 'react'

import {Carousel} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import ArrowBack from '../../assets/icons/ic_arrow_left.svg'
import ArrowNext from '../../assets/icons/ic_arrow_right.svg'
import {
  BDContainer,
  ArrowsContainer,
  BDCol,
  BDCol2,
  ArrowImg,
  BDImg
} from './BDStyles'

const BDComponent = ({chapter, session, onFinish, activeLanguage}) => {
  const refCarousel = useRef(null)
  const [index, setIndex] = useState(0)

  const onClickArrow = (isNext) => {
    if (!refCarousel || !refCarousel.current) return

    if (isNext) refCarousel.current.next()
    else refCarousel.current.prev()
  }

  return (
    <>
      {/* {step.title && (
        <Title22 color={White}>
          {GetValueLanguage(step.title, activeLanguage?.code)}
        </Title22>
      )} */}
      <BDContainer gutter={{xs: 0, md: 16, lg: 44}}>
        <BDCol2 xs={2} lg={4}>
          {index > 0 && (
            <ArrowsContainer>
              <ArrowImg src={ArrowBack} onClick={() => onClickArrow()} />
            </ArrowsContainer>
          )}
        </BDCol2>
        <BDCol xs={20} lg={16}>
          <Carousel
            dots={false}
            ref={refCarousel}
            afterChange={(e) => {
              setIndex(e)
              if (e === 11) onFinish()
            }}
          >
            {Array.from(Array(12).keys()).map((item, i) => (
              <div>
                <BDImg
                  style={{
                    textAlign: 'center',
                    background: '#fff',
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: 'calc(100vh - 105px - 70px - 30px)'
                  }}
                  src={require(`../../assets/bd/${
                    session.order === 6 ? '6.3' : '10.3'
                  }/${activeLanguage?.code}/${i + 1}.png`)}
                />
              </div>
            ))}
          </Carousel>
        </BDCol>
        <BDCol2 xs={2} lg={4}>
          {index < 11 && (
            <ArrowsContainer>
              <ArrowImg
                src={ArrowNext}
                onClick={() => onClickArrow(true)}
              />
            </ArrowsContainer>
          )}
        </BDCol2>
      </BDContainer>
    </>
  )
}

BDComponent.propTypes = {
  chapter: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired
}

export default withLocalize(withRouter(BDComponent))

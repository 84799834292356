import React from 'react'

import {ConnectedRouter} from 'connected-react-router'
import ReactDOM from 'react-dom'
import App from './App'
import {Provider} from 'react-redux'
import client from './infra/config/AxiosConfig'
import configureStore, {history} from './redux/ConfigStore'
import startThunk from './redux/thunks/StartThunk'
import {LocalizeProvider} from 'react-localize-redux'
import Theme from './styles/Theme'

import {ThemeProvider} from 'styled-components'

const store = configureStore()
client.setupInterceptors(store)
store.dispatch(startThunk(history))

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LocalizeProvider store={store}>
        <ThemeProvider theme={Theme}>
          <App />
        </ThemeProvider>
      </LocalizeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

import React from 'react'

import PropTypes from 'prop-types'

import Moeda from '../../assets/icons/moeda.svg'
import {StarIcon, StarText} from '../../pages/dashboard/DashboardStyles'

const StarComponent = ({active, starNumber, plus, style, size}) => (
  <div
    style={{
      ...style,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }}
  >
    <StarIcon
      src={Moeda}
      size={size}
      style={{filter: !active ? 'grayscale(100%)' : 'inherit'}}
    />
    <StarText inactive={!active} size={size}>
      {plus && '+'}
      {starNumber}
    </StarText>
  </div>
)

StarComponent.propTypes = {
  active: PropTypes.bool,
  starNumber: PropTypes.number,
  plus: PropTypes.bool,
  style: PropTypes.object,
  size: PropTypes.string
}

StarComponent.defaultProps = {
  active: true,
  starNumber: 0,
  plus: false,
  style: {},
  size: 'normal'
}
export default StarComponent

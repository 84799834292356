import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {
  MaterialInputSection,
  MaterialErrorLabel,
  RequiredIcon,
  HiddenLabelInputBox,
  HiddenLabelQuestionText,
  HiddenLabelQuestionInput
} from './MaterialStyles'

const HiddenLabelTextInput = ({
  input,
  meta: {invalid, submitFailed, error},
  typeText,
  step,
  label,
  disabled,
  placeholder,
  translate,
  onClick,
  readOnly,
  isRequired,
  requiredError,
  size,
  onChange
}) => {
  const showError = invalid && submitFailed

  let errorMsg = ''
  if (error?._error) {
    if (Array.isArray(error._error) && error._error.length === 3) {
      errorMsg = translate(error._error[0]).replace(
        error._error[1],
        error._error[2]
      )
    } else {
      errorMsg = translate(error._error)
    }
  }

  if (requiredError) {
    errorMsg = translate('INPUT_ERROR_REQUIRED')
  }

  return (
    <MaterialInputSection size={size}>
      <HiddenLabelInputBox>
        {label && (
          <HiddenLabelQuestionText
            size={size}
            showError={showError || requiredError}
          >
            {input.value && (
              <>
                {label} {isRequired && <RequiredIcon />}
              </>
            )}
          </HiddenLabelQuestionText>
        )}
        <HiddenLabelQuestionInput
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          type={typeText}
          value={input.value}
          onChange={(e) => {
            input.onChange(e)
            onChange(e)
          }}
          showError={showError || requiredError}
          onClick={onClick}
          step={step}
          min='0.1'
          size={size}
        />
      </HiddenLabelInputBox>
      {((invalid && submitFailed) || requiredError) && (
        <MaterialErrorLabel
          visible={(invalid && submitFailed) || requiredError}
        >
          {errorMsg.toUpperCase()}
        </MaterialErrorLabel>
      )}
    </MaterialInputSection>
  )
}

HiddenLabelTextInput.propTypes = {
  translate: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  typeText: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  requiredError: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  step: PropTypes.number,
  size: PropTypes.string,
  onChange: PropTypes.func
}

HiddenLabelTextInput.defaultProps = {
  label: undefined,
  placeholder: undefined,
  onClick: () => {},
  readOnly: false,
  requiredError: false,
  disabled: false,
  isRequired: false,
  step: undefined,
  typeText: 'text',
  size: 'medium',
  onChange: () => {}
}

export default withLocalize(HiddenLabelTextInput)

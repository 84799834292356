import React, {useState, useEffect} from 'react'

import PropTypes from 'prop-types'
import queryString from 'query-string'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import EyeOff from '../../assets/icons/eye-off.svg'
import Eye from '../../assets/icons/eye.svg'
import {BaseButton} from '../../components/buttons/BaseButton'
import TextInput from '../../components/inputs/TextInput'
import Layout from '../../components/layout/Layout'
import {ValidatePassword} from '../../infra/requests/AuthRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {PageForm} from '../../styles/BasicStyles'
import {PrimaryColour} from '../../styles/Colours'
import {Body} from '../../styles/TextSizes'
import {
  LoginContainer,
  LoginCard,
  LogoSection,
  LoginButtonDiv,
  FormTitle,
  FormSubTitle,
  BaseLink
} from './LoginPageStyles'

const formRules = FormValidator.make({
  email: 'required|email',
  password: 'required|min:6',
  confirm_password: 'required|min:6'
})

const DefinePasswordPage = ({translate, history, dispatch, location}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [success, setSuccess] = useState(false)
  const [initialValues, setInitialValues] = useState({})

  const onSubmit = async (values) => {
    try {
      const result = await ValidatePassword(values)
      if (result.success) setSuccess(true)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})

    const query = queryString.parse(location.search)
    setInitialValues({email: query.email, id: query.id})
  }, [])

  return (
    <Layout fixed auth>
      <LoginContainer>
        {success ? (
          <LoginCard>
            <LogoSection>
              <FormTitle>{translate('DEFINE_PASSWORD')}</FormTitle>
              <Body>{translate('DEFINE_PASSWORD_SUCCESS')}</Body>
              <FormSubTitle marginT={16}>
                <span onClick={() => history.push('/login')}>
                  {translate('LOGIN')}
                </span>
              </FormSubTitle>
            </LogoSection>
          </LoginCard>
        ) : (
          <Form
            onSubmit={onSubmit}
            validate={formRules}
            initialValues={initialValues}
          >
            {({handleSubmit, valid, submitting}) => (
              <PageForm onSubmit={handleSubmit}>
                <LoginCard>
                  <LogoSection>
                    <FormTitle>{translate('DEFINE_PASSWORD')}</FormTitle>
                  </LogoSection>
                  <Field
                    component={TextInput}
                    name='email'
                    typeText='email'
                    label={translate('EMAIL')}
                    disabled
                  />
                  <Field
                    component={TextInput}
                    name='password'
                    typeText={showPassword ? 'text' : 'password'}
                    label={translate('PASSWORD')}
                    suffix={
                      <img
                        src={showPassword ? EyeOff : Eye}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    }
                  />
                  <Field
                    component={TextInput}
                    name='confirm_password'
                    typeText={showPassword2 ? 'text' : 'password'}
                    label={translate('PASSWORD')}
                    suffix={
                      <img
                        src={showPassword2 ? EyeOff : Eye}
                        onClick={() => setShowPassword2(!showPassword2)}
                      />
                    }
                  />
                  {/* {error && <MaterialErrorLabel>{error}</MaterialErrorLabel>} */}
                  <LoginButtonDiv>
                    <BaseButton
                      align='initial'
                      variant='raised'
                      htmlType='submit'
                      type='primaryLarge'
                      loading={submitting}
                      disabled={!valid}
                      text={translate('ALTERAR')}
                    />
                    {/* <FormSubTitle marginT={16}>
                    <span onClick={() => history.push('/login')}>
                      {translate('LOGIN')}
                    </span>
                  </FormSubTitle> */}
                  </LoginButtonDiv>
                </LoginCard>
              </PageForm>
            )}
          </Form>
        )}
      </LoginContainer>
    </Layout>
  )
}

DefinePasswordPage.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(() => ({}), mapActionToProps)(DefinePasswordPage)
)

import React, {useRef, useState} from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {
  InactiveTextColor,
  SuccessBgColor,
  ErrorBgColor
} from '../../styles/Colours'
import {QuestionText, Title25} from '../../styles/TextSizes'
import {
  OptionsContainer,
  ExplanationContainer,
  IntroductionBlock
} from '../jogo_espirais/JogoEspiraisStyles'
import {QuestionOption, OptionText, QuestionImg} from './QuestionStyles'

const DichotomousQuestion = ({
  question,
  fieldName,
  activeLanguage,
  onChangeValue,
  isBig,
  isFour,
  hasCorrect,
  hasImage,
  disabled,
  center
}) => {
  const refExplanation = useRef(null)
  const [animation, setAnimation] = useState(undefined)

  const goToExplanation = () => {
    setTimeout(() => {
      if (refExplanation?.current) {
        refExplanation.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }, 300)
  }

  const onSelectOption = (idOption, form, name) => {
    form.change(name, idOption)
    onChangeValue(idOption)
    goToExplanation()
  }

  const optionsArray = (opt) => {
    const {options, form, input} = opt
    const currentOption = input?.value || undefined
    const currentOptionObj =
      options.find((o) => o._id === input?.value) || undefined
    const moreThan2 = options.length === 3

    if (currentOptionObj && currentOptionObj?.explanation) {
      goToExplanation()
    }

    const isCorrect =
      hasCorrect && currentOptionObj?.isCorrect ? input?.value : false

    return (
      <>
        {options.map((option, i) => (
          <Col xs={24} md={isFour ? 6 : moreThan2 ? 8 : 12} key={i}>
            <QuestionOption
              className={
                animation === i
                  ? hasCorrect
                    ? isCorrect
                      ? 'animated'
                      : 'animatedWrong'
                    : 'animated'
                  : ''
              }
              onClick={() => {
                if (disabled) return
                if (!(isCorrect && isCorrect !== option._id)) {
                  onSelectOption(option._id, form, input.name)
                  setAnimation(i)
                }
              }}
              onAnimationEnd={() => setAnimation(undefined)}
              isActive={
                (currentOption === option._id &&
                  hasCorrect &&
                  option.isCorrect) ||
                (!hasCorrect && currentOption === option._id)
              }
              isWrong={
                currentOption === option._id &&
                hasCorrect &&
                !option.isCorrect
              }
              style={{marginBottom: 0, justifyContent: 'center'}}
            >
              <OptionText
                style={{textAlign: isFour || center ? 'center' : 'inherit'}}
                color={
                  isCorrect && hasCorrect && isCorrect !== option._id
                    ? InactiveTextColor
                    : undefined
                }
              >
                {GetValueLanguage(option.title, activeLanguage?.code)}
              </OptionText>
            </QuestionOption>
          </Col>
        ))}
        <Col xs={24}>
          <ExplanationContainer
            ref={refExplanation}
            isActive={currentOptionObj && currentOptionObj?.explanation}
            bgColor={
              currentOptionObj?.isCorrect ? SuccessBgColor : ErrorBgColor
            }
            borderColor={
              currentOptionObj?.isCorrect ? SuccessBgColor : ErrorBgColor
            }
          >
            {currentOptionObj?.explanation && (
              <QuestionText
                dangerouslySetInnerHTML={{
                  __html: GetValueLanguage(
                    currentOptionObj.explanation,
                    activeLanguage?.code
                  )
                }}
              />
            )}
          </ExplanationContainer>
        </Col>
      </>
    )
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({form}) => (
        <>
          {hasImage ? (
            <IntroductionBlock>
              <QuestionImg src={hasImage} />
            </IntroductionBlock>
          ) : isBig ? (
            <Title25 style={{textAlign: 'center'}}>
              {GetValueLanguage(question.title, activeLanguage?.code)}
            </Title25>
          ) : (
            <QuestionText>
              {GetValueLanguage(question.title, activeLanguage?.code)}
            </QuestionText>
          )}
          <Margin size={isBig ? 50 : 24} />
          <OptionsContainer gutter={[16, 16]}>
            <Field
              name={`${fieldName}.options`}
              component={optionsArray}
              options={question.options}
              form={form}
            />
          </OptionsContainer>
        </>
      )}
    </FormSpy>
  )
}

DichotomousQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  isBig: PropTypes.bool,
  isFour: PropTypes.bool,
  hasCorrect: PropTypes.bool,
  hasImage: PropTypes.string,
  disabled: PropTypes.bool,
  center: PropTypes.bool
}

DichotomousQuestion.defaultProps = {
  onChangeValue: undefined,
  isBig: false,
  isFour: false,
  hasCorrect: false,
  hasImage: undefined,
  disabled: false,
  center: false
}

export default withLocalize(withRouter(DichotomousQuestion))

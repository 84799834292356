import React, {useState} from 'react'

import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'

import Close from '../../assets/icons/close.svg'
import Message from '../../assets/icons/message_grey.svg'
import Pencil from '../../assets/icons/pencil.svg'
import Reply from '../../assets/icons/reply.svg'
import {
  CreateSubComment,
  EditSubComment,
  EditComment
} from '../../infra/requests/ForumRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {
  GetUserPhoto,
  getUserNameForum
} from '../../infra/utils/CommonFunctions'
import {Margin, PageForm} from '../../styles/BasicStyles'
import {LightGrey, White, InputBorderColour} from '../../styles/Colours'
import {Body, BodyText} from '../../styles/TextSizes'
import {BaseButton} from '../buttons/BaseButton'
import TextAreaInput from '../inputs/TextAreaInput'
import {
  CommentContainer,
  ThreadCol1,
  UserCommentImage,
  ThreadDetail,
  MessageContainer,
  SubCommentContainer,
  UserSubCommentImage,
  ReplyBox,
  EditIcon,
  TitleEditable
} from './ThreadStyles'

const formRules = FormValidator.make({
  description: 'required'
})

const CommentsComponent = ({
  comments,
  isSub,
  translate,
  onChangeComment,
  parent,
  index
}) => {
  const [reply, setReply] = useState(false)
  const [editable, setEditable] = useState(false)

  const user = useSelector((state) => state.user)
  const isEducator = user.type === 3

  const renderSubComments = (subComments, p, k) => {
    if (!subComments || !subComments.length) return null
    return (
      <>
        <CommentComponent
          comments={subComments}
          isSub
          onChangeComment={onChangeComment}
          parent={p}
          index={k}
        />
      </>
    )
  }

  const onClickReply = (values) => {
    if (!reply) setReply(values)
    else if (reply.id !== values.id) {
      setReply(values)
    } else setReply(false)
  }

  const isReply = (c) => {
    if (!reply) return false

    if (reply.id && reply.id === c._id) return true

    return false
  }

  const onSubmit = async (values) => {
    try {
      const fields = {...values}
      const id = values.parent || reply.id
      if (values.parent) fields.parent = reply.id
      const result = await CreateSubComment(id, fields)

      if (result.success) {
        onChangeComment(result.data, true)
        setReply(false)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  const renderAddComment = () => (
    <Form
      key={Math.random()}
      onSubmit={onSubmit}
      validate={formRules}
      mutators={{
        ...arrayMutators
      }}
      initialValues={{
        description: undefined,
        pending: false,
        pendingUser: false,
        parent: parent?._id || undefined
      }}
    >
      {({handleSubmit, form, pristine, valid, submitting}) => (
        <PageForm
          id={`form${Math.random()}`}
          style={{
            display: 'flex',
            marginBottom: 16
          }}
          onSubmit={(event) => {
            const promise = handleSubmit(event).then(() => {
              form.reset()
            })
            return promise
          }}
        >
          <Field
            typeText='text'
            name='description'
            component={TextAreaInput}
            maxRows={3}
            minRows={1}
            bgColor={White}
            isSmall
            noMargin
            placeholder={translate(
              isEducator
                ? 'CRIA_AQUI_O_TEU_COMENTARIO_EDUCADOR'
                : 'CRIA_AQUI_O_TEU_COMENTARIO'
            )}
          />
          <BaseButton
            disabled={(submitting || pristine) && !valid}
            style={{
              width: 'auto',
              marginLeft: 8,
              minWidth: 'fit-content',
              height: 'fit-content'
            }}
            align='right'
            htmlType='submit'
            type='primaryMedium'
            text={translate('ADICIONAR')}
          />
        </PageForm>
      )}
    </Form>
  )

  const onEditComment = async (values) => {
    try {
      const result = await (values.parent
        ? EditSubComment(values.parent, values.id, {
            description: values.description
          })
        : EditComment(values.id, {description: values.description}))
      if (result.success) {
        setEditable(false)
        onChangeComment(result.data, values.parent || false, true)
      }
      setEditable(false)
    } catch (e) {
      console.warn(e)
    }
  }

  const renderCommentContent = (comment, parent) => {
    const isAuthor = user._id === comment?.author?._id

    if (editable && editable === comment._id) {
      return (
        <Form
          onSubmit={onEditComment}
          validate={formRules}
          initialValues={{
            id: comment._id,
            description: comment.description,
            parent: parent?._id || undefined
          }}
        >
          {({handleSubmit, valid, submitting, pristine}) => (
            <PageForm onSubmit={handleSubmit}>
              <TitleEditable isComment>
                <Body weight={600}>
                  {getUserNameForum(comment?.author?.name)}
                  {comment.edited && (
                    <Body
                      color={InputBorderColour}
                      weight='bold'
                      style={{marginLeft: 16}}
                    >
                      ({translate('EDITADO')})
                    </Body>
                  )}
                </Body>
              </TitleEditable>
              <BodyText>{moment(comment.updatedAt).fromNow()}</BodyText>
              <Margin size={8} />
              <Field
                typeText='text'
                name='description'
                component={TextAreaInput}
                maxRows={20}
                minRows={1}
                bgColor={White}
                isSmall
                noMargin
                placeholder={translate('CRIA_AQUI_O_TEU_COMENTARIO')}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 8
                }}
              >
                <BaseButton
                  disabled={!valid || submitting || pristine}
                  style={{
                    minWidth: 'fit-content',
                    width: 'auto',
                    marginLeft: 8
                  }}
                  htmlType='submit'
                  type='primaryMedium'
                  text={translate('ATUALIZAR')}
                />
                <EditIcon
                  style={{alignSelf: 'center'}}
                  src={Close}
                  onClick={() => setEditable(false)}
                />
              </div>
            </PageForm>
          )}
        </Form>
      )
    }
    return (
      <>
        <TitleEditable isComment>
          <Body weight={600}>
            {getUserNameForum(comment?.author?.name)}
            {comment.edited && (
              <Body
                color={InputBorderColour}
                weight='bold'
                style={{marginLeft: 16}}
              >
                ({translate('EDITADO')})
              </Body>
            )}
          </Body>
          {isAuthor && (
            <EditIcon
              isComment
              src={Pencil}
              onClick={() => setEditable(comment._id)}
            />
          )}
        </TitleEditable>
        <BodyText>{moment(comment.updatedAt).fromNow()}</BodyText>
        <Margin size={8} />
        <Body>{comment.description}</Body>
      </>
    )
  }

  return (
    <>
      {comments.map((comment, i) => {
        const subComments =
          comment.comments && comment.comments.length > 0
            ? comment.comments.filter(
                (c) => !c.parent && !c.pending && !c.pendingUser
              )
            : isSub
            ? parent.comments.filter(
                (c) =>
                  c.parent === comment._id && !c.pending && !c.pendingUser
              )
            : undefined
        const totalComments = subComments?.length || 0

        return (
          <div key={i}>
            <CommentContainer
              isSub={isSub}
              index={index}
              hasSub={totalComments}
            >
              <ThreadCol1 xs={24}>
                {isSub ? (
                  <UserSubCommentImage
                    src={GetUserPhoto(comment.author)}
                  />
                ) : (
                  <UserCommentImage src={GetUserPhoto(comment.author)} />
                )}
                <ThreadDetail>
                  {renderCommentContent(comment, parent)}
                  <Margin size={16} />
                  <MessageContainer style={{marginLeft: 0}}>
                    <img
                      alt='message'
                      src={Message}
                      style={{marginRight: 8}}
                    />
                    <Body color={LightGrey}>
                      {totalComments || 0}{' '}
                      {translate(
                        totalComments === 1 ? 'COMENTARIO' : 'COMENTARIOS'
                      )}
                    </Body>
                    <ReplyBox
                      onClick={() => onClickReply({id: comment._id})}
                    >
                      <img
                        alt='reply'
                        src={Reply}
                        style={{marginRight: 8}}
                      />
                      <Body color={LightGrey}>{translate('REPLY')}</Body>
                    </ReplyBox>
                  </MessageContainer>
                  <Margin size={16} />
                </ThreadDetail>
              </ThreadCol1>
            </CommentContainer>
            {reply && isReply(comment) && (
              <SubCommentContainer index={index} hasSub={totalComments}>
                <ThreadCol1 xs={24}>
                  <UserSubCommentImage src={GetUserPhoto(user)} />
                  {renderAddComment()}
                </ThreadCol1>
              </SubCommentContainer>
            )}
            {renderSubComments(
              subComments,
              parent || comment,
              (index || 0) + 1
            )}
          </div>
        )
      })}
    </>
  )
}

CommentsComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  comments: PropTypes.array.isRequired,
  isSub: PropTypes.bool,
  onChangeComment: PropTypes.func.isRequired,
  parent: PropTypes.object,
  index: PropTypes.number
}

CommentsComponent.defaultProps = {
  isSub: false,
  parent: undefined,
  index: undefined
}

const CommentComponent = withLocalize(CommentsComponent)

export default CommentComponent

import React from 'react'

import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import FooterComponent from '../footer/FooterComponent'
import HeaderComponent from '../header/HeaderComponent'
import {
  Layout,
  Dashboard,
  MainContainer,
  MainBasicContainer
} from './LayoutStyles'

const LayoutComponent = ({fixed, auth, basicHeader, children}) => (
  <Layout>
    {basicHeader ? (
      <MainBasicContainer>{children}</MainBasicContainer>
    ) : (
      <>
        <HeaderComponent auth={auth} />
        <MainContainer fixed={fixed}>
          <Dashboard>{children}</Dashboard>
        </MainContainer>
        <FooterComponent />
      </>
    )}
  </Layout>
)

LayoutComponent.propTypes = {
  fixed: PropTypes.bool,
  basicHeader: PropTypes.bool,
  children: PropTypes.object.isRequired,
  auth: PropTypes.bool
}
LayoutComponent.defaultProps = {
  fixed: false,
  basicHeader: false,
  auth: false
}

export default connect()(withRouter(LayoutComponent))

import styled from 'styled-components'

import {MenuBorderColor, SecondaryColour} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const ProgramContainer = styled.div`
  background-color: ${SecondaryColour};
  display: flex;
  width: 100%;
  background-image: url(${require('../../assets/backgrounds/background_program.png')});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
`

export const SessionsContainer = styled.div`
  width: 100%;
  margin-top: 70px;

  .ant-collapse
    > .ant-collapse-item.ant-collapse-no-arrow
    > .ant-collapse-header {
    padding: 0px;
  }

  .ant-collapse-borderless {
    background: transparent;

    .ant-collapse-item {
      border: none;
      margin-bottom: 48px;

      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0px;
      }
    }
  
  @media ${device.tablet} {
    margin-top: 0px;
  }
`

export const SessionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const SessionContainer = styled.div`
  position: relative;
  padding: 16px;
  margin-bottom: 48px;
  margin-left: ${(p) => (p.marginL ? 417 : 77)}px;
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  cursor: ${(active) => (active ? 'pointer' : 'auto')};

  @media ${device.laptop} {
    margin-left: 0px;
    width: 100%;
  }
`

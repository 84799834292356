import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_diario_meditacao.png'
import {GetProgramName, GetStepName} from '../../infra/utils/CommonFunctions'
import {
  MainContainerBackground,
  MainContainerScroll,
  MainContainer
} from '../../pages/step/StepStyles'
import {ThirdColour} from '../../styles/Colours'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'
import MeditatecaComponent from './MeditatecaComponent'

const MeditateComponent = ({step, chapter, nextStep, history}) => {
  const {info} = useSelector((state) => state)
  const stepName = GetStepName(info.program)
  const programName = GetProgramName(info.program)

  return (
    <>
      <BasicHeaderComponent
        onClose={() => history.push(`/${programName}`)}
        title={chapter.title}
        progress={(step.order / (chapter.steps.length + 6)) * 100}
      />
      <MainContainerBackground
        bgColor={ThirdColour}
        style={{
          backgroundImage: `url(${Illustration})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      >
        <MainContainerScroll>
          <MainContainer>
            {step.descriptions.map((description, index) => (
              <MessageComponent
                key={index}
                text={description.title}
                active={index === step.descriptions.length - 1}
              />
            ))}
            <MeditatecaComponent
              next={`/${stepName}/${
                nextStep._id
              }`}
              progress={{
                number: step.order,
                total: chapter.steps.length + 6
              }}
              isDiarioMeditacao={chapter.order === 5 ? chapter : false}
              isBiblioteca={chapter.order === 6}
            />
          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackground>
      <BasicFooterComponent onBack={() => history.goBack()} />
    </>
  )
}

MeditateComponent.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object
}

MeditateComponent.defaultProps = {
  nextStep: undefined,
  chapter: undefined
}

export default withLocalize(withRouter(MeditateComponent))

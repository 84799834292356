import React, {useEffect} from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Meditate from '../../assets/illustrations/illustration_meditate.svg'
import ChartMeditation from '../../components/charts/ChartMeditation'
import MeditatecaComponent from '../../components/diario_meditaçao/MeditatecaComponent'
import {White, Lilac} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import {DashboardContainer} from '../dashboard/DashboardStyles'
import {MeditationContainer, MeditationImg} from './MeditationStyles'

const MeditationPage = ({translate}) => {
  const {
    user
  } = useSelector((state) => state)
  const isEducator = user.type === 3

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <MeditationContainer>
      <DashboardContainer>
        <div>
          <Row style={{flex: 1}} gutter={[24]}>
            <Col xs={24} md={12} style={{textAlign: 'left'}}>
              <TitleH3 weight={700} color={White}>
                {translate('MEDITAÇAO')}
              </TitleH3>
              <MeditatecaComponent
                color={Lilac}
                height={722}
                next='isBack'
                back='isBack'
                isBiblioteca
              />
            </Col>
            <Col
              xs={24}
              md={12}
              style={{flex: 1, display: 'flex', flexDirection: 'column'}}
            >
              <MeditationImg src={Meditate} />
              {!isEducator &&
              <ChartMeditation />
              }
            </Col>
          </Row>
        </div>
      </DashboardContainer>
    </MeditationContainer>
  )
}

MeditationPage.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(withRouter(MeditationPage))

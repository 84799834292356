import React from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Check from '../../assets/icons/check_black.svg'
import Message from '../../assets/icons/message.svg'
import User from '../../assets/icons/user.svg'
import {BaseButton} from '../../components/buttons/BaseButton'
import EmptyLayoutComponent from '../../components/empty_layout/EmptyLayoutComponent'
import ThreadComponent from '../../components/thread/ThreadComponent'
import {GetThreads} from '../../infra/requests/ForumRequests'
import {getUserForum} from '../../infra/utils/CommonFunctions'
import {LoadingSpin, Margin} from '../../styles/BasicStyles'
import {Body} from '../../styles/TextSizes'
import {DashboardContainer} from '../dashboard/DashboardStyles'
import {
  ForumContainer,
  ForumRow,
  ForumButton,
  ForumCol
} from './ForumStyles'

const limit = 12

class ForumPage extends React.Component {
  constructor(props) {
    super(props)
    this.scrollComponent = null
    this.state = {
      isLoading: false,
      threads: [],
      page: 1,
      total: undefined,
      activeSection: 'all'
    }
  }

  componentDidMount = () => {
    this.getThreads()

    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  getThreads = async () => {
    const {page, isLoading, total, threads, activeSection} = this.state
    const {user} = this.props
    if (isLoading || total <= threads.length) return
    this.setState({isLoading: true})
    try {
      const userForum = getUserForum(user)
      const author = activeSection !== 'all' ? user._id : undefined
      const result = await GetThreads(page, limit, author, userForum)
      if (result.success) {
        const newThreads = [...threads, ...result.data.items]
        this.setState({
          isLoading: false,
          threads: newThreads,
          page: page + 1,
          total: result.data.total
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }

  renderForum = () => {
    const {threads, total} = this.state
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.getThreads}
        hasMore={total > threads.length}
        loader={<LoadingSpin />}
      >
        {threads.map((thread, i) => (
          <ThreadComponent key={i} thread={thread} />
        ))}
      </InfiniteScroll>
    )
  }

  onChangeSection = (type) => {
    this.setState(
      {
        activeSection: type,
        page: 1,
        total: undefined,
        threads: []
      },
      this.getThreads
    )
  }

  render() {
    const {threads, isLoading, activeSection} = this.state
    const {translate, history, user} = this.props
    const isEducator = user.type === 3

    return (
      <ForumContainer>
        <DashboardContainer>
          <ForumRow gutter={{xs: 24, lg: 48, xl: 124}}>
            {/* {isLoading ? (
              <LoadingSpin />
            ) : ( */}
            <>
              <Col xs={24} lg={16}>
                {isLoading || threads.length ? (
                  this.renderForum()
                ) : (
                  <EmptyLayoutComponent tag='MENSAGEM_FORUM_VAZIO' />
                )}
              </Col>
              <ForumCol xs={24} md={12} lg={8}>
                <BaseButton
                  onClick={() => history.push('/forum/add')}
                  style={{width: 'auto', minWidth: 'fit-content'}}
                  htmlType='button'
                  type='primaryMedium'
                  text={translate('COMEÇAR_NOVA_DISCUSSAO')}
                />
                <Margin size={24} />
                <ForumButton onClick={() => this.onChangeSection('all')}>
                  <img
                    alt='message'
                    style={{marginRight: 8}}
                    src={Message}
                  />
                  <Body weight={activeSection === 'all' ? 'bold' : 400}>
                    {translate('TODAS_AS_DISCUSSOES')}
                  </Body>
                  {activeSection === 'all' && (
                    <img
                      alt='message'
                      src={Check}
                      style={{marginLeft: 'auto'}}
                    />
                  )}
                </ForumButton>
                <ForumButton onClick={() => this.onChangeSection('user')}>
                  <img alt='check' style={{marginRight: 8}} src={User} />
                  <Body weight={activeSection === 'user' ? 'bold' : 400}>
                    {translate(
                      isEducator
                        ? 'CRIADAS_POR_TI_EDUCADOR'
                        : 'CRIADAS_POR_TI'
                    )}
                  </Body>
                  {activeSection === 'user' && (
                    <img
                      alt='check'
                      src={Check}
                      style={{marginLeft: 'auto'}}
                    />
                  )}
                </ForumButton>
              </ForumCol>
            </>
            {/* )} */}
          </ForumRow>
        </DashboardContainer>
      </ForumContainer>
    )
  }
}

ForumPage.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(
  withRouter(connect(mapStateToProps)(ForumPage))
)

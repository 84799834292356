import React, {useState} from 'react'

import {PlusOutlined} from '@ant-design/icons'
import {Divider, Input} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {StyledSelectInput, StyledOption} from './InputStyles'
import {
  MaterialInputSection,
  MaterialLabel,
  MaterialInputBox
} from './MaterialStyles'

const filterOption = (inputValue, option) => {
  if (option?.props?.children) {
    return (
      option?.props?.children
        .toLowerCase()
        .indexOf(inputValue.toLowerCase()) > -1
    )
  }
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
}

const SelectInput = ({
  input,
  items,
  placeholder,
  label,
  disabled,
  fetchData,
  loading,
  meta,
  allowClear,
  tag,
  noMargin,
  translate,
  noTranslate,
  showSearch,
  onChange,
  addNewItem
}) => {
  const [name, setName] = useState(undefined)
  const {invalid, submitFailed} = meta
  const showError = invalid && submitFailed

  const onNameChange = (event) => {
    if (event.target.value) setName(event.target.value)
  }

  const formatValue = (value) => {
    if (!value) return undefined
    if (Array.isArray(value)) return value
    return value.toString()
  }

  const changeSelect = (value) => {
    input.onChange(value || '')
    onChange(value)
  }

  return (
    <MaterialInputSection noMargin={noMargin}>
      {label && (
        <MaterialLabel tag={tag} showError={showError}>
          {label}
        </MaterialLabel>
      )}
      <MaterialInputBox noMargin={noMargin}>
        <StyledSelectInput
          disabled={disabled}
          placeholder={placeholder}
          filterOption={filterOption}
          allowClear={allowClear}
          onSearch={fetchData}
          onChange={changeSelect}
          value={formatValue(input?.value)}
          loading={loading}
          error={showError ? 1 : 0}
          showSearch={showSearch}
          dropdownRender={
            !addNewItem
              ? undefined
              : (menu) => (
                  <div>
                    {menu}
                    <Divider style={{margin: '4px 0'}} />
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        padding: 8
                      }}
                    >
                      <Input
                        style={{flex: 'auto'}}
                        value={name}
                        onChange={onNameChange}
                      />
                      <a
                        style={{
                          flex: 'none',
                          padding: '8px',
                          display: 'block',
                          cursor: 'pointer'
                        }}
                        onClick={() => addNewItem(name)}
                      >
                        <PlusOutlined /> {translate('OTHER')}
                      </a>
                    </div>
                  </div>
                )
          }
        >
          {items.map((elem, index) => (
            <StyledOption
              key={index}
              disabled={elem.disabled}
              value={elem._id}
              title={noTranslate ? elem.name : translate(elem.name)}
            >
              {noTranslate
                ? elem.name
                : elem.noTranslatable
                ? elem.name
                : translate(elem.name)}
            </StyledOption>
          ))}
        </StyledSelectInput>
      </MaterialInputBox>
    </MaterialInputSection>
  )
}

SelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  allowClear: PropTypes.bool,
  tag: PropTypes.string,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  noTranslate: PropTypes.bool,
  showSearch: PropTypes.bool,
  onChange: PropTypes.func,
  addNewItem: PropTypes.func
}

SelectInput.defaultProps = {
  allowClear: false,
  label: undefined,
  placeholder: undefined,
  fetchData: () => {},
  loading: false,
  tag: undefined,
  disabled: false,
  noMargin: false,
  noTranslate: false,
  showSearch: false,
  onChange: () => {},
  addNewItem: undefined
}

export default withLocalize(SelectInput)

import React from 'react'

import PropTypes from 'prop-types'

import {InputDiv, CheckboxField} from './InputStyles'
import {MaterialCheckboxLabel} from './MaterialStyles'
import {Body} from '../../styles/TextSizes'
import {withLocalize} from 'react-localize-redux'

const CheckboxInput = ({
  input,
  meta,
  label,
  first,
  disabled,
  checked,
  style,
  checkboxType,
  onClick,
  onClickLabel,
  translate
}) => {
  const {invalid, submitFailed} = meta
  const showError = invalid && submitFailed

  const labelClick = e => {
    if (onClickLabel) {
      e.preventDefault()
      e.stopPropagation()
      onClickLabel()
    }
  }

  return (
    <InputDiv first={first}>
      <CheckboxField
        disabled={disabled}
        style={style}
        checked={input.value || checked}
        onChange={input.onChange}
        error={showError ? 1 : 0}
        checkboxType={checkboxType}
        onClick={onClick}
      >
        {label ? (
          <Body style={{marginLeft: 16}}>
            {translate('ACCEPT_TERMS')}
            <MaterialCheckboxLabel
              clickable={onClickLabel}
              showError={showError}
              onClick={labelClick}
            >
              {label}
            </MaterialCheckboxLabel>
          </Body>
        ) : null}
      </CheckboxField>
    </InputDiv>
  )
}

CheckboxInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  checkboxType: PropTypes.string,
  onClick: PropTypes.func,
  translate: PropTypes.func.isRequired,
  first: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  style: PropTypes.object,
  onClickLabel: PropTypes.func
}

CheckboxInput.defaultProps = {
  checkboxType: 'square',
  onClick: () => {},
  onClickLabel: () => {},
  label: undefined,
  first: false,
  disabled: false,
  checked: false,
  style: undefined
}

export default withLocalize(CheckboxInput)

import React, {useEffect, useRef, useState} from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Close from '../../assets/icons/close.svg'
import IcCoroa from '../../assets/icons/ic_coroa.svg'
import IcMoeda from '../../assets/icons/moeda.svg'
import IcMoedaLilac from '../../assets/icons/moeda_lilac.svg'
import {
  GetValueLanguage,
  GetUserPhoto
} from '../../infra/utils/CommonFunctions'
import {PrimaryColour, Lilac} from '../../styles/Colours'
import {
  HeaderContent,
  BasicHeader,
  HeaderTitle,
  IconClose,
  ProgressBar,
  ProgressBarFill,
  Icon,
  IconsContainer,
  IconText,
  HeaderIcons,
  UserImg
} from './HeaderStyles'
import LanguageSelector from './LanguageSelector'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const BasicHeaderComponent = ({
  history,
  onClose,
  progress,
  title,
  activeLanguage,
  progressColor
}) => {
  const [animation, setAnimation] = useState(undefined)
  const {user} = useSelector((state) => state)
  const isStudent = user.type === 4
  const prevPoints = usePrevious(user.points)

  useEffect(() => {
    if (prevPoints && prevPoints !== user.points) {
      setAnimation(true)
    }
  }, [user])

  return (
    <BasicHeader>
      <HeaderContent>
        <IconClose
          src={Close}
          onClick={onClose || (() => history.push('/'))}
        />
        <Col
          xs={isStudent ? 12 : 16}
          md={isStudent ? 14 : 18}
          lg={isStudent ? 14 : 20}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <HeaderTitle>
            {GetValueLanguage(title, activeLanguage?.code)}
          </HeaderTitle>
        </Col>
        {
          isStudent ? (
            <HeaderIcons show>
              <IconsContainer
                show
                className={animation ? 'animated' : ''}
                onAnimationEnd={() => setAnimation(undefined)}
              >
                <Icon src={animation ? IcMoedaLilac : IcMoeda} />
                <IconText color={animation ? Lilac : PrimaryColour}>
                  {user.points}
                </IconText>
              </IconsContainer>
              <IconsContainer show>
                <Icon src={IcCoroa} />
                <IconText color='#F38231'>{user.totalCrowns}</IconText>
              </IconsContainer>
              <LanguageSelector />
              <IconsContainer last show>
                <UserImg src={GetUserPhoto(user)} />
              </IconsContainer>
            </HeaderIcons>
          )
          :
          (
            <HeaderIcons show>
              <LanguageSelector />
            </HeaderIcons>
          )
        }
      </HeaderContent>
      <Col xs={24}>
        <ProgressBar>
          <ProgressBarFill
            progress={progress}
            progressColor={progressColor}
          />
        </ProgressBar>
      </Col>
    </BasicHeader>
  )
}

BasicHeaderComponent.propTypes = {
  history: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  progress: PropTypes.number,
  title: PropTypes.object,
  progressColor: PropTypes.string
}

BasicHeaderComponent.defaultProps = {
  onClose: () => {},
  progress: 0,
  title: undefined,
  progressColor: undefined
}

export default withLocalize(withRouter(BasicHeaderComponent))

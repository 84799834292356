import styled from 'styled-components'

import {
  White,
  SecondaryColour,
  ThirdColour,
  InputBorderColour,
  Lilac,
  MenuBorderColor
} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const OptionsContainer = styled.div`
  display: inline-block;
`

export const OptionsSelect = styled.div`
  background: ${({isSelected}) => (isSelected ? SecondaryColour : White)};
  color: ${({isSelected}) => (isSelected ? White : SecondaryColour)};
  border: 2px solid ${InputBorderColour};
  box-sizing: border-box;
  border-radius: 30px;
  padding: 8px 24px;
  margin: 8px;
  cursor: pointer;
  float: left;

  &:hover {
    background: ${SecondaryColour};
    color: ${White};
  }
`
export const MeditatecaContainer = styled.div`
  z-index: 1;
  max-width: 626px;
  margin: auto;
  margin-top: 32px;
  height: 100%;
  background: ${({bgColor}) => bgColor || Lilac};
  border-radius: 8px;
  max-height: ${({height}) => height || 550}px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const MeditatecaScrollBox = styled.div`
  overflow-y: auto;
  padding: 24px;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tabletMin} {
    padding: 16px;
  }
`

export const AudioLineComponent = styled.div`
  z-index: 1;
  width: 100%;
  margin-bottom: ${({isLast}) => (isLast ? '0px' : '24px')};
  padding: 24px;
  background: ${White};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media ${device.tablet} {
    margin-bottom: ${({isLast}) => (isLast ? '0px' : '16px')};
    padding: 16px;
  }
`

export const MeditatecaShadow = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: ${(p) =>
    p.isYellow ?
    'linear-gradient(180deg, rgba(255, 191, 0, 0) 3%, rgba(255, 191, 0, 0.9) 50%, #FFBF00 100%)'
    : p.isOrange
      ? 'linear-gradient(180deg, rgba(243, 130, 49, 0) 3%, rgba(243, 130, 49, 0.9) 50%, #F38231 100%)'
      : 'linear-gradient(180deg, rgba(195, 190, 247, 0) 3%, rgba(195, 190, 247, 0.9) 50%, #c3bef7 100%)'};
  height: 55px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 2;
`

export const diarioHumor = '601d3dd8a3a7f104af6df33e'
export const diarioBordo = '602d0ad19e89e9063f6b539a'
export const agendaAtividades = '6022672c3b2f321a42d2e88b'
export const agendaAtividadesFirst = '602263f93b2f321a42d2e887'
export const registoObjetivos = '602a45683ed3fe04664283f1'
export const registoObjetivosFirst = '602a43cd3ed3fe04664283e2'
export const forumJovens = '603e191d3222800e9d983904'
export const forumEducadores = '603e192a3222800e9d983905'
export const programaJovens = '5fe1d835dc0ba87334bb1710'
export const programaEducadores = '607408b1461229668639dee5'
export const sessaoReforçoJovens = '606db5ef02b167aec387f4a3'
export const sessaoReforçoEducadores = '607081ba36cfb401f61cdb65'
export const sessaoExpressJovens = '6080532192b7e1fddd9f4d34'
export const sessaoExpressEducadores = '6080535f727d3002058628ed'

import React from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {Margin} from '../../styles/BasicStyles'
import {SecondaryColour} from '../../styles/Colours'
import {Body} from '../../styles/TextSizes'
import {BaseButton} from '../buttons/BaseButton'
import {TableCol, ValidationContainer} from './AtividadesLazerStyles'

const ValidateComponent = ({onClickValidate, onClickNo, translate}) => (
  <TableCol>
    <ValidationContainer>
      <Body color={SecondaryColour}>
        {translate('REALIZASTE_A_ATIVIDADE')}
      </Body>
      <Margin size={8} />
      <Row gutter={[8, 8]}>
        <Col xs={12}>
          <BaseButton
            htmlType='button'
            type='primaryMedium'
            text={translate('SIM')}
            onClick={onClickValidate}
          />
        </Col>
        <Col xs={12}>
          <BaseButton
            htmlType='button'
            type='default'
            text={translate('NAO')}
            onClick={onClickNo}
          />
        </Col>
      </Row>
    </ValidationContainer>
  </TableCol>
)

ValidateComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  onClickNo: PropTypes.func.isRequired
}

export default withLocalize(withRouter(ValidateComponent))

import React, {useState, useEffect} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {VictoryChart, VictoryBar, VictoryGroup, VictoryAxis} from 'victory'

import {GetChartMeditation} from '../../infra/requests/ProgramRequests'
import {SeeAllButton} from '../../pages/dashboard/DashboardStyles'
import {
  MenuBorderColor,
  HoverBorderColor,
  SecondaryColour,
  ThirdColour
} from '../../styles/Colours'
import {BodyText} from '../../styles/TextSizes'
import {
  ChartBox,
  LegendLine,
  BoxColor,
  LegendMeditationContainer,
  ChartTitle,
  ChartRow
} from './ChartsStyles'

const daysOfWeek = [
  'SEGUNDA',
  'TERÇA',
  'QUARTA',
  'QUINTA',
  'SEXTA',
  'SABADO',
  'DOMINGO'
]

const dataStatic = [
  [
    {x: 1, y: 0},
    {x: 2, y: 0},
    {x: 3, y: 0},
    {x: 4, y: 0},
    {x: 5, y: 0},
    {x: 6, y: 0},
    {x: 7, y: 0}
  ],
  [
    {x: 1, y: 0},
    {x: 2, y: 0},
    {x: 3, y: 0},
    {x: 4, y: 0},
    {x: 5, y: 0},
    {x: 6, y: 0},
    {x: 7, y: 0}
  ]
]

const ChartMeditationComponent = ({
  isStatic,
  previewChart,
  translate,
  seeAll,
  week
}) => {
  const user = useSelector(state => state.user)
  const isEducator = user.type === 3
  const [data, setData] = useState(dataStatic)

  useEffect(() => {
    async function getData() {
      let date = moment.utc()
      if (week) date = week
      const dateWeekStart = moment
        .utc(date)
        .clone()
        .startOf('isoWeek')
        .format('YYYY-MM-DD')
      const dateWeekEnd = date
        .clone()
        .endOf('week')
        .format('YYYY-MM-DD')
      const result = await GetChartMeditation(dateWeekStart, dateWeekEnd)

      if (result.success) {
        const newDataA = []
        const newDataB = []
        result.data.forEach((value, i) => {
          newDataA.push({x: i + 1, y: value.beforeAverage})
          newDataB.push({x: i + 1, y: value.afterAverage})
        })
        setData([newDataA, newDataB])
      }
    }
    getData()
  }, [isStatic, week])

  const renderChart = () => (
    <VictoryChart
      style={{width: '100%', height: 375}}
      domain={{x: [0, 8], y: [0, 10]}}
    >
      <VictoryAxis
        style={{
          axis: {stroke: MenuBorderColor},
          tickLabels: {
            fill: HoverBorderColor,
            fontSize: 14,
            fontFamily: 'Poppins'
          },
          grid: {stroke: MenuBorderColor}
        }}
        tickFormat={value =>
          value
            ? value === 0 || value === 8
              ? ''
              : translate(daysOfWeek[value - 1]).slice(0, 3)
            : undefined
        }
        tickValues={[0, 1, 2, 3, 4, 5, 6, 7]}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axis: {stroke: MenuBorderColor},
          tickLabels: {
            fill: HoverBorderColor,
            fontSize: 14,
            fontFamily: 'Poppins'
          },
          grid: {stroke: MenuBorderColor}
        }}
      />
      <VictoryAxis
        dependentAxis
        offsetX={400}
        style={{
          axis: {stroke: MenuBorderColor},
          tickLabels: {
            fill: 'transparent',
            fontSize: 14,
            fontFamily: 'Poppins'
          },
          grid: {stroke: MenuBorderColor}
        }}
        tickValues={undefined}
      />
      <VictoryGroup
        offset={10}
        colorScale={[SecondaryColour, ThirdColour]}
      >
        <VictoryBar data={data[0]} />
        <VictoryBar data={data[1]} />
      </VictoryGroup>
    </VictoryChart>
  )

  const renderLegend = () => (
    <LegendMeditationContainer>
      <Row gutter={[16]}>
        <Col sm={24} md={12}>
          <LegendLine>
            <BoxColor />
            <BodyText>
              {translate(
                isEducator
                  ? 'LEGENDA_GRAFICO_MEDITAÇAO_ANTES_EDUCADOR'
                  : 'LEGENDA_GRAFICO_MEDITAÇAO_ANTES'
              )}
            </BodyText>
          </LegendLine>
        </Col>
        <Col sm={24} md={12}>
          <LegendLine>
            <BoxColor color={ThirdColour} />
            <BodyText>
              {translate(
                isEducator
                  ? 'LEGENDA_GRAFICO_MEDITAÇAO_DEPOIS_EDUCADOR'
                  : 'LEGENDA_GRAFICO_MEDITAÇAO_DEPOIS'
              )}
            </BodyText>
          </LegendLine>
        </Col>
      </Row>
    </LegendMeditationContainer>
  )

  return (
    <ChartBox
      isStatic={isStatic}
      style={{height: 'auto', flex: 1, marginBottom: isStatic ? 0 : 24}}
      borderColor={previewChart ? 'transparent' : undefined}
    >
      <ChartRow gutter={[16, 16]}>
        {previewChart && (
          <Row style={{width: '100%'}}>
            {seeAll ? (
              <>
                <Col xs={12} md={12} lg={18}>
                  <ChartTitle margin={-14}>
                    {translate('GRAFICO_MEDITAÇAO')}
                  </ChartTitle>
                </Col>
                <Col xs={12} md={12} lg={6} style={{textAlign: 'right'}}>
                  <SeeAllButton
                    color={ThirdColour}
                    onClick={seeAll}
                    style={{padding: '24px 24px 0px 0px'}}
                  >
                    {translate('SEE_ALL')}
                  </SeeAllButton>
                </Col>
              </>
            ) : (
              <ChartTitle margin={-14}>
                {translate('GRAFICO_MEDITAÇAO')}
              </ChartTitle>
            )}
          </Row>
        )}
        {isStatic ? (
          <Row gutter={[16, 16]}>
            <Col xs={24} md={16}>
              {renderChart()}
            </Col>
            <Col xs={24} md={8}>
              <LegendMeditationContainer style={{marginTop: 50}}>
                <LegendLine>
                  <BoxColor />
                  <BodyText>
                    {translate('LEGENDA_GRAFICO_MEDITAÇAO_ANTES')}
                  </BodyText>
                </LegendLine>
                <LegendLine>
                  <BoxColor color={ThirdColour} />
                  <BodyText>
                    {translate('LEGENDA_GRAFICO_MEDITAÇAO_DEPOIS')}
                  </BodyText>
                </LegendLine>
              </LegendMeditationContainer>
            </Col>
          </Row>
        ) : (
          <>
            {renderChart()}
            {renderLegend()}
          </>
        )}
      </ChartRow>
    </ChartBox>
  )
}

ChartMeditationComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  isStatic: PropTypes.bool,
  seeAll: PropTypes.func,
  previewChart: PropTypes.bool,
  week: PropTypes.object
}

ChartMeditationComponent.defaultProps = {
  isStatic: false,
  seeAll: undefined,
  previewChart: false,
  week: undefined
}

export default withLocalize(withRouter(ChartMeditationComponent))

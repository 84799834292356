import client from '../config/AxiosConfig'

export const GetInfo = async () => client.get('/info/fo')

export const GetTranslations = async () =>
  client.get('/translations/platform/FO')

export const GetDynamicPage = async id =>
  client.get(`/dynamic_pages/${id}`)

export const GetSchools = async () => client.get('/schools')

export const GetClasses = async id =>
  client.get(`/schools_classes/school/${id}`)

export const SendHelpMessage = async fields =>
  client.post('/support/assistance', fields)

export const ChangeLanguage = async data =>
  client.post('/users/change-language', data)

import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_help.svg'
import {GetProgramName, GetStepName, GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {
  MainContainer,
  MainContainerBackgroundParent,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {Margin} from '../../styles/BasicStyles'
import {QuestionText} from '../../styles/TextSizes'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'

const StepText = ({
  step,
  activeLanguage,
  history,
  nextStep,
  chapter,
  isFirst
}) => {
const {
  program
} = useSelector((state) => state.info)

const stepName = GetStepName(program)
const programName = GetProgramName(program)

return (
  <>
    <BasicHeaderComponent
      onClose={() => history.push(`/${programName}`)}
      title={chapter.title}
      progress={(step.order / chapter.steps.length) * 100}
    />
    <MainContainerBackgroundParent
      style={{
        backgroundImage: `url(${Illustration})`,
        backgroundPosition: 'bottom right',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <MainContainerScroll>
        <MainContainer>
          {step.descriptions.map((description, index) => (
            <>
            <QuestionText
              key={index}
              dangerouslySetInnerHTML={{
                __html: GetValueLanguage(
                  description.title,
                  activeLanguage && activeLanguage.code
                )
              }}
              isBigger
            />
            <Margin size={32} />
            </>
          ))}
        </MainContainer>
      </MainContainerScroll>
    </MainContainerBackgroundParent>
    <BasicFooterComponent
      onBack={isFirst ? undefined : () => history.goBack()}
      onNext={() =>
        nextStep
          ? history.push(`/${stepName}/${nextStep._id}`)
          : history.push(`/${programName}`)
      }
    />
  </>
)
}

StepText.propTypes = {
  step: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  isFirst: PropTypes.bool
}

StepText.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  isFirst: false
}
export default withLocalize(withRouter(StepText))

import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {
  ForumShadow,
  SeeAllButton,
  ForumBoxScroll
} from '../../pages/dashboard/DashboardStyles'
import {Margin} from '../../styles/BasicStyles'
import {ThirdColour, LightGrey} from '../../styles/Colours'
import {Title18, QuestionText} from '../../styles/TextSizes'
import {ChartTitle} from '../charts/ChartsStyles'
import EmptyLayoutComponent from '../empty_layout/EmptyLayoutComponent'
import BasicComment from '../thread/BasicComment'
import {ThreadDetail, ThreadDescription} from '../thread/ThreadStyles'

const ForumBoxComponent = ({translate, history}) => {
  const {
    info: {forum}
  } = useSelector((state) => state)

  return (
    <>
      <Row gutter={[16]}>
        <Col xs={12}>
          <ChartTitle style={{padding: 0}}>
            {translate('NEW_THEMES')}
          </ChartTitle>
        </Col>
        <Col
          xs={12}
          style={{textAlign: 'right'}}
          onClick={() => history.push('/forum')}
        >
          <SeeAllButton color={ThirdColour}>
            {translate('SEE_ALL')}
          </SeeAllButton>
        </Col>
      </Row>
      <Margin size={16} />
      {forum.threads?.length ? (
        <>
          {forum.threads.map((thread, i) => (
            <ForumBoxScroll key={i}>
              {thread.comments?.length > 0 ? (
                <>
                  <Title18 style={{textAlign: 'left'}}>
                    {thread.title}
                  </Title18>
                  <Margin size={16} />
                  {thread.comments.map((comment, index) => (
                    <BasicComment comment={comment} key={index} />
                  ))}
                </>
              ) : (
                <ThreadDetail>
                  <QuestionText isBold>{thread.title}</QuestionText>
                  <Margin size={8} />
                  <ThreadDescription color={LightGrey}>
                    {thread.description}
                  </ThreadDescription>
                </ThreadDetail>
              )}
            </ForumBoxScroll>
          ))}
        </>
      ) : (
        <EmptyLayoutComponent tag='MENSAGEM_FORUM_VAZIO' />
      )}
      <ForumShadow />
    </>
  )
}

ForumBoxComponent.propTypes = {
  history: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(withRouter(ForumBoxComponent))

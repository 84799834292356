import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {CupContainer, ScoreBox} from '../score/ScoreStyles'
import StarComponent from '../star/StarComponent'

const res = {
  2: ['passivo', 'eficaz', 'agressivo'],
  3: ['passivo', 'eficaz', 'agressivo'],
  4: ['passivo', 'agressivo', 'eficaz'],
  5: ['eficaz', 'agressivo', 'passivo'],
  6: ['passivo', 'eficaz', 'agressivo'],
  7: ['agressivo', 'passivo', 'eficaz']
}
const Score = ({values, steps, step, points, activeLanguage}) => {
  let passivo = 0
  let eficaz = 0
  let agressivo = 0
  values.steps.map((s, i) => {
    s.questions.map((q, j) => {
      const opts = steps[i].questions[j].options
      if (q.options === opts.find((o) => o.isCorrect)._id) {
        eficaz += 1
      } else if (
        res[i][opts.findIndex((o) => o._id === q.options)] === 'passivo'
      ) {
        passivo += 1
      } else {
        agressivo += 1
      }
    })
  })

  let status = 'eficaz'
  if (agressivo > eficaz && agressivo > passivo) {
    status = 'agressivo'
  } else if (passivo > eficaz && passivo > agressivo) {
    status = 'passivo'
  }

  return (
    <>
      <CupContainer style={{backgroundColor: 'transparent'}}>
        <img
          src={require(status === 'eficaz'
            ? '../../assets/9.3/balanca_eficaz.svg'
            : status === 'agressivo'
            ? '../../assets/9.3/balanca_agressivo.svg'
            : '../../assets/9.3/balanca_passivo.svg')}
          style={{width: '100%'}}
        />
      </CupContainer>
      <ScoreBox>
        {GetValueLanguage(
          status === 'agressivo'
            ? step.descriptions[2].title
            : status === 'passivo'
            ? step.descriptions[3].title
            : step.descriptions[4].title,
          activeLanguage?.code
        )}
      </ScoreBox>
      <StarComponent starNumber={points} plus size='big' />
    </>
  )
}

Score.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  values: PropTypes.object,
  step: PropTypes.object,
  steps: PropTypes.array,
  points: PropTypes.number
}

Score.defaultProps = {
  values: undefined,
  step: undefined,
  steps: [],
  points: 0
}
export default withLocalize(withRouter(Score))

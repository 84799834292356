import React from 'react'

import {connect} from 'react-redux'
import {Route} from 'react-router-dom'

import LoadingComponent from '../../components/loading/LoadingComponent'

const UnauthenticatedRoute = ({
  component: Component,
  props: cProps,
  ready,
  ...rest
}) =>
  !ready ? (
    <LoadingComponent />
  ) : (
    <Route
      {...rest}
      render={(props) => <Component {...props} {...cProps} />}
    />
  )

const mapStateToProps = (state) => ({
  ready: state.info.ready
})

export default connect(mapStateToProps)(UnauthenticatedRoute)

import React, {useState, useEffect} from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'

import {BaseButton} from '../../components/buttons/BaseButton'
import {MaterialLabel} from '../../components/inputs/MaterialStyles'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import TextInput from '../../components/inputs/TextInput'
import {ExplanationContainer} from '../../components/jogo_espirais/JogoEspiraisStyles'
import {SendHelpMessage} from '../../infra/requests/BaseRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {PageForm, Margin} from '../../styles/BasicStyles'
import {White, SecondaryColour, SuccessBgColor} from '../../styles/Colours'
import {Title25, TitleH3, QuestionText} from '../../styles/TextSizes'
import {DashboardContainer} from '../dashboard/DashboardStyles'
import {HelpContainer} from './HelpStyles'

const formRules = FormValidator.make({
  name: 'required',
  email: 'required|email',
  message: 'required'
})

const HelpPage = ({translate}) => {
  const user = useSelector((state) => state.user)
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  const onSubmit = async (values) => {
    try {
      const result = await SendHelpMessage({...values, language: user.language})

      if (result.success) {
        setShowMessage(true)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={formRules}
      initialValues={{name: user.name, email: user.email}}
    >
      {({handleSubmit, form, valid, submitting}) => (
        <PageForm
          onSubmit={(event) => {
            const promise = handleSubmit(event).then(() => {
              form.reset()
            })
            return promise
          }}
        >
          <HelpContainer>
            <DashboardContainer>
              <Row>
                <Col xs={24} md={20} lg={16}>
                  <TitleH3 isBold style={{textAlign: 'left'}}>
                    {translate('AJUDA_TECNICA')}
                  </TitleH3>
                  <Margin size={24} />
                  <Title25
                    style={{textAlign: 'left'}}
                    color={SecondaryColour}
                  >
                    {translate('PEDIDO_DE_CONTACTO')}
                  </Title25>
                  <Field
                    component={TextInput}
                    name='name'
                    type='text'
                    label={translate('NOME_COMPLETO')}
                    placeholder={translate('AJUDA_NOME_PLACEHOLDER')}
                  />
                  <Field
                    component={TextInput}
                    name='email'
                    type='email'
                    label={translate('EMAIL')}
                    placeholder={translate('AJUDA_EMAIL_PLACEHOLDER')}
                  />
                  <Margin size={24} tablet={16} />
                  <MaterialLabel>{translate('MENSAGEM')}</MaterialLabel>
                  <Margin size={8} />
                  <Field
                    noMargin
                    isSmall
                    component={TextAreaInput}
                    name='message'
                    type='text'
                    bgColor={White}
                    placeholder={translate('DESCRIÇAO_AJUDA_PLACEHOLDER')}
                  />
                  <Margin size={24} tablet={16} />
                  {showMessage && (
                    <ExplanationContainer
                      isActive
                      bgColor={SuccessBgColor}
                      borderColor={SuccessBgColor}
                      style={{marginBottom: 16, padding: 16}}
                    >
                      <QuestionText>
                        {translate('MENSAGEM_ENVIADA_SUCESSO')}
                      </QuestionText>
                    </ExplanationContainer>
                  )}
                  <BaseButton
                    disabled={!valid || submitting}
                    style={{width: 'fit-content'}}
                    htmlType='submit'
                    type='primaryMedium'
                    text={translate('ENVIAR')}
                  />
                </Col>
              </Row>
            </DashboardContainer>
          </HelpContainer>
        </PageForm>
      )}
    </Form>
  )
}

HelpPage.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(HelpPage)

import {Row, Col} from 'antd'
import styled from 'styled-components'

import {
  SecondaryColour,
  Beige,
  MenuBorderColor,
  White
} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const HistoryRow = styled(Row)`
  height: calc(100% - 52px);

  @media ${device.tabletMin} {
    height: calc(100% - 52px);
  }
`

export const HistoryCol = styled(Col)`
  min-height: 100% !important;

  @media ${device.tabletMin} {
    min-height: ${({isFull}) =>
      isFull ? '100% !important' : 'auto !important'};
  }
`

export const HistoryCol2 = styled(Col)`
  max-height: -webkit-fill-available;
  @media ${device.tabletMin} {
    margin-top: 24px;
    height: 100%;
    min-height: 100%;
    max-height: 300px;
  }
`

export const HistoryContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  height: 100%;
  background: ${({bgColor}) => bgColor || Beige};
  border: 2px solid ${({borderColor}) => borderColor || MenuBorderColor};
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  @media ${device.tabletMin} {
    min-height: 100%;
  }
`

export const HistoryTitleContainer = styled.div`
  width: 100%;
  padding: 16px;
  border-bottom: 2px solid ${MenuBorderColor};
`

export const HistorySubContainer = styled.div`
  width: 100%;
  padding: 16px;
  overflow: auto;
  height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const HistoryLine = styled.div`
  width: 100%;
  margin-top: 16px;
  background: ${White};
  border: 2px solid
    ${({selected}) => (selected ? SecondaryColour : MenuBorderColor)};
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
`

export const HistoryShadow = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(254, 248, 241, 0) 3%,
    rgba(254, 248, 241, 0.68) 50%,
    ${Beige} 100%
  );
  height: 55px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 2;
`

import React from 'react'

import {Col} from 'antd'
import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import {PropTypes} from 'prop-types'
import {DragDropContext} from 'react-beautiful-dnd'
import {Form} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {
  SaveActivities,
  GetActivities
} from '../../infra/requests/ProgramRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {UpdateUserProgram} from '../../infra/utils/CommonFunctions'
import {
  MainContainerBackground,
  MainContainerScroll2,
  MainContainer
} from '../../pages/step/StepStyles'
import {UpdateActivitiesCalendar} from '../../redux/Info/info.actions'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {PageForm} from '../../styles/BasicStyles'
import {Beige} from '../../styles/Colours'
import ChartActivities from '../charts/ChartActivities'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import BaseModal from '../modals/BaseModal'
import TableComponent from './TableComponent'

const daysOfWeek = [
  'DOMINGO',
  'SEGUNDA',
  'TERÇA',
  'QUARTA',
  'QUINTA',
  'SEXTA',
  'SABADO'
]

const formRules = FormValidator.make({})

const ValidateDate = (date, start, end) => {
  const startOf = moment.utc(start).startOf('isoWeek')
  const endOf = moment.utc(end).endOf('isoWeek')
  const current = moment.utc(date)

  if (current.isAfter(endOf) || current.isBefore(startOf)) {
    return true
  }
  return false
}

const CreateDaysArray = (w) => {
  const weekStart = moment.utc(w).startOf('isoWeek')
  const d = []
  for (let i = 0; i <= 6; i++) {
    const day = moment.utc(weekStart).add(i, 'days')
    d.push({
      dayOfWeek: daysOfWeek[day.format('d')],
      dayOfWeekSmall: day.format('ddd'),
      day: day.format('D'),
      date: day.format('YYYY-MM-DD')
    })
  }
  return d
}

class AtividadesLazer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false,
      validation: undefined,
      week: moment.utc(),
      days: CreateDaysArray(moment.utc()),
      totalActivities: 0,
      initialValues: undefined,
      isLoading: false
    }
  }

  componentDidMount = () => {
    this.getActivitiesList()
  }

  getActivitiesList = async () => {
    const {days} = this.state
    const {user} = this.props
    try {
      const result = await GetActivities(
        user._id,
        days[0].date,
        days[6].date
      )
      if (result.success) {
        let total = 0
        result.data.forEach((d) => {
          total += d.activities.length
        })

        this.setState({
          totalActivities: total,
          initialValues: {activities: result.data}
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }

  onNextStep = () => {
    const {
      history,
      user,
      location,
      nextStep,
      dispatch,
      chapter,
      updateChapter
    } = this.props
    const isProgram = !location.state?.noProgram
    const isApplicator = user.type === 2
    this.setState({openModal: false})

    if (isProgram && !isApplicator) {
      UpdateUserProgram(updateChapter, chapter._id, dispatch)
    }

    if (location.state?.next) {
      if (location.state.next === 'isBack') history.goBack()
      else history.push(location.state.next)
    } else if (nextStep) {
      history.push(`/step/${nextStep._id}`)
    } else history.push('/program')
  }

  onSubmit = async (values) => {
    const {user, dispatch} = this.props
    const isApplicator = user.type === 2
    if (isApplicator) return this.onNextStep()

    // if (showMessage) {
    //   onNextStep()
    //   return
    // }
    try {
      this.setState({isLoading: true})
      const weekStart = moment
        .utc()
        .clone()
        .startOf('isoWeek')

      const weekEnd = moment
        .utc()
        .clone()
        .endOf('isoWeek')

      values.activities.map((a) => {
        a.startDate = weekStart
        a.endDate = weekEnd
        return a
      })

      const result = await SaveActivities(values.activities)
      if (result.success) {
        // setShowMessage(true)
        dispatch(UpdateActivitiesCalendar(result.data.activities))
        dispatch(UpdateUserScore(result.data.points))
        this.onNextStep()
      }
      this.setState({isLoading: false})
    } catch (e) {
      console.warn(e)
      this.setState({isLoading: false})
    }
  }

  onCloseStep = (pristine) => {
    if (!pristine) {
      this.setState({openModal: true})
    } else {
      this.exitPage()
    }
  }

  exitPage = () => {
    const {history, location} = this.props

    if (location.state?.back) {
      this.setState({openModal: false})
      if (location.state?.back === 'isBack') history.goBack()
      else history.push(location.state.back)
    } else history.push('/program')
  }

  validateNext = (values, pristine, submitting) => {
    if (values?.activities?.length && !pristine && !submitting) {
      return false
    }

    return true
  }

  // const renderFooterFeedback = (values) => {
  //   if (!showMessage) return null
  //   return (
  //     <FooterFeedbackComponent isCorrect>
  //       <OptionText style={{display: 'flex', alignItems: 'center'}}>
  //         {translate('AGENDA_ATIVIDADES_LAZER_FEEDBACK')}
  //       </OptionText>
  //     </FooterFeedbackComponent>
  //   )
  // }

  setOnForm = (form, values, option, date) => {
    const {user} = this.props

    const changes = values?.activities
      ? values.activities.map((v) => ({
          ...v,
          activities: v.activities.map((a) => ({...a}))
        }))
      : []
    const exist = changes.findIndex(
      (c) => moment.utc(c.date).format('YYYY-MM-DD') === date
    )

    if (exist > -1) {
      if (!changes[exist].activities.find((a) => a.title === option)) {
        changes[exist].activities.push({title: option, completed: false})
      }
    } else {
      changes.push({
        user: user._id,
        date: moment.utc(date),
        activities: [{title: option, completed: false}]
      })
    }
    form.change('activities', changes)
  }

  onDragEnd = (data, form, values) => {
    const {totalActivities} = this.state
    if (
      !data.destination ||
      data?.source?.droppableId !== 'droppableTags'
    ) {
      return null
    }
    const [t, droppableId] = data?.destination?.droppableId?.split('_')
    if (ValidateDate(droppableId, moment.utc(), moment.utc())) {
      return null
    }

    this.setOnForm(
      form,
      values,
      data.draggableId.toLowerCase(),
      droppableId
    )
    this.setState({totalActivities: totalActivities + 1})
  }

  onchangeDate = (date) => {
    if (!date) return
    this.setState({week: date}, () => {
      const d = CreateDaysArray(date)
      this.setState({days: d}, () => this.getActivitiesList())
    })
  }

  onRemoveTag = (tag, date, form, values) => {
    const {totalActivities} = this.state
    if (ValidateDate(date, moment.utc(), moment.utc())) {
      return null
    }

    this.setState({
      totalActivities: totalActivities - 1
    })

    const newValues = values?.activities
      ? values.activities.map((v) => ({
          ...v,
          activities: v.activities.map((a) => ({...a}))
        }))
      : []
    const index = newValues?.findIndex(
      (c) => moment.utc(c.date).format('YYYY-MM-DD') === date
    )
    const changes = newValues[index]?.activities?.filter(
      (a) => a.title !== tag
    )

    if (newValues[index]) {
      newValues[index].activities = changes
      form.change('activities', newValues)
    }
  }

  onClickTag = (tag, date) => {
    this.setState({
      validation: undefined
    })
    if (ValidateDate(date, moment.utc(), moment.utc())) {
      return null
    }

    this.setState({
      validation: {tag, date}
    })
  }

  validateActivity = (form, values) => {
    const {validation} = this.state

    this.setState({
      validation: undefined
    })

    const newActivities = values?.activities
      ? values.activities.map((v) => ({
          ...v,
          activities: v.activities.map((a) => ({...a}))
        }))
      : []

    const index = newActivities.findIndex(
      (c) => moment.utc(c.date).format('YYYY-MM-DD') === validation.date
    )
    const activityIndex = newActivities[index].activities.findIndex(
      (a) => a.title === validation.tag
    )
    newActivities[index].activities[activityIndex].completed = true

    form.change('activities', newActivities)
  }

  render() {
    const {history, chapter, step, translate, location} = this.props
    const {
      initialValues,
      days,
      validation,
      totalActivities,
      week,
      openModal,
      isLoading
    } = this.state
    const isProgram = !location.state?.noProgram
    let submit

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={formRules}
        mutators={{
          ...arrayMutators
        }}
        initialValues={initialValues}
      >
        {({handleSubmit, form, pristine, values, submitting}) => {
          submit = handleSubmit
          return (
            <PageForm onSubmit={handleSubmit}>
              <BaseModal
                visible={openModal}
                handleCancel={this.exitPage}
                handleConfirm={(event) => {
                  submit(event)
                }}
                handleClose={() => this.setState({openModal: false})}
                text={translate('AVISO_SAIR_SEM_REGISTAR_ATIVIDADES')}
                title={translate('AVISO')}
              />
              <BasicHeaderComponent
                onClose={() => this.onCloseStep(pristine)}
                title={chapter.title}
                progress={(step.order / chapter.steps.length) * 100}
              />
              <MainContainerBackground bgColor={Beige}>
                <MainContainerScroll2>
                  <MainContainer>
                    <DragDropContext
                      onDragEnd={(data) =>
                        this.onDragEnd(data, form, values)
                      }
                    >
                      <FieldArray
                        name='activities'
                        component={TableComponent}
                        // tags={tags}
                        days={days}
                        week={week}
                        validation={validation}
                        history={history}
                        isProgram={isProgram}
                        totalActivities={totalActivities}
                        validateActivity={this.validateActivity}
                        onClickTag={this.onClickTag}
                        onRemoveTag={this.onRemoveTag}
                        onClickNo={() =>
                          this.setState({validation: undefined})
                        }
                        onchangeDate={this.onchangeDate}
                      />
                    </DragDropContext>
                    {!isProgram && <ChartActivities />}
                  </MainContainer>
                </MainContainerScroll2>
              </MainContainerBackground>
              <BasicFooterComponent
                // leftContent={renderFooterFeedback(values)}
                onBack={() => history.goBack()}
                nextHtmlType='submit'
                nextDisabled={this.validateNext(
                  values,
                  pristine,
                  submitting
                )}
                // onNextText={showMessage ? false : translate('REGISTAR')}
                onNextText={translate('REGISTAR')}
                isLoading={isLoading}
              />
            </PageForm>
          )
        }}
      </Form>
    )
  }
}

AtividadesLazer.propTypes = {
  step: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  updateChapter: PropTypes.bool,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

AtividadesLazer.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  updateChapter: false
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  withRouter(connect(mapStateToProps, mapActionToProps)(AtividadesLazer))
)

import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const ImageContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const Image = styled.div`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
`

const ImageComponent = ({
  width,
  ratio,
  children,
  color,
  url,
  bgContain,
  bgAttach,
  bgPosition
}) => (
    <ImageContainer>
      <Image
        style={{
          width: width ? width : '100%',
          paddingTop: ratio ? 100 * ratio + '%' : url ? '100%' : '0%',
          backgroundColor: color ? color : '#ece8e2',
          backgroundImage: url ? `url(${url})` : undefined,
          backgroundSize: bgContain || !url ? 'contain' : 'cover',
          backgroundAttachment: bgAttach || 'inherit',
          backgroundPosition: bgPosition || 'inherit'
        }}
      />
      {children}
    </ImageContainer>
  )

ImageComponent.propTypes = {
  url: PropTypes.string.isRequired,
  ratio: PropTypes.number,
  width: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.object,
  bgAttach: PropTypes.string,
  bgPosition: PropTypes.string
}

ImageComponent.defaultProps = {
  ratio: 1,
  width: '100%',
  color: '#ece8e2',
  children: false,
  bgAttach: false,
  bgPosition: false
}

export default ImageComponent

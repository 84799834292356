import React, {useState} from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {SaveStepAnswerObjective, UpdateUserChapter} from '../../infra/requests/ProgramRequests'
import {
  MainContainer,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {UpdateProgram} from '../../redux/Info/info.actions'
import {BaseButton} from '../buttons/BaseButton'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'
import {ButtonsContainer} from '../message/MessageStyles'

const Step2_1_1 = ({
  step,
  history,
  nextStep,
  chapter,
  updateChapter,
  translate
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const isApplicator = user.type === 2

  const onNext = async () => {
    setIsLoading(true)
    if (!chapter.completed && !isApplicator) {
      // new upd due to unblock sessions, it is needed save answer to set chapter as completed
      const fields = {
        user: undefined,
        chapter: chapter._id,
        step: step._id,
        updateChapter: updateChapter && !isApplicator
      }
      const result = await SaveStepAnswerObjective(fields)
      // if (result.success) dispatch(UpdateProgram(result.data.sessions))
    }
    // if (updateChapter && !isApplicator) {
    //   const r = await UpdateUserChapter({chapter: chapter._id, updateChapter})
    //   if (r.success) dispatch(UpdateProgram(r.data.sessions))
    // }

    setIsLoading(false)
    if (nextStep) history.push(`/step/${nextStep._id}`)
    else history.push('/program')
  }

  return (
    <>
      <BasicHeaderComponent
        onClose={() => history.push('/program')}
        title={chapter.title}
        progress={(step.order / chapter.steps.length) * 100}
      />
      <MainContainerBackground>
        <MainContainerScroll>
          <MainContainer>
            {step.type === 'text' && (
              <>
                {step.descriptions.map((description, index) => (
                  <MessageComponent
                    key={index}
                    text={description.title}
                    active={index === step.descriptions.length - 1}
                  />
                ))}
              </>
            )}
            <MessageComponent isUser active>
              <ButtonsContainer>
                <BaseButton
                  style={{marginRight: 8}}
                  align='right'
                  htmlType='button'
                  type='primaryMedium'
                  text={translate('VAMOS_LA')}
                  auto
                  onClick={onNext
                    // () =>
                    // nextStep
                    //   ? history.push(`/step/${nextStep._id}`)
                    //   : history.push('/program')
                  }
                />
                <BaseButton
                  style={{marginLeft: 8}}
                  align='right'
                  htmlType='button'
                  type='default'
                  auto
                  text={translate('VEJO_MAIS_TARDE')}
                  onClick={() => history.push('/program')}
                />
              </ButtonsContainer>
            </MessageComponent>
          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackground>
      <BasicFooterComponent onNext={onNext} isLoading={isLoading} />
    </>
  )
}

Step2_1_1.propTypes = {
  translate: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  updateChapter: PropTypes.bool,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object
}

Step2_1_1.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  updateChapter: false
}
export default withLocalize(withRouter(Step2_1_1))

import React from 'react'

import PropTypes from 'prop-types'

import {ErrorColor, LightGrey} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import {TextAreaInputField, TextAreaDiv} from './InputStyles'
import {MaterialInputSection} from './MaterialStyles'

const TextAreaInput = ({
  input,
  meta,
  onPressEnter,
  label,
  disabled,
  placeholder,
  minRows,
  maxRows,
  onClick,
  readOnly,
  bgColor,
  noMargin,
  isSmall
}) => {
  const {invalid, submitFailed} = meta
  const showError = invalid && submitFailed

  return (
    <MaterialInputSection noMargin={noMargin}>
      <TextAreaDiv bgColor={bgColor} isSmall={isSmall}>
        {label && (
          <QuestionText isBold color={showError ? ErrorColor : LightGrey}>
            {label}
          </QuestionText>
        )}
        <TextAreaInputField
          disabled={disabled}
          isSmall={isSmall}
          size='large'
          value={input.value}
          onChange={input.onChange}
          onPressEnter={onPressEnter}
          placeholder={placeholder}
          autoSize={{
            minRows: minRows || 8,
            maxRows: maxRows || 8
          }}
          bordered={false}
          error={showError ? 1 : 0}
          readOnly={readOnly}
          onClick={onClick}
        />
      </TextAreaDiv>
    </MaterialInputSection>
  )
}

TextAreaInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  onPressEnter: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  bgColor: PropTypes.string,
  noMargin: PropTypes.bool,
  isSmall: PropTypes.bool,
  disabled: PropTypes.bool
}

TextAreaInput.defaultProps = {
  label: undefined,
  placeholder: undefined,
  onPressEnter: () => {},
  onClick: () => {},
  readOnly: undefined,
  minRows: undefined,
  maxRows: undefined,
  bgColor: undefined,
  noMargin: false,
  isSmall: false,
  disabled: false
}

export default TextAreaInput

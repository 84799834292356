import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import Ascendente from '../../assets/3.3/tornado_ascendente.svg'
import Descendente from '../../assets/3.3/tornado_descendente.svg'
import MessageComponent from '../message/MessageComponent'

const TextComponent = ({step}) => (
  <>
    {step.descriptions.map((description, index) => {
      const image =
        index === 1 ? Descendente : index === 3 ? Ascendente : false

      return (
        <MessageComponent
          key={index}
          text={description.title}
          active={index === step.descriptions.length - 1}
        >
          {image && (
            <img alt='espiral' src={image} style={{width: '20%'}} />
          )}
        </MessageComponent>
      )
    })}
  </>
)

TextComponent.propTypes = {
  step: PropTypes.object.isRequired
}

export default withLocalize(withRouter(TextComponent))

import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import Message from '../../assets/icons/message.svg'
import MoreUsers from '../../assets/icons/more_users.svg'
import {
  GetUserPhoto,
  getUserNameForum
} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {LightGrey} from '../../styles/Colours'
import {QuestionText, TextSmall, Body} from '../../styles/TextSizes'
import {
  ThreadContainer,
  UserImage,
  ThreadDetail,
  UserImageBox,
  UsersContainerCenter,
  ThreadDescription,
  MessageContainer,
  ThreadCol,
  ThreadCol1
} from './ThreadStyles'

const ThreadComponent = ({thread, translate, history}) => (
  <ThreadContainer onClick={() => history.push(`/forum/${thread._id}`)}>
    <ThreadCol1 xs={24} sm={18}>
      <UserImage src={GetUserPhoto(thread.author)} />
      <ThreadDetail>
        <QuestionText isBold>{thread.title}</QuestionText>
        {thread.last_comment?.author && (
          <TextSmall>
            {translate('ULTIMA_RESPOSTA_DE')}{' '}
            {getUserNameForum(thread.last_comment?.author.name)}
          </TextSmall>
        )}
        <Margin size={8} />
        <ThreadDescription color={LightGrey}>
          {thread.description}
        </ThreadDescription>
      </ThreadDetail>
    </ThreadCol1>
    <ThreadCol xs={24} sm={6}>
      <UsersContainerCenter>
        {thread.comments_authors &&
          thread.comments_authors
            .sort(() => 0.5 - Math.random())
            .slice(0, 4)
            .map((u, index) => {
              const add = thread.comments_authors.length <= 4 ? 0 : 24
              return (
                <UserImageBox
                  key={index}
                  style={{
                    right: 24 * index + add,
                    zIndex: index + 1
                  }}
                >
                  <img
                    alt='user'
                    src={GetUserPhoto(u)}
                    style={{width: '100%'}}
                  />
                </UserImageBox>
              )
            })}
        {thread.comments_authors?.length > 4 && (
          <UserImageBox style={{zIndex: 0, right: 0}}>
            <img alt='user' src={MoreUsers} style={{width: '100%'}} />
          </UserImageBox>
        )}
      </UsersContainerCenter>
      <MessageContainer>
        <Body weight={600}>{thread.total_comments || 0}</Body>
        <img alt='message' src={Message} style={{marginLeft: 8}} />
      </MessageContainer>
    </ThreadCol>
  </ThreadContainer>
)

ThreadComponent.propTypes = {
  history: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  thread: PropTypes.object.isRequired
}

export default withLocalize(withRouter(ThreadComponent))

import React from 'react'

import {DownloadOutlined} from '@ant-design/icons'
import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Cup from '../../assets/icons/Taça.svg'
import {BaseButton} from '../../components/buttons/BaseButton'
import EmptyLayoutComponent from '../../components/empty_layout/EmptyLayoutComponent'
import SessionPanelHeader from '../../components/session/SessionPanelHeader'
import SessionsCollapse from '../../components/session/SessionsCollapse'
import {GetUserProgram} from '../../infra/requests/ProgramRequests'
import {GetStepName, GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {
  programaEducadores,
  programaJovens
} from '../../infra/utils/PagesNames'
import {SaveProgram} from '../../redux/Info/info.actions'
import {LoadingSpin} from '../../styles/BasicStyles'
import {Beige} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import {
  DashboardContainer,
  TitleDashboard,
  DashboardBoxSmall,
  DashboardBoxCol
} from '../dashboard/DashboardStyles'
import {
  ProgramContainer,
  SessionBox,
  SessionsContainer
} from './ProgramStyles'

class ProgramPage extends React.Component {
  constructor(props) {
    super(props)
    this.scrollComponent = null
    this.state = {
      isLoading: true,
      program: undefined
    }
  }

  scrollToSession = () => {
    const {info} = this.props
    const actives = info.program?.sessions.filter((s) => s.enabled) || false
    const lastActive = info.program?.sessions[actives.length - 1]

    if (lastActive && lastActive.order) {
      const element = document.getElementById(
        `session_${lastActive.order}`
      )

      const headerOffset = 105
      const elementPosition = element?.getBoundingClientRect().top
      const offsetPosition = elementPosition - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }

  componentDidMount = () => {
    const {history, info, dispatch, user} = this.props
    const isApplicator = user.type === 2

    if (isApplicator) {
      if (
        (user.applicator_program === 2 || user.applicator_program === 3) &&
        history?.location?.pathname.indexOf('educators_program') !== -1
      ) {
        const currentProgram = info.programs.find((p) => p._id === programaEducadores)
        dispatch(SaveProgram(currentProgram))
        this.setState({
          isLoading: false,
          program: currentProgram
        })
      } else if (
        (user.applicator_program === 1 || user.applicator_program === 3) &&
        (history?.location?.pathname.indexOf('youth_program') !== -1 || history?.location?.pathname.indexOf('program') !== -1)
      ) {
        const currentProgram = info.programs.find((p) => p._id === programaJovens)
        dispatch(SaveProgram(currentProgram))
        this.setState({
          isLoading: false,
          program: currentProgram
        })
      }
      // else history.goBack()
    } else {
      this.setState({
        program: info.program
      })
      this.getProgram()
    }

    if (history?.location?.state?.nextSession) this.scrollToSession()
    else window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  getProgram = async () => {
    const {user, info, dispatch} = this.props

    this.setState({isLoading: true})
    try {
      const result = await GetUserProgram(info.program._id, user._id)

      if (result.success) {
        this.setState({
          isLoading: false,
          program: result.data
        })
        dispatch(
          SaveProgram(result.data)
        )
      }
    } catch (error) {
      console.warn(error)
      this.setState({isLoading: false})
    }
  }

  Download = () => {
    const {user, activeLanguage} = this.props
    window.open(
      `${process.env.REACT_APP_API}/certificate/${activeLanguage?.code}/${user?._id}`,
      '_blank'
    )
  }

  render() {
    const {user, info, history, translate, activeLanguage} = this.props
    const {program, isLoading} = this.state

    if (!program) {
      return (
        <ProgramContainer>
          <DashboardContainer>
            <EmptyLayoutComponent tag='MENSAGEM_PROGRAMA_VAZIO' />
          </DashboardContainer>
        </ProgramContainer>
      )
    }

    const isApplicator = user.type === 2
    const isEducator = user.type === 3
    const isStudent = user.type === 4
    const isFinished = isApplicator ? false : program.completed || user.programCompleted
    const hasSurveys = !isApplicator && info.surveys && !info.surveys.answered
    const activeAndCompleted =
      program?.sessions.filter((s) => s.enabled && s.completed) || false
    const lastActiveAndCompleted =
      program?.sessions[activeAndCompleted.length - 1]
    const nextWeek = program?.sessions[lastActiveAndCompleted?.order]
      ? !program?.sessions[lastActiveAndCompleted.order].enabled
      : false
    const stepName = GetStepName(program)

    return (
      <ProgramContainer>
        <DashboardContainer>
          {!isApplicator && (
            <DashboardBoxSmall bgColor='#FFFFFF'>
              <Col
                xs={24}
                md={hasSurveys && !isFinished ? 24 : 12}
                lg={hasSurveys && !isFinished ? 24 : 16}
              >
                {isFinished && isStudent ? (
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <QuestionText isBold>
                      {translate(
                        isEducator
                          ? 'PARABENS_TERMINOU_PROGRAMA_EDUCADOR'
                          : 'PARABENS_TERMINOU_PROGRAMA'
                      )}
                    </QuestionText>
                    <QuestionText>
                      {translate(
                        isEducator
                          ? 'CERTIFICADO_PROGRAMA_EDUCADORES'
                          : 'CERTIFICADO_PROGRAMA_JOVEM'
                      )}
                    </QuestionText>
                    <a download onClick={this.Download}>
                      <DownloadOutlined
                        style={{
                          fontSize: 32,
                          background: Beige,
                          borderRadius: '50%',
                          padding: 8
                        }}
                      />
                    </a>
                  </div>
                ) : hasSurveys ? (
                  <>
                    <QuestionText isBold>
                      {GetValueLanguage(
                        info.surveys.form.title,
                        activeLanguage && activeLanguage.code
                      )}
                    </QuestionText>
                    <TitleDashboard
                      dangerouslySetInnerHTML={{
                        __html: GetValueLanguage(
                          info.surveys.form.description,
                          activeLanguage && activeLanguage.code
                        )
                      }}
                    />
                  </>
                ) : (
                  <TitleDashboard>
                    {translate('PROGRAM_TITLE')}
                  </TitleDashboard>
                )}
              </Col>
              {(!hasSurveys || (isFinished && isStudent)) && (
                <DashboardBoxCol xs={24} md={12} lg={8}>
                  {(isFinished && isStudent) ? (
                    <DashboardBoxCol xs={24} md={12} lg={8}>
                      <img src={Cup} alt='cup' style={{width: 110}} />
                    </DashboardBoxCol>
                  ) : (
                    <BaseButton
                      disabled={nextWeek}
                      auto
                      variant='raised'
                      htmlType='button'
                      type='primaryLarge'
                      text={
                        nextWeek
                          ? translate('NEXT_CONTENT_NEXT_WEEK')
                          : translate('NEXT_CONTENT')
                      }
                      onClick={() => this.scrollToSession()}
                    />
                  )}
                </DashboardBoxCol>
              )}
            </DashboardBoxSmall>
          )}
          <SessionsContainer style={{marginTop: isLoading ? 0 : 70}}>
            {isLoading && (<LoadingSpin style={{margin: 22}} />)}
            <SessionBox>
              <SessionPanelHeader
                id='session_1'
                session={program?.sessions[0]}
                index={0}
                onClick={() =>
                history.push(
                  `/${stepName}/${
                    program?.sessions[0].chapters[0].steps[0]._id
                    }`
                  )
                }
                nextSession={program?.sessions ? program?.sessions[1] : undefined}
              />
            </SessionBox>
            <SessionsCollapse
              sessions={program?.sessions.slice(1, program.sessions.length) || []}
            />
          </SessionsContainer>
        </DashboardContainer>
      </ProgramContainer>
    )
  }
}

ProgramPage.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  info: state.info
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(withRouter(connect(mapStateToProps, mapActionToProps)(ProgramPage)))

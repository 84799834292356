import React from 'react'

import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Form} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Illustration10 from '../../assets/5.5/10.png'
import Illustration2 from '../../assets/5.5/2.svg'
import Illustration3 from '../../assets/5.5/3.svg'
import Illustration4 from '../../assets/5.5/4.svg'
import Illustration5 from '../../assets/5.5/5.png'
import Illustration6 from '../../assets/5.5/6.svg'
import Illustration9 from '../../assets/5.5/9.png'
import Goals from '../../assets/5.5/Goals.svg'
import Illustration from '../../assets/backgrounds/background_game.svg'
import {
  GetActiveGame,
  CreateGame
} from '../../infra/requests/ProgramRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {
  GetValueLanguage,
  UpdateUserProgram
} from '../../infra/utils/CommonFunctions'
import {
  MainContainerBackground,
  MainContainerScroll3,
  MainContainer3
} from '../../pages/step/StepStyles'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {PageForm, LoadingSpin} from '../../styles/BasicStyles'
import {Beige3, ThirdColour} from '../../styles/Colours'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import FooterFeedbackComponent from '../question/FooterFeedbackComponent'
import {OptionText} from '../question/QuestionStyles'
import ChallengesComponent from './ChallengesComponent'
import Question1 from './Question1'
import Question2 from './Question2'
import Question3 from './Question3'
import TextComponent from './TextComponent'
import {validation1, validation2} from './Validation'

const formRules = FormValidator.make({})

const components = {
  Illustration2,
  Illustration3,
  Illustration4,
  Illustration5,
  Illustration6,
  Illustration9,
  Illustration10
}
class JogoDesafios extends React.Component {
  constructor(props) {
    super(props)
    this.refScroll = null
    this.state = {
      answered: false,
      initialValues: {steps: []},
      currentStep: props.step,
      response: false,
      loading: false,
      isLoading: false
    }
  }

  componentDidMount = () => {
    this.getActive()
    this.setValues()
  }

  getActive = async () => {
    this.setState({loading: true})
    try {
      const result = await GetActiveGame()

      if (result.success) {
        if (result.data?.currentWeek === 0) {
          this.setValues()
        } else {
          this.setState(
            {
              response: result.data || false
            },
            () => this.setValues()
          )
        }
      }
      this.setState({loading: false})
    } catch (error) {
      console.warn(error)
    }
  }

  setValues = () => {
    const {chapter} = this.props
    const values = {
      steps: []
    }
    chapter.steps.map((s) => {
      const st = {_id: s._id, questions: []}
      s.questions.map((q) => {
        st.questions.push({
          _id: q._id,
          options: [],
          goal: undefined,
          challenges: []
        })
      })
      values.steps.push(st)
    })
    this.setState({initialValues: values})
  }

  scrollTop = () => {
    if (this.refScroll) {
      this.refScroll.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  onSubmit = async (values) => {
    const {
      chapter,
      location,
      history,
      nextStep,
      dispatch,
      user
    } = this.props
    const {currentStep, response} = this.state
    if (user.type === 2) {
      this.setState({
        answered: false,
        currentStep: chapter.steps[currentStep.order]
      })
      return
    }

    try {
      this.setState({isLoading: true})
      if (response) {
        if (location?.state?.next) {
          if (location?.state?.next === 'isBack') history.goBack()
          else history.push(location?.state?.next)
        } else if (nextStep) history.push(`/step/${nextStep._id}`)
        else history.push('/program')
        return
      }

      const newField = {
        options: [],
        main_objective: undefined,
        objectives: [],
        rewards: [],
        other_rewards: []
      }

      values.steps.forEach((field) => {
        const step = chapter.steps.find((s) => s._id === field._id)

        field.questions.forEach((question) => {
          if (question.goal) newField.main_objective = question.goal
          if (question.challenges?.length) {
            const challenges = question.challenges.filter((item) => item)
            newField.objectives = challenges.map((item) => ({name: item}))
          }
          if (question.rewards?.length) {
            newField.other_rewards = question.rewards.filter((item) => item)
          }
          if (question.options?.length) {
            if (step.order === 9) newField.rewards = question.options
            else if (newField.options.length > 0) {
              newField.options = newField.options.concat([
                ...question.options
              ])
            } else {
              newField.options = [...question.options]
            }
          }
        })
      })

      const result = await CreateGame(newField)

      if (result.success) {
        this.setState({
          answered: false,
          currentStep: chapter.steps[currentStep.order]
        })
        dispatch(UpdateUserScore(result.data.points))
      }
      this.setState({isLoading: false})
    } catch (e) {
      this.setState({isLoading: false})
      console.warn(e)
    }
  }

  onNextStep = (values) => {
    const {currentStep, answered, response, message} = this.state
    const {
      chapter,
      history,
      nextStep,
      location,
      dispatch,
      updateChapter,
      user
    } = this.props
    const isApplicator = user.type === 2

    if (response) {
      if (message) {
        if (
          !location?.state?.noProgram &&
          !isApplicator
        ) {
          UpdateUserProgram(updateChapter, chapter._id, dispatch)
        }
        if (location?.state?.next) {
          if (location?.state?.next === 'isBack') history.goBack()
          else history.push(location?.state?.next)
        } else if (nextStep) history.push(`/step/${nextStep._id}`)
        else history.push('/program')
      } else this.onSubmit(values)
      return
    }
    if (chapter.steps[currentStep.order]) {
      if (currentStep.order + 1 === chapter.steps.length) {
        this.onSubmit(values)
      } else if (
        currentStep.questions[0]?.wrong ||
        currentStep.questions[0]?.correct
      ) {
        const isDirty =
          currentStep.order === 7
            ? validation1(values)
            : validation2(values)

        if (isDirty) {
          if (!answered) this.setState({answered: 1})
          else if (answered === 1) {
            this.setState({
              answered: false,
              currentStep: chapter.steps[currentStep.order]
            })
          } else this.setState({answered: 1})
        } else {
          if (!answered) this.setState({answered: 2})
          if (answered === 2) {
            if (!location?.state?.noProgram &&
              !isApplicator
            ) {
              UpdateUserProgram(updateChapter, chapter._id, dispatch)
            }
            if (nextStep) history.push(`/step/${nextStep._id}`)
            else history.push('/program')
          } else if (answered === 1) this.setState({answered: 2})
        }
      } else {
        this.setState({
          answered: false,
          currentStep: chapter.steps[currentStep.order]
        })
      }
      this.scrollTop()
    } else {
      if (!location?.state?.noProgram && !isApplicator) {
        UpdateUserProgram(updateChapter, chapter._id, dispatch)
      }
      if (location?.state?.next) {
        if (location?.state?.next === 'isBack') history.goBack()
        else history.push(location?.state?.next)
      } else if (nextStep) history.push(`/step/${nextStep._id}`)
      else history.push('/program')
    }
  }

  onBackStep = () => {
    const {currentStep} = this.state
    const {chapter, history} = this.props

    this.setState({answered: false})
    if (chapter.steps[currentStep.order - 2]) {
      this.setState({currentStep: chapter.steps[currentStep.order - 2]})
      this.scrollTop()
    } else history.goBack()
  }

  onCloseStep = () => {
    const {history, location} = this.props
    if (location?.state?.back) {
      if (location?.state?.back === 'isBack') history.goBack()
      else history.push(location?.state.back)
    } else history.push('/program')
  }

  renderFooterFeedback = () => {
    const {currentStep, answered, message, response} = this.state
    const {activeLanguage, translate} = this.props
    const question = currentStep.questions[0]
    if (response && message) {
      if (message === 1) {
        return (
          <FooterFeedbackComponent isCorrect>
            <OptionText style={{display: 'flex', alignItems: 'center'}}>
              {translate('PARABENS_DESAFIO_REALIZADO')}
            </OptionText>
          </FooterFeedbackComponent>
        )
      }
      return (
        <FooterFeedbackComponent isPending>
          <OptionText style={{display: 'flex', alignItems: 'center'}}>
            {translate('PARABENS_DESAFIO_REALIZADO_EM_PARTE')}
          </OptionText>
        </FooterFeedbackComponent>
      )
    }
    switch (answered) {
      case 1: {
        return (
          <FooterFeedbackComponent isCorrect>
            <OptionText style={{display: 'flex', alignItems: 'center'}}>
              {GetValueLanguage(question.correct, activeLanguage?.code)}
            </OptionText>
          </FooterFeedbackComponent>
        )
      }
      case 2: {
        return (
          <FooterFeedbackComponent>
            <OptionText>
              {GetValueLanguage(question.wrong, activeLanguage?.code)}
            </OptionText>
          </FooterFeedbackComponent>
        )
      }

      default:
        return null
    }
  }

  renderContent = () => {
    const {currentStep} = this.state
    switch (currentStep.order) {
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 9:
        return (
          <MainContainer3
            ref={(ref) => {
              this.refScroll = ref
            }}
            style={{
              backgroundImage: `url(${
                components[`Illustration${currentStep.order}`]
              })`,
              backgroundPosition: 'right bottom',
              backgroundSize:
                currentStep.order === 3
                  ? '40%'
                  : currentStep.order === 9
                  ? '30%'
                  : '25%',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <Question2 step={currentStep} />
          </MainContainer3>
        )

      case 7:
        return (
          <MainContainer3
            ref={(ref) => {
              this.refScroll = ref
            }}
          >
            <Question1 step={currentStep} />
          </MainContainer3>
        )
      case 10:
        return (
          <MainContainer3
            ref={(ref) => {
              this.refScroll = ref
            }}
            style={{
              backgroundImage: `url(${components.Illustration10})`,
              backgroundPosition: 'right bottom',
              backgroundSize: '30%',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <Question3 step={currentStep} />
          </MainContainer3>
        )

      default:
        return (
          <MainContainer3
            ref={(ref) => {
              this.refScroll = ref
            }}
          >
            <TextComponent
              step={currentStep}
              onClickNext={this.onNextStep}
            />
          </MainContainer3>
        )
    }
  }

  render() {
    const {chapter} = this.props
    const {initialValues, currentStep, response, loading, isLoading} = this.state

    return (
      <Form
        onSubmit={this.onNextStep}
        validate={formRules}
        mutators={{
          ...arrayMutators
        }}
        initialValues={initialValues}
      >
        {({handleSubmit}) => (
          <PageForm onSubmit={handleSubmit}>
            <BasicHeaderComponent
              onClose={this.onCloseStep}
              title={chapter.title}
              progress={(currentStep.order / chapter.steps.length) * 100}
              progressColor={ThirdColour}
            />
            <MainContainerBackground
              bgColor={Beige3}
              style={{
                backgroundImage:
                  currentStep.order === 7 || response
                    ? `url(${Goals})`
                    : `url(${Illustration})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition:
                  currentStep.order === 7
                    ? 'right center'
                    : 'center center'
              }}
            >
              <MainContainerScroll3>
                {loading ? (
                  <MainContainer3
                    ref={(ref) => {
                      this.refScroll = ref
                    }}
                  >
                    <LoadingSpin />
                  </MainContainer3>
                ) : response ? (
                  <MainContainer3
                    ref={(ref) => {
                      this.refScroll = ref
                    }}
                  >
                    <ChallengesComponent
                      chapter={chapter}
                      response={response}
                      UpdateResponse={(r) => this.setState({response: r})}
                      showMessage={(value) =>
                        this.setState({message: value})
                      }
                    />
                  </MainContainer3>
                ) : (
                  <FieldArray
                    name='steps'
                    component={this.renderContent}
                  />
                )}
              </MainContainerScroll3>
            </MainContainerBackground>
            <BasicFooterComponent
              leftContent={this.renderFooterFeedback()}
              onBack={this.onBackStep}
              nextHtmlType='submit'
              // nextDisabled={!(answered && answered === 1)}
              isLoading={isLoading}
            />
          </PageForm>
        )}
      </Form>
    )
  }
}

JogoDesafios.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  location: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  updateChapter: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

JogoDesafios.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  updateChapter: false
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  withRouter(connect(mapStateToProps, mapActionToProps)(JogoDesafios))
)

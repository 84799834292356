import React from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import Message from '../../assets/icons/message_grey.svg'
import {GetUserPhoto} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {LightGrey, InputBorderColour} from '../../styles/Colours'
import {Body, BodyText} from '../../styles/TextSizes'
import {
  CommentContainer,
  ThreadCol1,
  UserCommentImage,
  ThreadDetail,
  MessageContainer,
  TitleEditable
} from './ThreadStyles'

const BasicComment = ({comment, translate}) => {
  const totalComments = comment.comments?.length || 0

  return (
    <>
      <CommentContainer hasSub={totalComments}>
        <ThreadCol1 xs={24}>
          <UserCommentImage src={GetUserPhoto(comment.author)} />
          <ThreadDetail>
            <TitleEditable isComment>
              <Body weight={600}>
                {comment.author?.name}
                {comment.edited && (
                  <Body
                    color={InputBorderColour}
                    weight='bold'
                    style={{marginLeft: 16}}
                  >
                    ({translate('EDITADO')})
                  </Body>
                )}
              </Body>
            </TitleEditable>
            <BodyText>{moment(comment.updatedAt).fromNow()}</BodyText>
            <Margin size={8} />
            <Body>{comment.description}</Body>
            <Margin size={16} />
            <MessageContainer style={{marginLeft: 0}}>
              <img alt='message' src={Message} style={{marginRight: 8}} />
              <Body color={LightGrey}>
                {totalComments || 0}{' '}
                {translate(
                  totalComments === 1 ? 'COMENTARIO' : 'COMENTARIOS'
                )}
              </Body>
            </MessageContainer>
            <Margin size={16} />
          </ThreadDetail>
        </ThreadCol1>
      </CommentContainer>
    </>
  )
}

BasicComment.propTypes = {
  translate: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired
}

export default withLocalize(BasicComment)

import {Col} from 'antd'
import styled from 'styled-components'

import {MenuBorderColor, Beige3} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const TotalPoints = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 2px solid ${MenuBorderColor};
  background-color: ${Beige3};

  @media ${device.tablet} {
    display: flex;
    margin-bottom: 24px;
  }
`

export const Inline = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
`

export const JewelContainer = styled.div`
  display: flex;
`

export const JewelBox = styled.div`
  padding: 8px;
  background-color: ${Beige3};
  border-radius: 8px;
  margin-right: 16px;
  width: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InfoBox = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
`

export const CompleteBar = styled.div`
  width: 90%;
  height: 11px;
  display: flex;
  border-radius: 8px;
  background-color: ${MenuBorderColor};
  margin-top: auto;
`

export const CompleteBarColor = styled.div`
  width: ${p => (p.width ? p.width : '0%')};
  background-color: ${p => (p.bgColor ? p.bgColor : 'transparent')};
  border-radius: 8px;
`

export const ProfileCol = styled(Col)`
  display: flex !important;
  flex-direction: column;

  @media ${device.mobileL} {
    margin-bottom: 24px;
  }
`

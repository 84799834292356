import {Row, Col} from 'antd'
import styled from 'styled-components'

import {
  MenuBorderColor,
  White,
  PrimaryCTA,
  Beige2,
  HoverBorderColor,
  ActiveBorderColor,
  SecondaryColour
} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const TableRow = styled(Row)`
  display: flex !important;

  @media ${device.tabletMin} {
    display: none !important;
  }
`

export const MobileTable = styled.div`
  display: none;

  @media ${device.tabletMin} {
    display: block;
  }
`

export const TableContainer = styled.div`
  width: 100%;
  background: ${White};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
`

export const LineContainer = styled.div`
  border-bottom: ${p =>
    p.isLast ? '0px' : `2px solid ${MenuBorderColor}`};
  display: flex;
`

export const DayContainer = styled.div`
  padding: 24px;
  border-right: 2px solid ${MenuBorderColor};
  flex: 2;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.tabletMin} {
    min-width: 56px;
    height: 67px;
    border: 2px solid
      ${p => (p.active ? SecondaryColour : MenuBorderColor)};
    padding: 0;
    align-items: center;
    margin-right: 8px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
  }
`

export const ActivitiesContainer = styled.div`
  padding: 8px 16px;
  border-right: 2px solid ${MenuBorderColor};
  flex: 5;

  @media ${device.tabletMin} {
    padding: 16px;
    min-height: 230px;
    background-color: ${White};
    border-radius: 8px;
    border: 2px solid ${MenuBorderColor};
    display: flex;
    margin-bottom: 16px;
  }
`

export const CountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const TableCol = styled(Col)`
  display: flex !important;

  @media ${device.tabletMin} {
    display: block !important;
  }
`

export const TableRow2 = styled(Row)`
  display: flex !important;
  flex-direction: column;
  flex: 1;
  flex-flow: column !important;

  @media ${device.tabletMin} {
    display: block !important;
  }
`

export const TableCol2 = styled(Col)`
  flex: 1;
  display: flex !important;

  @media ${device.tabletMin} {
    display: block !important;
  }
`

export const TotalActivitiesContainer = styled(Col)`
  display: flex;
`

export const TextActivities = styled.div`
  background: ${PrimaryCTA};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px 0px 0px 8px;
  padding: 22px 13px;
  flex: 5;
  height: 65px;
  display: flex;
  align-items: center;
`

export const CountActivities = styled.div`
  background: ${White};
  border: 2px solid ${MenuBorderColor};
  border-left: 0px;
  border-radius: 0px 8px 8px 0px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SelectActivitiesContainer = styled.div`
  width: 100%;
  display: flex;
  background: ${White};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  padding: 16px;
`

export const ValidationContainer = styled.div`
  width: 100%;
  background: ${White};
  border: 2px solid ${HoverBorderColor};
  border-radius: 8px;
  padding: 16px;

  @media ${device.tabletMin} {
    margin-bottom: 16px;
  }
`

export const TagsContainer = styled.div`
  width: 100%;
  display: inline-block;
  margin: 16px -4px;
`

export const Tag = styled.div`
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 37px;
  background: ${({isValid}) => (isValid ? ActiveBorderColor : Beige2)};
  border-radius: 30px;
  margin: 4px;
  cursor: pointer;
  border: 2px solid
    ${({isActive, isValid}) =>
      isValid ? ActiveBorderColor : isActive ? HoverBorderColor : Beige2};

  &:hover {
    border-color: ${HoverBorderColor};
  }
`

export const TagRemove = styled.img`
  width: 20px;
  height: auto;
  padding: 5px;
  margin-left: 7px;
`

export const DaysContainer = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
`

export const DaysContainerScroll = styled.div`
  overflow-x: auto;
  display: flex;
`

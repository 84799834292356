const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tabletS: '550px',
  tablet: '768px',
  tabletMin: '767px',
  tabletL: '850px',
  laptopS: '900px',
  laptop: '992px',
  laptopL: '1200px',
  desktop: '1400px',
  desktopL: '1600px',
  desktopXL: '1900px'
}

const minSizes = {
  laptop: '993px'
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tabletS: `(max-width: ${size.tabletS})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletMin: `(max-width: ${size.tabletMin})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktopL})`,
  desktopXL: `(max-width: ${size.desktopXL})`,
  minLaptop: `(min-width: ${minSizes.laptop})`
}

export const vertical = {
  small: '(max-height: 500px)',
  smallL: '(max-height: 600px)',
  mediumS: '(max-height: 700px)',
  medium: '(max-height: 800px)',
  large: '(max-height: 960px)'
}

import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'

import Lock from '../../assets/icons/lock.svg'
import {
  CompleteBox,
  CompleteTitle,
  LockIcon
} from '../../pages/dashboard/DashboardStyles'
import {Margin} from '../../styles/BasicStyles'
import {BaseButton} from '../buttons/BaseButton'
import StarComponent from '../star/StarComponent'

const CompleteBoxComponent = ({
  title,
  inactive,
  starNumber,
  translate,
  onClick,
  titleBtn,
  isEducator
}) => (
  <>
    {inactive && (
      <LockIcon top='-8px'>
        <img alt='lock' src={Lock} />
      </LockIcon>
    )}
    <CompleteBox>
      <Row style={{flex: 1, display: 'flex'}}>
        <Col xs={24}>
        {isEducator && title ? (
          <CompleteTitle
            inactive={inactive}
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
        ) : (
          <CompleteTitle inactive={inactive}>{title}</CompleteTitle>
        )}
        </Col>
        {/* <Col xs={4}>
          <StarComponent starNumber={starNumber} active={!inactive} />
        </Col> */}
      </Row>
      <Margin size={16} />
      <Row style={{flex: 1, display: 'flex'}}>
        <Col xs={24}>
          <BaseButton
            disabled={inactive}
            align='right'
            variant='raised'
            htmlType='button'
            type='primaryMedium'
            text={translate(titleBtn || 'COMPLETE_BUTTON')}
            onClick={onClick}
          />
        </Col>
      </Row>
    </CompleteBox>
  </>
)

CompleteBoxComponent.propTypes = {
  title: PropTypes.string.isRequired,
  inactive: PropTypes.bool.isRequired,
  starNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  translate: PropTypes.func.isRequired,
  titleBtn: PropTypes.string,
  isEducator: PropTypes.bool
}

CompleteBoxComponent.defaultProps = {
  onClick: () => {},
  titleBtn: undefined,
  isEducator: false
}

export default CompleteBoxComponent

import React from 'react'

import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Form} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_evaluation.svg'
import {SaveStepAnswer} from '../../infra/requests/ProgramRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {GetProgramName, GetStepName, UpdateUserProgram} from '../../infra/utils/CommonFunctions'
import {
  MainContainerBackground,
  MainContainerScroll3
} from '../../pages/step/StepStyles'
import {CompleteChapter} from '../../redux/Info/info.actions'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {PageForm} from '../../styles/BasicStyles'
import {ThirdColour} from '../../styles/Colours'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import PlatformEvaluation from './PlatformEvaluation'
import ProgramEvaluation from './ProgramEvaluation'
import SessionEvaluation from './SessionEvaluation'
import SessionEvaluationEducator from './SessionEvaluationEducator'

const formRules = FormValidator.make({})

class EvaluationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValues: {steps: []},
      currentStep: props.step,
      isFinished: false,
      isLoading: false
    }
  }

  componentDidMount = () => {
    const {chapter} = this.props
    const values = {
      steps: []
    }
    chapter.steps.map((s) => {
      const st = {_id: s._id, questions: []}
      s.questions.map((q) => {
        st.questions.push({
          _id: q._id,
          options: undefined
        })
      })
      values.steps.push(st)
    })
    this.setState({initialValues: values})
  }

  scrollTop = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  onSubmit = async (values) => {
    const {
      user,
      history,
      chapter,
      nextStep,
      updateChapter,
      dispatch,
      info
    } = this.props
    const isApplicator = user.type === 2
    const stepName = GetStepName(info.program)
    const programName = GetProgramName(info.program)

    if (isApplicator) {
      if (nextStep) history.push(`/${stepName}/${nextStep._id}`)
      else history.push(`/${programName}`)
      return
    }

    try {
      this.setState({isLoading: true})
      const fields = []
      values.steps.forEach((field) => {
        const newField = {
          user: user._id,
          chapter: chapter._id,
          step: field._id
        }
        field.questions.forEach((question) => {
          const opts = question.options ? typeof question.options === 'string' ? [question.options] : question.options : []
          fields.push({
            ...newField,
            question: question._id,
            options: opts,
            answer: question.answer ? [question.answer] : []
          })
        })
      })

      const result = await SaveStepAnswer(fields)

      if (!isApplicator) {
        UpdateUserProgram(updateChapter, chapter._id, dispatch)
      }
      if (result.success) {
        dispatch(UpdateUserScore(result.data.points))
        if (!chapter.completed) dispatch(CompleteChapter(chapter._id))
        if (nextStep) {
          history.push(
            `/${stepName}/${nextStep._id}`
          )
        } else history.push(`/${programName}`)
      }
      this.setState({isLoading: false})
    } catch (e) {
      this.setState({isLoading: false})
      console.warn(e)
    }
  }

  onNextStep = (values) => {
    const {chapter} = this.props
    const {currentStep} = this.state
    if (chapter.steps[currentStep.order]) {
      this.setState({currentStep: chapter.steps[currentStep.order]})
      this.scrollTop()
    } else {
      this.onSubmit(values)
    }
  }

  onBackStep = () => {
    const {chapter, history} = this.props
    const {currentStep} = this.state
    if (chapter.steps[currentStep.order - 2]) {
      this.setState({currentStep: chapter.steps[currentStep.order - 2]})
      this.scrollTop()
    } else history.goBack()
  }

  validateNext = (values) => {
    const {currentStep, isFinished} = this.state
    const {session, chapter, user} = this.props
    const isApplicator = user.type === 2
    const isEducator = user.type === 3

    if (isApplicator || isEducator) { return false }

    if (currentStep.type === 'media' && isFinished) return false

    const stepValues = values.steps.find((s) => s._id === currentStep._id)
    if (session.order === 11 && chapter.order === 6) {
      switch (currentStep.order) {
        case 1: {
          if (
            stepValues?.questions?.length &&
            !stepValues?.questions.find((q) => q.options === undefined)
          ) {
            return false
          }
          return true
        }
        case 2: {
          if (
            stepValues?.questions?.length &&
            stepValues?.questions.find((q) => q.options)
          ) {
            return false
          }
          return true
        }
        case 13: {
          if (
            stepValues?.questions?.length &&
            stepValues?.questions[0].answer
          ) {
            return false
          }
          return true
        }
        default: {
          if (
            stepValues?.questions?.length &&
            stepValues?.questions[0].options
          ) {
            return false
          }
          return true
        }
      }
    }

    if (session.order === 11 && chapter.order === 7) {
      switch (currentStep.order) {
        case 1:
        case 2: {
          if (
            stepValues?.questions?.length &&
            !stepValues?.questions.find((q) => q.options === undefined)
          ) {
            return false
          }
          return true
        }
        default: {
          if (
            stepValues?.questions?.length &&
            stepValues?.questions[0].options
          ) {
            return false
          }
          return true
        }
      }
    }

    if (currentStep.order === 1) {
      if (
        stepValues?.questions?.length &&
        !stepValues?.questions.find((q) => q.options === undefined)
      ) {
        return false
      }
      return true
    } if (
      stepValues?.questions?.length &&
      stepValues?.questions[0]?.options
    ) {
      return false
    }

    return true
  }

  renderContent = () => {
    const {currentStep} = this.state
    const {session, chapter, user, info} = this.props
    const isEducator = user.type === 3
    const isApplicator = user.type === 2

    if (session.order === 11 && chapter.order === 6) {
      return <ProgramEvaluation currentStep={currentStep} />
    }

    if (session.order === 11 && chapter.order === 7) {
      return <PlatformEvaluation currentStep={currentStep} />
    }

    const stepName = GetStepName(info.program)
    if ((isApplicator && stepName === 'step_educators') || isEducator) {
      return <SessionEvaluationEducator currentStep={currentStep} isLast={session.order === 11 && stepName === 'step_educators'} />
    }
    return <SessionEvaluation currentStep={currentStep} />
  }

  render() {
    const {initialValues, currentStep, isLoading} = this.state
    const {history, chapter, info} = this.props
    const programName = GetProgramName(info.program)
    return (
      <Form
        onSubmit={this.onNextStep}
        validate={formRules}
        mutators={{
          ...arrayMutators
        }}
        initialValues={initialValues}
      >
        {({handleSubmit, values}) => (
          <PageForm onSubmit={handleSubmit}>
            <BasicHeaderComponent
              onClose={() => history.push(`/${programName}`)}
              title={chapter?.title}
              progress={(currentStep.order / chapter.steps.length) * 100}
              progressColor={ThirdColour}
            />
            <MainContainerBackground
              bgColor='#FFF6DC'
              style={{
                backgroundImage: `url(${Illustration})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center center'
              }}
            >
              <MainContainerScroll3>
                <FieldArray name='steps' component={this.renderContent} />
              </MainContainerScroll3>
            </MainContainerBackground>
            <BasicFooterComponent
              onBack={this.onBackStep}
              nextHtmlType='submit'
              nextDisabled={this.validateNext(values)}
              isLoading={isLoading}
            />
          </PageForm>
        )}
      </Form>
    )
  }
}

EvaluationComponent.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  session: PropTypes.object,
  updateChapter: PropTypes.bool,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired
}

EvaluationComponent.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  session: undefined,
  updateChapter: false
}

const mapStateToProps = (state) => ({
  user: state.user,
  info: state.info
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  withRouter(
    connect(mapStateToProps, mapActionToProps)(EvaluationComponent)
  )
)

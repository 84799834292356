import React from 'react'

import {Col} from 'antd'
import moment from 'moment'
import {PropTypes} from 'prop-types'
import {FormSpy} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'

import IcQuestion from '../../assets/icons/question.svg'
import {agendaAtividadesFirst} from '../../infra/utils/PagesNames'
import {IconQuestion} from '../../styles/BasicStyles'
import {White, SecondaryColour} from '../../styles/Colours'
import {TitleH3, BodyText} from '../../styles/TextSizes'
import DateInput from '../inputs/DateInput'
import {
  TableCol2,
  TableCol,
  CountActivities,
  TextActivities,
  TotalActivitiesContainer,
  TableRow2,
  TableContainer,
  TableRow,
  SelectActivitiesContainer
} from './AtividadesLazerStyles'
import DaysComponent from './DaysComponent'
import MobileTableComponent from './MobileTableComponent'
import TagsComponent from './TagsComponent'
import ValidateComponent from './ValidateComponent'

const CustomFormat = (value) => {
  const dateWeekStart = value.clone().startOf('week')
  const dateWeekEnd = value.clone().endOf('week')
  return `${dateWeekStart.format('ddd, D MMM')} - ${dateWeekEnd.format(
    'ddd, D MMM'
  )}`
}

const DisabledDate = (current) => {
  const weekEnd = moment
    .utc()
    .clone()
    .endOf('isoWeek')
  return current && current.isAfter(weekEnd)
}

const TableComponent = ({
  days,
  translate,
  validation,
  history,
  onClickTag,
  onRemoveTag,
  isProgram,
  totalActivities,
  onchangeDate,
  week,
  validateActivity,
  onClickNo
}) => (
  <FormSpy subscription={{values: true}}>
    {({values, form}) => (
      <>
        <TableRow gutter={[16, 16]}>
          <Col sm={24} md={16}>
            {!isProgram && (
              <TitleH3 color={SecondaryColour} style={{height: 81}}>
                {translate('AGENDA_SEMANAL_ATIVIDADES_LAZER')}
                <IconQuestion
                  src={IcQuestion}
                  onClick={() =>
                    history.replace(`/step/${agendaAtividadesFirst}`)
                  }
                />
              </TitleH3>
            )}
            <TableContainer>
              <DaysComponent
                values={values}
                form={form}
                days={days}
                translate={translate}
                validation={validation}
                onClickTag={onClickTag}
                onRemoveTag={onRemoveTag}
              />
            </TableContainer>
          </Col>
          <TableCol sm={24} md={8}>
            <TableRow2 gutter={[16, 16]}>
              <TotalActivitiesContainer>
                <TextActivities>
                  <BodyText weight={500} color={White}>
                    {translate('TOTAL_ATIVIDADES_SEMANAIS')}
                  </BodyText>
                </TextActivities>
                <CountActivities>
                  <TitleH3 color={SecondaryColour}>
                    {totalActivities || 0}
                  </TitleH3>
                </CountActivities>
              </TotalActivitiesContainer>
              {!isProgram && (
                <TableCol>
                  <DateInput
                    first
                    meta={{}}
                    picker='week'
                    dateFormat={CustomFormat}
                    input={{
                      value: week,
                      onChange: onchangeDate
                    }}
                    disabledDate={DisabledDate}
                  />
                </TableCol>
              )}
              {validation && (
                <ValidateComponent
                  onClickValidate={() => validateActivity(form, values)}
                  onClickNo={onClickNo}
                />
              )}
              <TableCol2>
                <SelectActivitiesContainer>
                  <TagsComponent />
                </SelectActivitiesContainer>
              </TableCol2>
            </TableRow2>
          </TableCol>
        </TableRow>
        <MobileTableComponent
          days={days}
          week={week}
          isSession={isProgram}
          validation={validation}
          validate={validateActivity}
          onClickTag={onClickTag}
          onRemoveTag={onRemoveTag}
          onCloseValidation={onClickNo}
          customFormat={CustomFormat}
          onchangeDate={onchangeDate}
          disabledDate={DisabledDate}
        />
      </>
    )}
  </FormSpy>
)

TableComponent.propTypes = {
  days: PropTypes.array,
  validation: PropTypes.object,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onClickTag: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  isProgram: PropTypes.bool,
  totalActivities: PropTypes.number,
  onchangeDate: PropTypes.func.isRequired,
  week: PropTypes.object,
  validateActivity: PropTypes.func.isRequired,
  onClickNo: PropTypes.func.isRequired
}

TableComponent.defaultProps = {
  days: [],
  isProgram: false,
  totalActivities: 0,
  week: moment.utc(),
  validation: undefined
}

export default withLocalize(TableComponent)

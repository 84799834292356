import React from 'react'

import {Slider} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage, isEmpty} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {SecondaryColour} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import {SlideContainer} from './QuestionStyles'

const QuestionSlide = ({
  question,
  activeLanguage,
  onChangeValue,
  titleColor,
  fieldName,
  disabled,
  max,
  min,
  defaultValue
}) => {
  const marks = {
    [min]: {
      style: {
        color: SecondaryColour
      },
      label: GetValueLanguage(
        question.options[0].title,
        activeLanguage?.code
      )
    },
    [max]: {
      style: {
        color: SecondaryColour
      },
      label: GetValueLanguage(
        question.options[1].title,
        activeLanguage?.code
      )
    }
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({values, form}) => (
        <>
          <QuestionText color={titleColor}>
            {GetValueLanguage(question.title, activeLanguage?.code)}
          </QuestionText>
          <Margin size={24} />
          <SlideContainer>
            <Field name={`${fieldName}.options`}>
              {(props) => {
                const {value} = {...props.input}
                return (
                  <Slider
                    disabled={disabled}
                    style={{paddingTop: 20}}
                    onChange={(e) => {
                      form.change(`${fieldName}.options`, e)
                      onChangeValue(e)
                    }}
                    value={
                      isEmpty(value.toString()) ? defaultValue || 5 : value
                    }
                    defaultValue={defaultValue || 5}
                    tooltipVisible
                    max={max || 10}
                    min={min || 0}
                    marks={marks}
                  />
                )
              }}
            </Field>
          </SlideContainer>
        </>
      )}
    </FormSpy>
  )
}

QuestionSlide.propTypes = {
  question: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func,
  titleColor: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  defaultValue: PropTypes.number
}

QuestionSlide.defaultProps = {
  onChangeValue: undefined,
  titleColor: undefined,
  disabled: false,
  max: 10,
  min: 0,
  defaultValue: 5
}

export default withLocalize(withRouter(QuestionSlide))

import React from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {QuestionText} from '../../styles/TextSizes'
import HiddenLabelTextInput from '../inputs/HiddenLabelTextInput'
import {
  IntroductionBlock,
  Block1
} from '../jogo_espirais/JogoEspiraisStyles'

const Question1 = ({step, activeLanguage, translate, onChange}) => {
  const title = GetValueLanguage(step.title, activeLanguage?.code)
  return (
    <FormSpy subscription={{values: true}}>
      {({values, form}) => (
        <>
          {title && (
            <IntroductionBlock>
              <QuestionText isBold>{title}</QuestionText>
            </IntroductionBlock>
          )}
          <FieldArray name={`steps[${step.order - 1}].questions`}>
            {() =>
              step.questions.map((question, i) => (
                <Block1 key={i}>
                  <Field
                    typeText='text'
                    name={`steps[${step.order - 1}].questions[${i}].goal`}
                    component={HiddenLabelTextInput}
                    label={GetValueLanguage(
                      question.title,
                      activeLanguage?.code
                    )}
                    placeholder={GetValueLanguage(
                      question.title,
                      activeLanguage?.code
                    )}
                    size='big'
                    onChange={onChange}
                  />
                  <FieldArray
                    name={`steps[${step.order -
                      1}].questions[${i}].challenges`}
                  >
                    {({fields}) =>
                      Array.from(Array(4).keys()).map((item, index) => (
                        <Col xs={24} key={index}>
                          <Field
                            typeText='text'
                            name={`${fields.name}[${index}]`}
                            component={HiddenLabelTextInput}
                            label={translate('ESCREVE_AQUI_O_TEU_DESAFIO')}
                            placeholder={translate(
                              'ESCREVE_AQUI_O_TEU_DESAFIO'
                            )}
                            onChange={onChange}
                          />
                        </Col>
                      ))
                    }
                  </FieldArray>
                </Block1>
              ))
            }
          </FieldArray>
        </>
      )}
    </FormSpy>
  )
}

Question1.propTypes = {
  step: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onChange: PropTypes.func
}

Question1.defaultProps = {
  onChange: undefined
}
export default withLocalize(withRouter(Question1))

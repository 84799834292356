import React from 'react'

import {PropTypes} from 'prop-types'

import Correct from '../../assets/icons/correct.svg'
import Pending from '../../assets/icons/pending.svg'
import Wrong from '../../assets/icons/wrong.svg'
import {BasicFooterMessage} from '../footer/FooterStyles'
import {FooterImg} from './QuestionStyles'

const FooterFeedbackComponent = ({isCorrect, isPending, children}) => {
  const renderContent = () => (
    <BasicFooterMessage>
      <FooterImg src={isCorrect ? Correct : isPending ? Pending : Wrong} />
      {children}
    </BasicFooterMessage>
  )

  return renderContent()
}

FooterFeedbackComponent.propTypes = {
  children: PropTypes.object,
  isCorrect: PropTypes.bool,
  isPending: PropTypes.bool
}

FooterFeedbackComponent.defaultProps = {
  children: undefined,
  isCorrect: false,
  isPending: false
}
export default FooterFeedbackComponent

import React from 'react'

import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'

import AudioPage from '../../components/audio/AudioPage'
import BDPage from '../../components/bd/BDPage'
import DiarioBordo from '../../components/diario_bordo/DiarioBordo'
import DiarioBordo2 from '../../components/diario_bordo/DiarioBordo2'
import DiarioHumor from '../../components/diario_humor/DiarioHumor'
import MeditateComponent from '../../components/diario_meditaçao/MeditateComponent'
import EvaluationComponent from '../../components/evaluation/EvaluationComponent'
import JogoComportamento from '../../components/jogo_comportamento/JogoComportamento'
import JogoDesafios from '../../components/jogo_desafios/JogoDesafios'
import JogoEspirais from '../../components/jogo_espirais/JogoEspirais'
import QuestionComponent from '../../components/question/QuestionComponent'
import QuizComponent from '../../components/quiz/QuizComponent'
import ScorePage from '../../components/score/ScorePage'
import Step1_1_1 from '../../components/steps/Step1_1_1'
import Step1_1_5 from '../../components/steps/Step1_1_5'
import Step1_1_9 from '../../components/steps/Step1_1_9'
import Step2_1_1 from '../../components/steps/Step2_1_1'
import Step2_5_1 from '../../components/steps/Step2_5_1'
import Step4_4_1 from '../../components/steps/Step4_4_1'
import Step4_5_1 from '../../components/steps/Step4_5_1'
import Step4_6_2 from '../../components/steps/Step4_6_2'
import Step4_7_1 from '../../components/steps/Step4_7_1'
import Step5_3_1 from '../../components/steps/Step5_3_1'
import VideoPage from '../../components/video/VideoPage'
import {GetStepByIdFromProgram} from '../../infra/utils/CommonFunctions'
import {programaJovens} from '../../infra/utils/PagesNames'
import {SaveProgram} from '../../redux/Info/info.actions'
import {StepContainer} from './StepStyles'

const components = {
  Step1_1_1,
  Step1_1_5,
  Step1_1_9,
  Step1_1_13: Step1_1_5,
  Step2_1_1,
  Step3_1_1: Step2_1_1,
  Step4_1_1: Step2_1_1,
  Step4_4_1,
  Step4_5_1,
  Step4_6_1: Step4_5_1,
  Step4_6_2,
  Step4_6_4: Step4_6_2,
  Step4_7_1,
  Step5_1_1: Step2_1_1,
  Step5_3_1,
  Step5_3_2: Step5_3_1,
  Step5_3_3: Step5_3_1,
  Step5_3_4: Step5_3_1,
  Step5_3_5: Step5_3_1,
  Step5_4_1: Step5_3_1,
  Step5_4_2: Step5_3_1,
  Step5_6_1: Step2_5_1,
  Step6_1_1: Step2_1_1,
  Step6_4_1: Step2_5_1,
  Step6_4_2: Step2_5_1,
  Step7_1_1: Step2_1_1,
  Step7_4_1: Step2_5_1,
  Step7_5_1: Step2_5_1,
  Step8_1_1: Step2_1_1,
  Step9_1_1: Step2_1_1,
  Step10_1_1: Step2_1_1,
  Step11_1_1: Step2_1_1
}

class StepPage extends React.Component {
  constructor(props) {
    super(props)
    this.scrollComponent = null
    this.state = {
      step: undefined
    }
  }

  componentDidMount = () => {
    const {program, dispatch, info} = this.props
    const isApplicator = info.profile_type === 2

    if (!program && isApplicator) {
      dispatch(
        SaveProgram(info.programs.find((p) => p._id === programaJovens))
      )
    }
    this.GetStep()
  }

  componentDidUpdate = (prevProps) => {
    const {info, program} = this.props
    const isApplicator = info.profile_type === 2

    if (
      prevProps?.match?.params?.id !== this.props.match?.params?.id ||
      (prevProps?.program !== program && isApplicator)
    ) {
      this.GetStep()
    }
  }

  GetStep = () => {
    const {
      program,
      match: {params}
    } = this.props
    const stepInProgram = GetStepByIdFromProgram(params.id, program)
    this.setState({step: stepInProgram})
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  renderContent = () => {
    const {step} = this.state
    const {program, audio} = this.props
    const sessionNumber = step?.chapter?.session?.order || undefined
    const chapterNumber = step?.chapter?.order || undefined
    const stepNumber = step?.order || undefined

    let nextStep =
      program?.sessions[sessionNumber - 1]?.chapters[chapterNumber - 1]
        ?.steps[stepNumber] || undefined
    let nextChapter
    if (!nextStep) {
      nextChapter =
        program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
        undefined
    }
    if (nextChapter) [nextStep] = nextChapter.steps

    const session = program?.sessions[sessionNumber - 1]
    const chapter = session?.chapters[chapterNumber - 1]

    const NextChapter =
      nextChapter ||
      program?.sessions[sessionNumber - 1]?.chapters[chapterNumber]
    const updateChapter =
      (chapter?.order === session?.chapters.length &&
        (!program?.sessions[sessionNumber]?.enabled ||
          (program?.sessions[sessionNumber]?.enabled &&
            !NextChapter &&
            !program?.sessions[sessionNumber]?.chapters[0]?.completed))) ||
      (NextChapter && !NextChapter.enabled)

    let {type} = step
    if (
      (chapter.type === 4 || chapter.type === 5) &&
      chapter.steps.length === step.order
    ) {
      type = 'score'
    }

    if (
      session.order === 4 &&
      (chapter.order === 5 || chapter.order === 6) &&
      type === 'media'
    ) {
      type = 'meditate'
    }

    if (session.order === 2 && chapter.type === 6) {
      type = 'diario_humor'
    }
    if (session.order === 3 && chapter.type === 5) {
      type = 'jogo_espirais'
    }
    if (session.order === 5 && chapter.type === 5) {
      type = 'jogo_desafios'
    }
    if (session.order === 6 && chapter.type === 6) {
      type = 'diario_bordo'
    }
    if (session.order === 7 && chapter.type === 6) {
      type = 'diario_bordo2'
    }
    if (chapter.type === 3) {
      type = 'bd'
    }
    if (chapter.type === 4) {
      type = 'quiz'
    }
    if (session.order === 9 && chapter.type === 5) {
      type = 'jogo_comportamento'
    }

    if (chapter.type === 10) {
      type = 'evaluation'
    }

    switch (type) {
      case 'video': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <VideoPage
            isRoteiro={session.order === 1}
            numberOfSteps={session.order === 1 ? 2 : undefined}
            key={Math.random()}
            step={step}
            nextStep={
              session.order === 1 ? chapter.steps[step.order + 2] : nextS
            }
            chapter={chapter}
            session={sessionNumber}
            updateChapter={updateChapter}
          />
        )
      }
      case 'audio':
        return (
          <AudioPage
            key={Math.random()}
            step={step}
            nextStep={nextStep}
            chapter={chapter}
            updateChapter={updateChapter}
            audio={audio}
          />
        )

      case 'question': {
        return (
          <QuestionComponent
            key={Math.random()}
            step={step}
            nextStep={nextStep}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      // case 'score':
      //   return (
      //     <ScorePage
      //       key={Math.random()}
      //       step={step}
      //       nextStep={nextStep}
      //       chapter={chapter}
      //       updateChapter={updateChapter}
      //     />
      //   )

      case 'meditate':
        return (
          <MeditateComponent
            key={Math.random()}
            step={step}
            nextStep={nextStep}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )

      case 'quiz': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <QuizComponent
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'jogo_espirais': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <JogoEspirais
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'jogo_desafios': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <JogoDesafios
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'jogo_comportamento': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <JogoComportamento
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'diario_humor': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <DiarioHumor
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'diario_bordo': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <DiarioBordo
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'diario_bordo2': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <DiarioBordo2
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'bd': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps

        return (
          <BDPage
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'evaluation': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <EvaluationComponent
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            updateChapter={updateChapter}
            session={session}
          />
        )
      }

      default: {
        const MyComponent =
          components[`Step${sessionNumber}_${chapterNumber}_${stepNumber}`]
        return (
          <MyComponent
            key={Math.random()}
            step={step}
            nextStep={nextStep}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }
    }
  }

  render() {
    const {step} = this.state
    return (
      <StepContainer key={Math.random()}>
        {step && this.renderContent()}
      </StepContainer>
    )
  }
}

StepPage.propTypes = {
  program: PropTypes.object,
  audio: PropTypes.array.isRequired,
  info: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

StepPage.defaultProps = {
  program: undefined
}

const mapStateToProps = (state) => ({
  info: state.info,
  program: state.info.program,
  audio: state.info.audio
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default connect(mapStateToProps, mapActionToProps)(StepPage)

import React, {useState, useRef, useEffect} from 'react'

import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Form} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'
import {withRouter, useLocation} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_diario_bordo.svg'
import IcQuestion from '../../assets/icons/question.svg'
import Illustration2 from '../../assets/illustrations/illustration_sitting.svg'
import {SaveStepAnswer} from '../../infra/requests/ProgramRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {
  GetValueLanguage,
  UpdateUserProgram
} from '../../infra/utils/CommonFunctions'
import {
  MainContainerBackground,
  MainContainerScroll3,
  MainContainer3,
  MainContainerScroll2,
  MainContainer2,
  RegisterContainer
} from '../../pages/step/StepStyles'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {PageForm, IconQuestion} from '../../styles/BasicStyles'
import {TextColor, SecondaryColour, White} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import HistoryComponent from '../history/HistoryComponent'
import DichotomousQuestion from '../question/DichotomousQuestion'
import FooterFeedbackComponent from '../question/FooterFeedbackComponent'
import QuestionSlide from '../question/QuestionSlide'
import {OptionText} from '../question/QuestionStyles'
import QuestionTextArea from './QuestionTextArea'
import ResultComponent from './ResultComponent'
import TextComponent from './TextComponent'

const formRules = FormValidator.make({})

const DiarioBordo2 = ({
  step,
  chapter,
  nextStep,
  history,
  activeLanguage,
  translate,
  updateChapter
}) => {
  const dispatch = useDispatch()
  const [answered, setAnswered] = useState(false)
  const [initialValues, setInitialValues] = useState({steps: []})
  const [currentStep, setCurrentStep] = useState(step)
  const [historySelected, setHistorySelected] = useState(false)
  const [error, setError] = useState(false)
  const [editable, setEditable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const refScroll = useRef(null)
  const {state} = useLocation()
  const user = useSelector((state) => state.user)
  const isApplicator = user.type === 2
  const isProgram = !state?.noProgram

  useEffect(() => {
    function getValue(id) {
      const value = historySelected.answers.find((s) => s.step === id)

      if (value.sliderValue || value.sliderValue === 0) {
        return value.sliderValue
      }
      if (value.answer?.length) {
        return value.answer[0]
      }
      if (value.options?.length) {
        return value.options[0]
      }
      return undefined
    }

    const values = {
      steps: []
    }
    chapter.steps.map((s) => {
      const st = {_id: s._id, questions: []}
      s.questions.map((q) => {
        st.questions.push({
          _id: q._id,
          options: historySelected ? getValue(s._id) : undefined,
          answer: historySelected ? getValue(s._id) : undefined
        })
      })
      values.steps.push(st)
    })
    setInitialValues(values)
  }, [chapter, historySelected])

  const scrollTop = () => {
    if (refScroll?.current) {
      refScroll.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  const onSubmit = async (values) => {
    if (!editable || isApplicator) {
      setCurrentStep(chapter.steps[currentStep.order])
      scrollTop()
      return null
    }

    try {
      setIsLoading(true)
      const fields = []
      values.steps.forEach((field) => {
        const newField = {
          user: user._id,
          chapter: chapter._id,
          step: field._id
        }
        field.questions.forEach((question) => {
          const object = {
            ...newField,
            question: question._id
          }
          if (Number.isInteger(question.options)) {
            object.sliderValue = question.options
          } else if (question.answer) object.answer = [question.answer]
          else if (question.options) object.options = [question.options]

          if (
            !object.options?.length &&
            !object.answer?.length &&
            !object.sliderValue &&
            object.sliderValue !== 0
          ) {
            setError('ERRO_PREENCHER_REGISTO')
            throw error
          }
          fields.push(object)
        })
      })

      const result = await SaveStepAnswer(fields)

      if (result.success) {
        setCurrentStep(chapter.steps[currentStep.order])
        dispatch(UpdateUserScore(result.data.points))
        scrollTop()
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.warn(e)
    }
  }

  const onNextStep = (values) => {
    setError(false)
    const next = state?.next || false
    if (chapter.steps[currentStep.order]) {
      if (!editable) {
        if (currentStep.order === 7) {
          setAnswered(false)
          setCurrentStep(chapter.steps[currentStep.order + 1])
          return
        }
        if (currentStep.order + 2 === chapter.steps.length) {
          if (isProgram && !isApplicator) {
            UpdateUserProgram(updateChapter, chapter._id, dispatch)
          }

          if (next) {
            if (next === 'isBack') history.goBack()
            else history.push(next)
          } else history.goBack()
          return
        }
        setAnswered(false)
        setCurrentStep(chapter.steps[currentStep.order])
        return
      }
      if (currentStep.order + 2 === chapter.steps.length) {
        onSubmit(values)
      } else if (
        currentStep.questions[0]?.wrong ||
        currentStep.questions[0]?.correct
      ) {
        if (currentStep.order === 17) {
          const correctOpt =
            values.steps[currentStep.order - 1].questions[0].options
          const selectedOpt = currentStep.questions[0].options.find(
            (o) => o.isCorrect
          )?._id
          if (correctOpt === selectedOpt && answered !== 2) {
            setAnswered(2)
          } else {
            setAnswered(false)
            setCurrentStep(chapter.steps[currentStep.order])
          }
        } else if (!answered) setAnswered(2)
        else if (answered === 2) {
          setAnswered(false)
          setCurrentStep(chapter.steps[currentStep.order])
        } else {
          setAnswered(2)
        }
      } else {
        setAnswered(false)
        setCurrentStep(chapter.steps[currentStep.order])
      }
      scrollTop()
    } else {
      if (isProgram && !isApplicator) {
        UpdateUserProgram(updateChapter, chapter._id, dispatch)
      }

      if (next) {
        if (next === 'isBack') history.goBack()
        else history.push(next)
      } else if (nextStep) {
        history.push(`/step/${nextStep._id}`)
      } else history.push('/program')
    }
  }

  const onCloseStep = () => {
    if (state?.back) {
      if (state?.back === 'isBack') history.goBack()
      else history.push(state.back)
    } else history.push('/program')
  }

  const onBackStep = () => {
    setError(false)
    setAnswered(false)
    if (chapter.steps[currentStep.order - 2]) {
      setCurrentStep(chapter.steps[currentStep.order - 2])
      scrollTop()
    } else history.goBack()
  }

  const renderFooterFeedback = () => {
    if (error) {
      return (
        <FooterFeedbackComponent>
          <OptionText style={{display: 'flex', alignItems: 'center'}}>
            {translate(error)}
          </OptionText>
        </FooterFeedbackComponent>
      )
    }
    const question = currentStep.questions[0]
    switch (answered) {
      case 2: {
        return (
          <FooterFeedbackComponent isCorrect>
            <OptionText style={{display: 'flex', alignItems: 'center'}}>
              {GetValueLanguage(question?.correct, activeLanguage?.code)}
            </OptionText>
          </FooterFeedbackComponent>
        )
      }

      default:
        return null
    }
  }

  const renderContent = () => {
    switch (currentStep.order) {
      case 3:
      case 4:
      case 5:
      case 7:
        return (
          <RegisterContainer>
            <QuestionTextArea
              disabled={!editable}
              step={currentStep}
              onChange={(value) => {
                setAnswered(true)
              }}
            />
          </RegisterContainer>
        )

      case 6:
      case 14:
      case 16:
        return (
          <RegisterContainer>
            <FieldArray name={`steps[${currentStep.order - 1}].questions`}>
              {({fields}) =>
                currentStep.questions.map((question, i) => (
                  <QuestionSlide
                    disabled={!editable}
                    fieldName={`${fields.name}[${i}]`}
                    question={question}
                    titleColor={TextColor}
                    onChangeValue={(value) => {
                      setAnswered(true)
                    }}
                    min={0}
                    max={100}
                    defaultValue={50}
                  />
                ))
              }
            </FieldArray>
          </RegisterContainer>
        )

      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 17:
        return (
          <RegisterContainer
            style={{
              backgroundImage: `url(${Illustration2})`,
              backgroundPosition: 'left bottom',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <FieldArray name={`steps[${currentStep.order - 1}].questions`}>
              {({fields}) =>
                currentStep.questions.map((question, i) => (
                  <DichotomousQuestion
                    disabled={!editable}
                    fieldName={`${fields.name}[${i}]`}
                    question={question}
                    onChangeValue={(value) => {
                      // setAnswered(true)
                    }}
                  />
                ))
              }
            </FieldArray>
          </RegisterContainer>
        )

      case 15:
        return (
          <RegisterContainer>
            <QuestionTextArea
              disabled={!editable}
              step={currentStep}
              onChange={(value) => {
                setAnswered(true)
              }}
              showValue
            />
          </RegisterContainer>
        )

      case 19:
        return (
          <RegisterContainer style={{minHeight: 'auto'}}>
            <ResultComponent step={currentStep} />
          </RegisterContainer>
        )

      default:
        return (
          <TextComponent step={currentStep} onClickNext={onNextStep} />
        )
    }
  }

  const onClickHistory = (data, form) => {
    if (historySelected.date === data.date) {
      setEditable(true)
      setHistorySelected(false)
      form.reset()
    } else {
      setEditable(false)
      setHistorySelected(data)
    }
  }

  const renderAllContent = (form) => {
    switch (currentStep.order) {
      case 1:
      case 2:
      case 8:
      case 18:
        return (
          <MainContainerScroll3>
            <MainContainer3 ref={refScroll}>
              <FieldArray name='steps' component={renderContent} />
            </MainContainer3>
          </MainContainerScroll3>
        )

      default:
        return (
          <MainContainerScroll2>
            <MainContainer2 ref={refScroll}>
              <TitleH3 isBold color={White}>
                {GetValueLanguage(chapter.title, activeLanguage?.code)}
                <IconQuestion
                  src={IcQuestion}
                  onClick={() => setCurrentStep(chapter.steps[0])}
                />
              </TitleH3>
              {state?.noProgram ? (
                <HistoryComponent
                  chapter={chapter._id}
                  onClickHistory={(data) => onClickHistory(data, form)}
                  selected={historySelected}
                >
                  <FieldArray name='steps' component={renderContent} />
                </HistoryComponent>
              ) : (
                <FieldArray name='steps' component={renderContent} />
              )}
            </MainContainer2>
          </MainContainerScroll2>
        )
    }
  }

  const validateNext = (values) => {
    if (!editable) {
      return false
    }
    if (currentStep.type === 'text') return false

    const stepValues = values.steps.find((s) => s._id === currentStep._id)

    if (
      stepValues?.questions?.length &&
      (stepValues?.questions[0]?.options ||
        (!stepValues?.questions[0]?.options &&
          stepValues?.questions[0]?.options === 0) ||
        stepValues?.questions[0]?.answer)
    ) {
      return false
    }

    return true
  }

  return (
    <Form
      onSubmit={onNextStep}
      validate={formRules}
      mutators={{
        ...arrayMutators
      }}
      initialValues={initialValues}
    >
      {({handleSubmit, values, form}) => (
        <PageForm onSubmit={handleSubmit}>
          <BasicHeaderComponent
            onClose={onCloseStep}
            title={chapter.title}
            progress={(currentStep.order / chapter.steps.length) * 100}
            progressColor={SecondaryColour}
          />
          <MainContainerBackground
            bgColor={TextColor}
            style={{
              backgroundImage: `url(${Illustration})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center'
            }}
          >
            {renderAllContent(form)}
          </MainContainerBackground>
          <BasicFooterComponent
            leftContent={renderFooterFeedback()}
            onBack={onBackStep}
            // onNext={() => onNextStep(values)}
            nextHtmlType='submit'
            nextDisabled={validateNext(values)}
            isLoading={isLoading}
          />
        </PageForm>
      )}
    </Form>
  )
}

DiarioBordo2.propTypes = {
  step: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  updateChapter: PropTypes.bool
}

DiarioBordo2.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  updateChapter: false
}

export default withLocalize(withRouter(DiarioBordo2))

import {Row, Col} from 'antd'
import styled from 'styled-components'

import {White} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const ForumContainer = styled.div`
  background-color: ${White};
  display: flex;
  width: 100%;
  background-image: url(${require('../../assets/backgrounds/background_forum.svg')});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  min-height: calc(100vh - 64px - 109px);
`

export const ForumRow = styled(Row)`
  @media ${device.laptop} {
    flex-direction: column-reverse !important;
  }
`

export const ForumCol = styled(Col)`
  display: flex !important;
  flex-direction: column;
`

export const ForumButton = styled.div`
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;

  @media ${device.tablet} {
    margin-bottom: 16px;
  }
`

import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import Layout from '../../components/layout/Layout'
import {LoginContainer} from '../auth/LoginPageStyles'
import {TextContainer, Title, Subtitle} from './NotFoundPageStyles'

const NotFoundComponent = ({translate}) => (
  <Layout fixed auth>
    <LoginContainer>
      <TextContainer>
        <Title>404</Title>
        <Subtitle>{translate('PAGE_NOT_FOUND')}</Subtitle>
      </TextContainer>
    </LoginContainer>
  </Layout>
)

NotFoundComponent.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(NotFoundComponent)

import React, {useState} from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_objetivos_atividades.svg'
import {SaveStepAnswerObjective} from '../../infra/requests/ProgramRequests'
import {UpdateUserProgram} from '../../infra/utils/CommonFunctions'
import {
  MainContainer,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {UpdateProgram} from '../../redux/Info/info.actions'
import {Beige3} from '../../styles/Colours'
import ChartMeditationComponent from '../charts/ChartMeditationComponent'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'

const Step4_7_1 = ({step, history, nextStep, chapter, updateChapter}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector((state) => state.user)
  const isApplicator = user.type === 2
  const progress = (step.order / chapter.steps.length) * 100
  const isFirst = step.order === 1

  const onNextStep = async () => {
    setIsLoading(true)
    if (!chapter.completed && !isApplicator) {
      // new upd due to unblock sessions, it is needed save answer to set chapter as completed
      const fields = {
        user: undefined,
        chapter: chapter._id,
        step: step._id,
        updateChapter: updateChapter && !isApplicator
      }
      const result = await SaveStepAnswerObjective(fields)
      // if (result.success) dispatch(UpdateProgram(result.data.sessions))
    }
    // if (updateChapter && !isApplicator) {
    //   UpdateUserProgram(updateChapter, chapter._id, dispatch)
    // }

    setIsLoading(false)
    if (nextStep) history.push(`/step/${nextStep._id}`)
    else history.push('/program')
  }

  return (
    <>
      <BasicHeaderComponent
        onClose={() => history.push('/program')}
        title={chapter.title}
        progress={progress}
      />
      <MainContainerBackground
        bgColor={Beige3}
        style={{
          backgroundImage: `url(${Illustration})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      >
        <MainContainerScroll>
          <MainContainer>
            <MessageComponent isFull>
              <ChartMeditationComponent isStatic />
            </MessageComponent>
            {step.type === 'text' && (
              <>
                {step.descriptions.map((description, index) => (
                  <MessageComponent
                    key={index}
                    text={description.title}
                    active={
                      chapter.order === 3 && step.order === 2
                        ? false
                        : index === step.descriptions.length - 1
                    }
                  />
                ))}
              </>
            )}
          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackground>
      <BasicFooterComponent
        onBack={isFirst ? false : () => history.goBack()}
        onNext={onNextStep}
        isLoading={isLoading}
      />
    </>
  )
}
Step4_7_1.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  updateChapter: PropTypes.bool
}

Step4_7_1.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  updateChapter: false
}
export default withLocalize(withRouter(Step4_7_1))

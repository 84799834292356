import styled from 'styled-components'

export const Layout = styled.div``

export const MainContainer = styled.div`
  padding-top: ${({fixed}) => (fixed ? 0 : '64px')};
  height: ${({fixed}) => (fixed ? 'calc(100vh - 109px)' : '100%')};
`

export const MainBasicContainer = styled.div`
  height: 100vh;
`

export const Dashboard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${(p) => p.theme.primaryBackgroundColor};
  text-align: center;
`

import React, {useState} from 'react'

import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import {withLocalize} from 'react-localize-redux'

import UserIcon from '../../assets/icons/user_icon.svg'
import {LoadingSpin} from '../../styles/BasicStyles'
import {ErrorColor} from '../../styles/Colours'
import {BodyText} from '../../styles/TextSizes'
import ImageCropModal from './ImageCropModal'
import {
  ContainerCircle,
  ImageContainer,
  Image,
  PhotoContainer
} from './ImagePickerStyles'

const ImagePickerComponent = ({image, onSelectImage, isLoading}) => {
  const [errorMessage, setErrorMessage] = useState(false)
  const [openCrop, setOpenCrop] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [loading, setLoading] = useState(false)

  const onCloseCrop = async (img) => {
    if (img) {
      setLoading(true)
      onSelectImage(img)
      setOpenCrop(false)
      setUploaded(undefined)
      setLoading(false)
    } else {
      setLoading(false)
      setOpenCrop(false)
      setUploaded(undefined)
    }
  }

  const handleImageDrop = (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      setErrorMessage('ERROR_IMAGE_PROFILE')
    }
    const file = {
      preview: URL.createObjectURL(accepted[0]),
      size: accepted[0].size,
      type: accepted[0].type,
      blob: accepted[0]
    }
    setOpenCrop(true)
    setUploaded(file)
  }

  return (
    <>
      <Dropzone
        onDrop={handleImageDrop}
        style={{
          display: 'inline-block',
          float: 'right',
          marginBottom: '30px'
        }}
        accept='image/jpeg, image/png'
        maxSize={5000000}
      >
        {({getRootProps, getInputProps}) => (
          <ContainerCircle {...getRootProps()} style={{outline: 'none'}}>
            <input {...getInputProps()} />
            {image && (!loading || !isLoading) ? (
              <ImageContainer>
                <Image url={image} />
              </ImageContainer>
            ) : (
              <PhotoContainer>
                {isLoading || loading ? (
                  <LoadingSpin />
                ) : (
                  <>
                    <img
                      src={UserIcon}
                      alt='profile img'
                      style={{width: '100%'}}
                    />
                    {errorMessage && (
                      <BodyText color={ErrorColor}>
                        {errorMessage}
                      </BodyText>
                    )}
                  </>
                )}
              </PhotoContainer>
            )}
          </ContainerCircle>
        )}
      </Dropzone>
      <ImageCropModal
        visible={openCrop}
        image={uploaded}
        loading={loading || isLoading}
        onClose={onCloseCrop}
      />
    </>
  )
}

ImagePickerComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  image: PropTypes.object,
  onPressClose: PropTypes.func,
  onSelectImage: PropTypes.func,
  isLoading: PropTypes.bool
}

ImagePickerComponent.defaultProps = {
  image: undefined,
  onPressClose: undefined,
  onSelectImage: undefined,
  isLoading: false
}

export default withLocalize(ImagePickerComponent)

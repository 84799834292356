import {Row, Col} from 'antd'
import styled from 'styled-components'

import {
  MenuBorderColor,
  White,
  ActiveBorderColor,
  HoverBorderColor
} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const EvaluationBlock = styled.div`
  padding: 0px 4px;
  width: ${({width}) => width || 20}%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EvaluationOption = styled.div`
  border-radius: 50%;
  background-color: ${White};
  border: 2px solid
    ${({isActive}) => (isActive ? ActiveBorderColor : HoverBorderColor)};
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    background-color: ${({isActive}) =>
      isActive ? ActiveBorderColor : White};
  }
`

export const QuestionOptionEvaluation = styled.div`
  padding: 12px 16px;
  background: ${White};
  border: 2px solid;
  border-color: ${MenuBorderColor};
  box-sizing: border-box;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media ${device.laptopL} {
    padding: 8px 16px;
  }

  @media ${device.tablet} {
    padding: 8px;
  }
`

export const RowEvaluation = styled(Row)`
  @media ${device.tabletMin} {
    display: none;
  }
`

export const RowEvaluation2 = styled(Row)`
  display: none;

  @media ${device.tabletMin} {
    display: flex;
  }
`

export const EvaluationOptionsContainer = styled(Row)`
  margin-bottom: 16px;
  width: 100%;
  height: 100%;

  @media ${device.tabletMin} {
    margin-bottom: 24px;
  }
`

export const EvaluationCol = styled(Col)`
  @media ${device.tabletMin} {
    margin-bottom: 16px;
    border-bottom: 2px solid ${MenuBorderColor};
  }
`

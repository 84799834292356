import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {
  YourAreaBox,
  DashboardBox,
  DashboardComboBox,
  DashboardRow,
  DashboardCol
} from '../../pages/dashboard/DashboardStyles'
import {Margin} from '../../styles/BasicStyles'
import {White} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import ChartMeditationComponent from '../charts/ChartMeditationComponent'
import ForumBoxComponent from './ForumBoxComponent'

const YourAreaComponent = ({translate, history}) => {
  const {user} = useSelector((state) => state)
  const isStudent = user.type === 4
  const isEducator = user.type === 3
  const width = isEducator ? 24 : 12

  return (
    <DashboardBox
      column
      bgColor='#FFFFFF'
      image={`url(${require('../../assets/backgrounds/background_yourArea.png')})`}
    >
      <TitleH3 style={{textAlign: 'left'}} weight={700}>
        {translate(
          isStudent ? 'MORE_YOUR_AREA' : 'MAIS_SUA_AREA_EDUCADOR'
        )}
      </TitleH3>
      <Margin size={16} />
      <DashboardRow gutter={[16, 16]}>

        {!isEducator &&
        <DashboardCol xs={24} lg={12}>
          <DashboardComboBox style={{flex: 1}} bgColor={White}>
            <ChartMeditationComponent
              previewChart
              seeAll={() => history.push('/profile')}
            />
          </DashboardComboBox>
        </DashboardCol>
        }

        <DashboardCol xs={24} lg={width}>
          <YourAreaBox>
            <ForumBoxComponent />
          </YourAreaBox>
        </DashboardCol>
      </DashboardRow>
    </DashboardBox>
  )
}

YourAreaComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withLocalize(withRouter(YourAreaComponent))

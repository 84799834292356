import React from 'react'

import {PropTypes} from 'prop-types'
import Confetti from 'react-confetti'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {White} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import MessageComponent from '../message/MessageComponent'
import {ScoreContainer} from './ScoreStyles'

const ScoreComponent = ({step, activeLanguage, children}) => {
  const title = GetValueLanguage(step.title, activeLanguage?.code)

  return (
    <>
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={800}
        tweenDuration={30000}
      />
      {title && (
        <TitleH3 style={{textAlign: 'center'}} isBold color={White}>
          {title}
        </TitleH3>
      )}
      <ScoreContainer>{children}</ScoreContainer>
      {step.descriptions.map((description, index) => (
        <MessageComponent
          key={index}
          text={description.title}
          active={index === step.descriptions.length - 1}
        />
      ))}
    </>
  )
}

ScoreComponent.propTypes = {
  step: PropTypes.object.isRequired,
  children: PropTypes.object,
  activeLanguage: PropTypes.object.isRequired
}

ScoreComponent.defaultProps = {
  children: undefined
}
export default withLocalize(withRouter(ScoreComponent))

import React, {useRef, useState} from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import AscendentePT from '../../assets/3.3/ascendente_pt.gif'
import AscendenteEN from '../../assets/3.3/ascendente_en.gif'
import DescendentePT from '../../assets/3.3/descendente_pt.gif'
import DescendenteEN from '../../assets/3.3/descendente_en.gif'
import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {
  SuccessBgColor,
  ErrorBgColor,
  InactiveTextColor
} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import {QuestionOption, OptionText} from '../question/QuestionStyles'
import {
  OptionsContainer,
  IntroductionBlock,
  Illustration,
  QuestionTitle,
  ExplanationContainer
} from './JogoEspiraisStyles'

const Question1 = ({step, activeLanguage}) => {
  const refExplanation = useRef(null)
  const [animation, setAnimation] = useState(undefined)

  const goToExplanation = () => {
    setTimeout(() => {
      if (refExplanation?.current) {
        refExplanation.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }, 300)
  }

  const onSelectOption = (idOption, form, name) => {
    form.change(name, idOption)
    goToExplanation()
  }

  const optionsArray = opt => {
    const {options, form, input} = opt
    const currentOption = input?.value || undefined

    const currentOptionObj =
      options.find(o => o._id === input?.value) || undefined

    if (currentOptionObj && currentOptionObj?.explanation) {
      goToExplanation()
    }

    const isCorrect = currentOptionObj?.isCorrect ? input?.value : false

    return (
      <>
        {options.map((option, index) => (
          <Col xs={24} md={12} key={index}>
            <QuestionOption
              className={
                animation === index
                  ? isCorrect
                    ? 'animated'
                    : 'animatedWrong'
                  : ''
              }
              onClick={() => {
                if (!(isCorrect && isCorrect !== option._id)) {
                  onSelectOption(option._id, form, input.name)
                  setAnimation(index)
                }
              }}
              onAnimationEnd={() => setAnimation(undefined)}
              isActive={currentOption === option._id && option.isCorrect}
              isWrong={currentOption === option._id && !option.isCorrect}
              style={{marginBottom: 0}}
            >
              <OptionText
                color={
                  isCorrect && isCorrect !== option._id
                    ? InactiveTextColor
                    : undefined
                }
              >
                {GetValueLanguage(option.title, activeLanguage?.code)}
              </OptionText>
            </QuestionOption>
          </Col>
        ))}
        <Col xs={24}>
          <ExplanationContainer
            ref={refExplanation}
            isActive={currentOptionObj && currentOptionObj?.explanation}
            bgColor={
              currentOptionObj?.isCorrect ? SuccessBgColor : ErrorBgColor
            }
            borderColor={
              currentOptionObj?.isCorrect ? SuccessBgColor : ErrorBgColor
            }
          >
            {currentOptionObj?.explanation && (
              <QuestionText
                dangerouslySetInnerHTML={{
                  __html: GetValueLanguage(
                    currentOptionObj.explanation,
                    activeLanguage?.code
                  )
                }}
              />
            )}
          </ExplanationContainer>
        </Col>
      </>
    )
  }

  const getSpiralImage = () => {
    if (step.order === 3) {
      if (activeLanguage?.code === 'pt') return AscendentePT
      if (activeLanguage?.code === 'en') return AscendenteEN
    }

    if (activeLanguage?.code === 'pt') return DescendentePT
    if (activeLanguage?.code === 'en') return DescendenteEN

    return ''
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({form}) => (
        <>
          <IntroductionBlock>
            <QuestionText isBold>
              {GetValueLanguage(step.title, activeLanguage?.code)}
            </QuestionText>
            <Illustration
              src={getSpiralImage()}
            />
          </IntroductionBlock>
          <FieldArray name={`steps[${step.order - 1}].questions`}>
            {({fields}) =>
              step.questions.map((question, i) => (
                <div key={i}>
                  <QuestionTitle>
                    {GetValueLanguage(
                      question.title,
                      activeLanguage?.code
                    )}
                  </QuestionTitle>
                  <OptionsContainer gutter={[16, 16]}>
                    <Field
                      name={`${fields.name}[${i}].options`}
                      component={optionsArray}
                      options={question.options}
                      form={form}
                    />
                  </OptionsContainer>
                </div>
              ))
            }
          </FieldArray>
        </>
      )}
    </FormSpy>
  )
}

Question1.propTypes = {
  step: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(withRouter(Question1))

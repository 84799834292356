import {SAVE_USER, UPDATE_USER_SCORE} from '../ActionsType'

const user = {}

export default (state = user, action) => {
  switch (action.type) {
    case SAVE_USER:
      return {...action.user}

    case UPDATE_USER_SCORE:
      if (action.points) {
        return {...state, points: state.points + action.points}
      }
      return state

    default:
      return state
  }
}

import React from 'react'

import {Switch} from 'react-router-dom'

import NotFoundComponent from '../pages/notFound/NotFoundPage'
import CombinedRoutes from './CombinedRoutes'
import AuthenticatedRoute from './types/AuthenticatedRoute'
import UnauthenticatedRoute from './types/UnauthenticatedRoute'

const ApplicationRoutes = ({childProps}) => (
  <Switch>
    {CombinedRoutes.map((route, key) =>
      route.isAuthenticated ? (
        <AuthenticatedRoute
          key={key}
          path={route.path}
          exact
          permission={route.permission}
          component={route.component}
          props={childProps}
          basicHeader={route.path.indexOf('/step') >= 0 || false}
        />
      ) : (
        <UnauthenticatedRoute
          path={route.path}
          key={key}
          exact
          component={route.component}
          props={childProps}
        />
      )
    )}
    <UnauthenticatedRoute
      component={NotFoundComponent}
      props={childProps}
    />
  </Switch>
)

export default ApplicationRoutes

import React, {useState, useEffect, useRef} from 'react'

import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Form, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'
import {withRouter, useLocation} from 'react-router-dom'

import Illustration from '../../assets/illustrations/step2_6_2.png'
import {SaveStepAnswer} from '../../infra/requests/ProgramRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {
  GetStepName,
  GetValueLanguage,
  UpdateUserProgram
} from '../../infra/utils/CommonFunctions'
import {
  MainContainerBackground,
  MainContainer2,
  RegisterContainer,
  MainContainerScroll2
} from '../../pages/step/StepStyles'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {PageForm} from '../../styles/BasicStyles'
import {White} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import HistoryComponent from '../history/HistoryComponent'
import FooterFeedbackComponent from '../question/FooterFeedbackComponent'
import QuestionSelect from '../question/QuestionSelect'
import {OptionText} from '../question/QuestionStyles'
import QuestionThermometer from './QuestionThermometer'
import TextComponent from './TextComponent'

const formRules = FormValidator.make({})

const DiarioHumor = ({
  step,
  chapter,
  nextStep,
  history,
  activeLanguage,
  translate,
  updateChapter
}) => {
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({steps: []})
  const [currentStep, setCurrentStep] = useState(step)
  const [historySelected, setHistorySelected] = useState(false)
  const [editable, setEditable] = useState(true)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const refScroll = useRef(null)
  const {user, info} = useSelector((state) => state)
  const isApplicator = user.type === 2
  const stepName = GetStepName(info.program)
  const {state} = useLocation()
  const isProgram = !state?.noProgram

  useEffect(() => {
    function getValue(order) {
      const value = historySelected.answers[order - 1]
      if (value.sliderValue || value.sliderValue === 0) {
        return value.sliderValue
      }
      if (value.answer?.length) {
        return value.answer[0]
      }
      if (value.options?.length) {
        return value.options[0]
      }
      return undefined
    }

    const values = {
      steps: []
    }

    chapter.steps.map((s) => {
      const st = {_id: s._id, questions: []}
      s.questions.map((q) => {
        st.questions.push({
          _id: q._id,
          options: historySelected ? getValue(s.order) : undefined,
          other:
            historySelected &&
            historySelected.answers[s.order - 1].answer?.length
              ? historySelected.answers[s.order - 1].answer[0]
              : undefined
        })
      })
      values.steps.push(st)
    })
    setInitialValues(values)
  }, [chapter, historySelected])

  const scrollTop = () => {
    if (refScroll) {
      refScroll.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  const onSubmit = async (values) => {
    if (!editable || isApplicator) {
      setCurrentStep(chapter.steps[currentStep.order])
      scrollTop()
      return null
    }

    try {
      setIsLoading(true)
      const fields = []
      values.steps.forEach((field) => {
        const newField = {
          user: undefined,
          chapter: chapter._id,
          step: field._id
        }
        field.questions.forEach((question) => {
          const object = {
            ...newField,
            question: question._id
          }
          if (Number.isInteger(question.options)) {
            object.sliderValue = question.options
          } else if (question.other) object.answer = [question.other]
          else if (question.options) object.options = [question.options]

          if (
            !object.options?.length &&
            !object.answer?.length &&
            !object.sliderValue &&
            object.sliderValue !== 0
          ) {
            setError('ERRO_PREENCHER_REGISTO')
            throw error
          }

          fields.push(object)
        })
      })

      const result = await SaveStepAnswer(fields)
      if (result.success) {
        setCurrentStep(chapter.steps[currentStep.order])
        scrollTop()
        dispatch(UpdateUserScore(result.data.points))
        if (isProgram && !isApplicator) {
          UpdateUserProgram(updateChapter, chapter._id, dispatch)
        }
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.warn(e)
    }
  }

  const onNextStep = (values) => {
    setError(false)
    const next = state?.next || false
    if (chapter.steps[currentStep.order]) {
      if (currentStep.order + 1 === chapter.steps.length) {
        onSubmit(values)
      } else {
        setCurrentStep(chapter.steps[currentStep.order])
        scrollTop()
      }
    } else if (next) {
      if (next === 'isBack') history.goBack()
      else history.push(next)
    } else if (nextStep) {
      history.push(
        `/${stepName}/${nextStep._id}`
      )
    } else history.push('/program')
  }

  const onBackStep = () => {
    setError(false)
    if (chapter.steps[currentStep.order - 2]) {
      setCurrentStep(chapter.steps[currentStep.order - 2])
      scrollTop()
    } else history.goBack()
  }

  const onCloseStep = () => {
    if (state?.back) {
      if (state?.back === 'isBack') history.goBack()
      else history.push(state.back)
    } else history.push('/program')
  }

  const verifyForm = (values) => {
    if (!editable) {
      return false
    }
    if (currentStep.type === 'text') return false
    if (
      !values?.steps[currentStep.order - 1]?.questions[0]?.options &&
      values?.steps[currentStep.order - 1]?.questions[0]?.options !== 0 &&
      !values?.steps[currentStep.order - 1]?.questions[0]?.other
    ) {
      return true
    }
    return false
  }

  const renderContent = () => {
    switch (currentStep.order) {
      case 1:
        return (
          <FormSpy subscription={{values: true}}>
            {() => (
              <RegisterContainer>
                <FieldArray
                  name={`steps[${currentStep.order - 1}].questions`}
                >
                  {({fields}) =>
                    currentStep.questions.map((question, i) => (
                      <div
                        key={i}
                        style={{
                          display: 'flex',
                          height: '100%',
                          flexDirection: 'column'
                        }}
                      >
                        <QuestionThermometer
                          disabled={!editable}
                          step={currentStep}
                          fieldName={`${fields.name}[${i}]`}
                        />
                      </div>
                    ))
                  }
                </FieldArray>
              </RegisterContainer>
            )}
          </FormSpy>
        )

      case 2:
        return (
          <FormSpy subscription={{values: true}}>
            {() => (
              <RegisterContainer
                style={{
                  backgroundImage: `url(${Illustration})`,
                  backgroundPosition: 'right bottom',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <FieldArray
                  name={`steps[${currentStep.order - 1}].questions`}
                >
                  {({fields}) =>
                    currentStep.questions.map((question, i) => (
                      <div key={i}>
                        <QuestionSelect
                          disabled={!editable}
                          fieldName={`${fields.name}[${i}]`}
                          question={question}
                        />
                      </div>
                    ))
                  }
                </FieldArray>
              </RegisterContainer>
            )}
          </FormSpy>
        )

      default:
        return (
          <TextComponent
            editable={editable}
            step={currentStep}
            onClickNext={onNextStep}
          />
        )
    }
  }

  const onClickHistory = (data, form) => {
    if (historySelected.date === data.date) {
      setEditable(true)
      setHistorySelected(false)
      form.reset()
    } else {
      setEditable(false)
      setHistorySelected(data)
    }
  }

  const renderFooterFeedback = () => {
    if (error) {
      return (
        <FooterFeedbackComponent>
          <OptionText style={{display: 'flex', alignItems: 'center'}}>
            {translate(error)}
          </OptionText>
        </FooterFeedbackComponent>
      )
    }
    return null
  }

  return (
    <Form
      onSubmit={onNextStep}
      validate={formRules}
      mutators={{
        ...arrayMutators
      }}
      initialValues={initialValues}
    >
      {({handleSubmit, values, form}) => (
        <PageForm onSubmit={handleSubmit}>
          <BasicHeaderComponent
            onClose={onCloseStep}
            title={chapter.title}
            progress={(currentStep.order / chapter.steps.length) * 100}
          />
          <MainContainerBackground bgColor={White}>
            <MainContainerScroll2>
              <MainContainer2 ref={refScroll}>
                <TitleH3 isBold>
                  {GetValueLanguage(chapter.title, activeLanguage?.code)}
                </TitleH3>
                {state?.noProgram ? (
                  <HistoryComponent
                    isFull
                    chapter={chapter._id}
                    onClickHistory={(data) => onClickHistory(data, form)}
                    selected={historySelected}
                  >
                    <FieldArray name='steps' component={renderContent} />
                  </HistoryComponent>
                ) : (
                  <FieldArray name='steps' component={renderContent} />
                )}
              </MainContainer2>
            </MainContainerScroll2>
          </MainContainerBackground>
          <BasicFooterComponent
            leftContent={renderFooterFeedback()}
            onBack={onBackStep}
            nextHtmlType='submit'
            nextDisabled={verifyForm(values)}
            isLoading={isLoading}
          />
        </PageForm>
      )}
    </Form>
  )
}

DiarioHumor.propTypes = {
  step: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  updateChapter: PropTypes.bool
}

DiarioHumor.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  updateChapter: false
}

export default withLocalize(withRouter(DiarioHumor))

import React from 'react'

import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Form, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {
  SaveStepAnswer,
  WatchVideo
} from '../../infra/requests/ProgramRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {
  GetProgramName,
  GetStepName,
  GetValueLanguage,
  GetVideoByLanguage,
  getVideoEducator,
  UpdateUserProgram
} from '../../infra/utils/CommonFunctions'
import {
  MainContainerFlex,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {PageForm, Margin} from '../../styles/BasicStyles'
import {QuestionText} from '../../styles/TextSizes'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import {IntroductionBlock, QuestionTitle} from '../jogo_espirais/JogoEspiraisStyles'
import DichotomousQuestion from '../question/DichotomousQuestion'
import FooterFeedbackComponent from '../question/FooterFeedbackComponent'
import QuestionLine from '../question/QuestionLine'
import {OptionsContainer, OptionText} from '../question/QuestionStyles'
import VideoPlayer from './VideoPlayer'

const formRules = FormValidator.make({})

class VideoPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValues: {steps: []},
      currentStep: props.step,
      viewVideo: false,
      isLoading: false
    }
  }

  componentDidMount = () => {
    const {chapter} = this.props
    const values = {
      steps: []
    }
    chapter.steps.map((s) => {
      const st = {_id: s._id, questions: []}
      s.questions.map((q) => {
        st.questions.push({
          _id: q._id,
          options: undefined
        })
      })
      values.steps.push(st)
    })

    this.setState({initialValues: values})
  }

  scrollTop = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  goNext = () => {
    const {
      location,
      history,
      chapter,
      numberOfSteps,
      nextStep,
      step,
      info
    } = this.props
    const {currentStep} = this.state
    const stepName = GetStepName(info.program)
    const programName = GetProgramName(info.program)

    if (
      chapter.steps[currentStep.order] &&
      nextStep &&
      numberOfSteps &&
      numberOfSteps + step.order <= currentStep.order
    ) {
      history.push(
        `/${stepName}/${nextStep._id}`
      )
    } else if (location.state?.next) {
      if (location.state?.next === 'isBack') history.goBack()
      else history.push(location.state?.next)
    } else if (nextStep) {
      history.push(
        `/${stepName}/${nextStep._id}`
      )
    } else history.push(`/${programName}`)
  }

  onSubmit = async (values) => {
    const {
      user,
      dispatch,
      isRoteiro,
      step,
      chapter,
      updateChapter
    } = this.props
    const isApplicator = user.type === 2
    if (isApplicator) return this.goNext()

    try {
      this.setState({isLoading: true})
      let fields = []
      if (isRoteiro) {
        fields = [
          values.steps[step.order - 1],
          values.steps[step.order],
          values.steps[step.order + 1]
        ]
      } else {
        fields = values.steps
      }

      const newFields = []
      fields.forEach((field) => {
        const newF = {
          user: user._id,
          chapter: chapter._id,
          step: field._id
        }
        field.questions.forEach((question) => {
          newFields.push({
            ...newF,
            question: question._id,
            options: [question.options]
          })
        })
      })

      const result = await SaveStepAnswer(newFields)
      if (!isApplicator) {
        UpdateUserProgram(updateChapter, chapter._id, dispatch)
      }
      if (result.success) {
        dispatch(UpdateUserScore(result.data.points))
        setTimeout(() => {
          this.goNext()
        }, 300)
      }

      this.setState({isLoading: false})
    } catch (e) {
      console.warn(e)
      this.setState({isLoading: false})
    }
  }

  watchVideo = async () => {
    const {history, dispatch} = this.props
    const {currentStep} = this.state
    try {
      const result = await WatchVideo({step: currentStep._id})
      if (result.success) {
        dispatch(UpdateUserScore(result.data))
        history.goBack()
      }
    } catch (e) {
      console.warn(e)
    }
  }

  onNextStep = (values) => {
    const {
      user,
      location,
      chapter,
      nextStep,
      numberOfSteps,
      step
    } = this.props
    const {currentStep} = this.state
    const isStudent = user.type === 4

    if (location.state?.isVideoteca && isStudent) {
      this.watchVideo()
    } else if (
      chapter.steps[currentStep.order] &&
      !(
        nextStep &&
        numberOfSteps &&
        numberOfSteps + step.order <= currentStep.order
      )
    ) {
      this.setState({currentStep: chapter.steps[currentStep.order]})
      this.scrollTop()
    } else {
      this.onSubmit(values)
    }
  }

  onBackStep = () => {
    const {step, isRoteiro, chapter, history} = this.props
    const {currentStep} = this.state
    if (isRoteiro && step.order === currentStep.order) history.goBack()
    else if (chapter.steps[currentStep.order - 2]) {
      this.setState({currentStep: chapter.steps[currentStep.order - 2]})
      this.scrollTop()
    } else history.goBack()
  }

  onCloseStep = () => {
    const {location, history, info} = this.props
    const programName = GetProgramName(info.program)
    if (location.state?.back) {
      if (location.state?.back === 'isBack') history.goBack()
      else history.push(location.state.back)
    } else history.push(`/${programName}`)
  }

  validateNext = (values) => {
    const {user} = this.props
    const {currentStep, viewVideo} = this.state
    const isStudent = user.type === 4
    const isEducator = user.type === 3

    if (
      currentStep.type === 'video' ||
      currentStep.type === 'external_video'
    ) {
      if (isEducator) return false
      if (isStudent && !viewVideo) return true
      return false
    }

    const stepValues = values.steps.find((s) => s._id === currentStep._id)

    if (!isStudent ||
      (stepValues?.questions?.length &&
      stepValues?.questions[0]?.options)
    ) {
      return false
    }

    return true
  }

  renderFooterFeedback = (values) => {
    const {activeLanguage} = this.props
    const {currentStep} = this.state
    if (
      !values?.steps[currentStep.order - 1]?.questions[0]?.options ||
      !values?.steps[currentStep.order - 1]?.questions[0]?.options.length
    ) {
      return null
    }

    const question = currentStep.questions[0]
    const option = question.options.find(
      (q) =>
        q._id === values.steps[currentStep.order - 1].questions[0].options
    )
    if (!question.correct || !question.wrong) return null

    if (option.isCorrect) {
      return (
        <FooterFeedbackComponent isCorrect>
          <OptionText style={{display: 'flex', alignItems: 'center'}}>
            {GetValueLanguage(question.correct, activeLanguage?.code)}
          </OptionText>
        </FooterFeedbackComponent>
      )
    }
    return (
      <FooterFeedbackComponent>
        <OptionText>
          {GetValueLanguage(question.wrong, activeLanguage?.code)}
        </OptionText>
      </FooterFeedbackComponent>
    )
  }

  renderContent = () => {
    const {
      activeLanguage,
      isExternal,
      isRoteiro,
      session,
      chapter,
      step,
      info,
      user
    } = this.props
    const {currentStep} = this.state

    switch (currentStep.type) {
      case 'question':
        return (
          <FormSpy subscription={{values: true}}>
            {() => (
              <>
                <Margin size={64} tablet={24} mobile={24} />
                {currentStep.title && (
                  <>
                    <QuestionTitle weight='700'>
                      {GetValueLanguage(
                        currentStep.title,
                        activeLanguage?.code
                      )}
                    </QuestionTitle>
                    <Margin size={48} />
                  </>
                )}
                <FieldArray
                  name={`steps[${currentStep.order - 1}].questions`}
                >
                  {({fields}) =>
                    currentStep.questions.map((question, i) => (
                      <div key={i}>
                        {currentStep.order === 2 ||
                        (isRoteiro &&
                          (currentStep.order === 3 ||
                            currentStep.order === 7 ||
                            currentStep.order === 11)) ? (
                          <OptionsContainer gutter={[16, 16]}>
                            <QuestionLine
                              fieldName={`${fields.name}[${i}]`}
                              question={question}
                              onChangeValue={() => {}}
                            />
                          </OptionsContainer>
                        ) : (
                          <DichotomousQuestion
                            fieldName={`${fields.name}[${i}]`}
                            question={question}
                            onChangeValue={() => {}}
                            isBig
                            isFour
                          />
                        )}
                      </div>
                    ))
                  }
                </FieldArray>
              </>
            )}
          </FormSpy>
        )

      default:
        const isEducator = user.type === 3
        const isApplicator = user.type === 2
        const stepName = GetStepName(info.program)
        const videoLang = GetVideoByLanguage(session, chapter.order, step.order, info?.videos, stepName, isApplicator, isEducator, activeLanguage.code)

        if (videoLang?.video || isExternal) {
          return (
            <>
              {step.descriptions?.length > 0 && stepName === 'step_educators' && (
                <IntroductionBlock>
                {step.descriptions.map((description, index) => (
                  <div style={{marginBottom: 24}} key={index}>
                    <QuestionText
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: GetValueLanguage(
                          description.title,
                          activeLanguage && activeLanguage.code
                        )
                      }}
                    />
                  </div>
                ))}
                </IntroductionBlock>
              )}
              { isExternal &&
                <IntroductionBlock>
                {currentStep.descriptions.map((description, index) => (
                    <div style={{marginBottom: 24}} key={index}>

                      <QuestionText
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: GetValueLanguage(
                            description.title,
                            activeLanguage && activeLanguage.code
                          )
                        }}
                      />
                    </div>

                  ))}
                </IntroductionBlock>
              }
            <VideoPlayer
              video={
                isExternal
                  ? currentStep.video
                  : // : getImage(currentStep[`video_${activeLanguage.code}`])
                    [
                      // {
                      //   src: require('../../assets/videos/1.mp4'),
                      //   type: 'video/mp4'
                      // },
                      // {
                      //   src: require(`../../assets/videos/${session}_${chapter.order}_${step.order}.webm`),
                      //   type: 'video/webm'
                      // }
                      {
                        src: `${process.env.REACT_APP_IMAGES_URL}/${videoLang.video}`,
                        type: 'video/mp4'
                      }
                    ]
              }
              track={videoLang.subtitles ? `${process.env.REACT_APP_IMAGES_URL}/${videoLang.subtitles}` : undefined}
              onEnded={() => this.setState({viewVideo: true})}
              height={isExternal ? '100%' : 'auto'}
              width={isExternal ? '100%' : 'auto'}
              maxHeight='calc(100vh - 270px)'
              maxWidth='100%'
              isExternal={isExternal}
            />
            </>
          )
        }
    }
  }

  render() {
    const {location, chapter} = this.props
    const {currentStep, initialValues, isLoading} = this.state

    return (
      <Form
        onSubmit={this.onNextStep}
        validate={formRules}
        mutators={{
          ...arrayMutators
        }}
        initialValues={initialValues}
      >
        {({handleSubmit, values}) => (
          <PageForm onSubmit={handleSubmit}>
            <BasicHeaderComponent
              onClose={this.onCloseStep}
              title={
                location.state?.isVideoteca
                  ? currentStep.title
                  : chapter.title
              }
              progress={
                location.state?.isVideoteca
                  ? 100
                  : (currentStep.order / chapter.steps.length) * 100
              }
            />
            <MainContainerBackground>
              <MainContainerScroll>
                <MainContainerFlex ref={this.refScroll} isColumn>
                  <FieldArray
                    name='steps'
                    component={this.renderContent}
                  />
                </MainContainerFlex>
              </MainContainerScroll>
            </MainContainerBackground>
            <BasicFooterComponent
              leftContent={this.renderFooterFeedback(values)}
              onBack={this.onBackStep}
              nextHtmlType='submit'
              nextDisabled={this.validateNext(values)}
              isLoading={isLoading}
            />
          </PageForm>
        )}
      </Form>
    )
  }
}

VideoPage.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  activeLanguage: PropTypes.object.isRequired,
  chapter: PropTypes.object,
  numberOfSteps: PropTypes.number,
  isRoteiro: PropTypes.bool,
  updateChapter: PropTypes.bool,
  isExternal: PropTypes.bool,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  session: PropTypes.number.isRequired,
  info: PropTypes.object.isRequired
}

VideoPage.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  numberOfSteps: undefined,
  isRoteiro: false,
  updateChapter: false,
  isExternal: false
}

const mapStateToProps = (state) => ({
  user: state.user,
  info: state.info
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  withRouter(connect(mapStateToProps, mapActionToProps)(VideoPage))
)

import React from 'react'

import {PropTypes} from 'prop-types'
import Confetti from 'react-confetti'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Cup from '../../assets/icons/Taça.svg'
import {GetProgramName, GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {
  MainContainerBackground,
  MainContainer,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {SecondaryColour, White, ThirdColour} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'
import StarComponent from '../star/StarComponent'
import {ScoreContainer, CupContainer, ScoreBox} from './ScoreStyles'

const ScorePage = ({
  step,
  nextStep,
  chapter,
  history,
  activeLanguage,
  translate
}) => {
  const {info} = useSelector((state) => state)
  const title = GetValueLanguage(step.title, activeLanguage?.code)
  const totalQuestions = chapter.steps.filter((s) => s.type === 'question')
    .length
  const programName = GetProgramName(info.program)

  return (
    <>
      <BasicHeaderComponent
        onClose={() => history.push(`/${programName}`)}
        title={chapter.title}
        progress={(step.order / chapter.steps.length) * 100}
        progressColor={ThirdColour}
      />
      <MainContainerBackground bgColor={SecondaryColour}>
        <MainContainerScroll>
          <MainContainer>
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
              numberOfPieces={800}
              tweenDuration={30000}
            />
            {title && (
              <TitleH3 style={{textAlign: 'center'}} isBold color={White}>
                {title}
              </TitleH3>
            )}
            <ScoreContainer>
              <CupContainer>
                <img alt='cup' src={Cup} />
              </CupContainer>
              <ScoreBox>
                {translate('ACERTASTE')} 4/{totalQuestions}
              </ScoreBox>
              <StarComponent starNumber={12} plus size='big' />
            </ScoreContainer>
            {step.descriptions.map((description, index) => (
              <MessageComponent
                key={index}
                text={description.title}
                active={index === step.descriptions.length - 1}
              />
            ))}
          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackground>
      <BasicFooterComponent
        onNext={() =>
          nextStep
            ? history.push(`/step/${nextStep._id}`)
            : history.push(`/${programName}`)
        }
      />
    </>
  )
}

ScorePage.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

ScorePage.defaultProps = {
  nextStep: undefined,
  chapter: undefined
}
export default withLocalize(withRouter(ScorePage))

import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Lock from '../../assets/icons/lock.svg'
import Play from '../../assets/icons/play.svg'
import PlayDisabled from '../../assets/icons/play_disabled.svg'
import {
  GetAudiosFromProgram,
  GetStepName,
  GetValueLanguage
} from '../../infra/utils/CommonFunctions'
import {LockIcon} from '../../pages/dashboard/DashboardStyles'
import {TextColor, InputBorderColour} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import {
  AudioLineComponent,
  MeditatecaContainer,
  MeditatecaScrollBox,
  MeditatecaShadow
} from './DiarioMeditaçaoStyles'

const MeditatecaComponent = ({
  next,
  back,
  progress,
  history,
  activeLanguage,
  height,
  color,
  isDiarioMeditacao,
  isBiblioteca
}) => {
  const {program, user} = useSelector((state) => state.info)
  const audios = GetAudiosFromProgram(program)
  const stepName = GetStepName(program)
  const isEducator = user.type === 3

  const onClickAudio = (audio) => {
    history.push(
      `/${stepName}/${audio.steps[0]._id}`,
      {
        next,
        back,
        progress,
        isDiarioMeditacao,
        isBiblioteca
      }
    )
  }

  return (
    <MeditatecaContainer height={height} bgColor={color}>
      <MeditatecaScrollBox>
        {audios.map((audio, index) => {
          const active =
            audio.sessionOrder === 8 ? program.sessions[7].enabled : true
          return (
            <AudioLineComponent
              key={index}
              isLast={index === audios.length - 1}
              item={audio}
              active={active}
            >
              {!active && (
                <LockIcon top='-10px' right='-10px'>
                  <img alt='lock' src={Lock} />
                </LockIcon>
              )}
              {isEducator && audio?.description ? (
                <QuestionText
                  style={{textAlign: 'left'}}
                  isBold
                  color={active ? TextColor : InputBorderColour}
                  dangerouslySetInnerHTML={{
                    __html: GetValueLanguage(audio.description, activeLanguage?.code)
                  }}
                />
              ) : (
                <QuestionText
                  style={{textAlign: 'left'}}
                  isBold
                  color={active ? TextColor : InputBorderColour}
                >
                  {GetValueLanguage(audio.title, activeLanguage?.code)}
                </QuestionText>
              )}
              <img
                src={active ? Play : PlayDisabled}
                alt='play'
                style={{
                  marginLeft: 8,
                  cursor: active ? 'pointer' : 'no-drop'
                }}
                onClick={!active ? () => {} : () => onClickAudio(audio)}
              />
            </AudioLineComponent>
          )
        })}
      </MeditatecaScrollBox>
      <MeditatecaShadow />
    </MeditatecaContainer>
  )
}

MeditatecaComponent.propTypes = {
  history: PropTypes.object.isRequired,
  next: PropTypes.string,
  back: PropTypes.string,
  activeLanguage: PropTypes.object.isRequired,
  progress: PropTypes.object,
  height: PropTypes.number,
  color: PropTypes.string,
  isDiarioMeditacao: PropTypes.bool,
  isBiblioteca: PropTypes.bool
}

MeditatecaComponent.defaultProps = {
  next: undefined,
  back: undefined,
  progress: undefined,
  height: undefined,
  color: undefined,
  isDiarioMeditacao: false,
  isBiblioteca: false
}

export default withLocalize(withRouter(MeditatecaComponent))

import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {
  GetValueLanguage
} from '../../infra/utils/CommonFunctions'
import {
  MainContainerFlex,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {QuestionText} from '../../styles/TextSizes'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import {IntroductionBlock} from '../jogo_espirais/JogoEspiraisStyles'
import VideoPlayer from './VideoPlayer'

class ExternalVideoPage extends React.Component {
  scrollTop = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  onBackStep = () => {
    const {history} = this.props
    history.goBack()
  }

  onCloseStep = () => {
    const {location, history} = this.props
    if (location.state?.back) {
      if (location.state?.back === 'isBack') history.goBack()
      else history.push(location.state.back)
    } else history.push('/educators_program')
  }

  render() {
    const {location, chapter, nextStep, history, step, activeLanguage} = this.props

    return (
      <>
        <BasicHeaderComponent
          onClose={this.onCloseStep}
          title={
            location.state?.isVideoteca
              ? step.title
              : chapter.title
          }
          progress={(step.order / chapter.steps.length) * 100}
        />
        <MainContainerBackground>
          <MainContainerScroll>
            <MainContainerFlex ref={this.refScroll} isColumn>
              {step.video && (
                <>
                  <IntroductionBlock>
                  {step.descriptions.map((description, index) => (
                      <div style={{marginBottom: 24}} key={index}>
                        <QuestionText
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: GetValueLanguage(
                              description.title,
                              activeLanguage && activeLanguage.code
                            )
                          }}
                          isBigger
                        />
                      </div>
                    ))}
                  </IntroductionBlock>
                  <VideoPlayer
                    video={step.video}
                    // track={require('../../assets/videos/1.vtt')}
                    height='100%'
                    width='100%'
                    maxHeight='calc(100vh - 270px)'
                    maxWidth='100%'
                    isExternal
                  />
                </>
              )}
            </MainContainerFlex>
          </MainContainerScroll>
        </MainContainerBackground>
        <BasicFooterComponent
          onBack={this.onBackStep}
          onNext={() =>
            location?.state?.next === 'isBack'
            ? history.goBack()
            : nextStep
              ? history.push(`/step_educators/${nextStep._id}`)
              : history.push('/educators_program')
          }
        />
      </>
    )
  }
}

ExternalVideoPage.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  activeLanguage: PropTypes.object.isRequired,
  chapter: PropTypes.object,
  location: PropTypes.object.isRequired
}

ExternalVideoPage.defaultProps = {
  nextStep: undefined,
  chapter: undefined
}

const mapStateToProps = (state) => ({
  user: state.user,
  info: state.info
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  withRouter(connect(mapStateToProps, mapActionToProps)(ExternalVideoPage))
)

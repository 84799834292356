import React, {useState, useEffect} from 'react'

import {notification} from 'antd'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'

import EyeOff from '../../assets/icons/eye-off.svg'
import Eye from '../../assets/icons/eye.svg'
import {BaseButton} from '../../components/buttons/BaseButton'
import {MaterialErrorLabel} from '../../components/inputs/MaterialStyles'
import TextInput from '../../components/inputs/TextInput'
import Layout from '../../components/layout/Layout'
import {ResetPassword} from '../../infra/requests/AuthRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {PageForm} from '../../styles/BasicStyles'
import {Body} from '../../styles/TextSizes'
import {
  LoginContainer,
  LoginCard,
  LogoSection,
  LoginButtonDiv,
  FormTitle,
  FormSubTitle
} from './LoginPageStyles'

import {PrimaryColour} from '../../styles/Colours'

const formRules = (values) => {
  const errors = {}
  errors.password = FormValidator.validateField(
    'password',
    values.password,
    'required|min:6'
  )
  errors.passwordconfirm = FormValidator.validateField(
    'passwordconfirm',
    values.passwordconfirm,
    'required|min:6'
  )
  if (values.password !== values.passwordconfirm) {
    errors.passwordconfirm = {_error: "The passwords don't match."}
  }
  return errors
}

const ResetPasswordPage = ({translate, history, dispatch}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordR, setShowPasswordR] = useState(false)
  const [error, setError] = useState(false)
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  const onSubmit = async (values) => {
    const {
      location: {search}
    } = history
    const params = queryString.parse(search)
    const newParams = {
      password: values.password,
      token: params.token,
      email: params.email
    }

    try {
      const {message, success} = await ResetPassword(newParams)
      if (success) {
        setShowMessage(translate('ALERT_RESET_PASSWORD'))
      } else {
        setError(
          message ? translate(message) : translate('AN_ERROR_OCCURRED')
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Layout fixed auth>
      <LoginContainer>
        <Form onSubmit={onSubmit} validate={formRules}>
          {({handleSubmit, valid, submitting}) => (
            <PageForm onSubmit={handleSubmit}>
              <LoginCard>
                <LogoSection>
                  <FormTitle>{translate('CHANGE_PASSWORD')}</FormTitle>
                </LogoSection>
                {showMessage ? (
                  <>
                    <FormSubTitle style={{marginTop: 20}}>
                      {showMessage}
                    </FormSubTitle>
                    <LoginButtonDiv>
                      <FormSubTitle marginT={16}>
                        <span onClick={() => history.push('/login')}>
                          {translate('LOGIN')}!
                        </span>
                      </FormSubTitle>
                    </LoginButtonDiv>
                  </>
                ) : (
                  <>
                    <Field
                      component={TextInput}
                      name='password'
                      typeText={showPassword ? 'text' : 'password'}
                      label={translate('NEW_PASSWORD')}
                      suffix={
                        <img
                          src={showPassword ? EyeOff : Eye}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      }
                    />
                    <Field
                      component={TextInput}
                      name='passwordconfirm'
                      typeText={showPasswordR ? 'text' : 'password'}
                      label={translate('CONFIRM_PASSWORD')}
                      suffix={
                        <img
                          src={showPasswordR ? EyeOff : Eye}
                          onClick={() => setShowPasswordR(!showPasswordR)}
                        />
                      }
                    />
                    {error && (
                      <MaterialErrorLabel>{error}</MaterialErrorLabel>
                    )}
                    <LoginButtonDiv>
                      <BaseButton
                        align='initial'
                        variant='raised'
                        htmlType='submit'
                        type='primaryLarge'
                        loading={submitting}
                        disabled={!valid}
                        text={translate('ENVIAR')}
                      />
                    </LoginButtonDiv>
                  </>
                )}
              </LoginCard>
            </PageForm>
          )}
        </Form>
      </LoginContainer>
    </Layout>
  )
}

ResetPasswordPage.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withLocalize(ResetPasswordPage)

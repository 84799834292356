import styled from 'styled-components'

import {White, MenuBorderColor} from '../../styles/Colours'
import {device} from '../../styles/Responsive'
import {Body} from '../../styles/TextSizes'

export const ThemeDescription = styled(Body)`
  white-space: pre-wrap;
  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 16px;
  }
`

export const ThemeImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 16px;
  }
`

export const BoosterSessionContainer = styled.div`
  background-color: ${White};
  display: flex;
  width: 100%;
  min-height: calc(100vh - 64px - 109px);

  @media ${device.mobileS} {
    background-image: none;
  }
`

export const FileContainer = styled.div`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${MenuBorderColor};

  @media ${device.tablet} {
    margin-bottom: 16px;
    padding: 16px;
  }
`

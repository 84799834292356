import {isEmpty} from '../../infra/utils/CommonFunctions'

export const validation1 = values => {
  let isDirty = false
  values.steps.map((s, i) => {
    if (i < 8) {
      s.questions.map(q => {
        if (q.options && q.options.length) {
          isDirty = true
        }
        q.challenges.map(c => {
          if (!isEmpty(c)) {
            isDirty = true
          }
        })
      })
    }
  })

  return isDirty
}

export const validation2 = values => {
  let isDirty = false
  const q = values.steps[8].questions[0]
  if (q.options && q.options.length) {
    isDirty = true
  }
  q.challenges.map(c => {
    if (!isEmpty(c)) {
      isDirty = true
    }
  })

  const q2 = values.steps[9].questions[0]
  q2.challenges.map(c => {
    if (!isEmpty(c)) {
      isDirty = true
    }
  })

  return isDirty
}

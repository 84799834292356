import React, {useRef, useState} from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {
  SuccessBgColor,
  ErrorBgColor,
  InactiveTextColor
} from '../../styles/Colours'
import {QuestionText, Title22} from '../../styles/TextSizes'
import {
  OptionsContainer,
  IntroductionBlock,
  QuestionTitle,
  ExplanationContainer
} from '../jogo_espirais/JogoEspiraisStyles'
import {
  QuestionOption,
  OptionText,
  QuestionImgLine
} from './QuestionStyles'

const QuestionLineExplanation = ({
  step,
  type,
  activeLanguage,
  onChangeValue,
  hasImage
}) => {
  const refExplanation = useRef(null)
  const [animation, setAnimation] = useState(undefined)

  const goToExplanation = () => {
    setTimeout(() => {
      if (refExplanation?.current) {
        refExplanation.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }, 300)
  }

  const onSelectOption = (idOption, form, name) => {
    form.change(name, idOption)
    onChangeValue(idOption)
    goToExplanation()
  }

  const optionsArray = (opt) => {
    const {options, form, input} = opt
    const currentOption = input?.value || undefined
    const currentOptionObj =
      options.find((o) => o._id === input?.value) || undefined

    if (currentOptionObj && currentOptionObj?.explanation) {
      goToExplanation()
    }

    const isCorrect = currentOptionObj?.isCorrect ? input?.value : false

    return (
      <>
        {options.map((option, index) => (
          <Col xs={24} key={index}>
            <QuestionOption
              className={
                animation === index
                  ? isCorrect
                    ? 'animated'
                    : 'animatedWrong'
                  : ''
              }
              isAuto
              onClick={() => {
                if (!(isCorrect && isCorrect !== option._id)) {
                  onSelectOption(option._id, form, input.name)
                  setAnimation(index)
                }
              }}
              onAnimationEnd={() => setAnimation(undefined)}
              isActive={currentOption === option._id && option.isCorrect}
              isWrong={currentOption === option._id && !option.isCorrect}
              style={{marginBottom: 0}}
            >
              <OptionText
                isAuto
                color={
                  isCorrect && isCorrect !== option._id
                    ? InactiveTextColor
                    : undefined
                }
              >
                {GetValueLanguage(option.title, activeLanguage?.code)}
              </OptionText>
            </QuestionOption>
          </Col>
        ))}
        <Col xs={24}>
          <ExplanationContainer
            ref={refExplanation}
            isActive={currentOptionObj && currentOptionObj?.explanation}
            bgColor={
              currentOptionObj?.isCorrect ? SuccessBgColor : ErrorBgColor
            }
            borderColor={
              currentOptionObj?.isCorrect ? SuccessBgColor : ErrorBgColor
            }
          >
            {currentOptionObj?.explanation && (
              <QuestionText
                dangerouslySetInnerHTML={{
                  __html: GetValueLanguage(
                    currentOptionObj.explanation,
                    activeLanguage?.code
                  )
                }}
              />
            )}
          </ExplanationContainer>
        </Col>
      </>
    )
  }

  const title = GetValueLanguage(step.title, activeLanguage?.code)
  return (
    <FormSpy subscription={{values: true}}>
      {({values, form}) => (
        <>
          {title && type === 5 && (
            <IntroductionBlock>
              {hasImage ? (
                <Row>
                  <Col xs={24} md={12} lg={16}>
                    <QuestionText isBold>{title}</QuestionText>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <QuestionImgLine
                      src={require(`../../assets/9.3/${hasImage}.svg`)}
                    />
                  </Col>
                </Row>
              ) : (
                <QuestionText isBold>{title}</QuestionText>
              )}
            </IntroductionBlock>
          )}
          {title && type === 4 && (
            <>
              <Title22 weight='normal'>{title}</Title22>
              <Margin size={48} vertical={24} />
            </>
          )}
          <FieldArray name={`steps[${step.order - 1}].questions`}>
            {({fields}) =>
              step.questions.map((question, i) => (
                <div key={i}>
                  <QuestionTitle>
                    {GetValueLanguage(
                      question.title,
                      activeLanguage?.code
                    )}
                  </QuestionTitle>
                  <OptionsContainer gutter={[16, 16]}>
                    <Field
                      name={`${fields.name}[${i}].options`}
                      component={optionsArray}
                      options={question.options}
                      form={form}
                    />
                  </OptionsContainer>
                </div>
              ))
            }
          </FieldArray>
        </>
      )}
    </FormSpy>
  )
}

QuestionLineExplanation.propTypes = {
  step: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func,
  type: PropTypes.number
}

QuestionLineExplanation.defaultProps = {
  onChangeValue: () => {},
  type: undefined
}

export default withLocalize(withRouter(QuestionLineExplanation))

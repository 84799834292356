import React from 'react'

import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'

import AudioPage from '../../components/audio/AudioPage'
import DiarioHumor from '../../components/diario_humor/DiarioHumor'
import MeditateComponent from '../../components/diario_meditaçao/MeditateComponent'
import EvaluationComponent from '../../components/evaluation/EvaluationComponent'
import JogoEspirais from '../../components/jogo_espirais/JogoEspirais'
import QuizComponent from '../../components/quiz/QuizComponent'
import ParentBaseStep from '../../components/steps/ParentBaseStep'
import StepDocument from '../../components/steps/StepDocument'
import StepGoal from '../../components/steps/StepGoal'
import StepImage from '../../components/steps/StepImage'
import StepText from '../../components/steps/StepText'
import ExternalVideoPage from '../../components/video/ExternalVideoPage'
import VideoPage from '../../components/video/VideoPage'
import {GetStepByIdFromProgram} from '../../infra/utils/CommonFunctions'
import {programaEducadores} from '../../infra/utils/PagesNames'
import {SaveProgram} from '../../redux/Info/info.actions'
import {StepContainer} from './StepStyles'

class StepParentPage extends React.Component {
  constructor(props) {
    super(props)
    this.scrollComponent = null
    this.state = {
      step: undefined
    }
  }

  componentDidMount = () => {
    const {program, dispatch, info} = this.props
    const isApplicator = info.profile_type === 2

    if (!program && isApplicator) {
      dispatch(
        SaveProgram(info.programs.find((p) => p._id === programaEducadores))
      )
    }
    this.GetStep()
  }

  componentDidUpdate = (prevProps) => {
    const {info, program, match} = this.props
    const isApplicator = info.profile_type === 2

    if (
      prevProps?.match?.params?.id !== match?.params?.id ||
      (prevProps?.program !== program && isApplicator)
    ) {
      this.GetStep()
    }
  }

  GetStep = () => {
    const {
      program,
      match: {params}
    } = this.props
    const stepInProgram = GetStepByIdFromProgram(params.id, program)
    this.setState({step: stepInProgram})
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  renderStep = () => {
    const {step} = this.state
    const {program, audio} = this.props

    const sessionNumber = step?.chapter?.session?.order || undefined
    const chapterNumber = step?.chapter?.order || undefined
    const stepNumber = step?.order || undefined

    let nextStep =
      program?.sessions[sessionNumber - 1]?.chapters[chapterNumber - 1]
        ?.steps[stepNumber] || undefined
    let nextChapter
    if (!nextStep) {
      nextChapter =
        program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
        undefined
    }
    if (nextChapter) [nextStep] = nextChapter.steps

    const session = program?.sessions[sessionNumber - 1]
    const chapter = session?.chapters[chapterNumber - 1]

    const NextChapter =
      nextChapter ||
      program?.sessions[sessionNumber - 1]?.chapters[chapterNumber]
    const updateChapter =
      (chapter?.order === session?.chapters.length &&
        !program?.sessions[sessionNumber]?.enabled) ||
      (NextChapter && !NextChapter.enabled)

    let {type} = step

    if (chapter.type === 5) {
      type = 'jogo_espirais'
    }

    if (chapter.type === 4) {
      type = 'quiz'
    }

    if (chapter.type === 10) {
      type = 'evaluation'
    }

    if (chapter.type === 11) {
      type = 'external_video'
    }

    if (chapter.type === 8) {
      type = 'document'
    }

    if (chapter.order === 1) {
      type = 'goal'
    }
    if ((chapter.type === 12 || chapter.type === 7) && step.type === 'text') {
      type = ''
    }

    switch (type) {
      case 'video': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <VideoPage
            isRoteiro={session.order === 1}
            numberOfSteps={session.order === 1 ? 2 : undefined}
            key={Math.random()}
            step={step}
            nextStep={
              session.order === 1 ? chapter.steps[step.order + 2] : nextS
            }
            chapter={chapter}
            updateChapter={updateChapter}
            session={sessionNumber}
          />
        )
      }

      case 'external_video': {
        let nextS = false
        if (nextStep) nextS = nextStep
        else {
          nextChapter =
            program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
          if (nextChapter) [nextS] = nextChapter.steps
        }

        return (
          <ExternalVideoPage
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            updateChapter={updateChapter}
            session={sessionNumber}
          />
        )
      }

      case 'audio':
        return (
          <AudioPage
            key={Math.random()}
            step={step}
            nextStep={nextStep}
            chapter={chapter}
            updateChapter={updateChapter}
            audio={audio}
          />
        )

      case 'meditate':
        return (
          <MeditateComponent
            key={Math.random()}
            step={step}
            nextStep={nextStep}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )

      case 'quiz': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <QuizComponent
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'jogo_espirais': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <JogoEspirais
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'diario_humor': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <DiarioHumor
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'evaluation': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <EvaluationComponent
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            updateChapter={updateChapter}
            session={session}
          />
        )
      }

      case 'image': {
        let nextS = false
        if (nextStep) nextS = nextStep
        else {
              nextChapter =
              program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
            undefined
            if (nextChapter) [nextS] = nextChapter.steps
          }
        return (
          <StepImage
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'text': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <StepText
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
            isFirst={chapterNumber === 1}
          />
        )
      }

      case 'document': {
          let nextS = false
        if (nextStep) nextS = nextStep
        else {
              nextChapter =
              program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
            undefined
            if (nextChapter) [nextS] = nextChapter.steps
          }
        return (
          <StepDocument
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
          />
        )
      }

      case 'goal': {
        let nextS = false
        nextChapter =
          program?.sessions[sessionNumber - 1]?.chapters[chapterNumber] ||
          undefined
        if (nextChapter) [nextS] = nextChapter.steps
        return (
          <StepGoal
            key={Math.random()}
            step={step}
            nextStep={nextS}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
            isFirst={chapterNumber === 1}
          />
        )
      }

      default: {
        return (
          <ParentBaseStep
            key={Math.random()}
            step={step}
            nextStep={nextStep}
            chapter={chapter}
            session={session}
            updateChapter={updateChapter}
            isFirst={chapterNumber === 1}
          />
        )
      }
    }
  }

  render() {
    const {step} = this.state
    const {program} = this.props
    if (!program) return null
    return (
      <StepContainer key={Math.random()}>
        {step && this.renderStep()}
      </StepContainer>
    )
  }
}

StepParentPage.propTypes = {
  program: PropTypes.object,
  audio: PropTypes.array.isRequired,
  info: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object
}

StepParentPage.defaultProps = {
  program: undefined,
  match: undefined
}

const mapStateToProps = (state) => ({
  info: state.info,
  program: state.info.program,
  audio: state.info.audio
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default connect(mapStateToProps, mapActionToProps)(StepParentPage)

import React from 'react'

import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {
  MainContainerFlex,
  MainContainer
} from '../../pages/step/StepStyles'
import {Margin} from '../../styles/BasicStyles'
import {White, SecondaryCTA} from '../../styles/Colours'
import {QuestionText, Body} from '../../styles/TextSizes'
import TextAreaInput from '../inputs/TextAreaInput'
import {
  QuestionTitle,
  IntroductionBlock
} from '../jogo_espirais/JogoEspiraisStyles'
import DichotomousQuestion from '../question/DichotomousQuestion'
import Question from './Question'

const ProgramEvaluation = ({currentStep, activeLanguage}) => {
  switch (currentStep.order) {
    case 1:
      return (
        <FormSpy subscription={{values: true}}>
          {() => (
            <MainContainer>
              <Margin size={32} tablet={24} mobile={24} />
              {currentStep.descriptions?.length > 0 && (
                <>
                  {currentStep.descriptions.map((description) => (
                    <>
                      {GetValueLanguage(
                        description.title,
                        activeLanguage?.code
                      ) && (
                        <IntroductionBlock bgColor={SecondaryCTA}>
                          <QuestionText isBold>
                            {GetValueLanguage(
                              description.title,
                              activeLanguage?.code
                            )}
                          </QuestionText>
                        </IntroductionBlock>
                      )}
                    </>
                  ))}
                </>
              )}
              {/* {currentStep.title && (
                <>
                  <QuestionTitle weight='700'>
                    {GetValueLanguage(
                      currentStep.title,
                      activeLanguage?.code
                    )}
                  </QuestionTitle>
                </>
              )} */}
              <FieldArray
                name={`steps[${currentStep.order - 1}].questions`}
              >
                {({fields}) =>
                  currentStep.questions.map((question, i) => (
                    <div key={i}>
                      <Question
                        fieldName={`${fields.name}[${i}]`}
                        question={question}
                        onChangeValue={() => {}}
                        isFirst={i === 0}
                        title={GetValueLanguage(
                          currentStep.title,
                          activeLanguage?.code
                        )}
                      />
                    </div>
                  ))
                }
              </FieldArray>
              <Margin size={24} />
            </MainContainer>
          )}
        </FormSpy>
      )

    case 2:
      return (
        <FormSpy subscription={{values: true}}>
          {() => (
            <MainContainer>
              <Margin size={32} tablet={24} mobile={24} />
              {currentStep.title && (
                <>
                  <QuestionTitle weight='700'>
                    {GetValueLanguage(
                      currentStep.title,
                      activeLanguage?.code
                    )}
                    {currentStep.descriptions?.length > 0 && (
                      <>
                        {currentStep.descriptions.map((description) => (
                          <Body>
                            {GetValueLanguage(
                              description.title,
                              activeLanguage?.code
                            )}
                          </Body>
                        ))}
                      </>
                    )}
                  </QuestionTitle>
                </>
              )}
              <FieldArray
                name={`steps[${currentStep.order - 1}].questions`}
              >
                {({fields}) =>
                  currentStep.questions.map((question, i) => (
                    <div key={i}>
                      <Question
                        fieldName={`${fields.name}[${i}]`}
                        question={question}
                        onChangeValue={() => {}}
                        isFirst={i === 0}
                        addOther={i === currentStep.questions.length - 1}
                        // title={GetValueLanguage(
                        //   currentStep.title,
                        //   activeLanguage?.code
                        // )}
                      />
                    </div>
                  ))
                }
              </FieldArray>
              <Margin size={24} />
            </MainContainer>
          )}
        </FormSpy>
      )

    case 12:
      return (
        <FormSpy subscription={{values: true}}>
          {() => (
            <MainContainerFlex>
              <Margin size={64} tablet={24} mobile={24} />

              <FieldArray
                name={`steps[${currentStep.order - 1}].questions`}
              >
                {({fields}) =>
                  currentStep.questions.map((question, i) => (
                    <div key={i}>
                      <DichotomousQuestion
                        fieldName={`${fields.name}[${i}]`}
                        question={question}
                        onChangeValue={() => {}}
                        isBig
                      />
                    </div>
                  ))
                }
              </FieldArray>
            </MainContainerFlex>
          )}
        </FormSpy>
      )

    case 13:
      return (
        <FormSpy subscription={{values: true}}>
          {() => (
            <MainContainerFlex>
              <Margin size={64} tablet={24} mobile={24} />
              <FieldArray
                name={`steps[${currentStep.order - 1}].questions`}
              >
                {({fields}) =>
                  currentStep.questions.map((question, i) => (
                    <div key={i}>
                      <QuestionTitle weight='700'>
                        {GetValueLanguage(
                          question.title,
                          activeLanguage?.code
                        )}
                      </QuestionTitle>
                      <Field
                        component={TextAreaInput}
                        name={`${fields.name}[${i}].answer`}
                        typeText='text'
                        maxRows={6}
                        minRows={6}
                        bgColor={White}
                      />
                    </div>
                  ))
                }
              </FieldArray>
            </MainContainerFlex>
          )}
        </FormSpy>
      )

    default: {
      return (
        <FormSpy subscription={{values: true}}>
          {() => (
            <MainContainerFlex>
              <Margin size={64} tablet={24} mobile={24} />
              {currentStep.title && (
                <>
                  <QuestionTitle weight='700'>
                    {GetValueLanguage(
                      currentStep.title,
                      activeLanguage?.code
                    )}
                  </QuestionTitle>
                  <Margin size={48} />
                </>
              )}
              <FieldArray
                name={`steps[${currentStep.order - 1}].questions`}
              >
                {({fields}) =>
                  currentStep.questions.map((question, i) => (
                    <div key={i}>
                      <DichotomousQuestion
                        fieldName={`${fields.name}[${i}]`}
                        question={question}
                        onChangeValue={() => {}}
                        isBig
                        isFour
                      />
                    </div>
                  ))
                }
              </FieldArray>
            </MainContainerFlex>
          )}
        </FormSpy>
      )
    }
  }
}

ProgramEvaluation.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  currentStep: PropTypes.object.isRequired
}

export default withLocalize(ProgramEvaluation)

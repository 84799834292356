import { withLocalize } from 'react-localize-redux';
import { DropdownWrapper, DropdownContent, DropdownItem } from './HeaderStyles';
import { Menu } from 'antd';
import { Languages } from '../../infra/services/translations/AvailableTranslations';
import React from 'react';
import moment from 'moment';
import ArrowDown from "../../assets/icons/arrow_down.svg";
import { ChangeLanguage } from '../../infra/requests/BaseRequests';

const LanguageSelector = ({ activeLanguage, setActiveLanguage }) => {
  const toggleLanguage = async (code) => {
    setActiveLanguage(code);
    moment.locale(code);
    localStorage.setItem('LANG_COOKIE', code);

    await ChangeLanguage({ language: code });
  };

  const menu = (
    <Menu>
      {
        Languages
        .filter(elem => elem.code !== activeLanguage?.code)
        .map((elem, index) => (
          <DropdownItem key={index} onClick={() => toggleLanguage(elem.code)}>
            <img src={elem.icon} alt='Language flag' />
            <span>{elem.name}</span>
          </DropdownItem>
        ))
      }
    </Menu>
  );

  return (
    <DropdownWrapper overlay={menu} trigger='click' placement='bottomCenter'>
      <DropdownContent>
        <img src={Languages.find(elem => elem.code === activeLanguage?.code)?.icon} alt='Language flag' className='flag' />
        <img src={ArrowDown} alt='arrow' className='arrow' />
      </DropdownContent>
    </DropdownWrapper>
  );
};

export default withLocalize(LanguageSelector);

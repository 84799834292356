import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import ReactPlayer from 'react-player'

import {PlayerWrapper} from '../../styles/BasicStyles'

const VideoPlayer = ({
  video,
  track,
  width,
  height,
  isExternal,
  onEnded,
  maxHeight,
  maxWidth,
  activeLanguage,
  translate
}) => {
  useEffect(() => {
    // Force reload when changing language
    const videoPlayer = document.getElementById('sample_video')
    if (videoPlayer) videoPlayer.load()
  }, [video])

  return (
    <PlayerWrapper
      style={{
        paddingTop: isExternal ? '3%' : 0
      }}
    >
      {isExternal ? (
      <ReactPlayer
        id={video}
        url={video}
        controls
        controlsList='nodownload'
        height={maxHeight}
        width={width}
      />
      )
      : (
        <video
          id='sample_video'
          height={height}
          width={width}
          controls
          controlsList='nodownload'
          onEnded={onEnded}
          crossOrigin='anonymous'
        >
        {video.map((v, i) => (
          <source key={i} src={v.src} type={v.type} />
        ))}

        {track && (
          <track
            label={translate(`LANG_${activeLanguage.code.toUpperCase()}`)}
            kind='subtitles'
            srcLang={activeLanguage.code}
            src={track}
            default
          />
        )}
        </video>

      )}

      {/* <ReactPlayer
        url={video}
        height={height}
        width={width}
        controls
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              crossOrigin: 'anonymous'
            },
            tracks: [
              {
                kind: 'subtitles',
                src: track,
                srcLang: 'pt',
                default: true
              }
            ]
          }
        }}
        style={{
          position: isExternal ? 'absolute' : 'inherit',
          top: 0,
          left: 0,
          maxWidth,
          maxHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onEnded={onEnded}
      /> */}
    </PlayerWrapper>
  )
}

VideoPlayer.propTypes = {
  video: PropTypes.string,
  track: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  isExternal: PropTypes.bool,
  onEnded: PropTypes.func,
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

VideoPlayer.defaultProps = {
  video: undefined,
  track: undefined,
  height: 'auto',
  width: '100%',
  maxHeight: 'auto',
  maxWidth: '100%',
  isExternal: false,
  onEnded: () => {}
}

export default withLocalize(VideoPlayer)

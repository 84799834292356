import React, {useState, useEffect} from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {GetHistory} from '../../infra/requests/ProgramRequests'
import {SecondaryColour} from '../../styles/Colours'
import {Title22, BodyText, Body} from '../../styles/TextSizes'
import {
  HistoryRow,
  HistoryContainer,
  HistoryTitleContainer,
  HistorySubContainer,
  HistoryCol2,
  HistoryCol,
  HistoryLine,
  HistoryShadow
} from './HistoryStyles'

const HistoryComponent = ({
  children,
  chapter,
  onClickHistory,
  selected,
  translate,
  isFull
}) => {
  const [history, setHistory] = useState([])

  useEffect(() => {
    async function getData() {
      const result = await GetHistory(chapter)

      if (result.success) {
        setHistory(result.data)
      }
    }
    getData()
  }, [chapter])

  const showHistory = (data) => {
    onClickHistory(data)
  }

  const renderHistory = () => (
    <>
      {history.map((line, index) => (
        <HistoryLine
          selected={selected.date === line.date}
          key={index}
          onClick={() => showHistory(line)}
        >
          <Body weight={600}>{line.date}</Body>
        </HistoryLine>
      ))}
    </>
  )

  return (
    <HistoryRow gutter={[24]}>
      <HistoryCol xs={24} md={16} xl={18} isFull={isFull}>
        {children}
      </HistoryCol>
      <HistoryCol2 xs={24} md={8} xl={6}>
        <HistoryContainer>
          <HistoryTitleContainer>
            <Title22 color={SecondaryColour}>
              {translate('HISTORICO')}
            </Title22>
          </HistoryTitleContainer>
          <HistorySubContainer>
            {history?.length ? (
              renderHistory()
            ) : (
              <BodyText>{translate('HISTORICO_VAZIO')}</BodyText>
            )}
          </HistorySubContainer>
          <HistoryShadow />
        </HistoryContainer>
      </HistoryCol2>
    </HistoryRow>
  )
}

HistoryComponent.propTypes = {
  children: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  chapter: PropTypes.string.isRequired,
  isFull: PropTypes.bool,
  onClickHistory: PropTypes.func.isRequired,
  selected: PropTypes.object
}

HistoryComponent.defaultProps = {
  isFull: false,
  selected: undefined
}

export default withLocalize(HistoryComponent)

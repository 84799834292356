import styled from 'styled-components'

import {White} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const HelpContainer = styled.div`
  background-color: ${White};
  display: flex;
  width: 100%;
  background-image: url(${require('../../assets/backgrounds/background_help.svg')});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom right;
  background-attachment: fixed;
  min-height: calc(100vh - 64px - 109px);

  @media ${device.mobileS} {
    background-image: none;
  }
`

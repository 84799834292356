import React, {useState} from 'react'

import {Modal} from 'antd'
import PropTypes from 'prop-types'
import Cropper from 'react-cropper'
import {withLocalize} from 'react-localize-redux'
import styled from 'styled-components'

const PreviewsSection = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 30px;
`

const PreviewTitle = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`

const ImageCrop = ({visible, onClose, image, loading, translate}) => {
  const [cropper, setCropper] = useState(undefined)

  const buildImageObject = blob => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob
  })

  const onCropComplete = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob(blob => {
        const img = buildImageObject(blob)
        onClose(img)
      })
    }
  }

  return (
    <Modal
      title={translate('PERFIL_REDIMENSIONAR_IMAGEM')}
      visible={visible}
      onOk={onCropComplete}
      onCancel={onClose}
      confirmLoading={loading}
      maskClosable={false}
    >
      <Cropper
        style={{height: 300, width: '100%'}}
        initialAspectRatio={1}
        src={image ? image.preview : ''}
        guides
        viewMode={2}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive
        autoCropArea={1}
        checkOrientation={false}
        preview='.img-preview'
        onInitialized={instance => {
          setCropper(instance)
        }}
      />
      <PreviewsSection>
        <PreviewTitle>
          {translate('PERFIL_REDIMENSIONAR_IMAGEM_PREVIEW')}:{' '}
        </PreviewTitle>
        <div
          className='img-preview'
          style={{
            width: '100%',
            float: 'left',
            height: '300px',
            overflow: 'hidden'
          }}
        />
      </PreviewsSection>
    </Modal>
  )
}

ImageCrop.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  image: PropTypes.object,
  loading: PropTypes.bool,
  translate: PropTypes.func.isRequired
}

ImageCrop.defaultProps = {
  image: undefined,
  loading: false
}

export default withLocalize(ImageCrop)

import React, {useEffect, useState} from 'react'

import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'

import {BaseButton} from '../../components/buttons/BaseButton'
import {MaterialErrorLabel} from '../../components/inputs/MaterialStyles'
import TextInput from '../../components/inputs/TextInput'
import Layout from '../../components/layout/Layout'
import {RecoverAccount} from '../../infra/requests/AuthRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {PageForm} from '../../styles/BasicStyles'
import {Body} from '../../styles/TextSizes'
import {
  LoginContainer,
  LoginCard,
  LogoSection,
  LoginButtonDiv,
  FormTitle,
  FormSubTitle
} from './LoginPageStyles'

const formRules = FormValidator.make({
  email: 'required|email'
})

const RecoverAccountPage = ({translate, history}) => {
  const [error, setError] = useState(false)
  const [showMessage, setShowMessage] = useState(false)

  const onSubmit = async (values) => {
    try {
      const {message, success} = await RecoverAccount(values)

      if (success) {
        setShowMessage(translate('ALERT_RECOVER_PASSWORD'))
      } else {
        setError(
          message ? translate(message) : translate('AN_ERROR_OCCURRED')
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <Layout fixed auth>
      <LoginContainer>
        <Form onSubmit={onSubmit} validate={formRules}>
          {({handleSubmit, valid, submitting}) => (
            <PageForm onSubmit={handleSubmit}>
              <LoginCard>
                <LogoSection>
                  <FormTitle>{translate('RECOVER_ACCOUNT')}</FormTitle>
                  {!showMessage && (
                    <Body>{translate('LOGIN_FORGET')}</Body>
                  )}
                </LogoSection>
                {showMessage ? (
                  <>
                    <FormSubTitle style={{marginTop: 20}}>
                      {showMessage}
                    </FormSubTitle>
                    <LoginButtonDiv>
                      <FormSubTitle marginT={16}>
                        <span onClick={() => history.push('/login')}>
                          {translate('LOGIN')}!
                        </span>
                      </FormSubTitle>
                    </LoginButtonDiv>
                  </>
                ) : (
                  <>
                    <Field
                      component={TextInput}
                      name='email'
                      type='email'
                      label={translate('EMAIL')}
                    />
                    {error && (
                      <MaterialErrorLabel>{error}</MaterialErrorLabel>
                    )}
                    <LoginButtonDiv>
                      <BaseButton
                        align='initial'
                        variant='raised'
                        htmlType='submit'
                        type='primaryLarge'
                        disabled={!valid}
                        loading={submitting}
                        text={translate('SEND_EMAIL')}
                      />
                      <FormSubTitle marginT={16}>
                        <span onClick={() => history.push('/login')}>
                          {translate('LOGIN')}!
                        </span>
                      </FormSubTitle>
                    </LoginButtonDiv>
                  </>
                )}
              </LoginCard>
            </PageForm>
          )}
        </Form>
      </LoginContainer>
    </Layout>
  )
}

RecoverAccountPage.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withLocalize(RecoverAccountPage)

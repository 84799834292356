import axios from 'axios'

import {auth_token_key} from './LocalStorageKeys'

const {REACT_APP_API} = process.env

const mergeCommonHeaders = (rest) => {
  const authToken = localStorage.getItem(auth_token_key)

  return {
    ...rest,
    Authorization: `Bearer ${authToken}`
  }
}

const client = axios.create({
  baseURL: REACT_APP_API,
  responseType: 'json'
})

client.interceptors.request.use((request) => {
  const headers = mergeCommonHeaders(request.headers.common)
  request.headers.common = headers
  return request
})

client.setupInterceptors = (store) => {
  client.interceptors.response.use(
    (success) => success.data,
    (error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (error.response.data.message === 'Error: NO_USER') {
          error.response.data.message = 'NO_USER'
        } else if (
          error.response.data.message === 'Error: INVALID_PASSWORD'
        ) {
          error.response.data.message = 'INVALID_PASSWORD'
        } else if (error.response.data.message === 'Error: USER_EXISTS') {
          error.response.data.message = 'USER_EXISTS'
        } else if (error.response.data.message === 'Error: NO_ACCEPT') {
          error.response.data.message = 'NO_ACCEPT'
        } else if (error.response.data.message === 'Error: NO_ACTIVE') {
          error.response.data.message = 'NO_ACTIVE'
        } else if (error.response.data.message === 'Error: NO_ACCESS') {
          error.response.data.message = 'NO_ACCESS'
        } else if (
          error.response.data.message === 'Error: INVALID_PASSWORD_OR_USER'
        ) {
          error.response.data.message = 'INVALID_PASSWORD_OR_USER'
        } else if (error.response.data.message === 'Incorrect arguments') {
          error.response.data.message = 'INVALID_PASSWORD_OR_USER'
        } else if (
          error.response.data.message === 'Error: INVALID_USER_TYPE'
        ) {
          error.response.data.message = 'INVALID_USER_TYPE'
        } else {
          error.response.data.message = 'GENERIC_ERROR'
        }
      }

      if (error && error.response && error.response.status === 403) {
        localStorage.clear()
        // eslint-disable-next-line no-restricted-globals
        location.replace('/login')
      }

      return error.response
        ? {...error.response.data, success: false}
        : {success: false}
    }
  )
}

export default client

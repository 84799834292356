import React from 'react'

import {PropTypes} from 'prop-types'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/illustrations/step1_1_1.png'
import {
  MainContainer,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'

const Step1_1_9 = ({step, history, nextStep, chapter}) => (
  <>
    <BasicHeaderComponent
      onClose={() => history.push('/program')}
      title={chapter.title}
      progress={(step.order / chapter.steps.length) * 100}
    />
    <MainContainerBackground
      style={{
        backgroundImage: `url(${Illustration})`,
        backgroundPosition: 'left bottom',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <MainContainerScroll>
        <MainContainer>
          {step.type === 'text' && (
            <>
              {step.descriptions.map((description, index) => (
                <MessageComponent
                  key={index}
                  text={description.title}
                  active={index === step.descriptions.length - 1}
                  // isQuote={index === step.descriptions.length - 1}
                />
              ))}
            </>
          )}
        </MainContainer>
      </MainContainerScroll>
    </MainContainerBackground>
    <BasicFooterComponent
      onBack={() => history.goBack()}
      onNext={() =>
        nextStep
          ? history.push(`/step/${nextStep._id}`)
          : history.push('/program')
      }
    />
  </>
)

Step1_1_9.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object
}

Step1_1_9.defaultProps = {
  nextStep: undefined,
  chapter: undefined
}
export default withRouter(Step1_1_9)

import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {BaseButton} from '../buttons/BaseButton'
import MessageComponent from '../message/MessageComponent'
import {ButtonsContainer} from '../message/MessageStyles'

const TextComponent = ({step, onClickNext, translate}) => (
  <>
    {step.type === 'text' && (
      <>
        {step.descriptions.map((description, index) => (
          <MessageComponent
            key={index}
            text={description.title}
            active={index === step.descriptions.length - 1}
          />
        ))}
      </>
    )}
    {step.order === 2 && (
      <MessageComponent isUser active>
        <ButtonsContainer>
          <BaseButton
            align='right'
            htmlType='button'
            type='primaryMedium'
            text={translate('VAMOS_LA')}
            auto
            onClick={onClickNext}
          />
        </ButtonsContainer>
      </MessageComponent>
    )}
  </>
)

TextComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  onClickNext: PropTypes.func.isRequired
}

export default withLocalize(withRouter(TextComponent))

import React, {useState} from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {InactiveTextColor} from '../../styles/Colours'
import {
  OptionsContainer,
  QuestionTitle
} from '../jogo_espirais/JogoEspiraisStyles'
import {QuestionOption, OptionText} from './QuestionStyles'

const QuestionLine = ({
  question,
  fieldName,
  activeLanguage,
  onChangeValue
}) => {
  const [animation, setAnimation] = useState(undefined)

  const onSelectOption = (idOption, form, name) => {
    form.change(name, idOption)
    onChangeValue(idOption)
  }

  const optionsArray = opt => {
    const {options, form, input} = opt
    const currentOption = input?.value || undefined

    const currentOptionObj =
      options.find(o => o._id === input?.value) || undefined

    const isCorrect = currentOptionObj?.isCorrect ? input?.value : false

    return (
      <>
        {options.map((option, index) => (
          <Col xs={24} key={index}>
            <QuestionOption
              className={
                animation === index
                  ? isCorrect
                    ? 'animated'
                    : 'animatedWrong'
                  : ''
              }
              onClick={() => {
                if (!(isCorrect && isCorrect !== option._id)) {
                  onSelectOption(option._id, form, input.name)
                  setAnimation(index)
                }
              }}
              onAnimationEnd={() => setAnimation(undefined)}
              isActive={currentOption === option._id && option.isCorrect}
              isWrong={currentOption === option._id && !option.isCorrect}
              style={{marginBottom: 0}}
            >
              <OptionText
                color={
                  isCorrect && isCorrect !== option._id
                    ? InactiveTextColor
                    : undefined
                }
              >
                {GetValueLanguage(option.title, activeLanguage?.code)}
              </OptionText>
            </QuestionOption>
          </Col>
        ))}
      </>
    )
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({form}) => (
        <>
          <QuestionTitle>
            {GetValueLanguage(question.title, activeLanguage?.code)}
          </QuestionTitle>
          <OptionsContainer gutter={[16, 16]}>
            <Field
              name={`${fieldName}.options`}
              component={optionsArray}
              options={question.options}
              form={form}
            />
          </OptionsContainer>
        </>
      )}
    </FormSpy>
  )
}

QuestionLine.propTypes = {
  question: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func,
  fieldName: PropTypes.string.isRequired
}

QuestionLine.defaultProps = {
  onChangeValue: () => {}
}

export default withLocalize(withRouter(QuestionLine))

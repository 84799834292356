import styled from 'styled-components'

import {TextColor, InactiveTextColor} from './Colours'
import {device, vertical} from './Responsive'

export const titleH1 = styled.h1`
  font-family: 'Poppins', sans-serif;
  color: ${TextColor};
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  margin: 0;
  padding: 0;

  @media ${device.desktopXL} {
    font-size: 36px;
    line-height: 48px;
  }

  @media ${device.desktop} {
    font-size: 31px;
    line-height: 42px;
  }

  @media ${device.laptopL} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.laptop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${device.mobileM} {
    font-size: 20px;
    line-height: 26px;
  }
`

export const titleH2 = styled.h2`
  font-family: 'Poppins', sans-serif;
  color: ${TextColor};
  font-weight: 400;
  font-size: 35px;
  line-height: 52px;
  margin: 0;
  padding: 0;

  @media ${device.desktopXL} {
    font-size: 31px;
    line-height: 42px;
  }

  @media ${device.desktop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${device.laptop} {
    font-size: 20px;
    line-height: 30px;
  }
`

export const TitleH3 = styled.h3`
  font-family: 'Poppins', sans-serif;
  color: ${(p) => p.color || TextColor};
  font-weight: ${(p) => (p.isBold ? 600 : p.weight || 400)};
  font-size: 32px;
  line-height: 48px;
  margin: 0;
  padding: 0;

  @media ${device.desktop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${device.laptop} {
    font-size: 20px;
    line-height: 30px;
  }

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 24px;
  }
`

export const titleH4 = styled.h4`
  font-family: 'Poppins', sans-serif;
  color: ${TextColor};
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  margin: 0;
  padding: 0;

  @media ${device.desktop} {
    font-size: 20px;
    line-height: 30px;
  }

  @media ${device.laptop} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const titleH5 = styled.h5`
  font-family: 'Poppins', sans-serif;
  color: ${TextColor};
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  padding: 0;

  @media ${device.desktop} {
    font-size: 20px;
    line-height: 30px;
  }

  @media ${device.laptop} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const body2 = styled.span`
  color: ${TextColor};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media ${device.tablet} {
    font-size: 12px;
    line-height: 16px;
  }
`

export const Title22 = styled.div`
  font-family: 'Poppins', sans-serif;
  color: ${(p) =>
    p.color ? p.color : p.inactive ? InactiveTextColor : TextColor};
  font-weight: ${(p) => (p.weight ? p.weight : 'bold')};
  font-size: 22px;
  line-height: 33px;
  margin: 0;
  padding: 0;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
`
export const Title18 = styled.div`
  font-family: 'Poppins', sans-serif;
  color: ${(p) =>
    p.color ? p.color : p.inactive ? InactiveTextColor : TextColor};
  font-weight: ${(p) => (p.weight ? p.weight : 600)};
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  padding: 0;

  @media ${device.laptopL} {
    font-size: 17px;
    line-height: 24px;
  }

  @media ${device.laptop} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 20px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const BodyText = styled.span`
  font-family: 'Poppins', sans-serif;
  color: ${(p) =>
    p.color ? p.color : p.inactive ? InactiveTextColor : TextColor};
  font-weight: ${(p) => (p.weight ? p.weight : 400)};
  font-size: 14px;
  line-height: 21px;

  @media ${device.tablet} {
    font-size: 13px;
    line-height: 18px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 16px;
  }
`

export const QuestionText = styled.span`
  font-family: 'Poppins', sans-serif;
  color: ${(p) => p.color || TextColor};
  font-weight: ${(p) => (p.isBold ? 'bold' : 400)};
  font-size: 20px;
  line-height: ${({isBigger}) => (isBigger ? '36px' : '30px')};

  @media ${device.laptopL} {
    font-size: 16px;
    line-height: ${({isBigger}) => (isBigger ? '26px' : '22px')};
  }

  @media ${device.laptop} {
    font-size: 16px;
    line-height: ${({isBigger}) => (isBigger ? '26px' : '20px')};
  }

  @media ${vertical.medium} {
    font-size: 16px;
    line-height: ${({isBigger}) => (isBigger ? '26px' : '20px')};
  }

  @media ${device.tablet} {
    font-size: 14px;
    line-height: ${({isBigger}) => (isBigger ? '26px' : '18px')};
  }
`

export const Title25 = styled.div`
  font-family: 'Poppins', sans-serif;
  color: ${(p) => (p.inactive ? InactiveTextColor : p.color || TextColor)};
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  margin: 0;
  padding: 0;

  @media ${device.laptopL} {
    font-size: 22px;
    line-height: 33px;
  }

  @media ${device.laptop} {
    font-size: 22px;
    line-height: 33px;
  }

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 30px;
  }

  @media ${device.mobileL} {
    font-size: 18px;
    line-height: 27px;
  }
`

export const Body = styled.span`
  color: ${(p) => p.color || TextColor};
  font-weight: ${(p) => (p.weight ? p.weight : 400)};
  font-size: 16px;
  line-height: 24px;

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 21px;
  }
`

export const TextSmall = styled.span`
  color: ${(p) => p.color || TextColor};
  font-weight: ${(p) => (p.weight ? p.weight : 400)};
  font-size: 11px;
  line-height: 16px;
`

import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import Close from '../../assets/icons/close.svg'
import {MarginH} from '../../styles/BasicStyles'
import {Body} from '../../styles/TextSizes'
import {BaseButton} from '../buttons/BaseButton'
import {IconClose} from '../header/HeaderStyles'
import {
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalAction
} from './BaseModalStyles'

const BaseModal = ({
  title,
  visible,
  loading,
  text,
  handleConfirm,
  handleClose,
  handleCancel,
  translate
}) => (
  <ModalContent
    visible={visible}
    footer={null}
    onCancel={handleClose}
    closeIcon={<IconClose src={Close} />}
  >
    <ModalHeader>
      <ModalTitle>{title}</ModalTitle>
      <Body>{text}</Body>
    </ModalHeader>
    <ModalAction>
      <BaseButton
        htmlType='button'
        type='default'
        text={translate('SAIR_SEM_GUARDAR')}
        onClick={handleCancel}
        disabled={loading}
      />
      <MarginH size={16} tablet={16} mobile={16} />
      <BaseButton
        onClick={handleConfirm}
        type='primaryMedium'
        text={translate('REGISTAR_E_SAIR')}
        disabled={loading}
      />
    </ModalAction>
  </ModalContent>
)

BaseModal.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}

BaseModal.defaultProps = {
  visible: false,
  loading: false,
  text: undefined,
  title: undefined
}
export default withLocalize(BaseModal)

import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import EmptyIcon from '../../assets/illustrations/empty_layout.svg'
import {EmptyLayout, Margin} from '../../styles/BasicStyles'
import {InputBorderColour} from '../../styles/Colours'
import {Title22} from '../../styles/TextSizes'

const EmptyLayoutComponent = ({tag, translate}) => (
  <EmptyLayout>
    <img src={EmptyIcon} alt='Empty' />
    <Margin size={16} />
    <Title22 color={InputBorderColour}>
      {translate(tag || 'EMPTY_VIEW')}
    </Title22>
  </EmptyLayout>
)

EmptyLayoutComponent.propTypes = {
  tag: PropTypes.string,
  translate: PropTypes.func.isRequired
}

EmptyLayoutComponent.defaultProps = {
  tag: undefined
}

export default withLocalize(EmptyLayoutComponent)

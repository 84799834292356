import {Modal} from 'antd'
import styled from 'styled-components'

import {TextColor} from '../../styles/Colours'
import {Title22} from '../../styles/TextSizes'

export const ModalContent = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-title {
    color: #000000;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }

  .ant-modal-footer {
    border-top: none;

    & button {
      transition: background-color 0.5s linear, border-color 0.5s linear;
      border-color: ${(p) => p.theme.secondaryCTA};
      padding: 8px 15px;
      height: 38px;
      text-shadow: none;
      box-shadow: none;

      & span {
        color: ${(p) => p.theme.secondaryCTA};
        font-size: 18px;
        font-weight: 300;
        line-height: 20px;
        transition: color 0.5s linear;
      }

      &.ant-btn-primary {
        background-color: ${(p) => p.theme.primaryCTA};
        border-color: ${(p) => p.theme.primaryCTA};

        & span {
          color: #ffffff;
        }
      }

      &:hover {
        background-color: ${(p) => p.theme.secondaryCTA};
        border-color: ${(p) => p.theme.secondaryCTA};

        & span {
          color: #ffffff;
        }
      }
    }
  }

  .ant-modal-body {
    color: #000000;
    font-size: 15px;
  }
`

export const ModalHeader = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;
`

export const ModalTitle = styled(Title22)`
  color: ${TextColor};
`

export const ModalAction = styled.div`
  display: flex;
  flex-direction: row;
`

import {
  SAVE_INFO,
  UPDATE_ACTIVITIES,
  UPDATE_PROGRAM,
  DELETE_PROGRAM,
  SAVE_PROGRAM,
  UPDATE_GOALS_PROGRAM,
  COMPLETE_CHAPTER
} from '../ActionsType'

export function SaveInfo(info) {
  return {
    type: SAVE_INFO,
    info
  }
}

export function UpdateActivitiesCalendar(activities) {
  return {
    type: UPDATE_ACTIVITIES,
    activities
  }
}

export function SaveProgram(program) {
  return {
    type: SAVE_PROGRAM,
    program
  }
}

export function UpdateProgram(sessions) {
  return {
    type: UPDATE_PROGRAM,
    sessions
  }
}

export function DeleteProgram() {
  return {
    type: DELETE_PROGRAM,
    program: {}
  }
}

export function UpdateRegistoObjetivosProgram(data) {
  return {
    type: UPDATE_GOALS_PROGRAM,
    data
  }
}

export function CompleteChapter(idChapter) {
  return {
    type: COMPLETE_CHAPTER,
    idChapter
  }
}

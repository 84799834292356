import styled from 'styled-components'

import {ThirdColour} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const MeditationContainer = styled.div`
  background-color: ${ThirdColour};
  display: flex;
  width: 100%;
  background-image: url(${require('../../assets/backgrounds/background_diario_meditacao.png')});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
`

export const MeditationImg = styled.img`
  width: 100%;
  flex: 1;
  padding: 0px 140px;

  @media ${device.laptopL} {
    padding: 0px 60px;
  }

  @media ${device.tabletMin} {
    display: none;
  }
`

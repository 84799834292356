import React, {useState} from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {InactiveTextColor} from '../../styles/Colours'
import {
  QuestionOption,
  OptionText,
  BlockLeft,
  QuestionImg,
  BlockRight,
  TitleQuestion
} from './QuestionStyles'

const QuestionImage = ({step, chapterIndex, isGif, activeLanguage, intl}) => {
  const [animation, setAnimation] = useState(undefined)

  const onSelectOption = (idOption, form, name) => {
    form.change(name, idOption)
  }

  const optionsArray = (opt) => {
    const {options, form, input} = opt
    const currentOption = input?.value || undefined
    const currentOptionObj =
      options.find((o) => o._id === input?.value) || undefined

    const isCorrect = currentOptionObj?.isCorrect ? input?.value : false

    return (
      <>
        {options.map((option, index) => (
            <QuestionOption
              className={
                animation === index
                  ? isCorrect
                    ? 'animated'
                    : 'animatedWrong'
                  : ''
              }
              key={index}
              onClick={() => {
                if (!(isCorrect && isCorrect !== option._id)) {
                  onSelectOption(option._id, form, input.name)
                  setAnimation(index)
                }
              }}
              onAnimationEnd={() => setAnimation(undefined)}
              isActive={currentOption === option._id && option.isCorrect}
              isWrong={currentOption === option._id && !option.isCorrect}
            >
              <OptionText
                color={
                  isCorrect && isCorrect !== option._id
                    ? InactiveTextColor
                    : undefined
                }
              >
                {GetValueLanguage(option.title, activeLanguage?.code)}
              </OptionText>
            </QuestionOption>
          ))}
      </>
    )
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({values, form}) => (
        <FieldArray name={`steps[${step.order - 1}].questions`}>
          {({fields}) =>
            step.questions.map((question, i) => (
              <div key={i}>
                <TitleQuestion style={{fontWeight: 'bold'}}>
                  {GetValueLanguage(question.title, activeLanguage?.code)}
                </TitleQuestion>
                <Row>
                  <Col xs={24} md={12}>
                    <BlockLeft>
                      <QuestionImg
                        src={require(`../../assets/${chapterIndex}/${intl ? `${activeLanguage?.code}/` : ''}${step.order}.${isGif ? 'gif' : 'svg'}`)}
                      />
                    </BlockLeft>
                  </Col>
                  <Col xs={24} md={12}>
                    <BlockRight>
                      <Field
                        name={`${fields.name}[${i}].options`}
                        component={optionsArray}
                        options={question.options}
                        form={form}
                      />
                    </BlockRight>
                  </Col>
                </Row>
              </div>
            ))
          }
        </FieldArray>
      )}
    </FormSpy>
  )
}

QuestionImage.propTypes = {
  step: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  chapterIndex: PropTypes.string.isRequired,
  isGif: PropTypes.bool,
  intl: PropTypes.bool
}

QuestionImage.defaultProps = {
  isGif: false,
  intl: false
}
export default withLocalize(withRouter(QuestionImage))

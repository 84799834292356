import {Row, Col} from 'antd'
import styled from 'styled-components'

import {device} from '../../styles/Responsive'

export const AudioPlayerContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.tabletMin} {
    padding: 20px 0px;
  }
`

export const TableRow = styled(Row)`
  display: flex !important;
  flex: 1;
  height: calc(100% - 45px) !important;

  @media ${device.tabletMin} {
    display: block !important;
  }
`

export const TableCol = styled(Col)`
  flex: 1;
  display: flex !important;
  overflow: hidden;

  @media ${device.tabletMin} {
    display: block !important;
  }
`

export const AudioTextContainer = styled.div`
  z-index: 1;
  width: 100%;
  padding: 32px;
  height: 100%;
  background: rgba(189, 179, 234, 0.6);
  border-radius: 8px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tabletMin} {
    padding: 16px;
  }
`

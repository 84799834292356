import React, {useState, useEffect} from 'react'

import {InfoCircleOutlined} from '@ant-design/icons'
import {Row, Col, Tooltip} from 'antd'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'

import EyeOff from '../../assets/icons/eye-off.svg'
import Eye from '../../assets/icons/eye.svg'
import {BaseButton} from '../../components/buttons/BaseButton'
import CheckboxInput from '../../components/inputs/CheckboxInput'
import DateInput from '../../components/inputs/DateInput'
import {MaterialErrorLabel} from '../../components/inputs/MaterialStyles'
import SelectInput from '../../components/inputs/SelectInput'
import TextInput from '../../components/inputs/TextInput'
import Layout from '../../components/layout/Layout'
import {Register} from '../../infra/requests/AuthRequests'
import {GetSchools, GetClasses} from '../../infra/requests/BaseRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {PageForm, Margin} from '../../styles/BasicStyles'
import {PrimaryColour, SecondaryColour} from '../../styles/Colours'
import {Body, Title18} from '../../styles/TextSizes'
import {
  LoginContainer,
  LoginCard,
  LogoSection,
  LoginButtonDiv,
  BaseLink,
  FormTitle,
  FormSubTitle
} from './LoginPageStyles'

const formRules = (values) => {
  const errors = FormValidator.make({
    name: 'required',
    email: 'required|email',
    type: 'required',
    password: 'required|min:6',
    repeat_password: 'required|min:6|repeat_password',
    terms_and_conditions: 'required'
  })(values)

  if (values.type == 4) {
    errors.phone = FormValidator.validateField(
      'phone',
      values.phone,
      'required|min:9|max:9'
    )
  }

  return errors
}

const data = [
  {name: 'ALUNO', _id: '4'},
  {name: 'ENCARREGADO_EDUCAÇAO', _id: '3'},
  {name: 'APLICADOR', _id: '2'}
]

const data2 = [
  {name: 'FEMININO', _id: '1'},
  {name: 'MASCULINO', _id: '2'}
  // {name: 'GENERO_NAO_DIZER', _id: '3'}
]

const Data5 = [
  {name: 'PAI', _id: '1'},
  {name: 'MAE', _id: '2'}
]

const Data6 = [
  {name: 'PROFESSOR', _id: '1'},
  {name: 'PSICOLOGO', _id: '2'}
]

const data7 = [
  {name: 'SMSJOVENS', _id: '1'},
  {name: 'SMSEDUCADORES', _id: '2'},
  {name: 'AMBOS_OS_PROGRAMAS', _id: '3'}
]

const RegisterPage = ({translate, activeLanguage}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordR, setShowPasswordR] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [schools, setSchools] = useState([])
  const [classes, setClasses] = useState([])
  const [data5, setData5] = useState(Data5)
  const [data6, setData6] = useState(Data6)
  const [error, setError] = useState(false)

  const onSubmit = async (values) => {
    try {
      const fields = {...values, language: activeLanguage?.code}
      if (
        values.applicator_category &&
        values.applicator_category !== '1' &&
        values.applicator_category !== '2'
      ) {
        fields.applicator_category = '3'
        fields.applicator_category_other = values.applicator_category
      }
      if (
        values.parent &&
        values.parent !== '1' &&
        values.parent !== '2'
      ) {
        fields.parent = '3'
        fields.parent_other = values.parent
      }

      const result = await Register(fields)

      if (result.success) {
        setShowMessage(translate('SUCCESSO_PRE_REGISTO'))
      } else {
        setError(
          result.message
            ? translate(result.message)
            : translate('AN_ERROR_OCCURRED')
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    async function getData() {
      const result = await GetSchools()

      if (result.success) {
        setSchools(result.data)
      }
    }

    window.scroll({top: 0, left: 0, behavior: 'smooth'})

    getData()
  }, [])

  const onChangeSchool = async (value, values, form) => {
    if (values.school === value) return
    form.change('classes', undefined)
    const result = await GetClasses(value)

    if (result.success) {
      setClasses(result.data)
    }
  }

  const onChangeType = async (value, values, form) => {
    if (values.type == value) return

    form.change('class', undefined)
    form.change('school', undefined)
    form.change('code', undefined)
    form.change('parent', undefined)
    form.change('phone', undefined)
    form.change('applicator_category', undefined)
    form.change('applicator_program', undefined)
  }

  const renderChildren = (values, form) => (
    <>
      <Field
        component={SelectInput}
        name='school'
        label={translate('ESCOLA')}
        items={schools}
        noTranslate
        showSearch
        onChange={(v) => onChangeSchool(v, values, form)}
        dataItem='name'
        dataValue='_id'
      />
      <Row gutter={[16]}>
        <Col xs={24} sm={8}>
          <Field
            isAuth
            component={DateInput}
            placeholder='DD-MM-AAAA'
            name='birthday'
            label={translate('DATA_DE_NASCIMENTO')}
          />
        </Col>
        <Col xs={12} md={8}>
          <Field
            component={SelectInput}
            name='class'
            label={translate('TURMA')}
            items={classes}
            noTranslate
            dataItem='name'
            dataValue='_id'
          />
        </Col>
        <Col xs={12} md={8}>
          <Field
            component={SelectInput}
            name='gender'
            label={translate('GENERO')}
            items={data2}
          />
        </Col>
      </Row>
      <Field
        component={TextInput}
        name='phone'
        typeText='number'
        label={translate('NUMERO_TELEMOVEL')}
      />
    </>
  )

  const renderParent = () => (
    <>
      <Row gutter={[16]}>
        <Col xs={24} sm={12}>
          <Field
            isAuth
            component={DateInput}
            placeholder='DD-MM-AAAA'
            name='birthday'
            label={translate('DATA_DE_NASCIMENTO')}
          />
        </Col>
        <Col xs={12} sm={12}>
          <Field
            component={SelectInput}
            name='gender'
            label={translate('GENERO')}
            items={data2}
          />
        </Col>
      </Row>
      <Row gutter={[16]}>
        <Col xs={24} sm={12}>
          <Field
            isAuth
            component={TextInput}
            name='code'
            label={translate('CODIGO_ALUNO')}
            labelIcon={
              <Tooltip
                title={translate('CODIGO_ALUNO_INFO')}
                trigger='click'
                overlayClassName='basic'
              >
                <InfoCircleOutlined style={{marginLeft: 8}} />
              </Tooltip>
            }
          />
        </Col>
        <Col xs={12} sm={12}>
          <Field
            component={SelectInput}
            name='parent'
            label={translate('GRAU_PARENTESCO')}
            items={data5}
            addNewItem={(v) => {
              if (v) {
                setData5([
                  ...data5,
                  {
                    name: v,
                    _id: v,
                    noTranslatable: true
                  }
                ])
              }
            }}
          />
        </Col>
      </Row>
    </>
  )

  const renderTeacher = () => (
    <>
      <Field
        component={SelectInput}
        name='school'
        label={translate('ESCOLA')}
        items={schools}
        noTranslate
      />
      <Row gutter={[16]}>
        <Col xs={24} sm={12}>
          <Field
            isAuth
            component={DateInput}
            placeholder='DD-MM-AAAA'
            name='birthday'
            label={translate('DATA_DE_NASCIMENTO')}
          />
        </Col>
        <Col xs={12} sm={12}>
          <Field
            component={SelectInput}
            name='gender'
            label={translate('GENERO')}
            items={data2}
          />
        </Col>
      </Row>
      <Row gutter={[16]}>
        <Col xs={24} sm={12}>
          <Field
            component={SelectInput}
            name='applicator_category'
            label={translate('CATEGORIA')}
            items={data6}
            addNewItem={(v) => {
              if (v) {
                setData6([
                  ...data6,
                  {
                    name: v,
                    _id: v,
                    noTranslatable: true
                  }
                ])
              }
            }}
          />
        </Col>
        <Col xs={12} sm={12}>
          <Field
            component={SelectInput}
            name='applicator_program'
            label={translate('PROGRAMA_APLICAR')}
            items={data7}
          />
        </Col>
      </Row>
    </>
  )

  return (
    <Layout fixed auth>
      <LoginContainer
        style={{display: 'block', overflow: 'auto', paddingTop: 90}}
      >
        <Form onSubmit={onSubmit} validate={formRules}>
          {({handleSubmit, values, valid, submitting, form}) => (
            <PageForm onSubmit={handleSubmit}>
              <LoginCard>
                <LogoSection>
                  <FormTitle>{translate('REGISTER_TITLE')}</FormTitle>
                  <Body>
                    {translate('REGISTER_SUBTITLE')}{' '}
                    <Body color={PrimaryColour}>
                      <BaseLink to='/login'>
                        {translate('REGISTER_SUBSUBTITLE')}
                      </BaseLink>
                    </Body>
                  </Body>
                  <Margin size={16} />
                  {!showMessage && (
                    <Title18 color={SecondaryColour}>
                      {translate('REGISTER_SECOND_TITLE')}
                    </Title18>
                  )}
                </LogoSection>
                {showMessage ? (
                  <>
                    <FormSubTitle style={{marginTop: 20}}>
                      {showMessage}
                    </FormSubTitle>
                  </>
                ) : (
                  <>
                    <Field
                      component={TextInput}
                      name='name'
                      type='text'
                      label={translate('NOME_COMPLETO')}
                    />
                    <Field
                      component={TextInput}
                      name='email'
                      type='email'
                      label={translate('EMAIL')}
                    />
                    <Field
                      component={SelectInput}
                      name='type'
                      label={translate('TIPO')}
                      items={data}
                      dataItem='title'
                      onChange={(v) => onChangeType(v, values, form)}
                    />
                    {values && values.type === '4'
                      ? renderChildren(values, form)
                      : values && values.type === '3'
                      ? renderParent()
                      : values && values.type === '2'
                      ? renderTeacher()
                      : null}
                    <Field
                      component={TextInput}
                      name='password'
                      typeText={showPassword ? 'text' : 'password'}
                      label={translate('PASSWORD')}
                      suffix={
                        <img
                          src={showPassword ? EyeOff : Eye}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      }
                    />
                    <Field
                      component={TextInput}
                      name='repeat_password'
                      typeText={showPasswordR ? 'text' : 'password'}
                      label={translate('REPEAT_PASSWORD')}
                      suffix={
                        <img
                          src={showPasswordR ? EyeOff : Eye}
                          onClick={() => setShowPasswordR(!showPasswordR)}
                        />
                      }
                    />
                    <Field
                      component={CheckboxInput}
                      name='terms_and_conditions'
                      type='checkbox'
                      checked={values.terms_and_conditions}
                      label={translate('TERMS_CONDITIONS')}
                      onClickLabel={() =>
                        window.open(
                          'https://smsweb.codewip.com/privacy-policy',
                          '_blank'
                        )
                      }
                    />
                    {error && (
                      <MaterialErrorLabel>{error}</MaterialErrorLabel>
                    )}
                    <LoginButtonDiv>
                      <BaseButton
                        align='initial'
                        variant='raised'
                        htmlType='submit'
                        type='primaryLarge'
                        loading={submitting}
                        disabled={!valid}
                        text={translate('ENVIAR')}
                      />
                    </LoginButtonDiv>
                  </>
                )}
              </LoginCard>
            </PageForm>
          )}
        </Form>
      </LoginContainer>
    </Layout>
  )
}

RegisterPage.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(RegisterPage)

import React, {useState} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import {PropTypes} from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'
import {withRouter, useLocation} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_objetivos_atividades.svg'
import IcQuestion from '../../assets/icons/question.svg'
import {SaveActivitiesObjectives} from '../../infra/requests/ProgramRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {
  GetValueLanguage,
  UpdateUserProgram
} from '../../infra/utils/CommonFunctions'
import {registoObjetivosFirst} from '../../infra/utils/PagesNames'
import {
  MainContainerBackground,
  MainContainerScroll,
  MainContainer,
  RegisterContainer
} from '../../pages/step/StepStyles'
import {UpdateRegistoObjetivosProgram} from '../../redux/Info/info.actions'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {PageForm, IconQuestion} from '../../styles/BasicStyles'
import {Beige3, White} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import ChartComponent from '../charts/ChartComponent'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import QuestionSelectInput from '../inputs/QuestionSelectInput'
import QuestionTextInput from '../inputs/QuestionTextInput'

const formRules = FormValidator.make({
  average: 'required'
})

const RegistoObjetivos = ({
  step,
  chapter,
  nextStep,
  history,
  activeLanguage,
  translate,
  updateChapter
}) => {
  const dispatch = useDispatch()
  const {state} = useLocation()
  const user = useSelector((state) => state.user)
  const isApplicator = user.type === 2
  const [isLoading, setIsLoading] = useState(false)

  const daysOfWeek = [
    {dataKey: 1, title: translate('SEGUNDA')},
    {dataKey: 2, title: translate('TERÇA')},
    {dataKey: 3, title: translate('QUARTA')},
    {dataKey: 4, title: translate('QUINTA')},
    {dataKey: 5, title: translate('SEXTA')},
    {dataKey: 6, title: translate('SABADO')},
    {dataKey: 7, title: translate('DOMINGO')}
  ]
  const initialValues = {
    average: undefined
  }

  const onNextStep = () => {
    if (!isApplicator) {
      UpdateUserProgram(updateChapter, chapter._id, dispatch)
    }
    if (state?.next) {
      if (state.next === 'isBack') history.goBack()
      else history.push(state.next)
    } else if (nextStep) {
      history.push(`/step/${nextStep._id}`)
    } else history.push('/program')
  }

  const onSubmit = async (values) => {
    if (isApplicator) return onNextStep()

    try {
      setIsLoading(true)
      const weekStart = moment
        .utc()
        .clone()
        .startOf('isoWeek')

      const day = moment
        .utc(weekStart)
        .add(parseInt(values.dayOfWeek), 'days')
      const weekEnd = moment
        .utc()
        .clone()
        .endOf('isoWeek')
      const fields = {
        user: user._id,
        startDate: weekStart,
        endDate: weekEnd,
        date: day,
        media: values.average,
        activity: values.activity
      }

      const result = await SaveActivitiesObjectives(fields)

      if (result.success) {
        dispatch(UpdateUserScore(result.data.points))
        dispatch(
          UpdateRegistoObjetivosProgram({
            session: chapter.session,
            chapter: chapter._id,
            step: step._id
          })
        )
        onNextStep()
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.warn(e)
    }
  }

  const onCloseStep = () => {
    if (state?.back) {
      if (state?.back === 'isBack') history.goBack()
      else history.push(state.back)
    } else history.push('/program')
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={formRules}
      initialValues={initialValues}
    >
      {({handleSubmit, submitting, valid, dirty}) => (
        <PageForm onSubmit={handleSubmit}>
          <BasicHeaderComponent
            onClose={onCloseStep}
            title={chapter.title}
            progress={(step.order / chapter.steps.length) * 100}
          />
          <MainContainerBackground
            bgColor={Beige3}
            style={{
              backgroundImage: `url(${Illustration})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center'
            }}
          >
            <MainContainerScroll>
              <MainContainer>
                <RegisterContainer bgColor={White} style={{marginTop: 0}}>
                  <IconQuestion
                    src={IcQuestion}
                    onClick={() =>
                      history.replace(`/step/${registoObjetivosFirst}`)
                    }
                  />
                  <ChartComponent isAuto />
                  <QuestionText isBold>
                    {GetValueLanguage(step.title, activeLanguage?.code)}
                  </QuestionText>

                  <Col sm={24} lg={12} xl={8}>
                    <Field
                      typeText='number'
                      component={QuestionTextInput}
                      name='average'
                      label={GetValueLanguage(
                        step.questions[0].title,
                        activeLanguage?.code
                      )}
                      placeholder={translate('INTRODUZ_MEDIA_PLACEHOLDER')}
                      isRequired
                      step={0.1}
                    />
                  </Col>
                  <Row gutter={[16, 0]}>
                    <Col sm={24} lg={24} xl={14}>
                      <Field
                        typeText='text'
                        component={QuestionTextInput}
                        name='activity'
                        label={GetValueLanguage(
                          step.questions[1].title,
                          activeLanguage?.code
                        )}
                        placeholder={translate(
                          'ATIVIDADE_PRETENDO_REALIZAR_PLACEHOLDER'
                        )}
                      />
                    </Col>
                    <Col xs={24} lg={12} xl={10}>
                      <Field
                        component={QuestionSelectInput}
                        name='dayOfWeek'
                        label={GetValueLanguage(
                          step.questions[2].title,
                          activeLanguage?.code
                        )}
                        items={daysOfWeek}
                        placeholder={translate(
                          'DIA_DA_SEMANA_PLACEHOLDER'
                        )}
                      />
                    </Col>
                  </Row>
                </RegisterContainer>
              </MainContainer>
            </MainContainerScroll>
          </MainContainerBackground>
          <BasicFooterComponent
            onBack={() => history.goBack()}
            nextHtmlType='submit'
            nextDisabled={submitting || !valid || !dirty}
            onNextText={translate('REGISTAR')}
            isLoading={isLoading}
          />
        </PageForm>
      )}
    </Form>
  )
}

RegistoObjetivos.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  activeLanguage: PropTypes.object.isRequired,
  updateChapter: PropTypes.bool
}

RegistoObjetivos.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  updateChapter: false
}

export default withLocalize(withRouter(RegistoObjetivos))

import client from '../config/AxiosConfig'

export const GetThreads = async (page, limit, author, userForum) => {
  if (author) {
    return client.get(
      `/threads/published/${page}/${limit}?forum=${userForum}&author=${author}`
    )
  }
  return client.get(
    `/threads/published/${page}/${limit}?forum=${userForum}`
  )
}

export const GetThread = async (id) => client.get(`/threads/${id}`)

export const CreateComment = async (fields) =>
  client.post('/comments', fields)

export const CreateSubComment = async (id, fields) =>
  client.put(`/comments/${id}/add`, fields)

export const CreateThread = async (fields) => client.post('/threads', fields)

export const EditThread = async (id, fields) =>
  client.put(`/threads/fo/${id}`, fields)

export const EditComment = async (id, fields) =>
  client.put(`/comments/fo/${id}`, fields)

export const EditSubComment = async (idParent, id, fields) =>
  client.put(`/comments/fo/${idParent}/${id}`, fields)

export const GetComments = async (page, limit, thread) =>
  client.get(`/comments/published/${page}/${limit}?thread=${thread}`)

import React from 'react'

import {DownloadOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import HandleImageUrl from '../../infra/services/images/HandleImageUrl'
import {GetValueLanguage, Download} from '../../infra/utils/CommonFunctions'
import {
  ImageBox,
  InfoImage,
  IntroductionBlock,
  MainContainer,
  MainContainerBackgroundParent,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {Beige3} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'

const StepImage = ({step, activeLanguage, history, nextStep, chapter}) => {
  const onBackStep = () => {
    history.goBack()
  }

  const onCloseStep = () => {
    if (history.location.state?.back) {
      if (history.location.state?.back === 'isBack') history.goBack()
      else history.push(history.location.state.back)
    } else history.push('/educators_program')
  }

  // For PT the field is just "image", in other languages it's "image_LANG"
  let image = null;
  if(step[`image_${activeLanguage?.code}`]) image = step[`image_${activeLanguage?.code}`];
  else image = step.image;

  return (
    <>
      <BasicHeaderComponent
        onClose={onCloseStep}
        title={chapter.title}
        progress={(step.order / chapter.steps.length) * 100}
      />
      <MainContainerBackgroundParent>
        <MainContainerScroll>
          <MainContainer>
            <Row gutter={[16, 16]}>
            {step.descriptions?.length > 0 && (
              <Col sm={24} lg={12}>
                <IntroductionBlock>
                {step.descriptions.map((description, index) => (
                  <div style={{marginBottom: 24}} key={index}>
                    <QuestionText
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: GetValueLanguage(
                          description.title,
                          activeLanguage && activeLanguage.code
                        )
                      }}
                      isBigger
                    />
                  </div>
                ))}
                </IntroductionBlock>
              </Col>
            )}
            {image && (
              <Col sm={24} lg={step.descriptions?.length > 0 ? 12 : 24} style={{display: 'flex', width: '100%'}}>
                <ImageBox>
                  <InfoImage
                    src={HandleImageUrl(image.file)}
                  />
                  <a
                    download
                    onClick={(e) => Download(e, HandleImageUrl(image.file), 'image.jpg')}
                    style={{
                      position: 'absolute',
                      bottom: 10,
                      right: 10,
                      zIndex: 1
                    }}
                  >
                    <DownloadOutlined
                      style={{
                        fontSize: 32,
                        background: Beige3,
                        borderRadius: '50%',
                        padding: 8
                      }}
                    />
                  </a>
                </ImageBox>
              </Col>
            )}
            </Row>
          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackgroundParent>
      <BasicFooterComponent
        onBack={onBackStep}
        onNext={() =>
          history.location?.state?.next === 'isBack'
          ? history.goBack()
          : nextStep
            ? history.push(`/step_educators/${nextStep._id}`)
            : history.push('/educators_program')
        }
      />
    </>
  )
}

StepImage.propTypes = {
  step: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object
}

StepImage.defaultProps = {
  nextStep: undefined,
  chapter: undefined
}
export default withLocalize(withRouter(StepImage))

import moment from 'moment'

import {UpdateProgram} from '../../redux/Info/info.actions'
import {SMSe} from '../../styles/Variables'
import {UpdateUserChapter} from '../requests/ProgramRequests'
import {
  forumEducadores,
  forumJovens,
  sessaoReforçoJovens,
  sessaoReforçoEducadores,
  sessaoExpressJovens,
  sessaoExpressEducadores
} from './PagesNames'

export const GetValueLanguage = (value, lang) => {
  if (!value) return null
  if (typeof value === 'string') return value
  if (value && value[lang] && value[lang] !== '') return value[lang]
  if (value && value.pt && value.pt !== '') return value.pt
  return value[Object.keys(value)[0]]
}

export const getImage = (id) => `${process.env.REACT_APP_IMAGES_URL}/${id}`

export const GetUserPhoto = (user) => {
  if (user && user.photo) {
    // if (user.photo.url) return user.photo.url
    if (user.photo.file) {
      return `${process.env.REACT_APP_IMAGES_URL}/${user.photo.file}`
    }
  }

  return require('../../assets/icons/user_icon.svg')
}

export const GetAudiosFromProgram = (program) => {
  if (!program) return null
  const audios = []
  program.sessions.map((session) => {
    session.chapters.map((chapter) => {
      if (chapter.type === 7) {
        audios.push({...chapter, sessionOrder: session.order})
      }
    })
  })
  return audios
}

export const GetVideosFromProgram = (program, isEducator) => {
  if (!program) return null
  const videos = []
  program.sessions.map((session) => {
    session.chapters.map((chapter) => {
      if (chapter.type === 1) {
        if (isEducator) {
          if (!(session.order === 8 && chapter.order === 4)) {
            videos.push({...chapter, sessionOrder: session.order})
          }
        } else { videos.push({...chapter, sessionOrder: session.order}) }
      }
      if (!isEducator && session.order === 1) {
        chapter.steps.map((s) => {
          if (s.type === 'video') {
            videos.push({
              ...chapter,
              steps: [s],
              title: s.title,
              sessionOrder: session.order
            })
          }
        })
      }
      if (isEducator && chapter.type === 11) {
        videos.push({...chapter, sessionOrder: session.order})
      }
    })
  })
  return videos
}

export const GetRecordsFromProgram = (program, isActivities) => {
  if (!program) return null
  const records = []
  const session6Enabled = program.sessions[5].enabled
  program.sessions.map((session) => {
    session.chapters.map((chapter) => {
      if (
        isActivities &&
        !session6Enabled &&
        chapter.type === 6 &&
        (session.order === 5 || session.order === 6)
      ) {
        records.push({...chapter, sessionOrder: session.order})
      } else if (
        isActivities &&
        session6Enabled &&
        chapter.type === 6 &&
        (session.order === 5 || session.order === 7)
      ) {
        records.push({...chapter, sessionOrder: session.order})
      }
    })
    if (
      !isActivities &&
      session.chapters.find((c) => c.type === 6) &&
      session.order <= 5
    ) {
      records.push({
        ...session.chapters.find((c) => c.type === 6),
        sessionOrder: session.order
      })
    }
  })

  return records
}

export const GetInfosFromProgram = (program) => {
  if (!program) return null
  const infos = []
  program.sessions.map((session) => {
    session.chapters.map((chapter) => {
      if (chapter.type === 12) {
        if (!((session.order === 3 && chapter.order === 4) ||
          (session.order === 9 && chapter.order === 6) ||
          (session.order === 9 && chapter.order === 7))) {
            infos.push({...chapter, sessionOrder: session.order})
          }
      }
    })
  })
  return infos
}

export const isEnabledInProgram = (program, id) => {
  if (!program) return null
  let enabled = false

  program.sessions.forEach((session) => {
    session.chapters.forEach((chapter) => {
      const step = chapter.steps.find((s) => s._id === id)
      if (step) enabled = chapter?.enabled
    })
  })

  return enabled
}

export const GetStepByIdFromProgram = (id, program) => {
  if (!id || !program) return null
  let step
  for (let i = 0; i < program.sessions.length; i++) {
    for (let j = 0; j < program.sessions[i].chapters.length; j++) {
      step = program.sessions[i].chapters[j].steps.find((s) => s._id === id)
      if (step) {
        return {
          ...step,
          chapter: {
            ...program.sessions[i].chapters[j],
            session: program.sessions[i]
          }
        }
      }
    }
  }

  return step
}

export const GetGamesFromProgram = (program) => {
  if (!program) return null
  const games = []
  program.sessions.map((session) => {
    session.chapters.map((chapter) => {
      if (chapter.type === 5) {
        games.push({...chapter, sessionOrder: session.order})
      }
    })
  })
  return games
}

export const GetQuizzesFromProgram = (program) => {
  if (!program) return null
  const games = []
  program.sessions.map((session) => {
    session.chapters.map((chapter) => {
      if (chapter.type === 4) {
        games.push({...chapter, sessionOrder: session.order})
      }
    })
  })
  return games
}

export const isEmpty = (str) => {
  if (!str) return true
  return !str.trim().length
}

export const getRandomInt = (minimum, maximum) => {
  const min = Math.ceil(minimum)
  const max = Math.floor(maximum)
  return Math.floor(Math.random() * (max - min)) + min
}

export const calcProgress = (session) => {
  if (!session) return 0
  let chapters = 0
  let chaptersCompleted = 0
  session.chapters.map((chapter) => {
    chapters += 1
    if (chapter && chapter.completed) {
      chaptersCompleted += 1
    }
    return chapter
  })
  return ((chaptersCompleted / chapters) * 100).toFixed()
}

export const UpdateUserProgram = async (updateChapter, id, dispatch) => {
  try {
    const r = await UpdateUserChapter({chapter: id, updateChapter})
    // if (r.success) dispatch(UpdateProgram(r.data.sessions))
  } catch (e) {
    console.warn(e)
  }
}

export const GetPreviousRecords = (sessions, active) => {
  if (!sessions) return null
  const records = []

  sessions.map((session) => {
    if (session.order < active) {
      session.chapters.map((chapter) => {
        if (!(session.order === 4 && chapter.order === 5)) {
          if (active > 7 && chapter.type === 6 && session.order !== 6) {
            records.push({...chapter, sessionOrder: session.order})
          } else if (
            active <= 7 &&
            chapter.type === 6 &&
            session.order !== 6
          ) {
            records.push({...chapter, sessionOrder: session.order})
          }
        }
      })
    }
  })

  return records
}

export const getUserForum = (user) => {
  if (!user || !user.type) return null

  if (user.type === 3) return forumEducadores

  return forumJovens
}

export const getBoosterSession = (user) => {
  if (!user || !user.extra_session_availability) return false

  let id = sessaoReforçoJovens
  if (user.type === 3) id = sessaoReforçoEducadores

  const extraSession = user.extra_session_availability.find(
    (s) => s.extra_session === id
  )

  if (!extraSession) return false

  const isAvailable =
    extraSession.availability_date &&
    moment.utc().isAfter(moment.utc(extraSession.availability_date))

  if (isAvailable) return true

  return false
}

export const getExpressSession = (user) => {
  if (!user || !user.extra_session_availability) return false

  let id = sessaoExpressJovens
  if (user.type === 3) id = sessaoExpressEducadores

  const extraSession = user.extra_session_availability.find(
    (s) => s.extra_session === id
  )

  if (!extraSession) return false

  const isAvailable =
    extraSession.availability_date &&
    moment.utc().isAfter(moment.utc(extraSession.availability_date))

  if (isAvailable) return true

  return false
}

export const getUserNameForum = (name) => {
  if (!name) return false

  const names = name.split(' ')
  const firstName = names?.length >= 0 ? names[0] : false

  if (!firstName) return false

  const lastName = names?.length > 0 ? names[names.length - 1] : false

  const userName = lastName
    ? `${firstName} ${lastName.slice(0, 1)}`
    : firstName

  return userName
}

export const Download = (e, href, title) => {
  fetch(href, {
    method: 'GET',
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then((buffer) => {
        const url = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', title)
        document.body.appendChild(link)
        link.click()
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getVideoEducator = (session, chapter, step, videos) => {
  let video
  if (session === 2 && chapter === 6 && step === 1) {
    video = videos?.find((elem) => elem.session === 11 && elem.chapter === 2 && elem.step === 1)
  }
  if (session === 4 && chapter === 4 && step === 1) {
    video = videos?.find((elem) => elem.session === 2 && elem.chapter === 3 && elem.step === 1)
  }
  if (session === 5 && chapter === 5 && step === 1) {
    video = videos?.find((elem) => elem.session === 7 && elem.chapter === 2 && elem.step === 1)
  }
  if (session === 6 && chapter === 6 && step === 1) {
    video = videos?.find((elem) => elem.session === 2 && elem.chapter === 2 && elem.step === 1)
  }
  if (session === 7 && chapter === 3 && step === 1) {
    video = videos?.find((elem) => elem.session === 10 && elem.chapter === 2 && elem.step === 1)
  }
  if (session === 8 && chapter === 4 && step === 1) {
    video = videos?.find((elem) => elem.session === 11 && elem.chapter === 2 && elem.step === 1)
  }
  if (session === 11 && chapter === 4 && step === 1) {
    video = videos?.find((elem) => elem.session === 13 && elem.chapter === 1 && elem.step === 1)
  }
  return video
}

export const GetStepName = (program) => {
  if (program?._id === SMSe) {
    return 'step_educators'
  }

  return 'step'
}

export const GetProgramName = (program) => {
  if (program?._id === SMSe) {
    return 'educators_program'
  }

  return 'youth_program'
}

export const GetVideoByLanguage = (session, chapterOrder, stepOrder, videos, stepName, isApplicator, isEducator, lang) => {
  if (!videos && !videos.length) return null

  const video = isApplicator ?
  stepName === 'step' ?
    videos?.find((elem) => elem.session === session && elem.chapter === chapterOrder && elem.step === stepOrder)
    : getVideoEducator(session, chapterOrder, stepOrder, videos)
  : isEducator
  ? getVideoEducator(session, chapterOrder, stepOrder, videos)
  : videos?.find((elem) => elem.session === session && elem.chapter === chapterOrder && elem.step === stepOrder)

  if (typeof video === 'string') return video
  if (video && video[`file_${lang}`]) return {video: video[`file_${lang}`]._id, subtitles: video[`subtitles_${lang}`]?._id}
  if (video && video.file_pt) return {video: video.file_pt._id, subtitles: video.subtitles_pt?._id}
  if (video && video.file && video.file !== '') return {video: video.file, subtitles: false}
  return null
}

import React from 'react'

import {PropTypes} from 'prop-types'
import {withRouter} from 'react-router-dom'

import {
  MainContainer,
  MainContainerBackgroundParent,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'

const ParentBaseStep = ({step, history, nextStep, chapter, isFirst}) => {
  return (
  <>
    <BasicHeaderComponent
      onClose={() => history.push('/educators_program')}
      title={chapter.title}
      progress={(step.order / chapter.steps.length) * 100}
    />
    <MainContainerBackgroundParent>
      <MainContainerScroll>
        <MainContainer>
          {step.descriptions.map((description, index) => (
            <MessageComponent
              key={index}
              text={description.title}
              active={index === step.descriptions.length - 1}
              isBigger
            />
          ))}
        </MainContainer>
      </MainContainerScroll>
    </MainContainerBackgroundParent>
    <BasicFooterComponent
      onBack={isFirst ? undefined : () => history.goBack()}
      onNext={() =>
        nextStep
          ? history.push(`/step_educators/${nextStep._id}`)
          : history.push('/educators_program')
      }
    />
  </>
)
}

ParentBaseStep.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  isFirst: PropTypes.bool
}

ParentBaseStep.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  isFirst: false
}
export default withRouter(ParentBaseStep)

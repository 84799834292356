import React, {useState} from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {InactiveTextColor} from '../../styles/Colours'
import {Title25} from '../../styles/TextSizes'
import {
  QuestionOption,
  OptionText,
  BlockLeft,
  BlockRight,
  QuestionImg3
} from './QuestionStyles'

const QuestionImage3 = ({fieldName, question, image, activeLanguage}) => {
  const [animation, setAnimation] = useState(undefined)

  const onSelectOption = (idOption, form, name) => {
    form.change(name, idOption)
  }

  const optionsArray = opt => {
    const {options, form, input} = opt
    const currentOption = input?.value || undefined
    const currentOptionObj =
      options.find(o => o._id === input?.value) || undefined

    const isCorrect = currentOptionObj?.isCorrect ? input?.value : false

    return (
      <>
        {options.map((option, index) => (
          <QuestionOption
            className={
              animation === index
                ? isCorrect
                  ? 'animated'
                  : 'animatedWrong'
                : ''
            }
            key={index}
            onClick={() => {
              if (!(isCorrect && isCorrect !== option._id)) {
                onSelectOption(option._id, form, input.name)
                setAnimation(index)
              }
            }}
            onAnimationEnd={() => setAnimation(undefined)}
            isActive={currentOption === option._id && option.isCorrect}
            isWrong={currentOption === option._id && !option.isCorrect}
          >
            <OptionText
              color={
                isCorrect && isCorrect !== option._id
                  ? InactiveTextColor
                  : undefined
              }
            >
              {GetValueLanguage(option.title, activeLanguage?.code)}
            </OptionText>
          </QuestionOption>
        ))}
      </>
    )
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({form}) => (
        <>
          <Title25 style={{textAlign: 'center'}}>
            {GetValueLanguage(question.title, activeLanguage?.code)}
          </Title25>
          <Margin size={50} vertical={24} />
          <Row>
            <Col xs={24} md={12}>
              <BlockLeft>
                <QuestionImg3 src={image} />
              </BlockLeft>
            </Col>
            <Col xs={24} md={12}>
              <BlockRight>
                <Field
                  name={`${fieldName}.options`}
                  component={optionsArray}
                  options={question.options}
                  form={form}
                />
              </BlockRight>
            </Col>
          </Row>
        </>
      )}
    </FormSpy>
  )
}

QuestionImage3.propTypes = {
  question: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  image: PropTypes.string,
  fieldName: PropTypes.string.isRequired
}

QuestionImage3.defaultProps = {
  image: undefined
}
export default withLocalize(withRouter(QuestionImage3))

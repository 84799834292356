import React, {useEffect} from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {BaseButton} from '../../components/buttons/BaseButton'
import CompleteComponent from '../../components/dashboard/CompleteComponent'
import MeditateComponent from '../../components/dashboard/MeditateComponent'
import {GetStepName, isEnabledInProgram} from '../../infra/utils/CommonFunctions'
import {diarioHumor} from '../../infra/utils/PagesNames'
import {Margin} from '../../styles/BasicStyles'
import {
  DashboardContainer,
  TitleDashboard,
  DashboardBoxSmall,
  DashboardBoxCol
} from '../dashboard/DashboardStyles'
import ActivitiesCalendarComponent from './ActivitiesCalendarComponent'
import GamesComponent from './GamesComponent'
import InfotecaComponent from './InfotecaComponent'
import QuizzesComponent from './QuizzesComponent'
import VideotecaComponent from './VideotecaComponent'

const ActivitiesPage = ({translate, history}) => {
  const {
    user,
    info: {program}
  } = useSelector((state) => state)
  const isStudent = user.type === 4
  const isEducator = user.type === 3
  const stepName = GetStepName(program)

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <DashboardContainer>
      {isStudent && (
        <>
          <DashboardBoxSmall bgColor='#FFFFFF'>
            <Col xs={24} md={12} lg={16}>
              <TitleDashboard>
                {translate(
                  isStudent
                    ? 'COMO_TE_SENTES_HOJE'
                    : 'COMO_TE_SENTES_HOJE_EDUCADOR'
                )}
              </TitleDashboard>
            </Col>
            <DashboardBoxCol xs={24} md={12} lg={8}>
              <BaseButton
                auto
                variant='raised'
                htmlType='button'
                type='primaryMedium'
                text={translate('CONTINUE')}
                onClick={() =>
                  history.push(
                    `/${stepName}/${diarioHumor}`,
                    {
                      next: 'isBack',
                      back: 'isBack',
                      noProgram: true
                    }
                  )
                }
                disabled={!isEnabledInProgram(program, diarioHumor)}
              />
            </DashboardBoxCol>
          </DashboardBoxSmall>
          <Margin size={32} />
          <CompleteComponent isActivities />
          <Margin size={32} />
          <ActivitiesCalendarComponent />
          <Margin size={32} />
        </>
      )}
      <MeditateComponent />
      <Margin size={32} />
      <GamesComponent />
      <Margin size={32} />
      <QuizzesComponent />
      <Margin size={32} />
      <VideotecaComponent />
     {isEducator && (
      <>
        <Margin size={32} />
        <InfotecaComponent />
      </>
    )}
    </DashboardContainer>
  )
}

ActivitiesPage.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withLocalize(withRouter(ActivitiesPage))

import React from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_games_activities.svg'
import CompleteBoxComponent from '../../components/dashboard/CompleteBoxComponent'
import {
  GetValueLanguage,
  GetGamesFromProgram,
  GetStepName
} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {White} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import {DashboardBox} from '../dashboard/DashboardStyles'

const GamesComponent = ({translate, activeLanguage, history}) => {
  const {
    info: {program}
  } = useSelector((state) => state)
  const stepName = GetStepName(program)

  const records = GetGamesFromProgram(program)

  return (
    <DashboardBox
      column
      bgColor={White}
      image={`url(${Illustration})`}
      style={{
        backgroundPosition: 'top',
        backgroundSize: 'contain'
      }}
    >
      <TitleH3 weight={700} style={{textAlign: 'left'}}>
        {translate('JOGOS')}
      </TitleH3>
      <Margin size={21} />
      <Row gutter={[16, 16]}>
        {records.map((record, i) => (
          <Col xs={24} md={8} key={i}>
            <CompleteBoxComponent
              translate={translate}
              inactive={!record.enabled}
              title={GetValueLanguage(record.title, activeLanguage?.code)}
              starNumber={4}
              titleBtn='JOGAR'
              onClick={() =>
                history.push(
                  `/${stepName}/${
                    record.steps.find((s) => s.type === 'question')._id
                  }`,
                  {
                    next: 'isBack',
                    back: 'isBack',
                    noProgram: true
                  }
                )
              }
            />
          </Col>
        ))}
      </Row>
    </DashboardBox>
  )
}

GamesComponent.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withLocalize(withRouter(GamesComponent))

import React, {useState} from 'react'

import {Collapse, Col} from 'antd'
import PropTypes from 'prop-types'

import SessionComponent from './SessionComponent'
import SessionPanelHeader from './SessionPanelHeader'

const {Panel} = Collapse

const SessionsCollapse = ({sessions}) => {
  const [activePanel, setActivePanel] = useState(false)
  const renderHeader = (session, index, last, isOpen) => (
    <SessionPanelHeader
      id={`session_${session.order}`}
      session={session}
      isOpen={isOpen}
      last={last}
      index={index + 1}
      nextSession={sessions[index + 1]}
    />
  )

  return (
    <Collapse
      accordion
      bordered={false}
      ghost
      onChange={(e) => setActivePanel(parseInt(e))}
    >
      {sessions.map((session, index) => (
        <Panel
          key={index}
          showArrow={false}
          header={renderHeader(
            session,
            index,
            sessions.length,
            activePanel === index
          )}
        >
          <Col
            xs={{
              span: 18,
              offset:
                index + 1 === sessions.length
                  ? 0
                  : (index + 1) % 2 === 0
                  ? 0
                  : 6
            }}
            lg={{
              span: 18,
              offset:
                index + 1 === sessions.length
                  ? 4
                  : (index + 1) % 2 === 0
                  ? 0
                  : 6
            }}
          >
            <SessionComponent
              sessions={sessions}
              session={session}
              isOpen={index === activePanel}
            />
          </Col>
          {index + 1 === sessions.length && <Col xs={2} />}
        </Panel>
      ))}
    </Collapse>
  )
}

SessionsCollapse.propTypes = {
  sessions: PropTypes.array.isRequired
}

export default SessionsCollapse

import client from '../config/AxiosConfig'

export const GetStepById = async (id) => client.get(`/steps/id/${id}`)

export const SaveStepAnswer = async (fields) =>
  client.post('/steps_answers', fields)

export const SaveStepAnswerObjective = async (fields) =>
  client.post('/steps_answers/objective', fields)

export const GetActivities = async (userId, startDate, endDate) =>
  client.get(
    `/activities_calendar/${userId}?start_date=${startDate}&end_date=${endDate}`
  )

export const SaveActivities = async (fields) =>
  client.post('/activities_calendar', fields)

export const SaveActivitiesObjectives = async (fields) =>
  client.post('/activities_objectives', fields)

export const GetChartActivities = async (startDate, endDate) =>
  client.get(
    `/graphics/activities?start_date=${startDate}&end_date=${endDate}`
  )

export const GetChartMeditation = async (startDate, endDate) =>
  client.get(
    `/graphics/meditation?start_date=${startDate}&end_date=${endDate}`
  )

export const GetHistory = async (id) =>
  client.get(`/steps_answers/history/${id}`)

export const UpdateUserChapter = async (fields) =>
  client.put('/users/update_chapter', fields)

export const GetActiveGame = async () =>
  client.get('/challenge_game/active')

export const CreateGame = async (fields) =>
  client.post('/challenge_game', fields)

export const UpdateGame = async (fields) =>
  client.put('/challenge_game', fields)

export const WatchVideo = async (fields) =>
  client.post('points/add_video_points', fields)

export const GetBoosterSession = async (id) =>
  client.get(`/extra_session/${id}`)

export const GetUserProgram = async (id, user) =>
  client.get(`/programs/id/${id}/${user}`)

import {Image} from 'antd'
import styled from 'styled-components'

import {Beige, Beige2, MenuBorderColor} from '../../styles/Colours'
import {device, vertical} from '../../styles/Responsive'

export const StepContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

export const MainContainerBackground = styled.div`
  width: 100%;
  background-color: ${({bgColor}) => bgColor || Beige};
`

export const MainContainerBackgroundParent = styled.div`
  width: 100%;
  background-color: ${({bgColor}) => bgColor || Beige};

  @media ${device.tabletMin} {
    background-image: inherit !important;
  }
`

export const MainContainerScroll = styled.div`
  width: 100%;
  height: calc(100vh - 70px - 105px);
  overflow: auto;
  padding: 48px 0px;
  margin-top: 70px;
  display: flex;

  @media ${device.tablet} {
    height: calc(100vh - 64px - 105px);
    margin-top: 64px;
    padding: 24px 0px;
  }
`

export const MainContainer = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  padding: 0px 112px;
  margin: auto;
  min-height: 100%;

  media ${device.desktopL} {
    padding: 0px 112px;
  }

  @media ${device.laptop} {
    padding: 0px 20px;
  }

  @media ${device.tablet} {
    padding: 0px 10px;
  }

  @media ${device.mobileL} {
    padding: 0px 10px;
  }
`

export const MainContainerEducator = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  padding: 0px 112px;
  margin: auto;

  media ${device.desktopL} {
    padding: 0px 112px;
  }

  @media ${device.laptop} {
    padding: 0px 20px;
  }

  @media ${device.tablet} {
    padding: 0px 10px;
  }

  @media ${device.mobileL} {
    padding: 0px 10px;
  }
`

export const MainContainerFlex = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  padding: 0px 112px;
  margin: auto;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({isColumn}) => (isColumn ? 'column' : 'row')};

  media ${device.desktopL} {
    padding: 0px 112px;
  }

  @media ${device.laptop} {
    padding: 0px 20px;
  }

  @media ${device.tablet} {
    padding: 0px 10px;
  }

  @media ${device.mobileL} {
    padding: 0px 10px;
  }
`

export const MainContainerFlex2 = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  padding: 0px 112px;
  margin: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px - 105px - 48px);

  media ${device.desktopL} {
    padding: 0px 112px;
  }

  @media ${device.laptop} {
    padding: 0px 20px;
  }

  @media ${device.tablet} {
    padding: 0px 10px;
    height: calc(100vh - 64px - 105px);
  }

  @media ${device.mobileL} {
    padding: 0px 10px;
  }
`

export const MainContainerScroll2 = styled.div`
  width: 100%;
  height: calc(100vh - 70px - 105px);
  overflow: auto;
  padding: 24px 0px;
  margin-top: 70px;
  display: flex;

  @media ${device.tablet} {
    margin-top: 64px;
    height: calc(100vh - 64px - 105px);
    padding: 16px 0px;
  }
`

export const MainContainerScroll3 = styled.div`
  width: 100%;
  height: calc(100vh - 70px - 105px);
  overflow: auto;
  padding: 0px;
  margin-top: 70px;
  display: flex;

  @media ${device.tablet} {
    margin-top: 64px;
    height: calc(100vh - 64px - 105px);
    padding: 0px;
  }
`

export const MainContainer3 = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  padding: 24px 112px;
  margin: auto;
  min-height: 100%;

  media ${device.desktopL} {
    padding: 24px 112px;
  }

  @media ${device.laptop} {
    padding: 16px 20px;
  }

  @media ${device.tablet} {
    padding: 16px 10px;
  }

  @media ${device.mobileL} {
    padding: 16px 10px;
  }
`

export const MainContainer2 = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  padding: 0px 112px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;

  media ${device.desktopL} {
    padding: 0px 112px;
  }

  @media ${device.laptop} {
    padding: 0px 20px;
  }

  @media ${device.mobileL} {
    padding: 0px 10px;
  }
`

export const RegisterContainer = styled.div`
  z-index: 1;
  width: 100%;
  margin-top: 24px;
  padding: 32px;
  height: 100%;
  background: ${({bgColor}) => bgColor || Beige};
  border: 2px solid ${({borderColor}) => borderColor || MenuBorderColor};
  border-radius: 8px;

  @media ${vertical.medium} {
    background-size: 300px;
  }

  @media ${device.tabletMin} {
    background-size: 50%;
    min-height: 100%;
    padding: 16px;
  }
`

export const ImageBottom = styled.img`
  position: fixed;
  bottom: 0;
  left: 0px;
`

export const ImageBox = styled.div`
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .ant-image-img {
    width: auto;
  }
`

export const IntroductionBlock = styled.div`
  width: 100%;
  background: ${({bgColor}) => bgColor || Beige2};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;

  @media ${vertical.medium} {
    padding: 16px;
    margin-bottom: 24px;
  }

  @media ${device.tabletMin} {
    padding: 16px;
    margin-bottom: 24px;
  }
`

export const InfoImage = styled(Image)`
  border-radius: 8px;
  max-width: 100%;
  max-height: calc(100vh - 270px);
`

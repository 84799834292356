import React from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Lock from '../../assets/icons/lock.svg'
import Play from '../../assets/icons/play.svg'
import PlayDisabled from '../../assets/icons/play_disabled.svg'
import Illustration from '../../assets/illustrations/illustration_videoteca.svg'
import {
  RowMeditate,
  ColMeditate,
  TitleVideoteca
} from '../../components/dashboard/DashboardComponentStyles'
import {
  MeditatecaContainer,
  MeditatecaScrollBox,
  AudioLineComponent,
  MeditatecaShadow
} from '../../components/diario_meditaçao/DiarioMeditaçaoStyles'
import {
  GetStepName,
  GetValueLanguage,
  GetVideosFromProgram
} from '../../infra/utils/CommonFunctions'
import {
  SecondaryColour,
  TextColor,
  InputBorderColour
} from '../../styles/Colours'
import {TitleH3, QuestionText} from '../../styles/TextSizes'
import {DashboardBox, LockIcon} from '../dashboard/DashboardStyles'

const MeditateComponent = ({translate, history, activeLanguage}) => {
  const {
    user,
    info: {program}
  } = useSelector((state) => state)
  const stepName = GetStepName(program)
  const isEducator = user.type === 3
  const videos = GetVideosFromProgram(program, isEducator)

  const onClickVideo = (video) => {
    history.push(
      `/${stepName}/${video.steps[0]._id}`,
      {
        next: 'isBack',
        back: 'isBack',
        isVideoteca: true
      }
    )
  }

  return (
    <DashboardBox
      column
      bgColor='#FFFFFF'
      image={`url(${require('../../assets/backgrounds/background_videoteca.svg')})`}
    >
      <RowMeditate>
        <ColMeditate md={24} lg={12}>
          <Row style={{flex: 1, display: 'flex'}}>
            {/* <ColMeditate xs={0} lg={12}> */}
            <TitleH3 weight={700} color={SecondaryColour}>
              {translate('VIDEOTECA')}
            </TitleH3>
            <img
              style={{width: '100%', padding: '0px 103px 0px 33px'}}
              src={Illustration}
            />
            {/* </ColMeditate> */}
          </Row>
        </ColMeditate>
        <Col md={24} lg={12} style={{flex: 1}}>
          <TitleVideoteca>
            <TitleH3 weight={700} color={SecondaryColour}>
              {translate('VIDEOTECA')}
            </TitleH3>
          </TitleVideoteca>
          <MeditatecaContainer
            style={{marginTop: 0}}
            height={459}
            bgColor={SecondaryColour}
          >
            <MeditatecaScrollBox>
              {videos.map((video, index) => {
                const active = video.enabled
                return (
                  <AudioLineComponent
                    key={index}
                    isLast={index === videos.length - 1}
                    item={video}
                    active={active}
                  >
                    {!active && (
                      <LockIcon top='-10px' right='-10px'>
                        <img alt='lock' src={Lock} />
                      </LockIcon>
                    )}
                    <QuestionText
                      style={{textAlign: 'left'}}
                      isBold
                      color={active ? TextColor : InputBorderColour}
                    >
                      {GetValueLanguage(video.title, activeLanguage?.code)}
                    </QuestionText>
                    <img
                      src={active ? Play : PlayDisabled}
                      alt='play'
                      style={{
                        marginLeft: 8,
                        cursor: active ? 'pointer' : 'no-drop'
                      }}
                      onClick={
                        !active ? () => {} : () => onClickVideo(video)
                      }
                    />
                  </AudioLineComponent>
                )
              })}
            </MeditatecaScrollBox>
            <MeditatecaShadow isOrange />
          </MeditatecaContainer>
        </Col>
      </RowMeditate>
    </DashboardBox>
  )
}

MeditateComponent.propTypes = {
  history: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(withRouter(MeditateComponent))

import {Col, Row} from 'antd'
import styled from 'styled-components'

import {
  InactiveTextColor,
  MenuBorderColor,
  TextColor,
  SecondaryColour
} from '../../styles/Colours'
import {device} from '../../styles/Responsive'
import {QuestionText, titleH4} from '../../styles/TextSizes'

export const DashboardContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 32px 112px;
  margin: 0 auto;
  text-align: left;
  min-height: calc(100vh - 64px - 109px);

  @media ${device.desktopL} {
    padding: 32px 112px;
  }

  @media ${device.laptop} {
    padding: 32px 20px;
  }

  @media ${device.tablet} {
    padding: 32px 10px;
  }

  @media ${device.mobileL} {
    padding: 32px 10px;
  }
`

export const DashboardBoxSmall = styled(Row)`
  width: 100%;
  padding: 24px;
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  background-image: ${(p) => (p.image ? p.image : null)};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  align-items: center;
`

export const DashboardBox = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: ${(p) => (p.column ? 'column' : 'row')};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  justify-content: space-between;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  background-image: ${(p) => (p.image ? p.image : null)};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media ${device.laptop} {
    flex-direction: column;
  }

  @media ${device.tabletMin} {
    background-image: ${(p) =>
      p.tabletRemove ? 'inherit' : p.image || null};
  }

  .ant-picker-cell {
    padding: 10px 0;

    @media ${device.tablet} {
      padding: 5px 0;
    }

    &:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
      .ant-picker-cell-inner {
      background: inherit;
    }

    .ant-picker-cell-inner {
      font-family: Poppins;
      font-size: 20px;
      line-height: 30px;
      font-weight: bold;

      @media ${device.tablet} {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .ant-picker-cell-in-view {
    color: ${TextColor};

    &.ant-picker-cell-selected .ant-picker-cell-inner {
      background: inherit;
    }
    &.ant-picker-cell-today .ant-picker-cell-inner::before {
      width: 34px;
      height: 34px;
      top: -3px;
      left: -5px;

      @media ${device.tablet} {
        width: 30px;
        height: 30px;
        top: -6px;
        left: -3px;
      }
    }
  }

  .ant-picker-calendar-mini {
    border: 2px solid ${MenuBorderColor};
    box-sizing: border-box;
    border-radius: 15px;
    overflow: hidden;

    .ant-picker-content th {
      font-family: Poppins;
      font-size: 20px;
      line-height: 30px;
      color: ${SecondaryColour};

      @media ${device.tablet} {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`

export const DashboardComboBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
`

export const SubBox = styled.div`
  width: 100%;
  padding: ${(p) => p.padding || '24px'};
  border-top: ${(p) =>
    p.hasBorder ? `2px solid ${MenuBorderColor}` : 'none'};
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
`

export const InlineInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const TitleDashboard = styled(titleH4)`
  font-weight: ${({weight}) => weight || 400};
  font-size: 36px;
  line-height: 54px;
  color: ${(p) => (p.color ? p.color : TextColor)};
  text-align: left;
  margin-bottom: ${({marginB}) => marginB || 0}px;
`

export const DashboardBoxTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: ${(p) => (p.color ? p.color : TextColor)};
`

export const ButtonDashboardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SeeAllButton = styled.div`
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: ${(p) => (p.color ? p.color : TextColor)};
`

// ---------------- COMPLETE BOXES ----------------

export const CompleteBox = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 7px;
  padding: 24px;
  cursor: pointer;
`

export const CompleteTitle = styled(QuestionText)`
  font-weight: 700;
  color: ${(p) => (p.inactive ? InactiveTextColor : TextColor)};
  text-align: left;
  height: ${(p) => (p.height ? p.height : 92)}px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media ${device.laptopL} {
    -webkit-line-clamp: 4;
    height: ${(p) => (p.height ? p.height : 95)}px;
  }

  @media ${device.tablet} {
    -webkit-line-clamp: 4;
    height: ${(p) => (p.height ? p.height : 75)}px;
  }

  @media ${device.mobileL} {
    -webkit-line-clamp: 3;
    height: ${(p) => (p.height ? p.height : 55)}px;
  }
`

export const StarIcon = styled.img`
  width: ${({size}) => (size === 'big' ? '30px' : '20px')};
  height: auto;
  margin-right: 2px;
`

export const StarText = styled.div`
  color: ${(p) => (p.inactive ? InactiveTextColor : '#FFBF00')};
  font-size: ${({size}) => (size === 'big' ? '40px' : '18px')};
  font-weight: 700;
  line-height: ${({size}) => (size === 'big' ? '60px' : '16px')};
  align-self: flex-end;

  @media ${device.laptopL} {
    font-size: 16px;
  }
`

export const LockIcon = styled.div`
  position: absolute;
  background-color: #dcdcdc;
  border-radius: 50px;
  width: 26px;
  height: 26px;
  border: 2px solid #ffffff;
  top: ${(p) => (p.top ? p.top : 0)};
  right: ${(p) => (p.right ? p.right : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
`

// ------------------------------------------------

// ---------------- YOU AREA BOXES ----------------

export const YourAreaBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #ffffff;
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  padding: 24px;
  flex: 1;
  position: relative;
`

export const ChartBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const ForumShadow = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 3.72%,
    rgba(255, 255, 255, 0.57) 40.1%,
    rgba(255, 255, 255, 0.67) 55.76%,
    rgba(255, 255, 255, 0.99) 81.02%,
    #ffffff 92.64%
  );
  height: 129px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const DashboardRow = styled(Row)`
  display: flex !important;
  flex-direction: row;
  flex: 1;

  @media ${device.tablet} {
    flex-direction: column;
  }
`

export const DashboardCol = styled(Col)`
  display: flex !important;
  flex-direction: column;
  flex: 1;
`

export const ForumBoxScroll = styled.div`
  max-height: 450px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${device.laptopL} {
    max-height: 400px;
  }
`

export const DashboardBoxCol = styled(Col)`
  display: flex !important;
  justify-content: flex-end;

  @media ${device.tabletMin} {
    margin-top: 8px;
    justify-content: center;
  }
`

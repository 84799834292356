import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import Cup from '../../assets/icons/Taça.svg'
import {CupContainer, ScoreBox} from '../score/ScoreStyles'
import StarComponent from '../star/StarComponent'

const ScoreQuiz = ({children, points}) => (
  <>
    <CupContainer>
      <img alt='cup' src={Cup} />
    </CupContainer>
    {children && <ScoreBox>{children}</ScoreBox>}
    <StarComponent starNumber={points} plus size='big' />
  </>
)

ScoreQuiz.propTypes = {
  children: PropTypes.array,
  points: PropTypes.number
}

ScoreQuiz.defaultProps = {
  children: undefined,
  points: 0
}
export default withLocalize(withRouter(ScoreQuiz))

import React from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import Meditate from '../../assets/illustrations/illustration_meditate.svg'
import {DashboardBox} from '../../pages/dashboard/DashboardStyles'
import {Purple2} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import {BaseButton} from '../buttons/BaseButton'
import MeditatecaComponent from '../diario_meditaçao/MeditatecaComponent'
import {RowMeditate, ColMeditate} from './DashboardComponentStyles'

const MeditateComponent = ({translate, history}) => (
  <DashboardBox
    column
    bgColor='#FFFFFF'
    image={`url(${require('../../assets/backgrounds/background_meditate.png')})`}
  >
    <Row style={{flex: 1}}>
      <Col xs={12} style={{textAlign: 'left'}}>
        <TitleH3 weight={700} color='#46329B'>
          {translate('MEDITATE')}
        </TitleH3>
      </Col>
      <Col xs={12} style={{textAlign: 'right'}}>
        <BaseButton
          auto
          align='right'
          variant='raised'
          htmlType='button'
          type='primaryLarge'
          text={translate('SEE_ALL')}
          onClick={() => history.push('/meditation')}
        />
      </Col>
    </Row>
    <RowMeditate>
      <Col md={24} lg={12} style={{flex: 1}}>
        <MeditatecaComponent
          color={Purple2}
          height={406}
          next='isBack'
          back='isBack'
          isBiblioteca
        />
      </Col>
      <ColMeditate xs={0} lg={12}>
        <img
          style={{width: '100%', padding: '0px 52px 0px 60px'}}
          src={Meditate}
        />
      </ColMeditate>
    </RowMeditate>
  </DashboardBox>
)

MeditateComponent.propTypes = {
  history: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(withRouter(MeditateComponent))

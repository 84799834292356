import React from 'react'

import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Form, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {SaveStepAnswer} from '../../infra/requests/ProgramRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {
  GetValueLanguage,
  UpdateUserProgram
} from '../../infra/utils/CommonFunctions'
import {
  MainContainerBackground,
  MainContainerScroll3,
  MainContainerFlex
} from '../../pages/step/StepStyles'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {PageForm, Margin} from '../../styles/BasicStyles'
import {SecondaryColour, ThirdColour} from '../../styles/Colours'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import {QuestionTitle} from '../jogo_espirais/JogoEspiraisStyles'
import DichotomousQuestion from '../question/DichotomousQuestion'
import FooterFeedbackComponent from '../question/FooterFeedbackComponent'
import QuestionLine from '../question/QuestionLine'
import {OptionsContainer, OptionText} from '../question/QuestionStyles'
import BDComponent from './BDComponent'
import {MainContainerBD} from './BDStyles'

const formRules = FormValidator.make({})

class BDPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValues: {steps: []},
      currentStep: props.step,
      isFinished: false,
      isLoading: false
    }
  }

  componentDidMount = () => {
    const {chapter} = this.props
    const values = {
      steps: []
    }
    chapter.steps.map((s) => {
      const st = {_id: s._id, questions: []}
      s.questions.map((q) => {
        st.questions.push({
          _id: q._id,
          options: undefined
        })
      })
      values.steps.push(st)
    })
    this.setState({initialValues: values})
  }

  scrollTop = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  onSubmit = async (values) => {
    const {
      user,
      history,
      chapter,
      nextStep,
      updateChapter,
      dispatch
    } = this.props
    const isApplicator = user.type === 2

    if (isApplicator) {
      if (nextStep) history.push(`/step/${nextStep._id}`)
      else history.push('/program')
      return
    }

    try {
      this.setState({isLoading: true})
      const fields = []
      values.steps.forEach((field) => {
        const newField = {
          user: user._id,
          chapter: chapter._id,
          step: field._id
        }
        field.questions.forEach((question) => {
          fields.push({
            ...newField,
            question: question._id,
            options: [question.options]
          })
        })
      })

      const result = await SaveStepAnswer(fields)

      if (!isApplicator) {
        UpdateUserProgram(updateChapter, chapter._id, dispatch)
      }
      if (result.success) {
        dispatch(UpdateUserScore(result.data.points))
        if (nextStep) history.push(`/step/${nextStep._id}`)
        else history.push('/program')
      }
      this.setState({isLoading: false})
    } catch (e) {
      this.setState({isLoading: false})
      console.warn(e)
    }
  }

  onNextStep = (values) => {
    const {chapter} = this.props
    const {currentStep} = this.state
    if (chapter.steps[currentStep.order]) {
      this.setState({currentStep: chapter.steps[currentStep.order]})
      this.scrollTop()
    } else {
      this.onSubmit(values)
    }
  }

  onBackStep = () => {
    const {chapter, history} = this.props
    const {currentStep} = this.state
    if (chapter.steps[currentStep.order - 2]) {
      this.setState({currentStep: chapter.steps[currentStep.order - 2]})
      this.scrollTop()
    } else history.goBack()
  }

  validateNext = (values) => {
    const {currentStep, isFinished} = this.state
    if (currentStep.type === 'media' && isFinished) return false

    const stepValues = values.steps.find((s) => s._id === currentStep._id)
    if (
      stepValues?.questions?.length &&
      stepValues?.questions[0]?.options
    ) {
      return false
    }

    return true
  }

  renderFooterFeedback = (values) => {
    const {currentStep} = this.state
    const {activeLanguage} = this.props
    if (
      !values?.steps[currentStep.order - 1]?.questions[0]?.options ||
      !values?.steps[currentStep.order - 1]?.questions[0]?.options.length
    ) {
      return null
    }

    const question = currentStep.questions[0]
    const option = question.options.find(
      (q) =>
        q._id === values.steps[currentStep.order - 1].questions[0].options
    )
    if (!question.correct || !question.wrong) return null

    if (option.isCorrect) {
      return (
        <FooterFeedbackComponent isCorrect>
          <OptionText style={{display: 'flex', alignItems: 'center'}}>
            {GetValueLanguage(question.correct, activeLanguage?.code)}
          </OptionText>
        </FooterFeedbackComponent>
      )
    }
    return (
      <FooterFeedbackComponent>
        <OptionText>
          {GetValueLanguage(question.wrong, activeLanguage?.code)}
        </OptionText>
      </FooterFeedbackComponent>
    )
  }

  renderContent = () => {
    const {currentStep} = this.state
    const {activeLanguage, chapter, session} = this.props
    switch (currentStep.type) {
      case 'question':
        return (
          <FormSpy subscription={{values: true}}>
            {() => (
              <MainContainerFlex>
                <Margin size={64} tablet={24} mobile={24} />
                {currentStep.title && (
                  <>
                    <QuestionTitle weight='700'>
                      {GetValueLanguage(
                        currentStep.title,
                        activeLanguage?.code
                      )}
                    </QuestionTitle>
                    <Margin size={48} />
                  </>
                )}
                <FieldArray
                  name={`steps[${currentStep.order - 1}].questions`}
                >
                  {({fields}) =>
                    currentStep.questions.map((question, i) => (
                      <div key={i}>
                        {currentStep.order === 2 ? (
                          <OptionsContainer gutter={[16, 16]}>
                            <QuestionLine
                              fieldName={`${fields.name}[${i}]`}
                              question={question}
                              onChangeValue={(value) => {
                                // setAnswered(true)
                              }}
                            />
                          </OptionsContainer>
                        ) : (
                          <DichotomousQuestion
                            fieldName={`${fields.name}[${i}]`}
                            question={question}
                            onChangeValue={(value) => {
                              // setAnswered(true)
                            }}
                            isBig
                            isFour
                          />
                        )}
                      </div>
                    ))
                  }
                </FieldArray>
              </MainContainerFlex>
            )}
          </FormSpy>
        )

      default:
        return (
          <MainContainerBD>
            <BDComponent
              step={currentStep}
              chapter={chapter}
              session={session}
              onFinish={() => this.setState({isFinished: true})}
            />
          </MainContainerBD>
        )
    }
  }

  render() {
    const {initialValues, currentStep, isLoading} = this.state
    const {history, chapter} = this.props
    return (
      <Form
        onSubmit={this.onNextStep}
        validate={formRules}
        mutators={{
          ...arrayMutators
        }}
        initialValues={initialValues}
      >
        {({handleSubmit, values}) => (
          <PageForm onSubmit={handleSubmit}>
            <BasicHeaderComponent
              onClose={() => history.push('/program')}
              title={chapter?.title}
              progress={(currentStep.order / chapter.steps.length) * 100}
              progressColor={ThirdColour}
            />
            <MainContainerBackground bgColor={SecondaryColour}>
              <MainContainerScroll3>
                <FieldArray name='steps' component={this.renderContent} />
              </MainContainerScroll3>
            </MainContainerBackground>
            <BasicFooterComponent
              leftContent={this.renderFooterFeedback(values)}
              onBack={this.onBackStep}
              nextHtmlType='submit'
              nextDisabled={this.validateNext(values)}
              isLoading={isLoading}
            />
          </PageForm>
        )}
      </Form>
    )
  }
}

BDPage.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  updateChapter: PropTypes.bool,
  session: PropTypes.object,
  user: PropTypes.object.isRequired
}

BDPage.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  updateChapter: false,
  session: undefined
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})
export default withLocalize(
  withRouter(connect(mapStateToProps, mapActionToProps)(BDPage))
)

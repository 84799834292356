import React, {useEffect} from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {BaseButton} from '../../components/buttons/BaseButton'
import {MaterialLabel} from '../../components/inputs/MaterialStyles'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import TextInput from '../../components/inputs/TextInput'
import {CreateThread} from '../../infra/requests/ForumRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {Margin, PageForm} from '../../styles/BasicStyles'
import {SecondaryColour, White} from '../../styles/Colours'
import {Title25} from '../../styles/TextSizes'
import {DashboardContainer} from '../dashboard/DashboardStyles'
import {ForumContainer} from './ForumStyles'

const formRules = FormValidator.make({
  title: 'required',
  description: 'required',
  forum: 'required'
})

const CreateThreadPage = ({translate, history}) => {
  const dispatch = useDispatch()
  const {user, info} = useSelector((state) => state)

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  const onSubmit = async (values) => {
    try {
      const result = await CreateThread({...values, author: user})

      if (result.success) {
        dispatch(UpdateUserScore(result.data.points))
        history.push('/forum')
      }
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={formRules}
      initialValues={{
        forum: info.forum._id,
        pending: false,
        pendingUser: false
      }}
    >
      {({handleSubmit, valid, submitting}) => (
        <PageForm onSubmit={handleSubmit}>
          <ForumContainer>
            <DashboardContainer>
              <Row>
                <Col xs={24} md={20} lg={16}>
                  <Title25
                    style={{textAlign: 'left'}}
                    color={SecondaryColour}
                  >
                    {translate('NOVA_DISCUSSAO')}
                  </Title25>
                  <Field
                    component={TextInput}
                    name='title'
                    type='text'
                    label={translate('TITULO')}
                    placeholder={translate(
                      'TITULO_NOVA_DISCUSSAO_PLACEHOLDER'
                    )}
                  />
                  <Margin size={24} tablet={16} />
                  <MaterialLabel>{translate('DESCRIÇAO')}</MaterialLabel>
                  <Margin size={8} />
                  <Field
                    noMargin
                    isSmall
                    component={TextAreaInput}
                    name='description'
                    type='text'
                    bgColor={White}
                    placeholder={translate(
                      'DESCRIÇAO_NOVA_DISCUSSAO_PLACEHOLDER'
                    )}
                  />
                  <Margin size={24} tablet={16} />
                  <BaseButton
                    disabled={!valid || submitting}
                    style={{width: 'fit-content'}}
                    htmlType='submit'
                    type='primaryMedium'
                    text={translate('PUBLICAR_ASSUNTO')}
                  />
                </Col>
              </Row>
            </DashboardContainer>
          </ForumContainer>
        </PageForm>
      )}
    </Form>
  )
}

CreateThreadPage.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withLocalize(withRouter(CreateThreadPage))

import React from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {DashboardBox} from '../../pages/dashboard/DashboardStyles'
import {Margin} from '../../styles/BasicStyles'
import {PrimaryColour} from '../../styles/Colours'
import {TitleH3, QuestionText, Title22} from '../../styles/TextSizes'

const NotificationsComponent = ({
  translate,
  Answer,
  activeLanguage,
  isInfo
}) => {
  return (
    <DashboardBox
      column
      bgColor='#FFF6DC'
      image={`url(${require('../../assets/backgrounds/background_notifications.svg')})`}
    >
      <Row>
        <Col xs={24} style={{textAlign: 'left'}}>
          <TitleH3 weight={700} color={PrimaryColour}>
            {translate('ALERTAS')}
          </TitleH3>
        </Col>
      </Row>
      <Margin size={21} />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          {isInfo && <Title22 weight={400}>{isInfo}</Title22>}
          {Answer && (
            <>
              <QuestionText isBold>
                {GetValueLanguage(
                  Answer.form.title,
                  activeLanguage && activeLanguage.code
                )}
              </QuestionText>
              <Title22
                weight={400}
                dangerouslySetInnerHTML={{
                  __html: GetValueLanguage(
                    Answer.form.description,
                    activeLanguage && activeLanguage.code
                  )
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </DashboardBox>
  )
}

NotificationsComponent.propTypes = {
  Answer: PropTypes.object,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  isInfo: PropTypes.bool
}

NotificationsComponent.defaultProps = {
  Answer: undefined,
  isInfo: false
}

export default withLocalize(withRouter(NotificationsComponent))

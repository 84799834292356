import React from 'react'

import {PropTypes} from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {
  MainContainerFlex,
  MainContainerEducator
} from '../../pages/step/StepStyles'
import {Margin} from '../../styles/BasicStyles'
import {SecondaryCTA, White} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import TextAreaInput from '../inputs/TextAreaInput'
import {
  QuestionTitle,
  IntroductionBlock
} from '../jogo_espirais/JogoEspiraisStyles'
import DichotomousQuestion from '../question/DichotomousQuestion'
import Question from './Question'
import Question2 from './Question2'

const SessionEvaluationEducator = ({currentStep, activeLanguage, translate, isLast}) => {
  let {order} = currentStep

  if (isLast && (order === 11 || order === 12)) { order = 13 }

  switch (order) {
    case 1:
    case 2:
    case 4:
    case 13:
    case 15:
      return (
        <FormSpy subscription={{values: true}}>
        {() => (
          <MainContainerEducator>
            <Margin size={32} tablet={24} mobile={24} />
            {currentStep.descriptions?.length > 0 && (
              <IntroductionBlock bgColor={SecondaryCTA}>
                {currentStep.descriptions.map((description) => (
                  <QuestionText
                    dangerouslySetInnerHTML={{
                      __html: GetValueLanguage(
                        description.title,
                        activeLanguage?.code
                      )
                    }}
                  />
                ))}
              </IntroductionBlock>
            )}
            {/* {currentStep.title && (
              <>
                <QuestionTitle weight='700'>
                  {GetValueLanguage(
                    currentStep.title,
                    activeLanguage?.code
                  )}
                </QuestionTitle>
              </>
            )} */}
            <FieldArray name={`steps[${currentStep.order - 1}].questions`}>
              {({fields}) =>
                currentStep.questions.map((question, i) => (
                  <div key={i}>
                    <Question
                      fieldName={`${fields.name}[${i}]`}
                      question={question}
                      onChangeValue={() => {}}
                      isFirst={i === 0}
                      title={GetValueLanguage(
                        currentStep.title,
                        activeLanguage?.code
                      )}
                    />
                  </div>
                ))
              }
            </FieldArray>
            <Margin size={24} />
          </MainContainerEducator>
          )}
        </FormSpy>
      )

    case 5:
    case 6:
    case 10:
      return (
        <FormSpy subscription={{values: true}}>
          {() => (
            <MainContainerEducator>
              <Margin size={64} tablet={24} mobile={24} />
              <FieldArray
                name={`steps[${currentStep.order - 1}].questions`}
              >
                {({fields}) =>
                  currentStep.questions.map((question, i) => (
                    <div key={i}>
                      <QuestionTitle weight='700'>
                        {GetValueLanguage(
                          question.title,
                          activeLanguage?.code
                        )}
                      </QuestionTitle>
                      <Field
                        component={TextAreaInput}
                        name={`${fields.name}[${i}].answer`}
                        typeText='text'
                        maxRows={6}
                        minRows={6}
                        bgColor={White}
                      />
                    </div>
                  ))
                }
              </FieldArray>
            </MainContainerEducator>
          )}
        </FormSpy>
      )

    case 7:
    case 8:
    case 9:
      return (
        <FormSpy subscription={{values: true}}>
          {() => (
            <MainContainerEducator>
              <Margin size={64} tablet={24} mobile={24} />
              <FieldArray
                name={`steps[${currentStep.order - 1}].questions`}
              >
                {({fields}) =>
                  currentStep.questions.map((question, i) => (
                    <div key={i}>
                      <DichotomousQuestion
                        fieldName={`${fields.name}[${i}]`}
                        question={question}
                        onChangeValue={() => {}}
                        isBig
                        center
                      />
                      <Field
                        component={TextAreaInput}
                        name={`${fields.name}[${i}].answer`}
                        typeText='text'
                        maxRows={6}
                        minRows={6}
                        bgColor={White}
                        placeholder={translate('PLACEHOLDER_TEXT_AREA_EXTRA')}
                      />
                    </div>
                  ))
                }
              </FieldArray>
            </MainContainerEducator>
          )}
        </FormSpy>
      )

    case 11:
    case 12: {
      return (
        <FormSpy subscription={{values: true}}>
          {() => (
            <MainContainerFlex>
              <FieldArray name={`steps[${currentStep.order - 1}].questions`}>
              {({fields}) =>
                currentStep.questions.map((question, i) => (
                  <div key={i}>
                    <Margin size={64} tablet={24} mobile={24} />
                    <Question2
                      fieldName={`${fields.name}[${i}]`}
                      question={question}
                      onChangeValue={() => {}}
                    />
                    <Field
                      component={TextAreaInput}
                      name={`${fields.name}[${i}].answer`}
                      typeText='text'
                      maxRows={6}
                      minRows={6}
                      bgColor={White}
                      placeholder={translate('PLACEHOLDER_TEXT_AREA_EXTRA')}
                    />
                  </div>
                ))
              }
              </FieldArray>
              <Margin size={24} />
            </MainContainerFlex>
          )}
        </FormSpy>
      )
    }

    default: {
      return (
        <FormSpy subscription={{values: true}}>
          {() => (
          <MainContainerFlex>
            <Margin size={64} tablet={24} mobile={24} />
            {currentStep.title && (
              <>
                <QuestionTitle weight='700'>
                  {GetValueLanguage(
                    currentStep.title,
                    activeLanguage?.code
                  )}
                </QuestionTitle>
                <Margin size={48} />
              </>
            )}
            <FieldArray name={`steps[${currentStep.order - 1}].questions`}>
              {({fields}) =>
                currentStep.questions.map((question, i) => (
                  <div key={i}>
                    <DichotomousQuestion
                      fieldName={`${fields.name}[${i}]`}
                      question={question}
                      onChangeValue={() => {}}
                      isBig
                      isFour
                    />
                    <Field
                      component={TextAreaInput}
                      name={`${fields.name}[${i}].answer`}
                      typeText='text'
                      maxRows={6}
                      minRows={6}
                      bgColor={White}
                      placeholder={translate('PLACEHOLDER_TEXT_AREA_EXTRA')}
                    />
                  </div>
                ))
              }
            </FieldArray>
          </MainContainerFlex>
        )}
        </FormSpy>
      )
    }
  }
}

SessionEvaluationEducator.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  currentStep: PropTypes.object.isRequired
}

export default withLocalize(SessionEvaluationEducator)

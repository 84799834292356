import {Row} from 'antd'
import styled from 'styled-components'

import {
  TextColor,
  White,
  MenuBorderColor,
  HoverBorderColor,
  ThirdColour,
  SecondaryColour,
  InputBorderColour,
  ActiveBorderColor,
  ErrorColor,
  Beige2
} from '../../styles/Colours'
import {device, vertical} from '../../styles/Responsive'
import {Body} from '../../styles/TextSizes'

export const TitleQuestion = styled.div`
  font-family: 'Poppins', sans-serif;
  color: ${({color}) => color || TextColor};
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 48px;

  @media ${vertical.medium} {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const QuestionImg = styled.img`
  width: 100%;
`

export const QuestionImg2 = styled.img`
  max-width: 50%;
  height: fit-content;
  align-self: flex-end;
  margin-right: auto;
  margin-top: 30px;
  max-height: -webkit-fill-available;

  @media ${device.laptopL} {
    margin-top: 0px;
  }

  @media ${device.mobileL} {
    margin-top: 30px;
  }
`

export const QuestionImg3 = styled.img`
  width: auto;
  height: 100%;
`

export const QuestionOption = styled.div`
  height: ${({isAuto}) => (isAuto ? 'auto' : '94px')};
  padding: ${({isAuto}) => (isAuto ? '32px' : '0px 32px')};
  background: ${White};
  border: 2px solid;
  border-color: ${({isActive, isWrong}) =>
    isActive ? ActiveBorderColor : isWrong ? ErrorColor : MenuBorderColor};
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: center;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
  box-shadow: 2px 2px 7px 3px rgb(0 0 0 / 3%);

  &:hover,
  &:focus,
  &:active {
    border-color: ${({isActive, isWrong}) =>
      isActive
        ? ActiveBorderColor
        : isWrong
        ? ErrorColor
        : HoverBorderColor};
  }

  @media ${vertical.medium} {
    padding: ${({isAuto}) => (isAuto ? '24px' : '0px 32px')};
  }

  @media ${device.tabletMin} {
    padding: ${({isAuto}) => (isAuto ? '16px' : '0px 16px')};
  }

  &.animated {
    animation: wobble 0.3s 1;
  }
  &.animatedWrong {
    animation: wrong 0.3s 1;
  }

  @keyframes wobble {
    25% {
      transform: scale(1.05);
    }
    50% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes wrong {
    25% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(10px);
    }
    75% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(10px);
    }
  }
`

export const QuestionOptionSmall = styled.div`
  height: auto;
  padding: 16px;
  background: ${White};
  border: 2px solid;
  border-color: ${({isActive, isWrong}) =>
    isActive ? ActiveBorderColor : isWrong ? ErrorColor : MenuBorderColor};
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 8px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
  box-shadow: 2px 2px 7px 3px rgb(0 0 0 / 3%);

  &:hover,
  &:focus,
  &:active {
    border-color: ${({isActive, isWrong}) =>
      isActive
        ? ActiveBorderColor
        : isWrong
        ? ErrorColor
        : HoverBorderColor};
  }

  @media ${device.tabletMin} {
    padding: 8px;
  }

  &.animated {
    animation: wobble 0.3s 1;
  }
  &.animatedWrong {
    animation: wrong 0.3s 1;
  }

  @keyframes wobble {
    25% {
      transform: scale(1.05);
    }
    50% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes wrong {
    25% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(10px);
    }
    75% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(10px);
    }
  }
`

export const OptionText = styled.div`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({isAuto}) => (isAuto ? 'inherit' : '3')};
  -webkit-box-orient: vertical;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: ${({color}) => color || TextColor};
  overflow: hidden;

  @media ${device.tabletMin} {
    font-size: 16px;
    line-height: 20px;
  }
  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 16px;
  }
`

export const OptionTextSmall = styled.div`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({isAuto}) => (isAuto ? 'inherit' : '3')};
  -webkit-box-orient: vertical;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({color}) => color || TextColor};
  overflow: hidden;

  @media ${device.tabletMin} {
    font-size: 16px;
    line-height: 20px;
  }
  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 16px;
  }
`

export const BlockRight = styled.div`
  margin-left: 8px;

  @media ${device.tabletMin} {
    margin-left: 0px;
  }
`

export const BlockLeft = styled.div`
  padding: 32px;
  height: 424px;
  background: ${White};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  margin-right: 8px;
  display: flex;
  justify-content: center;

  @media ${device.tabletMin} {
    margin-right: 0px;
    margin-bottom: 16px;
    height: auto;
    min-height: 200px;
    padding: 16px;
  }
`

export const SlideContainer = styled.div`
  z-index: 1;
  width: 100%;
  margin-top: 24px;
  padding: 66px 48px;
  background: ${({bgColor}) => bgColor || White};
  border: 2px solid ${({borderColor}) => borderColor || MenuBorderColor};
  border-radius: 8px;

  @media ${device.tablet} {
    padding: 66px 34px;
  }

  .ant-slider {
    .ant-slider-rail {
      height: 13px;
      background: ${MenuBorderColor};
    }
    .ant-slider-handle {
      width: 27px;
      height: 27px;
      background: ${ThirdColour};
      border-color: ${ThirdColour};
      margin-top: -8px;
    }
    .ant-slider-dot {
      display: none;
    }
    .ant-slider-track {
      height: 13px;
      background: ${ThirdColour} !important;
    }
    .ant-slider-step {
      height: 13px;
    }
    .ant-slider-mark {
      top: 40px;
      font-weight: 600;
      .ant-slider-mark-text{
        max-width: 100px;
        min-width: 100px;
      }
    }
`

export const OptionsContainer = styled.div`
  display: inline-block;
  margin: -8px;
`

export const OptionsSelect = styled(Body)`
  background: ${({isSelected, color}) =>
    isSelected ? color || SecondaryColour : White};
  color: ${({isSelected, color}) =>
    isSelected ? White : color || SecondaryColour};
  border: 2px solid ${InputBorderColour};
  box-sizing: border-box;
  border-radius: 30px;
  padding: 8px 24px;
  margin: 8px;
  cursor: pointer;
  float: left;

  &:hover {
    background: ${({color}) => color || SecondaryColour};
    color: ${White};
  }
`

export const ResultText = styled.div`
  width: 100%;
  background-color: ${White};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  padding: 8px;
  height: 244px;
  overflow: auto;

  @media ${device.laptopL} {
    height: 244px;
    overflow: auto;
  }
`

export const ResultRow = styled(Row)`
  display flex !important;

  @media ${device.laptopL} {
    height: auto;
  }
`

export const FooterImg = styled.img`
  margin-right: 24px;

  @media ${device.tabletMin} {
    margin-right: 8px;
    width: 32px;
  }
`
export const ThoughtBox = styled.div`
  z-index: 1;
`

export const Thought = styled.div`
  display: flex;
  background-color: ${Beige2};
  padding: 16px 12px;
  border-radius: 30px;
  min-width: 40px;
  max-width: 220px;
  min-height: 40px;
  margin: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 95px;

  &:before,
  &:after {
    content: '';
    background-color: ${Beige2};
    border-radius: 50%;
    display: block;
    position: absolute;
    z-index: -1;
  }
  &:before {
    width: 44px;
    height: 44px;
    top: -14px;
    left: -5px;
    box-shadow: -16px 12px 0 -12px ${Beige2}, -55px 16px 0 -17px ${Beige2},
      -40px 8px 0 -14px ${Beige2}, -31px -10px 0 -12px ${Beige2};
  }
  &:after {
    bottom: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    box-shadow: 8px -8px 0 -2px ${Beige2}, 7px -25px 0 -4px ${Beige2},
      -13px 14px 0 -7px ${Beige2};
  }
`

export const QuestionOptionChallenge = styled.div`
  height: calc(100% - 8px);
  padding: 16px 32px;
  background: ${White};
  border: 2px solid;
  border-color: ${MenuBorderColor};
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  @media ${device.tabletMin} {
    padding: 16px;
  }
`

export const QuestionImgLine = styled.img`
  width: 80%;
  float: right;

  @media ${vertical.medium} {
    width: 50%;
  }

  @media ${device.tabletMin} {
    float: inherit;
  }
`

import {Row, Col} from 'antd'
import styled from 'styled-components'

import {White} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const BDContainer = styled(Row)`
  height: 100%;
  width: 100%;
  padding: 32px 0px;
  flex: 1;
`

export const MainContainerBD = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  padding: 24px 112px;
  margin: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  media ${device.desktopL} {
    padding: 24px 112px;
  }

  @media ${device.laptop} {
    padding: 16px 20px;
  }

  @media ${device.tablet} {
    padding: 16px 10px;
  }

  @media ${device.mobileL} {
    padding: 16px 10px;
  }
`

export const ArrowsContainer = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BDCol = styled(Col)`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
`

export const BDCol2 = styled(Col)`
  overflow: hidden;
`

export const ArrowImg = styled.img`
  cursor: pointer;
  @media ${device.tabletMin} {
    width: 60px;
  }
`

export const BDImg = styled.img`
  text-align: center;
  background: ${White};
  width: auto;
  max-width: 100%',
  max-height: calc(100vh - 105px - 70px - 30px);
`

import {Collapse, Dropdown, Menu} from 'antd'
import styled from 'styled-components'

import {transitionsDelay} from '../../styles/BasicStyles'
import {
  TextColor,
  MenuBorderColor,
  SecondaryColour,
  PrimaryColour
} from '../../styles/Colours'
import {device, vertical} from '../../styles/Responsive'
import {titleH1, body2} from '../../styles/TextSizes'

const {Panel} = Collapse

const mobileMenuTransition = '0.5s'
const settingsMenuTransition = '0.5s'

/** ****************************** Styles for HEADER ******************************* */

export const Header = styled.div`
  height: 64px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  border-bottom: 2px solid ${(p) => p.theme.menuBorderColor};

  @media ${device.laptop} {
    border-bottom: 2px solid ${(p) => p.theme.menuBorderColor};
  }

  @media ${device.desktopL} {
    height: 64px;
  }

  @media ${device.desktop} {
    height: 64px;
  }

  @media ${device.laptopL} {
    height: 64px;
  }

  @media ${device.mobileL} {
    height: 64px;
  }
`

export const BasicHeader = styled.div`
  height: 62px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  @media ${device.desktopL} {
    height: 62px;
  }

  @media ${device.desktop} {
    height: 62px;
  }

  @media ${device.tablet} {
    height: 56px;
  }
`

export const HeaderContent = styled.div`
  max-width: 1440px;
  height: 100%;
  padding: 0px 112px;
  margin: auto;
  display: flex;
  justify-content: space-between;

  @media ${device.laptop} {
    padding: 0px 20px;
  }
  @media ${device.tablet} {
    padding: 0px 16px;
  }
`

export const LogoContainer = styled.div`
  flex: 1;
  height: 100%;
  background-color: white;
  padding: 8px 0px;

  @media ${device.laptop} {
    border-bottom: none;
  }

  @media ${device.tablet} {
    max-width: 50px;
    min-width: 50px;
  }
`

export const LogoImg = styled.img`
  height: 100%;
  cursor: pointer;
`

export const HeaderLinks = styled.div`
  flex: ${({isApplicator}) => (isApplicator ? 'auto' : 7)};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;

  @media ${device.laptop} {
    display: none;
  }
`

export const HeaderLinksAuth = styled.div`
  flex: 7;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: flex-end;

  @media ${device.laptop} {
    display: none;
  }
`

export const HeaderLink = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${TextColor};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  padding: 0px 20px;
  background-color: #ffffff;
  text-align: center;

  &:hover,
  &.active {
    font-weight: 700;
    color: ${PrimaryColour};
  }

  @media ${device.desktop} {
    padding: 0px 10px;
    font-size: 15px;
  }

  @media ${device.laptopL} {
    padding: 0px 5px;
    font-size: 14px;
  }
`

export const HeaderTitle = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${TextColor};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 21px;
  }
`

export const HeaderIcons = styled.div`
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;

  @media ${device.laptop} {
    display: ${({show}) => (show ? 'flex' : 'none')};
  }
`

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-right: ${(p) => (p.last ? 0 : 37)}px;

  @media ${device.desktop} {
    margin-right: ${(p) => (p.last ? 0 : 15)}px;
  }

  @media ${device.laptop} {
    margin-right: ${({show, last}) => (show && !last ? '5px' : '0px')};
    margin-bottom: ${({show}) => (show ? '0px' : '20px')};
  }
  @media ${device.mobileL} {
    display: ${({show, last}) => (show && last ? 'none' : 'flex')};
  }

  &.animated {
    animation: wobble 0.3s 1;
  }

  @keyframes wobble {
    25% {
      transform: scale(1.05);
    }
    50% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`

export const Icon = styled.img`
  height: 100%;
  max-width: ${(p) => (p.userIcon ? p.userIcon : 20)}px;
  min-width: ${(p) => (p.userIcon ? p.userIcon : 20)}px;
  margin-right: 10px;

  @media ${device.laptopL} {
    margin-right: 5px;
  }
`

export const UserImg = styled.img`
  height: auto;
  max-width: 38px;
  min-width: 38px;
  margin-right: 0px;
  border-radius: 50%;
`

export const IconClose = styled.img`
  padding: 6px;
  width: 24px;
  cursor: pointer;
`

export const IconText = styled.div`
  color: ${(p) => (p.color ? p.color : '#333333')};
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;

  @media ${device.laptopL} {
    font-size: 16px;
  }

  @media ${device.laptop} {
    font-size: 14px;
  }
`

export const ProgressBar = styled.div`
  background-color: ${MenuBorderColor};
  height: 8px;
`

export const ProgressBarFill = styled.div`
  background-color: ${({progressColor}) =>
    progressColor || SecondaryColour};
  height: 8px;
  width: ${({progress}) => (progress ? `${progress}%` : '0%')};
`

export const ClientName = styled(titleH1)``

export const WeddingInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  & span:first-child {
    padding-left: 0px;
    border-left: 0;
  }

  & span:last-child {
    padding-right: 0px;
  }

  @media ${device.tablet} {
    display: none;
  }
`

export const TextInfo = styled(body2)`
  padding-left: 25px;
  padding-right: 25px;
  border-left: 1px solid #979797;

  @media ${device.desktop} {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media ${device.laptopL} {
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const IconContainer = styled.div`
  width: 100%;
  max-width: 163px;
  height: 100%;
  border: 0;
  border-left-width: 1px;
  border-color: ${(p) => p.theme.menuBorderColor};
  border-style: solid;
  padding: 5px;
  cursor: pointer;
  transition: background-color 1s linear;

  background-color: ${(p) =>
    p.settingsOpen || p.selected ? p.theme.primaryCTA : 'transparent'};

  &:hover {
    background-color: ${(p) => p.theme.primaryCTA};
  }
`

export const IconGroup = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const IconImg = styled.img`
  width: 100%;
  height: 35px;

  @media ${device.desktop} {
    height: 25px;
  }

  @media ${device.laptopL} {
    height: 22px;
  }
`

/** ****************************** Styles for MENU MOBILE ******************************* */

export const MobileMenuIcon = styled.div`
  display: none;

  @media ${device.laptop} {
    display: block;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`

export const MobileMenuImg = styled.div`
  cursor: pointer;

  & #SVG_MENU,
  & #SVG_CLOSE {
    transition: fill ${transitionsDelay} linear;
  }

  &:hover {
    & #SVG_MENU,
    & #SVG_CLOSE {
      fill: ${(p) => p.theme.thirdColor};
    }
  }

  @media ${device.laptop} {
    & svg {
      width: 25px;
      height: 17px;
    }
  }
`

export const MobileCloseContainer = styled.div`
  padding: 30px 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media ${vertical.mediumS} {
    padding: 10px 20px;
  }

  @media ${vertical.small} {
    padding: 5px 20px;
  }
`

export const MobileMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(p) => (p.open ? '100vh' : '0')};
  transition: height 0.2s linear;
  overflow: hidden;
  background-color: #ffffff;
  z-index: 99999;
`

export const MobileMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px 60px 20px;

  & button {
    margin-top: 45px;
    margin-bottom: 50px;
    padding: 12px 100px !important;
  }

  @media ${device.tablet} {
    & button {
      padding: 12px 60px !important;
    }
  }

  @media ${device.mobileL} {
    & button {
      padding: 10px 30px !important;
    }
  }

  @media ${vertical.mediumS} {
    & button {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }

  @media ${vertical.small} {
    & button {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  @media ${vertical.smallS} {
    & button {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`

export const MobileMenuText = styled.div`
  text-transform: uppercase;
  padding: 15px 0px;
  transition: color ${transitionsDelay} linear;
  cursor: pointer;

  &:hover,
  &.active {
    color: ${SecondaryColour};
  }

  @media ${device.tablet} {
    font-size: 15px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${vertical.mediumS} {
    padding: 10px 0px;
  }

  @media ${vertical.small} {
    padding: 7px 0px;
  }

  @media ${vertical.smallS} {
    padding: 5px 0px;
    font-size: 13px;
  }
`

export const MobileMenuGroup = styled.div`
  border: 0;
  border-top-width: ${({border}) => (border ? '1px' : '0px')};
  border-color: ${(p) => p.theme.primaryCTA};
  border-style: ${({border}) => (border ? 'solid' : 'none')};
`

export const MobileMenuRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;

  @media ${device.tablet} {
    padding: 15px 10px;
  }
`

export const MobileImg = styled.img`
  height: 24px;
  width: 20%;
  cursor: pointer;

  @media ${device.mobileL} {
    height: 18px;
  }
`

export const MobileText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  padding-left: 40px;
  cursor: pointer;
  transition: color 0.5s linear;

  &:hover {
    color: ${(p) => p.theme.secondaryCTA};
  }

  @media ${device.mobileL} {
    padding-left: 20px;
    font-size: 14px;
  }
`

export const MobileArrow = styled.div`
  cursor: pointer;
  padding-left: 40px;

  & svg {
    width: 12px;
    height: 12px;
    transition: transform ${mobileMenuTransition} linear;
    transform: ${(p) => (p.open ? 'rotateZ(90deg)' : 'rotateZ(-90deg)')};

    & #right,
    & #left {
      fill: ${(p) => p.theme.primaryCTA};
    }

    &:hover #right,
    &:hover #left {
      fill: ${(p) => p.theme.secondaryCTA};
    }
  }
`

export const MobileMenuCollapse = styled(Collapse)`
  border: 0 !important;
  border-radius: 0 !important;
  border-top-width: ${({border}) => (border ? '1px' : '0px')} !important;
  border-color: ${(p) => p.theme.primaryCTA} !important;
  border-style: ${({border}) => (border ? 'solid' : 'none')} !important;
  background-color: transparent !important;
  font-variant: none !important;
  color: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  font-feature-settings: inherit !important;

  & .ant-collapse-header {
    padding: 0 !important;
  }
`

export const MobileMenuPanel = styled(Panel)`
  border-radius: 0 !important;
  border-bottom: 0 !important;

  & .ant-collapse-content {
    color: inherit;
    background-color: transparent;
    border-top: 0 !important;

    & .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
`

export const MobileSettingsMenu = styled.div`
  overflow: hidden;
  border: 0;
  border-top-width: ${({open}) => (open ? '1px' : '0px')};
  border-color: ${(p) => p.theme.primaryCTA};
  border-style: ${({open}) => (open ? 'solid' : 'none')};
  background-color: ${(p) => p.theme.secondaryColor};
`

export const MobileSettingsMenuGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border: 0;
  border-top-width: ${({border}) => (border ? '1px' : '0px')};
  border-color: ${(p) => p.theme.primaryCTA};
  border-style: ${({border}) => (border ? 'solid' : 'none')};

  @media ${device.tablet} {
    padding: 15px 10px;
  }
`

export const MobileLanguageImg = styled.img`
  height: 14px;
  width: 20%;
`

export const MobileLanguageSpace = styled.div`
  width: 20%;
`

export const MobileSettingsText = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  padding-left: 40px;
  cursor: pointer;
  transition: color 0.5s linear;

  &:hover {
    color: ${(p) => p.theme.secondaryCTA};
  }

  @media ${device.mobileL} {
    padding-left: 20px;
    font-size: 13px;
  }
`

/** ****************************** Styles for SETTINGS MENU ******************************* */

export const SettingsMenu = styled.div`
  background-color: ${(p) => p.theme.secondaryColor};
  overflow-y: auto;
  height: auto;
  max-height: calc(100% - 108px);
  position: fixed;
  right: ${({open}) => (open ? '0px' : '-300px')};
  top: 108px;
  z-index: 1000;
  width: 300px;
  transition: right ${settingsMenuTransition} linear;
  padding: 20px 40px;

  @media ${device.desktopL} {
    max-height: calc(100% - 89px);
    top: 89px;
    padding: 10px 30px;
  }

  @media ${device.desktop} {
    max-height: calc(100% - 80px);
    top: 80px;
    padding: 10px 20px;
  }

  @media ${device.laptopL} {
    max-height: calc(100% - 66px);
    top: 66px;
  }

  @media ${device.laptop} {
    right: -300px;
  }

  @media ${device.mobileL} {
    max-height: calc(100% - 60px);
    top: 60px;
  }
`

export const SettingsMenuGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border: 0;
  border-top-width: ${({border}) => (border ? '1px' : '0px')};
  border-color: ${(p) => p.theme.primaryBorderColor};
  border-style: ${({border}) => (border ? 'solid' : 'none')};
`

export const SettingsImg = styled.img`
  height: 24px;
  width: 20%;
  margin-right: 25px;
`

export const LanguageImg = styled.img`
  height: 14px;
  width: 20%;
  margin-right: 25px;
`

export const LanguageSpace = styled.div`
  width: 20%;
  margin-right: 25px;
`

export const SettingsText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  padding-left: 15px;
  cursor: pointer;
  transition: color 0.5s linear;

  &:hover {
    color: ${(p) => p.theme.secondaryCTA};
  }
`

/** ****************************** Styles for LANGUAGE SELECTOR ******************************* */

export const DropdownWrapper = styled(Dropdown)`
  margin-right: 15px;

  @media ${device.tablet} {
    margin-right: 10px;
  }

  @media ${device.tabletS} {
    margin-right: 5px;
  }
`

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & .flag {
    width: 25px;
    height: auto;
  }

  & .arrow {
    width: 12px;
    height: auto;
    margin-left: 5px;
  }
`

export const DropdownItem = styled(Menu.Item)`
  display: flex;
  flex-direction: row;
  align-items: center;

  & img {
    width: 20px;
    height: auto;
  }
  
  & span {
    margin-left: 5px;
  }
`

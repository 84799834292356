import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'

import Logo from '../../assets/icons/logo_header.svg'
import {
  GetValueLanguage,
  GetUserPhoto
} from '../../infra/utils/CommonFunctions'
import {
  MessageContainer,
  MessageText,
  IconSms,
  MessageLine,
  ImgUser,
  ImgSMS
} from './MessageStyles'

const MessageComponent = ({
  text,
  active,
  children,
  isUser,
  activeLanguage,
  isQuote,
  isFull,
  isBigger
}) => {
  const user = useSelector((state) => state.user)

  return (
    <MessageLine isUser={isUser}>
      {active && !isUser && (
        <IconSms>
          <ImgSMS src={Logo} />
        </IconSms>
      )}
      <MessageContainer
        active={active}
        isUser={isUser}
        isQuote={isQuote}
        isFull={isFull}
      >
        {text && (
          <MessageText
            isBigger={isBigger}
            dangerouslySetInnerHTML={{
              __html: GetValueLanguage(
                text,
                activeLanguage && activeLanguage.code
              )
            }}
          />
        )}
        {children}
      </MessageContainer>
      {active && isUser && (
        <ImgUser
          src={GetUserPhoto(user)}
          style={{
            alignSelf: 'flex-end'
          }}
        />
      )}
    </MessageLine>
  )
}

MessageComponent.propTypes = {
  text: PropTypes.object,
  activeLanguage: PropTypes.object.isRequired,
  children: PropTypes.object,
  isUser: PropTypes.bool,
  active: PropTypes.bool,
  isQuote: PropTypes.bool,
  isFull: PropTypes.bool,
  isBigger: PropTypes.bool
}

MessageComponent.defaultProps = {
  text: undefined,
  children: undefined,
  isUser: false,
  active: false,
  isQuote: false,
  isFull: false,
  isBigger: false
}

export default withLocalize(MessageComponent)

import client from '../config/AxiosConfig'

export const UpdatePhoto = async (image) => {
  const payload = new FormData()
  payload.append('photo', image.blob)
  return client.put('/auth/photo', payload, {
    headers: {
      common: {'Content-Type': 'multipart/form-data'}
    }
  })
}

export const GetUser = async () => client.get('/users/my-profile')

export const GetJewelByTag = async (tag) =>
  client.get(`/jewels/tag/JEWEL_${tag}`)

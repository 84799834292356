import React, {useEffect, useRef, useState} from 'react'

import {Menu, Dropdown} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'
import {withRouter} from 'react-router-dom'

import IcCoroa from '../../assets/icons/ic_coroa.svg'
import Logo from '../../assets/icons/logo_header.svg'
import IcMoeda from '../../assets/icons/moeda.svg'
import IcMoedaLilac from '../../assets/icons/moeda_lilac.svg'
import {
  GetUserPhoto,
  getBoosterSession,
  getExpressSession
} from '../../infra/utils/CommonFunctions'
import {
  programaJovens,
  programaEducadores,
  sessaoReforçoEducadores,
  sessaoReforçoJovens,
  sessaoExpressJovens,
  sessaoExpressEducadores
} from '../../infra/utils/PagesNames'
import {DeleteProgram, SaveProgram} from '../../redux/Info/info.actions'
import {DeleteUser} from '../../redux/User/user.actions'
import {PrimaryColour, Lilac} from '../../styles/Colours'
import {menuItems} from './HeaderOptions'
import {
  Header,
  HeaderContent,
  HeaderIcons,
  HeaderLink,
  HeaderLinks,
  IconsContainer,
  Icon,
  LogoContainer,
  LogoImg,
  IconText,
  UserImg,
  HeaderLinksAuth
} from './HeaderStyles'
import MobileMenuComponent from './MobileMenuComponent'
import LanguageSelector from './LanguageSelector'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const HeaderComponent = ({auth, history, translate}) => {
  const dispatch = useDispatch()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [animation, setAnimation] = useState(undefined)
  const {user, info} = useSelector((state) => state)
  const isApplicator = user.type === 2
  const isStudent = user.type === 4
  const prevPoints = usePrevious(user.points)
  const Logout = () => {
    localStorage.clear()
    dispatch(DeleteUser())
    dispatch(DeleteProgram())
    history.push('/login')
  }

  useEffect(() => {
    if (prevPoints && prevPoints !== user.points) {
      setAnimation(true)
    }
  }, [user])

  const showBoosterSession = !isApplicator
    ? getBoosterSession(user)
    : false

  const showExpressSession = !isApplicator
    ? getExpressSession(user)
    : false

  const toggleMobileMenu = () => {
    // If the Menu is open, means we're about to close so we restore the Body scroll
    if (showMobileMenu) {
      // Add a timeout to only show the scroll bar after the menu closes
      setTimeout(() => {
        document.body.style.overflowY = 'auto'
      }, 200)
    }
    // Opening the Menu, hide the Body scroll
    else {
      document.body.style.overflowY = 'hidden'
    }

    setShowMobileMenu(!showMobileMenu)
  }

  const setProgram = (program, name) => {
    if (!program) return
    dispatch(SaveProgram(program))
    history.push(`/${name}`)
  }

  const renderMenu = () => (
    <Menu style={{padding: 0}}>
      {!isApplicator && (
        <>
          <Menu.Item
            style={{
              padding: '8px 16px'
            }}
          >
            <div onClick={() => history.push('/profile')}>
              {translate('PERFIL')}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              padding: '8px 16px'
            }}
          >
            <div onClick={() => history.push('/help')}>
              {translate('AJUDA_TECNICA')}
            </div>
          </Menu.Item>
        </>
      )}
      <Menu.Item
        style={{
          padding: '8px 16px'
        }}
      >
        <div onClick={Logout}>{translate('LOGOUT')}</div>
      </Menu.Item>
    </Menu>
  )

  const renderMenuApplicator = () => {
    const program = user.applicator_program
    switch (program) {
      case 2:
        return (
          <HeaderLinks>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf(
                  'educators_program'
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                setProgram(
                  info.programs.find((p) => p._id === programaEducadores),
                  'educators_program'
                )
              }
            >
              {translate('PROGRAM_EDUCATORS')}
            </HeaderLink>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf('booster_session') !==
                -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/booster_session/${sessaoReforçoEducadores}`)
              }
            >
              {translate('SESSAO_REFORÇO')}
            </HeaderLink>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf('express_session') !==
                -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/express_session/${sessaoExpressEducadores}`)
              }
            >
              {translate('SESSAO_EXPRESS')}
            </HeaderLink>
          </HeaderLinks>
        )

      case 3:
        return (
          <HeaderLinks isApplicator>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf('youth_program') !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                setProgram(
                  info.programs.find((p) => p._id === programaJovens),
                  'youth_program'
                )
              }
            >
              {translate('PROGRAM_YOUTH')}
            </HeaderLink>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf(
                  'educators_program'
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                setProgram(
                  info.programs.find((p) => p._id === programaEducadores),
                  'educators_program'
                )
              }
            >
              {translate('PROGRAM_EDUCATORS')}
            </HeaderLink>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf(
                  `/booster_session/${sessaoReforçoJovens}`
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/booster_session/${sessaoReforçoJovens}`)
              }
            >
              {translate('SESSAO_REFORÇO_JOVENS')}
            </HeaderLink>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf(
                  `/booster_session/${sessaoReforçoEducadores}`
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/booster_session/${sessaoReforçoEducadores}`)
              }
            >
              {translate('SESSAO_REFORÇO_EDUCADORES')}
            </HeaderLink>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf(
                  `/booster_session/${sessaoExpressJovens}`
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/express_session/${sessaoExpressJovens}`)
              }
            >
              {translate('SESSAO_EXPRESS_JOVENS')}
            </HeaderLink>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf(
                  `/booster_session/${sessaoExpressEducadores}`
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/express_session/${sessaoExpressEducadores}`)
              }
            >
              {translate('SESSAO_EXPRESS_EDUCADORES')}
            </HeaderLink>
          </HeaderLinks>
        )

      default:
        return (
          <HeaderLinks>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf('youth_program') !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                setProgram(
                  info.programs.find((p) => p._id === programaJovens),
                  'youth_program'
                )
              }
            >
              {translate('PROGRAM_YOUTH')}
            </HeaderLink>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf('/booster_session') !==
                -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/booster_session/${sessaoReforçoJovens}`)
              }
            >
              {translate('SESSAO_REFORÇO')}
            </HeaderLink>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf('express_session') !==
                -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/express_session/${sessaoExpressJovens}`)
              }
            >
              {translate('SESSAO_EXPRESS')}
            </HeaderLink>
          </HeaderLinks>
        )
    }
  }

  return (
    <Header>
      <HeaderContent>
        <LogoContainer>
          <LogoImg src={Logo} onClick={() => history.push('/')} />
        </LogoContainer>
        {auth ? (
          <HeaderLinksAuth>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf('login') !== -1
                  ? 'active'
                  : ''
              }
              onClick={() => history.push('/login')}
            >
              {translate('LOGIN')}
            </HeaderLink>
            <HeaderLink
              className={
                history?.location?.pathname.indexOf('register') !== -1
                  ? 'active'
                  : ''
              }
              onClick={() => history.push('/register')}
            >
              {translate('REGISTO_HEADER')}
            </HeaderLink>
            <LanguageSelector />
          </HeaderLinksAuth>
        ) : (
          <>
            {!isApplicator ? (
              <HeaderLinks>
                {menuItems.map((item, index) => (
                  <HeaderLink
                    key={index}
                    className={
                      history?.location?.pathname.indexOf(item.url) !== -1
                        ? 'active'
                        : ''
                    }
                    onClick={() => history.push(item.url)}
                  >
                    {translate(item.name)}
                  </HeaderLink>
                ))}
                {showBoosterSession && (
                  <HeaderLink
                    className={
                      history?.location?.pathname.indexOf(
                        'booster_session'
                      ) !== -1
                        ? 'active'
                        : ''
                    }
                    onClick={() =>
                      history.push(
                        isStudent
                          ? `/booster_session/${sessaoReforçoJovens}`
                          : `/booster_session/${sessaoReforçoEducadores}`
                      )
                    }
                  >
                    {translate('SESSAO_REFORÇO')}
                  </HeaderLink>
                )}
                {showExpressSession && (
                  <HeaderLink
                    className={
                      history?.location?.pathname.indexOf(
                        'express_session'
                      ) !== -1
                        ? 'active'
                        : ''
                    }
                    onClick={() =>
                      history.push(
                        isStudent
                          ? `/express_session/${sessaoExpressJovens}`
                          : `/express_session/${sessaoExpressEducadores}`
                      )
                    }
                  >
                    {translate('SESSAO_EXPRESS')}
                  </HeaderLink>
                )}
              </HeaderLinks>
            ) : (
              renderMenuApplicator()
            )}
            <HeaderIcons>
              {isStudent && (
                <>
                  <IconsContainer
                    onClick={() => history.push('/profile')}
                    className={animation ? 'animated' : ''}
                    onAnimationEnd={() => setAnimation(undefined)}
                  >
                    <Icon
                      src={animation ? IcMoedaLilac : IcMoeda}
                      onClick={() => history.push('/')}
                    />
                    <IconText color={animation ? Lilac : PrimaryColour}>
                      {user.points}
                    </IconText>
                  </IconsContainer>
                  <IconsContainer onClick={() => history.push('/profile')}>
                    <Icon
                      src={IcCoroa}
                      onClick={() => history.push('/')}
                    />
                    <IconText color='#F38231'>{user.totalCrowns}</IconText>
                  </IconsContainer>
                </>
              )}
              <LanguageSelector />
              <IconsContainer last>
                <Dropdown
                  overlay={renderMenu}
                  placement='bottomCenter'
                  trigger={['click']}
                >
                  <UserImg src={GetUserPhoto(user)} />
                </Dropdown>
              </IconsContainer>
            </HeaderIcons>
          </>
        )}
        <MobileMenuComponent
          showMobileMenu={showMobileMenu}
          toggleMobileMenu={toggleMobileMenu}
          auth={auth}
          isApplicator={isApplicator}
          applicatorProgram={user.applicator_program}
          user={user}
          showBoosterSession={showBoosterSession}
          showExpressSession={showExpressSession}
          isStudent={isStudent}
          setProgram={setProgram}
          info={info}
        />
      </HeaderContent>
    </Header>
  )
}

HeaderComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.bool
}

HeaderComponent.defaultProps = {
  auth: false
}

export default withLocalize(withRouter(HeaderComponent))

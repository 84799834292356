import React, {useRef, useState} from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {QuestionText, Title18} from '../../styles/TextSizes'
import TextInput from '../inputs/TextInput'
import {QuestionTitle} from '../jogo_espirais/JogoEspiraisStyles'
import {
  EvaluationBlock,
  EvaluationOption,
  QuestionOptionEvaluation,
  RowEvaluation,
  RowEvaluation2,
  EvaluationOptionsContainer,
  EvaluationCol
} from './EvaluationStyles'

const Question = ({
  question,
  fieldName,
  activeLanguage,
  onChangeValue,
  hasCorrect,
  disabled,
  isFirst,
  addOther,
  title
}) => {
  const refExplanation = useRef(null)
  const [animation, setAnimation] = useState(undefined)

  const goToExplanation = () => {
    setTimeout(() => {
      if (refExplanation?.current) {
        refExplanation.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }, 300)
  }

  const onSelectOption = (idOption, form, name) => {
    form.change(name, idOption)
    onChangeValue(idOption)
    goToExplanation()
  }

  const optionsArray = (opt) => {
    const {options, form, input} = opt
    const currentOption = input?.value || undefined
    const currentOptionObj =
      options.find((o) => o._id === input?.value) || undefined

    if (currentOptionObj && currentOptionObj?.explanation) {
      goToExplanation()
    }

    const isCorrect =
      hasCorrect && currentOptionObj?.isCorrect ? input?.value : false

    return (
      <>
        {options.map((option, i) => (
          <EvaluationBlock key={i} width={100 / options.length}>
            <EvaluationOption
              className={
                animation === i
                  ? hasCorrect
                    ? isCorrect
                      ? 'animated'
                      : 'animatedWrong'
                    : 'animated'
                  : ''
              }
              onClick={() => {
                if (disabled) return
                if (!(isCorrect && isCorrect !== option._id)) {
                  onSelectOption(option._id, form, input.name)
                  setAnimation(i)
                }
              }}
              onAnimationEnd={() => setAnimation(undefined)}
              isActive={
                (currentOption === option._id &&
                  hasCorrect &&
                  option.isCorrect) ||
                (!hasCorrect && currentOption === option._id)
              }
              isWrong={
                currentOption === option._id &&
                hasCorrect &&
                !option.isCorrect
              }
            />
          </EvaluationBlock>
        ))}
      </>
    )
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({form}) => (
        <Row
          gutter={[
            {xs: 8, md: 16},
            {md: 8, lg: 16}
          ]}
        >
          {title && isFirst && (
            <Col
              xs={0}
              md={8}
              style={{display: 'flex', alignItems: 'center'}}
            >
              <QuestionTitle
                weight='700'
                style={{paddingBottom: 0, textAlign: 'left'}}
              >
                {title}
              </QuestionTitle>
            </Col>
          )}
          <Col
            xs={24}
            md={16}
            offset={title && isFirst ? 0 : 8}
            style={{display: 'grid'}}
          >
            {isFirst && (
              <RowEvaluation gutter={[16, {xs: 0, md: 16}]}>
                {question.options.map((option, i) => (
                  <EvaluationBlock
                    key={i}
                    width={100 / question.options.length}
                  >
                    <Title18 style={{textAlign: 'center'}}>
                      {GetValueLanguage(
                        option.title,
                        activeLanguage?.code
                      )}
                    </Title18>
                  </EvaluationBlock>
                ))}
              </RowEvaluation>
            )}
          </Col>
          <Col xs={24} md={8}>
            <QuestionOptionEvaluation
              style={{
                flexDirection: addOther ? 'column' : 'row',
                alignItems: addOther ? 'flex-start' : 'center'
              }}
            >
              <QuestionText style={{fontWeight: 600}}>
                {GetValueLanguage(question?.title, activeLanguage?.code)}
              </QuestionText>
              {addOther && (
                <Field
                  component={TextInput}
                  name={`${fieldName}.answer`}
                  typeText='text'
                  noMargin
                  isSmall
                />
              )}
            </QuestionOptionEvaluation>
          </Col>
          <Col xs={24} md={0}>
            <RowEvaluation2 gutter={[16, {xs: 0, md: 16}]}>
              {question.options.map((option, i) => (
                <EvaluationBlock
                  key={i}
                  width={100 / question.options.length}
                >
                  <Title18 style={{textAlign: 'center'}}>
                    {GetValueLanguage(option.title, activeLanguage?.code)}
                  </Title18>
                </EvaluationBlock>
              ))}
            </RowEvaluation2>
          </Col>
          <EvaluationCol xs={24} md={16}>
            <EvaluationOptionsContainer gutter={[8, 8]}>
              <Field
                name={`${fieldName}.options`}
                component={optionsArray}
                options={question.options}
                form={form}
              />
            </EvaluationOptionsContainer>
          </EvaluationCol>
        </Row>
      )}
    </FormSpy>
  )
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  title: PropTypes.string,
  hasCorrect: PropTypes.bool,
  disabled: PropTypes.bool,
  addOther: PropTypes.bool,
  isFirst: PropTypes.bool
}

Question.defaultProps = {
  onChangeValue: undefined,
  hasCorrect: false,
  disabled: false,
  addOther: false,
  isFirst: false,
  title: undefined
}

export default withLocalize(withRouter(Question))

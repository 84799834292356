import React from 'react'

import {ConfigProvider} from 'antd'
import locale from 'antd/es/locale/pt_PT'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  InputDiv,
  InputLabelDiv,
  DateInputField,
  InputBox
} from './InputStyles'
import {MaterialLabel} from './MaterialStyles'

const DateInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  dateFormat,
  picker,
  isAuth,
  disabledDate
}) => {
  const {invalid, submitFailed} = meta
  const showError = invalid && submitFailed ? 1 : 0

  return (
    <InputDiv first={first} isAuth={isAuth}>
      {label && isAuth ? (
        <MaterialLabel showError={showError}>{label}</MaterialLabel>
      ) : label ? (
        <InputLabelDiv>{label}</InputLabelDiv>
      ) : null}
      <InputBox isAuth={isAuth}>
        <ConfigProvider locale={locale}>
          <DateInputField
            disabledDate={disabledDate}
            disabled={disabled}
            error={showError}
            placeholder={placeholder}
            format={dateFormat || 'DD-MM-YYYY'}
            defaultValue={input.value ? moment(input.value) : null}
            onChange={(m, string) => input.onChange(m, string)}
            picker={picker}
            size={52}
            bordered={false}
          />
        </ConfigProvider>
      </InputBox>
    </InputDiv>
  )
}

DateInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  first: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  dateFormat: PropTypes.func,
  picker: PropTypes.string,
  isAuth: PropTypes.bool,
  disabledDate: PropTypes.func
}

DateInput.defaultProps = {
  label: undefined,
  first: false,
  placeholder: undefined,
  disabled: false,
  dateFormat: undefined,
  picker: undefined,
  isAuth: false,
  disabledDate: () => {}
}

export default DateInput

import React, {useState, useEffect} from 'react'

import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Logo from '../../assets/icons/logo_header.svg'
import {GetUserPhoto} from '../../infra/utils/CommonFunctions'
import MessageComponent from './MessageComponent'
import {
  IconSms,
  MessageLine,
  ImgSMS,
  TypingIndicator,
  ImgUser
} from './MessageStyles'

const ChatMessageComponent = ({content, userContent, onFinish}) => {
  const user = useSelector((state) => state.user)
  const [active, setActive] = useState(0)
  const [showOther, setShowOther] = useState(false)
  const [timer, setTimer] = useState(false)
  const [showOtherTyping, setShowOtherTyping] = useState(false)

  useEffect(() => {
    if (timer) clearTimeout(timer)
    const timerId = setTimeout(() => {
      setActive(active + 1)
      if (active + 1 === content.length - 1 && !userContent) onFinish()
      if (!!userContent && active + 1 === content.length - 1) {
        setShowOtherTyping(true)
        setTimeout(() => {
          setShowOtherTyping(false)
          setShowOther(true)
          onFinish()
        }, 2000)
      }
    }, 2000)

    setTimer(timerId)
  }, [active])

  const onClickNext = (a) => {
    setActive(a + 1)
    if (!!userContent && a + 1 === content.length - 1) {
      setTimeout(() => {
        setShowOther(true)
      }, 300)
    }
  }

  const messages = content.map((description, index) => (
    <>
      {index <= active && (
        <MessageComponent
          key={index}
          text={description.title}
          active={index === content.length - 1}
        />
      )}
    </>
  ))

  return (
    <>
      {!showOther && showOtherTyping && (
        <MessageLine isUser>
          <TypingIndicator isUser onClick={() => onClickNext(active)}>
            <span />
            <span className='one' />
            <span className='two' />
          </TypingIndicator>
          <ImgUser
            src={GetUserPhoto(user)}
            style={{
              alignSelf: 'flex-end'
            }}
          />
        </MessageLine>
      )}
      {showOther && (
        <MessageComponent isUser active>
          {userContent}
        </MessageComponent>
      )}
      {active < content.length - 1 && (
        <MessageLine>
          <IconSms>
            <ImgSMS src={Logo} />
          </IconSms>
          <TypingIndicator onClick={() => onClickNext(active)}>
            <span />
            <span className='one' />
            <span className='two' />
          </TypingIndicator>
        </MessageLine>
      )}
      {messages.reverse().map((elem) => elem)}
    </>
  )
}

ChatMessageComponent.propTypes = {
  content: PropTypes.array,
  userContent: PropTypes.object,
  onFinish: PropTypes.func
}

ChatMessageComponent.defaultProps = {
  content: undefined,
  userContent: undefined,
  onFinish: () => {}
}

export default ChatMessageComponent

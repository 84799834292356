import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {
  MaterialInputSection,
  MaterialLabel,
  MaterialInput,
  MaterialErrorLabel,
  MaterialIcon,
  MaterialInputBox
} from './MaterialStyles'

const TextInput = ({
  input,
  meta: {invalid, submitFailed, error},
  typeText,
  label,
  disabled,
  suffix,
  placeholder,
  tag,
  translate,
  onClick,
  readOnly,
  requiredError,
  color,
  noMargin,
  isSmall,
  labelIcon
}) => {
  const showError = invalid && submitFailed

  let errorMsg = ''
  if (error?._error) {
    if (Array.isArray(error._error) && error._error.length === 3) {
      errorMsg = translate(error._error[0]).replace(
        error._error[1],
        error._error[2]
      )
    } else {
      errorMsg = translate(error._error)
    }
  }

  if (requiredError) {
    errorMsg = translate('INPUT_ERROR_REQUIRED')
  }

  return (
    <MaterialInputSection noMargin={noMargin}>
      {label && (
        <MaterialLabel
          tag={tag}
          color={color}
          showError={showError || requiredError}
        >
          {label}
          {labelIcon && labelIcon}
        </MaterialLabel>
      )}
      <MaterialInputBox noMargin={noMargin}>
        <MaterialInput
          isSmall={isSmall}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          type={typeText}
          value={input.value}
          onChange={input.onChange}
          showError={showError || requiredError}
          tag={tag}
          onClick={onClick}
        />
        {suffix && <MaterialIcon>{suffix}</MaterialIcon>}
      </MaterialInputBox>
      {((invalid && submitFailed) || requiredError) && (
        <MaterialErrorLabel
          visible={(invalid && submitFailed) || requiredError}
        >
          {errorMsg.toUpperCase()}
        </MaterialErrorLabel>
      )}
    </MaterialInputSection>
  )
}

TextInput.propTypes = {
  translate: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  typeText: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  tag: PropTypes.string,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  requiredError: PropTypes.bool,
  suffix: PropTypes.object,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  isSmall: PropTypes.bool,
  color: PropTypes.string,
  labelIcon: PropTypes.object
}

TextInput.defaultProps = {
  label: undefined,
  placeholder: undefined,
  tag: undefined,
  onClick: () => {},
  readOnly: false,
  requiredError: false,
  suffix: false,
  disabled: false,
  noMargin: false,
  isSmall: false,
  color: false,
  labelIcon: undefined
}

export default withLocalize(TextInput)

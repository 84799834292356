import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_quiz.svg'
import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {
  MainContainer,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {Margin} from '../../styles/BasicStyles'
import {ThirdColour, SecondaryColour} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'

const Step2_5_1 = ({
  step,
  history,
  nextStep,
  chapter,
  session,
  activeLanguage
}) => {
  const showStory = session.order === 6 && step.order === 2
  return (
    <>
      <BasicHeaderComponent
        onClose={() => history.push('/program')}
        title={chapter.title}
        progress={(step.order / chapter.steps.length) * 100}
        progressColor={ThirdColour}
      />
      <MainContainerBackground
        bgColor={SecondaryColour}
        style={{
          backgroundImage: `url(${Illustration})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      >
        <MainContainerScroll>
          <MainContainer>
            {step.type === 'text' && (
              <>
                {showStory ? (
                  <>
                    <MessageComponent
                      active
                      text={step.descriptions[0].title}
                    />
                    <div style={{padding: 24, marginTop: 24}}>
                      <QuestionText isBold>
                        {GetValueLanguage(
                          step.descriptions[1].title,
                          activeLanguage?.code
                        )}
                      </QuestionText>
                      <Margin size={16} />
                      <QuestionText isBold>
                        {GetValueLanguage(
                          step.descriptions[2].title,
                          activeLanguage?.code
                        )}
                      </QuestionText>
                    </div>
                  </>
                ) : (
                  step.descriptions.map((description, index) => (
                    <MessageComponent
                      key={index}
                      text={description.title}
                      active={index === step.descriptions.length - 1}
                    />
                  ))
                )}
              </>
            )}
          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackground>
      <BasicFooterComponent
        onNext={() =>
          nextStep
            ? history.push(`/step/${nextStep._id}`)
            : history.push('/program')
        }
      />
    </>
  )
}

Step2_5_1.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  session: PropTypes.object
}

Step2_5_1.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  session: undefined
}
export default withLocalize(withRouter(Step2_5_1))

import {Row} from 'antd'
import styled from 'styled-components'

import {
  Beige,
  MenuBorderColor,
  TextColor,
  White,
  Beige2
} from '../../styles/Colours'
import {device, vertical} from '../../styles/Responsive'

export const IntroductionBlock = styled.div`
  width: 100%;
  background: ${({bgColor}) => bgColor || Beige2};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;

  @media ${vertical.medium} {
    padding: 16px;
    margin-bottom: 24px;
  }

  @media ${device.tabletMin} {
    padding: 16px;
    margin-bottom: 24px;
  }
`

export const Block1 = styled.div`
  width: 70%;

  @media ${device.tablet} {
    width: 100%;
  }
`

export const Illustration = styled.img`
  width: 60%;
  margin: 0px auto -8px;

  @media ${device.tablet} {
    width: 100%;
    margin: 8px auto -8px;
  }
`

export const QuestionTitle = styled.div`
  font-family: 'Poppins', sans-serif;
  color: ${(p) => p.color || TextColor};
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  text-align: center;
  padding-bottom: 50px;

  @media ${vertical.medium} {
    padding-bottom: 24px;
    font-size: 20px;
    line-height: 30px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 32px;
  }
`

export const QuestionTitle2 = styled.div`
  font-family: 'Poppins', sans-serif;
  color: ${(p) => p.color || TextColor};
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  text-align: center;
  padding-bottom: 24px;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 30px;
  }
`

export const OptionsContainer = styled(Row)`
  margin-bottom: 16px;
  width: 100%;
`

export const ExplanationContainer = styled.div`
  width: 100%;
  background: ${(p) => p.bgColor || White};
  border: 2px solid ${(p) => p.borderColor || MenuBorderColor};
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 60px;
  margin-top: 16px;
  display: ${({isActive}) => (isActive ? 'block' : 'none')};

  @media ${device.tabletMin} {
    padding: 16px;
  }
`

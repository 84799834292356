import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_diario_meditacao.png'
import {UpdateUserProgram} from '../../infra/utils/CommonFunctions'
import {
  MainContainer,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {ThirdColour} from '../../styles/Colours'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'

const Step4_6_2 = ({
  step,
  history,
  nextStep,
  chapter,
  session,
  updateChapter
}) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const isApplicator = user.type === 2
  const isMeditation =
    session.order === 4 && (chapter.order === 5 || chapter.order === 6)
  const progress =
    chapter.steps.length === step.order
      ? 100
      : (step.order / (chapter.steps.length + (isMeditation ? 6 : 0))) *
        100

  const onNextStep = () => {
    if (step.order === 4 && !isApplicator) {
      UpdateUserProgram(updateChapter, chapter._id, dispatch)
    }

    if (nextStep) {
      history.push(`/step/${nextStep._id}`)
    } else history.push('/program')
  }

  return (
    <>
      <BasicHeaderComponent
        onClose={() => history.push('/program')}
        title={chapter.title}
        progress={progress}
      />
      <MainContainerBackground
        bgColor={ThirdColour}
        style={{
          backgroundImage: `url(${Illustration})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      >
        <MainContainerScroll>
          <MainContainer>
            {step.type === 'text' && (
              <>
                {step.descriptions.map((description, index) => (
                  <MessageComponent
                    key={index}
                    text={description.title}
                    active={index === step.descriptions.length - 1}
                  />
                ))}
              </>
            )}
          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackground>
      <BasicFooterComponent
        onBack={() => history.goBack()}
        onNext={onNextStep}
      />
    </>
  )
}
Step4_6_2.propTypes = {
  step: PropTypes.object.isRequired,
  session: PropTypes.object,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  updateChapter: PropTypes.bool
}

Step4_6_2.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  session: undefined,
  updateChapter: false
}
export default withLocalize(withRouter(Step4_6_2))

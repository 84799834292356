import React, {useState} from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_objetivos_atividades.svg'
import {SaveStepAnswerObjective} from '../../infra/requests/ProgramRequests'
import {UpdateUserProgram} from '../../infra/utils/CommonFunctions'
import {
  MainContainer,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {UpdateProgram} from '../../redux/Info/info.actions'
import {Beige3} from '../../styles/Colours'
import ChartComponent from '../charts/ChartComponent'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'

const Step5_3_1 = ({step, history, nextStep, chapter, updateChapter}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector((state) => state.user)
  const isApplicator = user.type === 2
  const progress = (step.order / chapter.steps.length) * 100
  const isFirst = step.order === 1
  const showFirstChart =
    (chapter.order === 3 && (step.order === 3 || step.order === 4)) ||
    (chapter.order === 4 && (step.order === 1 || step.order === 2))

  const onNextStep = async () => {
    setIsLoading(true)
    if (
      chapter.order === 4 &&
      step.order === 2 &&
      !chapter.completed &&
      !isApplicator
    ) {
      // new upd due to unblock sessions, it is needed save answer to set chapter as completed
      const fields = {
        user: undefined,
        chapter: chapter._id,
        step: step._id,
        updateChapter: chapter.order === 4 && step.order === 2 && !isApplicator
      }
      const result = await SaveStepAnswerObjective(fields)
      // if (result.success) dispatch(UpdateProgram(result.data.sessions))
    }

    // if (
    //   chapter.order === 4 &&
    //   step.order === 2 &&
    //   !isApplicator
    // ) {
    //   UpdateUserProgram(updateChapter, chapter._id, dispatch)
    // }

    setIsLoading(false)
    if (nextStep) history.push(`/step/${nextStep._id}`)
    else history.push('/program')
  }

  return (
    <>
      <BasicHeaderComponent
        onClose={() => history.push('/program')}
        title={chapter.title}
        progress={progress}
      />
      <MainContainerBackground
        bgColor={Beige3}
        style={{
          backgroundImage: `url(${Illustration})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      >
        <MainContainerScroll>
          <MainContainer>
            {showFirstChart && (
              <MessageComponent isFull={chapter.order === 4 || false}>
                <ChartComponent isStatic={chapter.order === 3 || false} />
              </MessageComponent>
            )}
            {step.type === 'text' && (
              <>
                {step.descriptions.map((description, index) => (
                  <MessageComponent
                    key={index}
                    text={description.title}
                    active={
                      chapter.order === 3 && step.order === 2
                        ? false
                        : index === step.descriptions.length - 1
                    }
                  />
                ))}
              </>
            )}
            {chapter.order === 3 && step.order === 2 && (
              <MessageComponent active isFull>
                <ChartComponent isStatic />
              </MessageComponent>
            )}
          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackground>
      <BasicFooterComponent
        onBack={isFirst ? false : () => history.goBack()}
        onNext={onNextStep}
        isLoading={isLoading}
      />
    </>
  )
}
Step5_3_1.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  updateChapter: PropTypes.bool
}

Step5_3_1.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  updateChapter: false
}
export default withLocalize(withRouter(Step5_3_1))

import React from 'react'

import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'

import {
  CreateComment,
  CreateSubComment
} from '../../infra/requests/ForumRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {PageForm} from '../../styles/BasicStyles'
import {White} from '../../styles/Colours'
import {BaseButton} from '../buttons/BaseButton'
import TextAreaInput from '../inputs/TextAreaInput'

const formRules = FormValidator.make({
  thread: 'required',
  description: 'required'
})

const AddCommentComponent = ({
  thread,
  translate,
  onChangeComment,
  isSubComment,
  comment,
  isEducator
}) => {
  const initialValues = {
    thread: thread?._id || undefined,
    description: undefined,
    pending: false,
    pendingUser: false
  }

  const onSubmit = async values => {
    try {
      const result = await (isSubComment
        ? CreateSubComment(comment._id, values)
        : CreateComment(values))

      if (result.success) {
        onChangeComment(result.data)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={formRules}
      mutators={{
        ...arrayMutators
      }}
      initialValues={initialValues}
    >
      {({handleSubmit, form, pristine, valid, submitting}) => (
        <PageForm
          style={{display: isSubComment ? 'flex' : 'inline-block'}}
          onSubmit={event => {
            const promise = handleSubmit(event).then(() => {
              form.reset()
            })
            return promise
          }}
        >
          <Field
            typeText='text'
            name='description'
            component={TextAreaInput}
            maxRows={isSubComment ? 3 : 6}
            minRows={isSubComment ? 1 : 6}
            bgColor={White}
            isSmall={isSubComment}
            noMargin={isSubComment}
            placeholder={translate(
              isEducator
                ? 'CRIA_AQUI_O_TEU_COMENTARIO_EDUCADOR'
                : 'CRIA_AQUI_O_TEU_COMENTARIO'
            )}
          />
          <BaseButton
            disabled={(submitting || pristine) && !valid}
            style={{
              width: 'auto',
              marginTop: isSubComment ? 0 : 16,
              marginLeft: isSubComment ? 8 : 0,
              minWidth: isSubComment ? 'fit-content' : 'auto'
            }}
            align='right'
            htmlType='submit'
            type='primaryMedium'
            text={translate('ADICIONAR')}
          />
        </PageForm>
      )}
    </Form>
  )
}

AddCommentComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  thread: PropTypes.object,
  comment: PropTypes.object,
  onChangeComment: PropTypes.func.isRequired,
  isSubComment: PropTypes.bool,
  isEducator: PropTypes.bool
}

AddCommentComponent.defaultProps = {
  isSubComment: false,
  thread: undefined,
  comment: undefined,
  isEducator: false
}

export default withLocalize(AddCommentComponent)

import ActivitiesPage from '../../pages/activities/ActivitiesPage'
import BoosterSessionPage from '../../pages/booster_session/BoosterSessionPage'
import DashboardPage from '../../pages/dashboard/DashboardPage'
import CreateThreadPage from '../../pages/forum/CreateThreadPage'
import ForumPage from '../../pages/forum/ForumPage'
import ThreadPage from '../../pages/forum/ThreadPage'
import HelpPage from '../../pages/help/HelpPage'
import MeditationPage from '../../pages/meditation/MeditationPage'
import ProfilePage from '../../pages/profile/ProfilePage'
import ProgramPage from '../../pages/program/ProgramPage'
import StepPage from '../../pages/step/StepPage'
import StepParentPage from '../../pages/step/StepParentPage'

export default [
  {
    path: '/',
    component: DashboardPage,
    isAuthenticated: true
  },
  {
    path: '/program',
    component: ProgramPage,
    isAuthenticated: true
  },
  {
    path: '/youth_program',
    component: ProgramPage,
    isAuthenticated: true
  },
  {
    path: '/educators_program',
    component: ProgramPage,
    isAuthenticated: true
  },
  {
    path: '/step/:id',
    component: StepPage,
    isAuthenticated: true
  },
  {
    path: '/step_educators/:id',
    component: StepParentPage,
    isAuthenticated: true
  },
  {
    path: '/activities',
    component: ActivitiesPage,
    isAuthenticated: true,
    permission: [3, 4]
  },
  {
    path: '/meditation',
    component: MeditationPage,
    isAuthenticated: true,
    permission: [3, 4]
  },
  {
    path: '/profile',
    component: ProfilePage,
    isAuthenticated: true,
    permission: [3, 4]
  },
  {
    path: '/forum',
    component: ForumPage,
    isAuthenticated: true,
    permission: [3, 4]
  },
  {
    path: '/forum/add',
    component: CreateThreadPage,
    isAuthenticated: true,
    permission: [3, 4]
  },
  {
    path: '/forum/:id',
    component: ThreadPage,
    isAuthenticated: true,
    permission: [3, 4]
  },
  {
    path: '/help',
    component: HelpPage,
    isAuthenticated: true,
    permission: [3, 4]
  },
  {
    path: '/booster_session/:id',
    component: BoosterSessionPage,
    isAuthenticated: true
  },
  {
    path: '/express_session/:id',
    component: BoosterSessionPage,
    isAuthenticated: true
  }
]

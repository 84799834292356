import {Col} from 'antd'
import styled from 'styled-components'

import {MenuBorderColor, White} from '../../styles/Colours'
import {device} from '../../styles/Responsive'
import {BodyText} from '../../styles/TextSizes'

export const ThreadContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 2px solid ${MenuBorderColor};
  background-color: ${White};
  display: flex;
  margin-bottom: 28px;
  cursor: pointer;

  @media ${device.tablet} {
    padding: 16px;
  }

  @media ${device.mobileL} {
    flex-direction: column;
  }
`

export const UserImage = styled.img`
  height: 54px;
  width: 54px;
  margin-right: 16px;
  border-radius: 50%;
`

export const ThreadDetail = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;
`

export const UsersImgs = styled.div``

export const UsersContainerCenter = styled.div`
  position: relative;
  padding: 0px 24px;
  float: right;
`

export const UserImageBox = styled.div`
  width: 33px;
  height: 33px;
  background-color: ${MenuBorderColor};
  position: absolute;
  border-radius: 50%;
  border: 1px solid ${White};
  overflow: hidden;
  display: flex;
`

export const ThreadDescription = styled(BodyText)`
  height: 41px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media ${device.tablet} {
    height: 35px;
  }
`

export const MessageContainer = styled.div`
  margin-top: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media ${device.mobileL} {
    margin: auto;
    margin-left: 0;
  }
`

export const ThreadCol = styled(Col)`
  display: flex !important;
  flex-direction: column;
  padding: 0px !important;

  @media ${device.mobileL} {
    height: 33px;
  }
`

export const ThreadCol1 = styled(Col)`
  padding: 0px !important;
  display: flex !important;
`

export const CommentContainer = styled.div`
  padding: 0px;
  display: flex;
  margin-bottom: ${({hasSub, index}) =>
    hasSub || index >= 1 ? '0px' : '24px'};
  margin-left: ${({isSub, index}) =>
    isSub && index ? (index === 1 ? 72 : index * 48) : 0}px;

  @media ${device.tablet} {
    margin-bottom: ${({isSub}) => (isSub ? '0px' : '8px')};
    margin-left: ${({isSub, index}) =>
      isSub && index ? (index === 1 ? 48 : index * 40) : 0}px;
  }
`

export const UserCommentImage = styled.img`
  height: 56px;
  width: 56px;
  margin-right: 16px;
  border-radius: 50%;

  @media ${device.tablet} {
    height: 40px;
    width: 40px;
    margin-right: 8px;
  }

  @media ${device.mobileL} {
    height: 40px;
    width: 40px;
    margin-right: 8px;
  }
`

export const SubCommentContainer = styled.div`
  padding: 0px;
  display: flex;
  margin-top: ${({index, hasSub}) =>
    !index && !hasSub ? '-24px' : '0px'};
`

export const UserSubCommentImage = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border-radius: 50%;

  @media ${device.tablet} {
    height: 32px;
    width: 32px;
  }

  @media ${device.mobileL} {
    height: 32px;
    width: 32px;
  }
`

export const ReplyBox = styled.div`
  cursor: pointer;
  margin-left: 16px;
`

export const CommentsList = styled.div`
  width: 100%;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const TitleEditable = styled.div`
  display: flex;
  justify-content: ${({isComment}) =>
    isComment ? 'flex-start' : 'space-between'};
  align-items: flex-start;
`

export const EditIcon = styled.img`
  margin-left: ${({isComment}) => (isComment ? '8px' : '16px')};
  width: ${({isComment}) => (isComment ? '20px' : '24px')};
  cursor: pointer;
`

import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Complete from '../../assets/icons/complete.svg'
import Lock from '../../assets/icons/lock.svg'
import {
  GetValueLanguage,
  GetPreviousRecords,
  GetStepName
} from '../../infra/utils/CommonFunctions'
import {programaEducadores} from '../../infra/utils/PagesNames'
import {LockIcon} from '../../pages/dashboard/DashboardStyles'
import {Margin} from '../../styles/BasicStyles'
import {Title22, BodyText, Title18, Body} from '../../styles/TextSizes'
import {
  SessionDetail,
  SessionChapter,
  CompleteIcon,
  SessionSubDetail,
  SessionInnerDetail
} from './SessionStyles'

const SessionComponent = ({
  session,
  sessions,
  activeLanguage,
  history,
  translate
}) => {
  const {
    user,
    info: {program}
  } = useSelector((state) => state)
  const isEducator = user.type === 3 || program._id === programaEducadores
  const isApplicator = user.type === 2
  const stepName = GetStepName(program)

  const onPressChapter = (chapter, active) => {
    if (!active) return
    const id = chapter.steps[0]._id
    history.push(`/${stepName}/${id}`)
  }
  const records = GetPreviousRecords(sessions, session.order)

  return (
    <SessionDetail>
      <SessionInnerDetail isEducator={isEducator}>
        {isEducator && (
          <>
            <Body
              dangerouslySetInnerHTML={{
                __html: GetValueLanguage(
                  session.description,
                  activeLanguage?.code
                )
              }}
            />
            <Margin size={16} />
          </>
        )}
        {session.chapters.map((chapter, i) => {
          const active = chapter.enabled || isApplicator
          // const active = true
          return (
            <SessionChapter
              key={i}
              active={active}
              onClick={() => onPressChapter(chapter, active)}
            >
              {!active ? (
                <LockIcon top='-10px' right='-10px'>
                  <img alt='lock' src={Lock} />
                </LockIcon>
              ) : chapter.completed && !isEducator ? (
                <CompleteIcon top='-10px' right='-10px'>
                  <img alt='Complete' src={Complete} />
                </CompleteIcon>
              ) : null}
              <Row>
                <Col xs={24} sm={24}>
                  <Title22 inactive={!active}>
                    {GetValueLanguage(chapter.title, activeLanguage?.code)}
                  </Title22>
                </Col>
                {/* <Col xs={24} sm={4} style={{textAlign: 'right'}}>
                <StarComponent
                  active={active}
                  starNumber={session.starNumber}
                />
              </Col> */}
              </Row>
              <Margin size={8} />
              <BodyText
                inactive={!active}
                dangerouslySetInnerHTML={{
                  __html: GetValueLanguage(
                    chapter.description,
                    activeLanguage?.code
                  )
                }}
              />
            </SessionChapter>
          )
        })}
      </SessionInnerDetail>
      {records && records.length > 0 && (
        <SessionSubDetail>
          <Title18 inactive={!session.enabled}>
            {translate('FERRAMENTAS_PROGRAMA')}
          </Title18>
          <Margin size={8} />
          {records.map((record, i) => {
            const recordActive =
              (!(
                !record.enabled || record.steps.find((s) => s.completedWeek)
              ) &&
                session.enabled) ||
              isApplicator
            // const recordActive = true
            return (
              <SessionChapter
                key={i}
                active={recordActive}
                onClick={
                  !recordActive
                    ? () => {}
                    : () =>
                        history.push(
                          `/${stepName}/${
                            record.steps.find((s) => s.type === 'question')
                              ._id
                          }`,
                          {
                            next: 'isBack',
                            back: 'isBack',
                            noProgram: true
                          }
                        )
                }
              >
                <Row>
                  <Col xs={24} sm={24}>
                    <Title18 inactive={!recordActive}>
                      {GetValueLanguage(
                        record.title,
                        activeLanguage?.code
                      )}
                    </Title18>
                  </Col>
                </Row>
              </SessionChapter>
            )
          })}
        </SessionSubDetail>
      )}
    </SessionDetail>
  )
}

SessionComponent.propTypes = {
  session: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  sessions: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(withRouter(SessionComponent))

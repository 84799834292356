import styled from 'styled-components'

import {InputBorderColour} from '../../styles/Colours'
import ImageComponent from '../images/ImageComponent'

export const ContainerCircle = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  border-radius: 50%;
  background-color: ${InputBorderColour};
  cursor: pointer;
  margin-right: 24px;
  overflow: hidden;
`
export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`

export const Image = styled(ImageComponent)`
  with: auto;
  height: auto;
`

export const PhotoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

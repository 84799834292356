import styled from 'styled-components'

import {ThirdColour, White, SecondaryColour} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const ScoreContainer = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 80px;
  flex-direction: column;

  @media ${device.tabletMin} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const CupContainer = styled.div`
  width: 256px;
  height: 256px;
  border-radius: 50%;
  background-color: ${ThirdColour};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

export const ScoreBox = styled.div`
  padding: 8px 16px;
  background-color: ${White};
  color: ${SecondaryColour};
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  margin-bottom: 16px;
  border-radius: 12px;
`

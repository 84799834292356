export default {
  languages: [
    {
      code: 'en',
      name: 'English'
    }
  ],
  user: {},
  info: {
    ready: false,
    configs: {}
  }
};

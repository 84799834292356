import React from 'react'

import {Slider} from 'antd'
import {PropTypes} from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {TextColor} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import {ThermometerContainer} from './DiarioHumorStyles'

const QuestionThermometer = ({
  step,
  disabled,
  activeLanguage,
  fieldName
}) => {
  const question = step.questions[0]
  const marks = {}
  for (let i = 0; i <= 10; i++) {
    marks[i * 10] = {
      style: {
        color: TextColor
      },
      label: i * 10
    }
  }

  return (
    <FormSpy>
      {({form}) => (
        <Field name={`${fieldName}.options`}>
          {(props) => (
            <>
              <QuestionText>
                {GetValueLanguage(question.title, activeLanguage?.code)}
              </QuestionText>
              <ThermometerContainer>
                <Slider
                  disabled={disabled}
                  onChange={(value) =>
                    form.change(`${fieldName}.options`, value)
                  }
                  vertical
                  defaultValue={50}
                  max={100}
                  min={0}
                  marks={marks}
                  value={
                    props?.input?.value
                      ? props?.input?.value
                      : props?.input?.value === 0
                      ? 0
                      : 50
                  }
                />
              </ThermometerContainer>
            </>
          )}
        </Field>
      )}
    </FormSpy>
  )
}

QuestionThermometer.propTypes = {
  step: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

QuestionThermometer.defaultProps = {
  disabled: false
}

export default withLocalize(withRouter(QuestionThermometer))

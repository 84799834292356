import React from 'react'
import styled from 'styled-components'
import LoadingIcon from '../../assets/icons/ic_loading.gif'

const Container = styled.div`
  display: flex;
  width: ${({page}) => (page ? '100%' : '100vw')};
  height: ${({page}) => (page ? '100%' : '100vh')};
  justify-content: center;
  align-items: center;
`

const Loading = styled.img`
  width: ${({width}) => width || 150}px;
`

const LoadingComponent = ({page, width}) => {
  return (
    <Container page={page}>
      <Loading width={width} src={LoadingIcon} />
    </Container>
  )
}

export default LoadingComponent

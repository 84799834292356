import styled from 'styled-components'

import {
  MenuBorderColor,
  SecondaryColour,
  TextColor,
  White
} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const MessageLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-self: ${({isUser}) => (isUser ? 'flex-end' : 'flex-start')};
  animation-iteration-count: 1;
  animation: 0.5s msg1 ease-in-out;
  animation-fill-mode: forwards;
  float: ${({isUser}) => (isUser ? 'right' : 'inherit')};

  @keyframes msg1 {
    0% {
      left: -10px;
      opacity: 0;
    }
    50% {
      left: 0;
      opacity: 0.7;
    }
    100% {
      top: 0;
      left: 0;
      opacity: 1;
    }
  }
`

export const IconSms = styled.div`
  width: 73px;
  height: 73px;
  background: #ffffff;
  border-radius: 50%;
  padding: 7px;
  align-self: flex-end;

  @media ${device.tablet} {
    width: 53px;
    height: 53px;
    padding: 3px;
  }
`

export const ImgUser = styled.img`
  width: 73px;
  height: 73px;
  border-radius: 50%;

  @media ${device.tablet} {
    width: 53px;
    height: 53px;
  }
`

export const ImgSMS = styled.img`
  height: 100%;
  width: 50px;

  @media ${device.tablet} {
    width: 36px;
    height: 100%;
  }
`

export const MessageContainer = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({isQuote}) => (isQuote ? SecondaryColour : '#ffffff')};
  color: ${({isQuote}) =>
    isQuote ? `${White} !important` : `${TextColor} !important`};
  border: 2px solid;
  border-color: ${({isQuote}) =>
    isQuote ? 'transparent' : MenuBorderColor};
  border-radius: 25px;
  width: ${({isFull}) => (isFull ? '100%' : 'fit-content')};
  margin-left: ${({active, isUser, isQuote}) =>
    isQuote
      ? 'auto'
      : isUser && active
      ? '89px'
      : active
      ? '16px'
      : '89px'};
  margin-right: ${({active, isUser, isQuote}) =>
    isQuote
      ? 'auto'
      : isUser && active
      ? '16px'
      : !isUser
      ? '16px'
      : '89px'};
  margin-top: ${({isQuote}) => (isQuote ? '46px' : '0px')};

  @media ${device.tablet} {
    padding: 16px;
    margin-left: ${({active, isUser, isQuote}) =>
      isQuote
        ? 'auto'
        : isUser && active
        ? '60px'
        : active
        ? '6px'
        : '60px'};
    margin-right: ${({active, isUser, isQuote}) =>
      isQuote
        ? 'auto'
        : isUser && active
        ? '6px'
        : !isUser
        ? '6px'
        : '60px'};
  }
`

export const MessageText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: ${({isBigger}) => (isBigger ? '36px' : '30px')};

  @media ${device.tablet} {
    font-size: 16px;
    line-height: ${({isBigger}) => (isBigger ? '26px' : '20px')};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  @media ${device.tablet} {
    display: grid;
    gap: 10px;

    & button {
      margin: 0px !important;
    }
  }
`

export const TypingIndicator = styled.div`
  cursor: pointer;
  padding: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${MenuBorderColor};
  color: ${TextColor} !important;
  border: 2px solid;
  border-color: ${MenuBorderColor};
  border-radius: 25px;
  width: fit-content;
  margin-left: 16px;
  margin-right: ${({isUser}) => (isUser ? 16 : 89)}px;
  margin-top: 0px;
  animation: 2s bulge infinite ease-out;
  $ti-color-bg: #e6e7ed;
  background-color: $ti-color-bg;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: $ti-color-bg;
  }
  &::after {
    height: 10px;
    width: 10px;
    left: -10px;
    bottom: -10px;
  }
  span {
    height: 15px;
    width: 15px;
    float: left;
    margin: 0 1px;
    background-color: #9e9ea1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    animation: 1s blink infinite 1s;
    animation-delay: 0s;
    &.one {
      animation-delay: 0.33333s;
    }
    &.two {
      animation-delay: 0.66666s;
    }
  }

  @keyframes blink {
    50% {
      opacity: 1;
    }
  }

  @keyframes bulge {
    50% {
      transform: scale(1.05);
    }
  }

  @media ${device.tablet} {
    padding: 16px;
    margin-left: 10px;
    margin-right: 60px;
    span {
      height: 10px;
      width: 10px;
    }
  }
`

import React, {useState, useEffect} from 'react'

import {DownloadOutlined, FileDoneOutlined} from '@ant-design/icons'
import {Row} from 'antd'
import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'

import Book from '../../assets/icons/book-open.svg'
import Gift from '../../assets/icons/gift.svg'
import ChartComponent from '../../components/charts/ChartComponent'
import ChartMeditationComponent from '../../components/charts/ChartMeditationComponent'
import {LineSeparator} from '../../components/charts/ChartsStyles'
import ImagePickerComponent from '../../components/image_picker/ImagePickerComponent'
import {UpdatePhoto} from '../../infra/requests/ProfileRequests'
import {GetUserPhoto} from '../../infra/utils/CommonFunctions'
import {SaveUser} from '../../redux/User/user.actions'
import {Margin} from '../../styles/BasicStyles'
import {Beige3, White} from '../../styles/Colours'
import {Title25, QuestionText} from '../../styles/TextSizes'
import {
  DashboardContainer,
  TitleDashboard,
  SubBox,
  DashboardComboBox,
  InlineInfo
} from '../dashboard/DashboardStyles'
import {ProfileCol} from './ProfileStyles'
import ScoreComponent from './ScoreComponent'

const ProfilePage = ({translate, activeLanguage}) => {
  const dispatch = useDispatch()
  const {
    user,
    info: {program}
  } = useSelector((state) => state)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(
    user.photo ? GetUserPhoto(user) : undefined
  )
  const isStudent = user.type === 4
  const isApplicator = user.type === 2
  const isEducator = user.type === 3
  const isFinished = isApplicator ? false : program.completed || user.programCompleted

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
    document.body.style.overflowY = 'auto'
  })

  const onPickImage = async (img) => {
    try {
      setLoading(true)
      const result = await UpdatePhoto(img)
      if (result.success) {
        setImage(GetUserPhoto(result.data))
        dispatch(SaveUser({...user, photo: result.data.photo}))
      }
      setLoading(false)
    } catch (e) {
      console.warn(e)
      setLoading(false)
    }
  }

  const Download = () => {
    window.open(
      `${process.env.REACT_APP_API}/certificate/${activeLanguage?.code}/${user?._id}`,
      '_blank'
    )
  }

  return (
    <DashboardContainer>
      <Row gutter={[22]}>
        <ProfileCol sm={24} md={12}>
          <TitleDashboard weight='bold' marginB={16}>
            {translate('PERFIL')}
          </TitleDashboard>
          <DashboardComboBox>
            <SubBox>
              <div style={{display: 'flex'}}>
                <ImagePickerComponent
                  isCropCircle
                  onSelectImage={onPickImage}
                  onPressClose={() => setImage(false)}
                  image={image}
                  loading={loading}
                />
                <Title25>{user.name}</Title25>
              </div>
            </SubBox>
            <SubBox bgColor={Beige3} hasBorder padding='16px 24px 24px'>
              <InlineInfo>
                <img alt='gift' src={Gift} style={{marginRight: 24}} />
                <QuestionText>
                  {moment(user.birthday).format('DD-MM-YYYY')}
                </QuestionText>
              </InlineInfo>
              <Margin size={20} />
              {isStudent && (
                <InlineInfo>
                  <img alt='book' src={Book} style={{marginRight: 24}} />
                  <QuestionText>{user.school?.name}</QuestionText>
                </InlineInfo>
              )}
              {isFinished && isStudent && (
                <InlineInfo style={{marginTop: 24}}>
                  <FileDoneOutlined
                    style={{
                      fontSize: 24,
                      marginRight: 24
                    }}
                  />
                  <QuestionText>
                    {translate(
                      isStudent
                        ? 'PARABENS_CERTIFICADO_PROGRAMA_JOVEM'
                        : 'PARABENS_CERTIFICADO_PROGRAMA_EDUCADORES'
                    )}
                  </QuestionText>
                  <a
                    download
                    onClick={Download}
                    style={{
                      margin: 'auto'
                    }}
                  >
                    <DownloadOutlined
                      style={{
                        fontSize: 32,
                        background: White,
                        borderRadius: '50%',
                        padding: 8
                      }}
                    />
                  </a>
                </InlineInfo>
              )}
            </SubBox>
          </DashboardComboBox>
          <Margin size={24} />
          {isStudent && <ScoreComponent />}
        </ProfileCol>
        { !isEducator &&
        <ProfileCol sm={24} md={12}>
          <TitleDashboard weight='bold' marginB={16}>
            {translate('PROGRESSO')}
          </TitleDashboard>
          <DashboardComboBox>
            <ChartComponent previewChart />
            <LineSeparator />
            <ChartMeditationComponent previewChart />
          </DashboardComboBox>
        </ProfileCol>
        }
      </Row>
    </DashboardContainer>
  )
}

ProfilePage.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(ProfilePage)

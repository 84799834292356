import React, {useState, useRef} from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {
  InactiveTextColor,
  SuccessBgColor,
  ErrorBgColor
} from '../../styles/Colours'
import {QuestionText, Title22} from '../../styles/TextSizes'
import {ExplanationContainer} from '../jogo_espirais/JogoEspiraisStyles'
import {
  QuestionOption,
  OptionText,
  BlockLeft,
  BlockRight,
  QuestionImg2,
  Thought,
  ThoughtBox
} from './QuestionStyles'

const QuestionImage2 = ({
  fieldName,
  question,
  image,
  activeLanguage,
  hasCorrect,
  title
}) => {
  const refExplanation = useRef(null)
  const [animation, setAnimation] = useState(undefined)

  const goToExplanation = () => {
    setTimeout(() => {
      if (refExplanation?.current) {
        refExplanation.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }, 300)
  }

  const onSelectOption = (idOption, form, name) => {
    form.change(name, idOption)
    goToExplanation()
  }

  const optionsArray = opt => {
    const {options, form, input} = opt
    const currentOption = input?.value || undefined
    const currentOptionObj =
      options.find(o => o._id === input?.value) || undefined

    if (currentOptionObj && currentOptionObj?.explanation) {
      goToExplanation()
    }

    const isCorrect =
      hasCorrect && currentOptionObj?.isCorrect ? input?.value : false
    return (
      <>
        {options.map((option, index) => (
          <QuestionOption
            className={
              animation === index
                ? hasCorrect
                  ? isCorrect
                    ? 'animated'
                    : 'animatedWrong'
                  : 'animated'
                : ''
            }
            key={index}
            onClick={() => {
              if (!(isCorrect && isCorrect !== option._id)) {
                onSelectOption(option._id, form, input.name)
                setAnimation(index)
              }
            }}
            onAnimationEnd={() => setAnimation(undefined)}
            isActive={
              (currentOption === option._id &&
                hasCorrect &&
                option.isCorrect) ||
              (!hasCorrect && currentOption === option._id)
            }
            isWrong={
              currentOption === option._id &&
              hasCorrect &&
              !option.isCorrect
            }
          >
            <OptionText
              color={
                isCorrect && hasCorrect && isCorrect !== option._id
                  ? InactiveTextColor
                  : undefined
              }
            >
              {GetValueLanguage(option.title, activeLanguage?.code)}
            </OptionText>
          </QuestionOption>
        ))}
        <ExplanationContainer
          style={{marginTop: 0}}
          ref={refExplanation}
          isActive={currentOptionObj && currentOptionObj?.explanation}
          bgColor={
            currentOptionObj?.isCorrect ? SuccessBgColor : ErrorBgColor
          }
          borderColor={
            currentOptionObj?.isCorrect ? SuccessBgColor : ErrorBgColor
          }
        >
          {currentOptionObj?.explanation && (
            <QuestionText
              dangerouslySetInnerHTML={{
                __html: GetValueLanguage(
                  currentOptionObj.explanation,
                  activeLanguage?.code
                )
              }}
            />
          )}
        </ExplanationContainer>
      </>
    )
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({form}) => (
        <>
          <Row>
            <Col xs={24} md={12}>
              <BlockLeft>
                <QuestionImg2 src={image} />
                <ThoughtBox>
                  <Thought>
                    <Title22 weight={500}>
                      {GetValueLanguage(title, activeLanguage?.code)}
                    </Title22>
                  </Thought>
                </ThoughtBox>
              </BlockLeft>
            </Col>
            <Col xs={24} md={12}>
              <BlockRight>
                <Field
                  name={`${fieldName}.options`}
                  component={optionsArray}
                  options={question.options}
                  form={form}
                />
              </BlockRight>
            </Col>
          </Row>
        </>
      )}
    </FormSpy>
  )
}

QuestionImage2.propTypes = {
  question: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  image: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  hasCorrect: PropTypes.bool
}

QuestionImage2.defaultProps = {
  image: undefined,
  hasCorrect: false
}
export default withLocalize(withRouter(QuestionImage2))

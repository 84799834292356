import React from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {Margin} from '../../styles/BasicStyles'
import {SecondaryColour} from '../../styles/Colours'
import {Title25} from '../../styles/TextSizes'
import {ResultText, ResultRow} from '../question/QuestionStyles'

const ResultComponent = ({translate}) => (
  <FormSpy subscription={{values: true}}>
    {({values}) => {
      const questions = []
      values.steps.map((s, i) => {
        if (
          s.questions &&
          s.questions.length > 0 &&
          ((i >= 2 && i <= 4) || i === 6 || i === 14)
        ) {
          const title =
            i === 2
              ? 'SITUAÇAO'
              : i === 3
              ? 'PAN'
              : i === 4
              ? 'EMOÇAO'
              : i === 14
              ? 'PENSAMENTO_ALTERNATIVO'
              : 'COMPORTAMENTO'
          questions.push({...s.questions[0], title})
        }
      })

      return (
        <ResultRow gutter={[16, 16]}>
          {questions.map((q) => (
            <Col
              xs={24}
              sm={12}
              xl={8}
              xxl={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              <Title25
                color={SecondaryColour}
                style={{
                  whiteSpace: 'nowrap'
                }}
              >
                {translate(q.title)}
              </Title25>
              <Margin size={6} />
              <ResultText>{q.answer}</ResultText>
            </Col>
          ))}
        </ResultRow>
      )
    }}
  </FormSpy>
)

ResultComponent.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(withRouter(ResultComponent))

import React from 'react'

import {DownloadOutlined} from '@ant-design/icons'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import IconFile from '../../assets/icons/file-text.svg'
import HandleImageUrl from '../../infra/services/images/HandleImageUrl'
import {Download, getImage} from '../../infra/utils/CommonFunctions'
import {FileContainer} from '../../pages/booster_session/BoosterSessionStyles'
import {
  MainContainer,
  MainContainerBackgroundParent,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {Beige3} from '../../styles/Colours'
import {Body} from '../../styles/TextSizes'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'

const StepDocument = ({step, activeLanguage, history, nextStep, chapter}) => {
  const onBackStep = () => {
    history.goBack()
  }

  let file = null
  if (step[`file_${activeLanguage?.code}`]) file = step[`file_${activeLanguage?.code}`]
  else if (step.file_pt) file = step.file_pt

  return (
    <>
      <BasicHeaderComponent
        onClose={() => history.push('/educators_program')}
        title={chapter.title}
        progress={(step.order / chapter.steps.length) * 100}
      />
      <MainContainerBackgroundParent>
        <MainContainerScroll>
          <MainContainer>
            {step.descriptions.map((description, index) => (
              <div style={{marginBottom: 24}} key={index}>

                <MessageComponent
                  key={index}
                  text={description.title}
                  active={index === step.descriptions.length - 1}
                />
              </div>
            ))}

            {step.image && (
              <div style={{maxWidth: 800, margin: 'auto'}}>
                <img
                  style={{width: '100%'}}
                  src={HandleImageUrl(step.image.file)}
                />
              </div>
            )}

            <FileContainer key='file' style={{backgroundColor: 'white'}}>
              <img src={IconFile} alt='file' />
              <Body>{step?.title[activeLanguage.code]}</Body>
              <a
                download
                onClick={(e) =>
                  Download(
                    e,
                    getImage(file._id),
                    file.filename
                  )
                }
                style={{
                  marginLeft: 'auto'
                }}
              >
                 <DownloadOutlined
                   style={{
                    fontSize: 32,
                    background: Beige3,
                    borderRadius: '50%',
                    padding: 8
                  }}
                 />
              </a>
            </FileContainer>

          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackgroundParent>
      <BasicFooterComponent
        onBack={onBackStep}
        onNext={() =>
          nextStep
            ? history.push(`/step_educators/${nextStep._id}`)
            : history.push('/educators_program')
        }
      />
    </>
  )
}

StepDocument.propTypes = {
  step: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object
}

StepDocument.defaultProps = {
  nextStep: undefined,
  chapter: undefined
}
export default withLocalize(withRouter(StepDocument))

import styled from 'styled-components'

import {SecondaryColour, ThirdColour} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const ThermometerContainer = styled.div`
  display: flex;
  justify-content: center;
  height: calc(60% - 50px);
  margin-left: -38px;
  padding: 50px 0px 30px;
  min-height: 300px;

  .ant-slider-vertical {
    .ant-slider-rail {
      width: 38px;
      height: calc(100% + 30px);
      margin-bottom: 30px;
      margin-top: -13px;
      border-radius: 0px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background: #d7d7d7;
    }
    .ant-slider-handle {
      width: 38px;
      height: 38px;
      margin-left: 0px;
      background: ${ThirdColour};
      border-color: ${ThirdColour};
    }
    .ant-slider-dot {
      display: none;
      &:first-child {
        display: block;
        width: 75px;
        height: 75px;
        margin-bottom: -65px;
        margin-left: -21px;
        background: ${SecondaryColour};
        border-color: ${SecondaryColour};
        box-shadow: 0px 4px 5px 3px rgb(70 50 156 / 20%);
      }
    }
    .ant-slider-track {
      width: 38px;
      border-radius: 8px;
      background: ${SecondaryColour} !important;
      box-shadow: 0px 0px 5px 4px rgb(70 50 156 / 20%);
    }
    .ant-slider-step {
      width: 38px;
    }
    .ant-slider-mark {
      top: -6px;
      left: 42px;
    }
  }
  .ant-slider-disabled .ant-slider-dot {
    border-color: transparent !important;
  }

  @media ${device.tabletMin} {
    padding: 20px 0px 40px;
  }
`

import {
  SAVE_INFO,
  UPDATE_ACTIVITIES,
  UPDATE_PROGRAM,
  DELETE_PROGRAM,
  SAVE_PROGRAM,
  UPDATE_GOALS_PROGRAM,
  COMPLETE_CHAPTER
} from '../ActionsType'
import InitialState from '../InitialState'

const UpdateCompleteChapter = (state, idChapter) => {
  state.program.sessions.map((s, i) => {
    const chapterIndex = s.chapters.findIndex(c => c._id === idChapter)
    if (chapterIndex && chapterIndex > -1) {
      state.program.sessions[i].chapters[chapterIndex].completed = true

      const allChaptersCompleted = state.program.sessions[
        i
      ].chapters.filter(c => c.completed)
      if (
        allChaptersCompleted.length ===
        state.program.sessions[i].chapters.length
      ) {
        state.program.sessions[i].completed = true
      }
    }
  })

  return state
}

const UpdateActivities = (state, activities) => {
  activities.forEach(a => {
    if (
      a.activities.find(ac => ac.completed) &&
      !state.agenda.find(d => d === a.date)
    ) {
      state.agenda.push(a.date)
    }
  })

  return state
}

const UpdateRegistoObjetivos = (state, data) => {
  const sessionIndex = state.program.sessions.findIndex(
    a => a._id === data.session
  )
  const chapterIndex = state.program.sessions[
    sessionIndex
  ].chapters.findIndex(a => a._id === data.chapter)

  const newStep = state.program.sessions[sessionIndex].chapters[
    chapterIndex
  ].steps.find(a => a._id === data.step)
  newStep.completedWeek = true
  return state
}

export default function InfoReducer(state = InitialState.info, action) {
  switch (action.type) {
    case SAVE_INFO:
      return {
        ...action.info,
        profile_type: action.info.user ? action.info.user.type : undefined,
        ready: true
      }

    case SAVE_PROGRAM:
      return {...state, program: action.program}

    case UPDATE_ACTIVITIES:
      return UpdateActivities(state, action.activities)

    case UPDATE_PROGRAM:
      return {
        ...state,
        program: {...state.program, sessions: action.sessions}
      }

    case DELETE_PROGRAM:
      return {
        ...state,
        program: {}
      }

    case UPDATE_GOALS_PROGRAM:
      return UpdateRegistoObjetivos(state, action.data)

    case COMPLETE_CHAPTER:
      return UpdateCompleteChapter(state, action.idChapter)

    default:
      return state
  }
}

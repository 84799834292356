import React, {useEffect} from 'react'

import {Col, Row, Calendar} from 'antd'
import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {BaseButton} from '../../components/buttons/BaseButton'
import {isEnabledInProgram} from '../../infra/utils/CommonFunctions'
import {agendaAtividades} from '../../infra/utils/PagesNames'
import {Margin} from '../../styles/BasicStyles'
import {Beige3} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import {DashboardBox} from '../dashboard/DashboardStyles'
import {CalendarDone} from './ActivitiesStyles'

const getListData = (value, agenda) => {
  let listData
  if (
    agenda.find(
      a =>
        moment.utc(a).format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
    )
  ) {
    listData = true
  }

  return listData || false
}

const ActivitiesCalendarComponent = ({
  translate,
  activeLanguage,
  history
}) => {
  const {
    info: {agenda, program}
  } = useSelector(state => state)

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  const dateCellRender = value => {
    const listData = getListData(value, agenda)
    if (!listData) return null
    return <CalendarDone />
  }

  return (
    <DashboardBox
      column
      bgColor={Beige3}
      image={`url(${require('../../assets/backgrounds/background_atividades.svg')})`}
      style={{
        backgroundPosition: 'right bottom',
        backgroundSize: 'contain'
      }}
      tabletRemove
    >
      <Row>
        <Col xs={24} sm={12} md={16} style={{textAlign: 'left'}}>
          <TitleH3 weight={700}>
            {translate('AGENDA_SEMANAL_ATIVIDADES_LAZER')}
          </TitleH3>
        </Col>
        <Col xs={24} sm={12} md={8} style={{textAlign: 'right'}}>
          <BaseButton
            auto
            align='right'
            variant='raised'
            htmlType='button'
            type='primaryMedium'
            text={translate('COMPLETE')}
            onClick={() =>
              history.push(`/step/${agendaAtividades}`, {
                next: 'isBack',
                back: 'isBack',
                noProgram: true
              })
            }
            disabled={!isEnabledInProgram(program, agendaAtividades)}
          />
        </Col>
      </Row>
      <Margin size={24} />
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Calendar
            fullscreen={false}
            headerRender={() => null}
            locale={{
              lang: {
                locale: activeLanguage.code
              }
            }}
            dateCellRender={dateCellRender}
          />
        </Col>
      </Row>
    </DashboardBox>
  )
}

ActivitiesCalendarComponent.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withLocalize(withRouter(ActivitiesCalendarComponent))

import {Form, Card} from 'antd'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {TextColor, PrimaryColour} from '../../styles/Colours'
import {device} from '../../styles/Responsive'
import {TitleH3} from '../../styles/TextSizes'

export const LoginContainer = styled.div`
  display: flex;
  min-height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: url(${require('../../assets/backgrounds/background_login.png')});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media ${device.tablet} {
    background-position: inherit;
  }
`

export const LoginForm = styled(Form)`
  display: flex;
  justify-content: center;
`

export const LoginCard = styled(Card)`
  &&& {
    max-width: 593px;
    padding: 56px;
    display: inline-block;
    box-shadow: 0px 4px 4px rgba(219, 108, 28, 0.2);
    border-radius: 8px;

    & .ant-card-body {
      padding: 0;
    }

    @media ${device.mobileL} {
      padding: 32px 16px;
    }
  }
`

export const LogoSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
`

export const LogoImg = styled.img`
  width: 100%;
  max-width: 301px;
`

export const LoginButtonDiv = styled.div`
  text-align: center;
  margin-top: 24px;

  @media ${device.mobileL} {
    margin-top: 14px;
  }
`

export const FormTitle = styled(TitleH3)`
  color: ${TextColor};
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 8px;
  text-align: center;
`

export const FormSubTitle = styled.div`
  color: ${TextColor};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: ${(p) => (p.marginT ? p.marginT : 0)}px;

  & span {
    color: #ffbf00;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
`

export const BaseLink = styled(Link)`
  color: ${PrimaryColour};
  text-decoration: underline;
  display: inherit;
  cursor: pointer;
  &:hover {
    color: ${PrimaryColour};
  }
`

export const TemporaryContainer = styled.div`
  background-color: #faf9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 40px;
`

export const TemporaryText = styled.span`
  text-align: left;
  font-family: font-family: 'Abel',  'sans-serif';
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  div{
    margin-top: 24px;
  }
`

export const TermsLink = styled.div`
  margin-top: 10px;
  display: block;
  text-align: left;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #000000;
    opacity: 0.6;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`

import React, {useState} from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/illustrations/step1_1_1.png'
import {
  MainContainer,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {BaseButton} from '../buttons/BaseButton'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import ChatMessageComponent from '../message/ChatMessageComponent'
import MessageComponent from '../message/MessageComponent'
import {ButtonsContainer} from '../message/MessageStyles'

const Step1_1_1 = ({step, translate, history, nextStep, chapter}) => {
  // const [showNext, setShowNext] = useState(false)
  const [showNext, setShowNext] = useState(true)

  return (
    <>
      <BasicHeaderComponent
        onClose={() => history.push('/program')}
        title={chapter.title}
        progress={(step.order / chapter.steps.length) * 100}
      />
      <MainContainerBackground
        style={{
          backgroundImage: `url(${Illustration})`,
          backgroundPosition: 'left bottom',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <MainContainerScroll>
          <MainContainer >
            {/* {step.type === 'text' && (
              <ChatMessageComponent
                content={step.descriptions}
                onFinish={() => setShowNext(true)}
                userContent={
                  <ButtonsContainer>
                    <BaseButton
                      style={{marginRight: 8}}
                      align='right'
                      htmlType='button'
                      type='primaryMedium'
                      text={translate('VAMOS_LA')}
                      auto
                      onClick={() =>
                        nextStep
                          ? history.push(`/step/${nextStep._id}`)
                          : history.push('/program')
                      }
                    />
                    <BaseButton
                      style={{marginLeft: 8}}
                      align='right'
                      htmlType='button'
                      type='default'
                      auto
                      text={translate('VEJO_MAIS_TARDE')}
                      onClick={() => history.push('/program')}
                    />
                  </ButtonsContainer>
                }
              />
              // <>
              //   {step.descriptions.map((description, index) => (
              //     <MessageComponent
              //       key={index}
              //       text={description.title}
              //       active={index === 2}
              //     />
              //   ))}
              // </>
            )} */}
            {step.type === 'text' && (
              <>
                {step.descriptions.map((description, index) => (
                  <MessageComponent
                    key={index}
                    text={description.title}
                    active={index === 2}
                  />
                ))}
              </>
            )}
            <MessageComponent isUser active>
              <ButtonsContainer>
                <BaseButton
                  style={{marginRight: 8}}
                  align='right'
                  htmlType='button'
                  type='primaryMedium'
                  text={translate('VAMOS_LA')}
                  auto
                  onClick={() =>
                    nextStep
                      ? history.push(`/step/${nextStep._id}`)
                      : history.push('/program')
                  }
                />
                <BaseButton
                  style={{marginLeft: 8}}
                  align='right'
                  htmlType='button'
                  type='default'
                  auto
                  text={translate('VEJO_MAIS_TARDE')}
                  onClick={() => history.push('/program')}
                />
              </ButtonsContainer>
            </MessageComponent>
          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackground>
      <BasicFooterComponent
        // onRetry={!isFirstStep && !isFirstChapter && (() => {})}
        // onBack={!isFirstStep && !isFirstChapter && (() => {})}
        nextDisabled={!showNext}
        onNext={() =>
          nextStep
            ? history.push(`/step/${nextStep._id}`)
            : history.push('/program')
        }
      />
    </>
  )
}

Step1_1_1.propTypes = {
  step: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object
}

Step1_1_1.defaultProps = {
  nextStep: undefined,
  chapter: undefined
}
export default withLocalize(withRouter(Step1_1_1))

import React from 'react'

import {PropTypes} from 'prop-types'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_diario_meditacao.png'
import {
  MainContainer,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import {ThirdColour} from '../../styles/Colours'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'

const Step4_5_1 = ({step, history, nextStep, chapter, session}) => {
  const isMeditation =
    session.order === 4 && (chapter.order === 5 || chapter.order === 6)
  const progress =
    chapter.steps.length === step.order
      ? 100
      : (step.order / (chapter.steps.length + (isMeditation ? 6 : 0))) *
        100
  return (
    <>
      <BasicHeaderComponent
        onClose={() => history.push('/program')}
        title={chapter.title}
        progress={progress}
      />
      <MainContainerBackground
        bgColor={ThirdColour}
        style={{
          backgroundImage: `url(${Illustration})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      >
        <MainContainerScroll>
          <MainContainer>
            {step.type === 'text' && (
              <>
                {step.descriptions.map((description, index) => (
                  <MessageComponent
                    key={index}
                    text={description.title}
                    active={index === step.descriptions.length - 1}
                  />
                ))}
              </>
            )}
          </MainContainer>
        </MainContainerScroll>
      </MainContainerBackground>
      <BasicFooterComponent
        onNext={() =>
          nextStep
            ? history.push(`/step/${nextStep._id}`)
            : history.push('/program')
        }
      />
    </>
  )
}

Step4_5_1.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  session: PropTypes.object
}

Step4_5_1.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  session: undefined
}
export default withRouter(Step4_5_1)

import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'
import {withRouter} from 'react-router-dom'

import CloseIcon from '../../assets/icons/close.svg'
import IcCoroa from '../../assets/icons/ic_coroa.svg'
import MenuIcon from '../../assets/icons/menu_mobile.svg'
import IcMoeda from '../../assets/icons/moeda.svg'
import {
  programaEducadores,
  sessaoReforçoEducadores,
  programaJovens,
  sessaoReforçoJovens,
  sessaoExpressJovens,
  sessaoExpressEducadores
} from '../../infra/utils/PagesNames'
import {DeleteProgram} from '../../redux/Info/info.actions'
import {DeleteUser} from '../../redux/User/user.actions'
import {menuItems} from './HeaderOptions'
import {
  MobileMenuIcon,
  MobileMenuContainer,
  MobileMenuText,
  MobileMenuContent,
  MobileCloseContainer,
  IconsContainer,
  Icon,
  IconText,
  MobileMenuImg
} from './HeaderStyles'
import LanguageSelector from './LanguageSelector'

const MobileMenuComponent = ({
  showMobileMenu,
  toggleMobileMenu,
  history,
  translate,
  auth,
  isApplicator,
  applicatorProgram,
  user,
  showBoosterSession,
  showExpressSession,
  isStudent,
  setProgram,
  info
}) => {
  const dispatch = useDispatch()

  const Logout = () => {
    localStorage.clear()
    dispatch(DeleteUser())
    dispatch(DeleteProgram())
    history.push('/login')
  }

  const renderMenuApplicator = () => {
    switch (applicatorProgram) {
      case 2:
        return (
          <>
            <MobileMenuText
              className={
                history?.location?.pathname.indexOf(
                  'educators_program'
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                setProgram(
                  info.programs.find((p) => p._id === programaEducadores),
                  'educators_program'
                )
              }
            >
              {translate('PROGRAM')}
            </MobileMenuText>
            <MobileMenuText
              className={
                history?.location?.pathname.indexOf('booster_session') !==
                -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/booster_session/${sessaoReforçoEducadores}`)
              }
            >
              {translate('SESSAO_REFORÇO')}
            </MobileMenuText>
          </>
        )

      case 3:
        return (
          <>
            <MobileMenuText
              className={
                history?.location?.pathname.indexOf('youth_program') !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                setProgram(
                  info.programs.find((p) => p._id === programaJovens),
                  'youth_program'
                )
              }
            >
              {translate('PROGRAM_YOUTH')}
            </MobileMenuText>
            <MobileMenuText
              className={
                history?.location?.pathname.indexOf(
                  'educators_program'
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                setProgram(
                  info.programs.find((p) => p._id === programaEducadores),
                  'educators_program'
                )
              }
            >
              {translate('PROGRAM_EDUCATORS')}
            </MobileMenuText>
            <MobileMenuText
              className={
                history?.location?.pathname.indexOf(
                  `/booster_session/${sessaoReforçoJovens}`
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/booster_session/${sessaoReforçoJovens}`)
              }
            >
              {translate('SESSAO_REFORÇO_JOVENS')}
            </MobileMenuText>
            <MobileMenuText
              className={
                history?.location?.pathname.indexOf(
                  `/booster_session/${sessaoReforçoEducadores}`
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/booster_session/${sessaoReforçoEducadores}`)
              }
            >
              {translate('SESSAO_REFORÇO_EDUCADORES')}
            </MobileMenuText>
          </>
        )

      default:
        return (
          <>
            <MobileMenuText
              className={
                history?.location?.pathname.indexOf('youth_program') !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                setProgram(
                  info.programs.find((p) => p._id === programaJovens),
                  'youth_program'
                )
              }
            >
              {translate('PROGRAM_YOUTH')}
            </MobileMenuText>
            <MobileMenuText
              className={
                history?.location?.pathname.indexOf(
                  `/booster_session/${sessaoReforçoJovens}`
                ) !== -1
                  ? 'active'
                  : ''
              }
              onClick={() =>
                history.push(`/booster_session/${sessaoReforçoJovens}`)
              }
            >
              {translate('SESSAO_REFORÇO_JOVENS')}
            </MobileMenuText>
          </>
        )
    }
  }

  return (
    <>
      <MobileMenuIcon>
        <LanguageSelector />
        <MobileMenuImg onClick={() => toggleMobileMenu()}>
          <img src={MenuIcon} alt='menu' />
        </MobileMenuImg>
      </MobileMenuIcon>
      <MobileMenuContainer open={showMobileMenu}>
        <MobileCloseContainer>
          <MobileMenuImg onClick={() => toggleMobileMenu()}>
            <img src={CloseIcon} alt='close' />
          </MobileMenuImg>
        </MobileCloseContainer>
        <MobileMenuContent>
          {auth ? (
            <>
              <MobileMenuText
                className={
                  history?.location?.pathname.indexOf('login') !== -1
                    ? 'active'
                    : ''
                }
                onClick={() => history.push('/login')}
              >
                {translate('LOGIN')}
              </MobileMenuText>
              <MobileMenuText
                className={
                  history?.location?.pathname.indexOf('register') !== -1
                    ? 'active'
                    : ''
                }
                onClick={() => history.push('/register')}
              >
                {translate('REGISTO_HEADER')}
              </MobileMenuText>
            </>
          ) : (
            <>
              {!isApplicator ? (
                <>
                  {menuItems.map((item, index) => (
                    <MobileMenuText
                      key={index}
                      className={
                        history?.location?.pathname.indexOf(item.url) !==
                        -1
                          ? 'active'
                          : ''
                      }
                      onClick={() => history.push(item.url)}
                    >
                      {translate(item.name)}
                    </MobileMenuText>
                  ))}
                  {showBoosterSession && (
                    <MobileMenuText
                      className={
                        history?.location?.pathname.indexOf(
                          'booster_session'
                        ) !== -1
                          ? 'active'
                          : ''
                      }
                      onClick={() =>
                        history.push(
                          isStudent
                            ? `/booster_session/${sessaoReforçoJovens}`
                            : `/booster_session/${sessaoReforçoEducadores}`
                        )
                      }
                    >
                      {translate('SESSAO_REFORÇO')}
                    </MobileMenuText>
                  )}
                  {showExpressSession && (
                    <MobileMenuText
                      className={
                        history?.location?.pathname.indexOf(
                          'express_session'
                        ) !== -1
                          ? 'active'
                          : ''
                      }
                      onClick={() =>
                        history.push(
                          isStudent
                            ? `/express_session/${sessaoExpressJovens}`
                            : `/express_session/${sessaoExpressEducadores}`
                        )
                      }
                    >
                      {translate('SESSAO_EXPRESS')}
                    </MobileMenuText>
                  )}
                </>
              ) : (
                renderMenuApplicator()
              )}
              {!isApplicator && (
                <>
                  <MobileMenuText
                    className={
                      history?.location?.pathname.indexOf('profile') !== -1
                        ? 'active'
                        : ''
                    }
                    onClick={() => history.push('/profile')}
                  >
                    {translate('PERFIL')}
                  </MobileMenuText>
                  <MobileMenuText
                    className={
                      history?.location?.pathname.indexOf('help') !== -1
                        ? 'active'
                        : ''
                    }
                    onClick={() => history.push('/help')}
                  >
                    {translate('AJUDA_TECNICA')}
                  </MobileMenuText>
                </>
              )}
              <MobileMenuText onClick={Logout}>
                {translate('LOGOUT')}
              </MobileMenuText>
              {isStudent && (
                <>
                  <IconsContainer onClick={() => history.push('/profile')}>
                    <Icon
                      src={IcMoeda}
                      onClick={() => history.push('/')}
                    />
                    <IconText color='#FFBF00'>{user.points}</IconText>
                  </IconsContainer>
                  <IconsContainer onClick={() => history.push('/profile')}>
                    <Icon
                      src={IcCoroa}
                      onClick={() => history.push('/')}
                    />
                    <IconText color='#F38231'>{user.totalCrowns}</IconText>
                  </IconsContainer>
                </>
              )}
            </>
          )}
        </MobileMenuContent>
      </MobileMenuContainer>
    </>
  )
}

MobileMenuComponent.propTypes = {
  showMobileMenu: PropTypes.bool,
  toggleMobileMenu: PropTypes.func,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.bool,
  isApplicator: PropTypes.bool,
  applicatorProgram: PropTypes.number,
  user: PropTypes.object,
  showBoosterSession: PropTypes.bool,
  showExpressSession: PropTypes.bool,
  isStudent: PropTypes.bool,
  setProgram: PropTypes.func,
  info: PropTypes.object
}

MobileMenuComponent.defaultProps = {
  showMobileMenu: false,
  toggleMobileMenu: () => {},
  auth: false,
  isApplicator: false,
  applicatorProgram: false,
  user: undefined,
  showBoosterSession: false,
  showExpressSession: false,
  isStudent: false,
  setProgram: () => {},
  info: undefined
}

export default withLocalize(withRouter(MobileMenuComponent))

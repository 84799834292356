import React, {useState, useEffect} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {VictoryChart, VictoryLine, VictoryAxis} from 'victory'

import {GetChartActivities} from '../../infra/requests/ProgramRequests'
import {Margin} from '../../styles/BasicStyles'
import {
  MenuBorderColor,
  HoverBorderColor,
  SecondaryColour,
  ThirdColour
} from '../../styles/Colours'
import {BodyText, QuestionText} from '../../styles/TextSizes'
import {
  ChartBox,
  LegendLine,
  BoxColor,
  LegendContainer,
  ChartBoxInfo,
  ChartCol,
  InfoTop,
  ChartRow,
  LegendMeditationContainer,
  ChartTitle
} from './ChartsStyles'

const daysOfWeek = [
  'SEGUNDA',
  'TERÇA',
  'QUARTA',
  'QUINTA',
  'SEXTA',
  'SABADO',
  'DOMINGO'
]

const initialData = [
  [
    {x: 1, y: 0},
    {x: 2, y: 0},
    {x: 3, y: 0},
    {x: 4, y: 0},
    {x: 5, y: 0},
    {x: 6, y: 0},
    {x: 7, y: 0}
  ],
  [
    {x: 1, y: 0},
    {x: 2, y: 0},
    {x: 3, y: 0},
    {x: 4, y: 0},
    {x: 5, y: 0},
    {x: 6, y: 0},
    {x: 7, y: 0}
  ]
]

const dataStatic = [
  [
    {x: 1, y: 3},
    {x: 2, y: 2},
    {x: 3, y: 0},
    {x: 4, y: 2},
    {x: 5, y: 3},
    {x: 6, y: 7},
    {x: 7, y: 2}
  ],
  [
    {x: 1, y: 60},
    {x: 2, y: 40},
    {x: 3, y: 20},
    {x: 4, y: 40},
    {x: 5, y: 55},
    {x: 6, y: 90},
    {x: 7, y: 23}
  ]
]
const xOffsets = [50, 400]
const tickValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
const tickPadding = [0, -35]
const anchors = ['end', 'end', 'start']

const ChartComponent = ({
  isStatic,
  isAuto,
  previewChart,
  week,
  translate
}) => {
  const user = useSelector((state) => state.user)
  const isEducator = user.type === 3
  const colors = isEducator
    ? [SecondaryColour]
    : [ThirdColour, SecondaryColour]
  const [data, setData] = useState(isStatic ? dataStatic : initialData)
  const [maxima, setMaxima] = useState(
    dataStatic.map((dataset, i) => {
      const m = Math.max(...dataset.map((d) => d.y))
      let max = m
      if (i === 0) max = Math.max(m, 10)
      else max = Math.max(m, 100)
      return max
    })
  )
  const [averageA, setAverageA] = useState(
    isStatic
      ? dataStatic[0]
          .reduce(
            (total, value) => total + value.y / dataStatic[0].length,
            0
          )
          .toFixed(1)
      : 0
  )
  const [averageB, setAverageB] = useState(
    isStatic
      ? dataStatic[1]
          .reduce(
            (total, value) => total + value.y / dataStatic[1].length,
            0
          )
          .toFixed(1)
      : 0
  )

  useEffect(() => {
    async function getData() {
      let date = moment.utc()
      if (week) date = week
      const dateWeekStart = moment
        .utc(date)
        .clone()
        .startOf('isoWeek')
        .format('YYYY-MM-DD')
      const dateWeekEnd = moment
        .utc(date)
        .clone()
        .endOf('isoWeek')
        .format('YYYY-MM-DD')
      const result = await GetChartActivities(dateWeekStart, dateWeekEnd)

      if (result.success) {
        setAverageA(isEducator ? undefined : result.data.mediaActivities)
        setAverageB(result.data.mediaEmotions)
        const newDataA = []
        const newDataB = []
        result.data.values.forEach((value, i) => {
          newDataA.push({x: i + 1, y: value.activities})
          newDataB.push({x: i + 1, y: value.emotions})
        })
        if (isEducator) {
          setData([newDataB])
          const mx = [newDataB].map((dataset, i) => {
            const m = Math.max(...dataset.map((d) => d.y))
            let max = m
            if (i === 0) max = Math.max(m, 10)
            else max = Math.max(m, 100)
            return max
          })
          setMaxima(mx)
        } else {
          setData([newDataA, newDataB])
          const mx = [newDataA, newDataB].map((dataset, i) => {
            const m = Math.max(...dataset.map((d) => d.y))
            let max = m
            if (i === 0) max = Math.max(m, 10)
            else max = Math.max(m, 100)
            return max
          })
          setMaxima(mx)
        }
      }
    }
    if (!isStatic) getData()
  }, [isStatic, week, isEducator])

  const renderChart = () => (
    <VictoryChart
      style={{width: '100%', height: 375}}
      domain={{y: [0, 1], x: [0, 8]}}
    >
      <VictoryAxis
        style={{
          axis: {stroke: MenuBorderColor},
          tickLabels: {
            fill: HoverBorderColor,
            fontSize: 14,
            fontFamily: 'Poppins'
          },
          grid: {stroke: MenuBorderColor}
        }}
        tickFormat={(value) =>
          value
            ? value === 0 || value === 8
              ? ''
              : translate(daysOfWeek[value - 1]).slice(0, 3)
            : undefined
        }
        tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
      />
      {data?.map((d, i) => (
        <VictoryAxis
          dependentAxis
          key={i}
          offsetX={xOffsets[i]}
          style={{
            axis: {stroke: MenuBorderColor},
            ticks: {padding: tickPadding[i]},
            tickLabels: {
              fill: HoverBorderColor,
              textAnchor: anchors[i],
              fontSize: 14,
              fontFamily: 'Poppins'
            },
            grid: {stroke: MenuBorderColor}
          }}
          // Use normalized tickValues (0 - 1)
          tickValues={tickValues * maxima[i]}
          // Re-scale ticks by multiplying by correct maxima
          tickFormat={(t) => t * maxima[i]}
        />
      ))}
      {data?.map((d, i) => (
        <VictoryLine
          key={i}
          data={d}
          style={{data: {stroke: colors[i]}}}
          y={(datum) => datum.y / maxima[i]}
        />
      ))}
    </VictoryChart>
  )

  return (
    <ChartBox
      isStatic={isStatic}
      isAuto={isAuto}
      borderColor={previewChart ? 'transparent' : undefined}
    >
      <ChartRow gutter={[16, 16]}>
        {previewChart ? (
          <>
            <ChartTitle margin={-44}>
              {translate(
                isEducator
                  ? 'GRAFICO_DIARIO_HUMOR'
                  : 'GRAFICO_ATIVIDADES_VS_DIARIO_HUMOR'
              )}
            </ChartTitle>
            {renderChart()}
            <LegendMeditationContainer style={{marginTop: -24}}>
              <Row gutter={[16]}>
                {!isEducator && (
                  <Col sm={24} md={12}>
                    <LegendLine>
                      <BoxColor color={ThirdColour} />
                      <BodyText>
                        {translate('NUMERO_DE_ATIVIDADES_LAZER')}
                      </BodyText>
                    </LegendLine>
                  </Col>
                )}
                <Col sm={24} md={12}>
                  <LegendLine>
                    <BoxColor />
                    <BodyText>
                      {translate(
                        isEducator
                          ? 'INTENSIDADE_DAS_TUAS_EMOÇOES_EDUCADOR'
                          : 'INTENSIDADE_DAS_TUAS_EMOÇOES'
                      )}
                    </BodyText>
                  </LegendLine>
                </Col>
              </Row>
            </LegendMeditationContainer>
          </>
        ) : (
          <>
            <Col sm={24} md={16} lg={18}>
              {renderChart()}
            </Col>
            <ChartCol sm={24} md={8} lg={6}>
              <InfoTop>
                {!isEducator && (
                  <ChartBoxInfo isLeft>
                    <BodyText>{translate('MEDIA_ATIVIDADES')}</BodyText>
                    <QuestionText color={ThirdColour}>
                      {averageA}
                    </QuestionText>
                  </ChartBoxInfo>
                )}
                <ChartBoxInfo>
                  <BodyText>{translate('MEDIA_EMOÇOES')}</BodyText>
                  <QuestionText color={SecondaryColour}>
                    {averageB}
                  </QuestionText>
                </ChartBoxInfo>
              </InfoTop>
              <LegendContainer>
                {!isEducator && (
                  <>
                    <LegendLine>
                      <BoxColor color={ThirdColour} />
                      <BodyText>
                        {translate('NUMERO_DE_ATIVIDADES_LAZER')}
                      </BodyText>
                    </LegendLine>
                    <Margin size={8} />
                  </>
                )}
                <LegendLine>
                  <BoxColor />
                  <BodyText>
                    {translate(
                      isEducator
                        ? 'INTENSIDADE_DAS_TUAS_EMOÇOES_EDUCADOR'
                        : 'INTENSIDADE_DAS_TUAS_EMOÇOES'
                    )}
                  </BodyText>
                </LegendLine>
              </LegendContainer>
            </ChartCol>
          </>
        )}
      </ChartRow>
    </ChartBox>
  )
}

ChartComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  isStatic: PropTypes.bool,
  isAuto: PropTypes.bool,
  previewChart: PropTypes.bool,
  week: PropTypes.object
}

ChartComponent.defaultProps = {
  isStatic: false,
  isAuto: false,
  previewChart: false,
  week: undefined
}

export default withLocalize(withRouter(ChartComponent))

import React from 'react'

import * as loadash from 'lodash'
import {connect} from 'react-redux'
import {Redirect, Route} from 'react-router-dom'

import Layout from '../../components/layout/Layout'
import LoadingComponent from '../../components/loading/LoadingComponent'

const userPermission = (user, permission) => {
  if (!permission) return true

  return permission.find(p => p === user.type)
}

const AuthenticatedRoute = ({
  user,
  component: Component,
  props: cProps,
  ready,
  basicHeader,
  permission,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (!ready) return <LoadingComponent />
      if (!loadash.isEmpty(user)) {
        if (!userPermission(user, permission)) {
          return <Redirect to='/' />
        }

        return (
          <Layout {...props} {...cProps} basicHeader={basicHeader}>
            <Component {...props} {...cProps} />
          </Layout>
        )
      }
      return <Redirect to='/login' />
    }}
  />
)

const mapStateToProps = state => ({
  user: state.user,
  ready: state.info.ready
})

export default connect(mapStateToProps)(AuthenticatedRoute)

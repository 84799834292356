import moment from 'moment'

import 'moment/locale/pt'
import {renderToStaticMarkup} from 'react-dom/server'
import {initialize, addTranslationForLanguage} from 'react-localize-redux'

import {GetInfo, GetTranslations} from '../../infra/requests/BaseRequests'
import {SaveInfo} from '../Info/info.actions'
import {SaveUser} from '../User/user.actions'

const DefineLanguage = (languages, user_lang, default_language) => {
  if(user_lang && languages.find(x => x === user_lang)) {
    localStorage.setItem("LANG_COOKIE", user_lang);
    return user_lang;
  }

  const cookieLang = localStorage.getItem("LANG_COOKIE");
  if (cookieLang && languages.find(x => x === cookieLang)) return cookieLang;

  localStorage.setItem("LANG_COOKIE", default_language);
  return default_language;
}

export default function start() {
  return async (dispatch) => {
    try {
      const info = await GetInfo()
      const {data} = await GetTranslations()

      // dispatch(SaveUser(info?.data?.user))

      const languages = info?.data?.languages || []
      const defaultLanguage = DefineLanguage(
        languages,
        info?.data?.user?.language,
        info?.data?.default_language || 'pt'
      )

      moment.updateLocale(defaultLanguage, {
        week: {
          dow: 1 // Monday is the first day of the week.
        }
      })
      moment.locale(defaultLanguage)

      dispatch(
        initialize({
          languages,
          translation: [],
          options: {
            renderToStaticMarkup,
            defaultLanguage
          }
        })
      )

      languages.map((code) =>
        dispatch(addTranslationForLanguage(data[code], code))
      )

      if (info?.data?.user) dispatch(SaveUser({...info.data.user}))
      dispatch(SaveInfo(info?.data))
    } catch (e) {
      console.warn(e)
    }
  }
}

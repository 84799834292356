import {Col} from 'antd'
import styled from 'styled-components'

import {
  InactiveTextColor,
  MenuBorderColor,
  SecondaryColour,
  TextColor,
  White,
  Beige
} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const SessionContainer = styled.div`
  position: relative;
  padding: 16px;
  margin-bottom: ${({noMarginBottom}) => (noMarginBottom ? -1 : 48)}px;
  margin-left: ${(p) => (p.marginL ? 417 : 77)}px;
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  cursor: ${({active}) => (active ? 'pointer' : 'auto')};
  border-bottom: ${({isOpen}) => (isOpen ? '0px' : '2px')};
  border-bottom-left-radius: ${({isOpen}) => (isOpen ? '0px' : '8px')};
  border-bottom-right-radius: ${({isOpen}) => (isOpen ? '0px' : '8px')};

  @media ${device.laptop} {
    margin-left: 0px;
    width: 100%;
  }
`

export const SessionTitle = styled.div`
  color: ${(p) => (p.inactive ? InactiveTextColor : TextColor)};
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
`

export const CompleteIcon = styled.div`
  position: absolute;
  background-color: #59b122;
  border-radius: 50px;
  width: 26px;
  height: 26px;
  border: 2px solid #ffffff;
  top: ${(p) => (p.top ? p.top : 0)};
  right: ${(p) => (p.right ? p.right : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CompleteBar = styled.div`
  width: 81px;
  height: 10px;
  display: flex;
  border-radius: 10px;
  background-color: #ffffff;
  border: 2px solid ${SecondaryColour};
  margin-right: 32px;
`

export const CompleteBarColor = styled.div`
  width: ${(p) => (p.width ? p.width : '0')}%;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  border-radius: 10px;
`

export const SessionInner = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tabletMin} {
    display: block;
  }
`

export const SessionDetail = styled.div`
  margin-left: 77px;
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  background-color: ${Beige};
  border-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  @media ${device.laptop} {
    margin-left: 0px;
    width: 100%;
  }
`

export const SessionInnerDetail = styled.div`
  padding: ${({isEducator}) => isEducator ? '0px 62px 0px 40px' : '16px 62px 0px 40px'};

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    padding: 16px 16px 0px 16px;
    padding: ${({isEducator}) => isEducator ? '0px 16px 0px 16px' : '16px 16px 0px 16px'};
  }
`

export const SessionSubDetail = styled.div`
  padding: 16px 62px 16px 40px;
  border-top: 2px solid ${MenuBorderColor};
  background-color: ${Beige};

  @media ${device.laptop} {
    margin-left: 0px;
    width: 100%;
  }

  @media ${device.tablet} {
    padding: 16px 16px 16px 16px;
  }
`

export const SessionChapter = styled.div`
  padding: 16px
  background-color: ${White};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  margin-bottom: 16px;
  text-align: left;
  position: relative;
  cursor: ${({active}) => (active ? 'pointer' : 'auto')};
`

export const TableCol = styled(Col)`
  margin-top: ${({isSession1}) => (isSession1 ? '0px' : '50px')};

  @media ${device.tabletMin} {
    margin-top: ${({isSession1}) => (isSession1 ? '0px' : '30px')};
  }
`

import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'

import IcCoroa from '../../assets/icons/ic_coroa.svg'
import IcMoeda from '../../assets/icons/moeda.svg'
import {LineSeparator} from '../../components/charts/ChartsStyles'
import {GetJewelByTag, GetUser} from '../../infra/requests/ProfileRequests'
import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {SaveUser} from '../../redux/User/user.actions'
import {
  SecondaryColour,
  PrimaryColour,
  LightGrey
} from '../../styles/Colours'
import {QuestionText, Body} from '../../styles/TextSizes'
import {TitleDashboard, DashboardBox} from '../dashboard/DashboardStyles'
import {
  TotalPoints,
  Inline,
  JewelContainer,
  JewelBox,
  InfoBox,
  CompleteBar,
  CompleteBarColor
} from './ProfileStyles'

const ScoreComponent = ({translate, activeLanguage}) => {
  const dispatch = useDispatch()
  const {user, info} = useSelector((state) => state)
  const [jewelProgress, setJewelProgress] = useState(0)
  const [jewel, setJewel] = useState(undefined)

  const jewelNumber = user.crown < 12 ? user.crown + 1 : 1

  useEffect(() => {
    async function getJewel(u) {
      const result = await GetJewelByTag(u.crown + 1)
      if (result.success) {
        setJewel(result.data)
        const progress = (u.crown_level * 100) / result.data.points
        setJewelProgress(progress)
      }
    }

    async function getUser() {
      const result = await GetUser()
      if (result.success) {
        dispatch(SaveUser(result.data))
      }
      getJewel(result.data)
    }

    getUser()
  }, [dispatch])

  return (
    <>
      <TitleDashboard weight='bold' marginB={16}>
        {translate('CONQUISTAS')}
      </TitleDashboard>
      <DashboardBox column style={{flex: 1}}>
        <Row gutter={[24]}>
          <Col xs={24} lg={16} xl={18}>
            <img
              alt='Coroa'
              src={require(`../../assets/score/crowns/${user.crown ||
                0}.svg`)}
              style={{width: '100%'}}
            />
          </Col>
          <Col xs={24} lg={8} xl={6}>
            <TotalPoints>
              <Inline>
                <img alt='Coroa' src={IcCoroa} style={{marginRight: 8}} />
                <Body weight='bold' color={SecondaryColour}>
                  x{user.totalCrowns}
                </Body>
              </Inline>
              <Inline>
                <img alt='Coroa' src={IcMoeda} style={{marginRight: 8}} />
                <Body weight='bold' color={PrimaryColour}>
                  x{user.points}
                </Body>
              </Inline>
            </TotalPoints>
          </Col>
        </Row>
        <Body>
          {translate('DESCRICAO_PONTUACAO_COROAS', {
            pontos: info.crownPoints
          })}
        </Body>
        <LineSeparator style={{margin: '0px 0px 24px', width: '100%'}} />
        <JewelContainer>
          <JewelBox>
            <img
              style={{maxWidth: 82, maxHeight: 72}}
              alt='Coroa'
              src={require(`../../assets/score/jewels/${jewelNumber}.svg`)}
            />
          </JewelBox>
          <InfoBox>
            <QuestionText>
              {GetValueLanguage(jewel?.title, activeLanguage?.code)}
            </QuestionText>
            <Body color={LightGrey}>
              {user.crown_level} / {jewel?.points}
            </Body>
            <CompleteBar>
              <CompleteBarColor
                width={`${jewelProgress}%`}
                bgColor={SecondaryColour}
              />
            </CompleteBar>
          </InfoBox>
        </JewelContainer>
      </DashboardBox>
    </>
  )
}

ScoreComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(ScoreComponent)

import React, {useState} from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {QuestionText} from '../../styles/TextSizes'
import {
  OptionsContainer,
  IntroductionBlock,
  QuestionTitle2
} from '../jogo_espirais/JogoEspiraisStyles'
import {QuestionOption} from '../question/QuestionStyles'

const Question2 = ({step, activeLanguage}) => {
  const [animation, setAnimation] = useState(undefined)

  const onSelectOption = (fields, idOption, form, name) => {
    const selected = fields || []
    const exist = selected.findIndex((id) => idOption === id)
    let changes = [...selected]
    if (exist >= 0) {
      changes = fields.filter((id) => idOption !== id)
    } else {
      changes.push(idOption)
      setAnimation(idOption)
    }
    form.change(name, changes)
  }

  const optionsArray = (input) => {
    const {options, form, fields} = input
    const currentOptions = fields.value || []
    return (
      <>
        {options.map((option, index) => (
          <Col xs={24} md={12} lg={8} key={index}>
            <QuestionOption
              className={animation === option._id ? 'animated' : ''}
              onClick={() =>
                onSelectOption(
                  currentOptions,
                  option._id,
                  form,
                  fields.name
                )
              }
              onAnimationEnd={() => setAnimation(undefined)}
              isActive={
                currentOptions.findIndex((a) => option._id === a) >= 0
              }
              style={{marginBottom: 0, height: '100%', padding: 16}}
            >
              <QuestionText isBold>
                {GetValueLanguage(option.title, activeLanguage?.code)}
              </QuestionText>
            </QuestionOption>
          </Col>
        ))}
      </>
    )
  }

  const title = GetValueLanguage(step.title, activeLanguage?.code)
  return (
    <FormSpy subscription={{values: true}}>
      {({values, form}) => (
        <>
          {title && (
            <IntroductionBlock>
              <QuestionText isBold>{title}</QuestionText>
              {/* <img src={require(`../../assets/5.5/${step.order}.svg`)} /> */}
            </IntroductionBlock>
          )}
          <FieldArray name={`steps[${step.order - 1}].questions`}>
            {() =>
              step.questions.map((question, i) => (
                <div key={i}>
                  <QuestionTitle2>
                    {GetValueLanguage(
                      question.title,
                      activeLanguage?.code
                    )}
                  </QuestionTitle2>
                  <OptionsContainer gutter={[16, 16]}>
                    <FieldArray
                      name={`steps[${step.order -
                        1}].questions[${i}].options`}
                      component={optionsArray}
                      options={question.options}
                      form={form}
                    />
                  </OptionsContainer>
                </div>
              ))
            }
          </FieldArray>
        </>
      )}
    </FormSpy>
  )
}

Question2.propTypes = {
  step: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(withRouter(Question2))

import styled from 'styled-components'

import {MenuBorderColor, ActiveBorderColor} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const CalendarDone = styled.div`
  width: 34px;
  height: 34px;
  background: ${ActiveBorderColor};
  position: absolute;
  top: -3px;
  left: -5px;
  z-index: -1;
  border-radius: 50%;

  @media ${device.tablet} {
    width: 30px;
    height: 30px;
    top: -6px;
    left: -3px;
  }
`

export const DashboardBox = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: ${(p) => (p.column ? 'column' : 'row')};
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  justify-content: space-between;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  background-image: ${(p) => (p.image ? p.image : null)};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media ${device.laptop} {
    flex-direction: column;
  }
`

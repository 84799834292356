import styled from 'styled-components'

import {SecondaryColour, ThirdColour} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Title = styled.div`
  color: ${SecondaryColour};
  font-size: 250px;
  font-weight: 700;
  line-height: 285px;

  @media ${device.laptopL} {
    font-size: 200px;
    line-height: 185px;
  }

  @media ${device.laptop} {
    font-size: 150px;
    line-height: 170px;
  }

  @media ${device.tablet} {
    font-size: 100px;
    line-height: 100px;
  }

  @media ${device.tabletS} {
    font-size: 80px;
    line-height: 80px;
  }
`

export const Subtitle = styled.div`
  color: ${ThirdColour};
  font-size: 34px;
  font-weight: 700;
  line-height: 51px;
  white-space: nowrap;

  @media ${device.laptop} {
    font-size: 30px;
    line-height: 36px;
  }

  @media ${device.tablet} {
    font-size: 22px;
    line-height: 30px;
  }

  @media ${device.tabletS} {
    font-size: 18px;
    line-height: 25px;
  }
`

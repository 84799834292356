import React, {useState, useEffect} from 'react'

import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import EyeOff from '../../assets/icons/eye-off.svg'
import Eye from '../../assets/icons/eye.svg'
import {BaseButton} from '../../components/buttons/BaseButton'
import {MaterialErrorLabel} from '../../components/inputs/MaterialStyles'
import TextInput from '../../components/inputs/TextInput'
import Layout from '../../components/layout/Layout'
import {auth_token_key} from '../../infra/config/LocalStorageKeys'
import {Login} from '../../infra/requests/AuthRequests'
import {GetInfo} from '../../infra/requests/BaseRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {SaveProgram, SaveInfo} from '../../redux/Info/info.actions'
import {SaveUser} from '../../redux/User/user.actions'
import {PageForm} from '../../styles/BasicStyles'
import {PrimaryColour} from '../../styles/Colours'
import {Body} from '../../styles/TextSizes'
import {
  LoginContainer,
  LoginCard,
  LogoSection,
  LoginButtonDiv,
  FormTitle,
  FormSubTitle,
  BaseLink
} from './LoginPageStyles'

const formRules = FormValidator.make({
  email: 'required|email',
  password: 'required|min:6'
})

const LoginPage = ({translate, history, dispatch}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(false)

  const onSubmit = async (values) => {
    try {
      const result = await Login(values)

      if (result.success) {
        localStorage.setItem(auth_token_key, result.data.token)
        // dispatch(SaveUser(result.data.user))
        // dispatch(SaveProgram(result.data.program))
        const info = await GetInfo()
        if (info.success) {
          dispatch(SaveInfo(info?.data))
          dispatch(SaveUser(info?.data.user))
          history.push('/')
        }
      } else {
        setError(
          result.message
            ? translate(result.message.toUpperCase())
            : translate('AN_ERROR_OCCURRED')
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <Layout fixed auth>
      <LoginContainer>
        <Form onSubmit={onSubmit} validate={formRules}>
          {({handleSubmit, valid, submitting}) => (
            <PageForm onSubmit={handleSubmit}>
              <LoginCard>
                <LogoSection>
                  <FormTitle>{translate('LOGIN_TITLE')}</FormTitle>
                  <Body>
                    {translate('LOGIN_SUBTITLE')}{' '}
                    <Body color={PrimaryColour}>
                      <BaseLink to='/register'>
                        {translate('LOGIN_SUBSUBTITLE')}
                      </BaseLink>
                    </Body>
                  </Body>
                </LogoSection>
                <Field
                  component={TextInput}
                  name='email'
                  typeText='email'
                  label={translate('EMAIL')}
                />
                <Field
                  component={TextInput}
                  name='password'
                  typeText={showPassword ? 'text' : 'password'}
                  label={translate('PASSWORD')}
                  suffix={
                    <img
                      src={showPassword ? EyeOff : Eye}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  }
                />
                {error && <MaterialErrorLabel>{error}</MaterialErrorLabel>}
                <LoginButtonDiv>
                  <BaseButton
                    align='initial'
                    variant='raised'
                    htmlType='submit'
                    type='primaryLarge'
                    loading={submitting}
                    disabled={!valid}
                    text={translate('LOGIN')}
                  />
                  <FormSubTitle marginT={16}>
                    <span onClick={() => history.push('/recover-account')}>
                      {translate('LOGIN_FORGET')}
                    </span>
                  </FormSubTitle>
                </LoginButtonDiv>
              </LoginCard>
            </PageForm>
          )}
        </Form>
      </LoginContainer>
    </Layout>
  )
}

LoginPage.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(() => ({}), mapActionToProps)(LoginPage)
)

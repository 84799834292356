import React, {useState} from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import ChartComponent from '../charts/ChartComponent'
import {
  OptionsContainer,
  IntroductionBlock,
  QuestionTitle
} from '../jogo_espirais/JogoEspiraisStyles'
import {QuestionOption, OptionText} from '../question/QuestionStyles'

const QuestionChart = ({step, activeLanguage}) => {
  const [animation, setAnimation] = useState(undefined)

  const onSelectOption = (idOption, form, name) => {
    form.change(name, idOption)
  }

  const optionsArray = (input) => {
    const {options, form, fields} = input
    const currentOption = fields.value || []
    return (
      <>
        {options.map((option, index) => (
          <Col xs={24} sm={12} key={index}>
            <QuestionOption
              className={animation === index ? 'animated' : ''}
              isAuto
              onClick={() => {
                onSelectOption(option._id, form, fields.name)
                setAnimation(index)
              }}
              onAnimationEnd={() => setAnimation(undefined)}
              isActive={currentOption === option._id}
              style={{marginBottom: 0, justifyContent: 'center'}}
            >
              <OptionText>
                {GetValueLanguage(option.title, activeLanguage?.code)}
              </OptionText>
            </QuestionOption>
          </Col>
        ))}
      </>
    )
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({values, form}) => (
        <>
          <IntroductionBlock>
            <ChartComponent isAuto />
          </IntroductionBlock>
          <FieldArray name={`steps[${step.order - 1}].questions`}>
            {({fields}) =>
              step.questions.map((question, i) => (
                <div key={i}>
                  <QuestionTitle>
                    {GetValueLanguage(
                      question.title,
                      activeLanguage?.code
                    )}
                  </QuestionTitle>
                  <OptionsContainer gutter={[16, 16]}>
                    <FieldArray
                      name={`${fields.name}[${i}].options`}
                      component={optionsArray}
                      options={question.options}
                      form={form}
                    />
                  </OptionsContainer>
                </div>
              ))
            }
          </FieldArray>
        </>
      )}
    </FormSpy>
  )
}

QuestionChart.propTypes = {
  step: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(withRouter(QuestionChart))

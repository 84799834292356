import DefinePasswordPage from '../../pages/auth/DefinePasswordPage'
import LoginPage from '../../pages/auth/LoginPage'
import RecoverAccountPage from '../../pages/auth/RecoverAccountPage'
import RegisterPage from '../../pages/auth/RegisterPage'
import ResetPasswordPage from '../../pages/auth/ResetPasswordPage'

export default [
  {
    path: '/login',
    component: LoginPage,
    isAuthenticated: false
  },
  {
    path: '/register',
    component: RegisterPage,
    isAuthenticated: false
  },
  {
    path: '/recover-account',
    component: RecoverAccountPage,
    isAuthenticated: false
  },
  {
    path: '/recover-password',
    component: ResetPasswordPage,
    isAuthenticated: false
  },
  {
    path: '/define-password',
    component: DefinePasswordPage,
    isAuthenticated: false
  }
]

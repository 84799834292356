import React from 'react'

import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'

import {GetStepName} from '../../infra/utils/CommonFunctions'
import QuizEmoçoes from './QuizEmoçoes'
import QuizEmoçoesAtividades from './QuizEmoçoesAtividades'
import QuizEscutaAtiva from './QuizEscutaAtiva'
import QuizExpressarSentimentos from './QuizExpressarSentimentos'
import QuizPan from './QuizPan'
import QuizPensamentoEmoçaoComportamento from './QuizPensamentoEmoçaoComportamento'
import QuizPensamentoRealista from './QuizPensamentoRealista'
import QuizPensamentosEmoçoes from './QuizPensamentosEmoçoes'

const components = {
  QuizEmoçoes,
  QuizEmoçoesAtividades,
  QuizPensamentosEmoçoes,
  QuizPensamentoEmoçaoComportamento,
  QuizPan,
  QuizPensamentoRealista,
  QuizEscutaAtiva,
  QuizExpressarSentimentos
}

class QuizComponent extends React.Component {
  renderContent = () => {
    const {step, nextStep, chapter, session, updateChapter, info} = this.props
    const stepName = GetStepName(info.program)

    let MyComponent = false
    if (stepName === 'step_educators') {
      if (session.order === 4 && chapter.order === 5) {
        MyComponent = components.QuizPensamentoEmoçaoComportamento
      }
    } else {
      if (session.order === 2 && chapter.order === 4) {
        MyComponent = components.QuizEmoçoes
      }
      if (session.order === 5 && chapter.order === 6) {
        MyComponent = components.QuizEmoçoesAtividades
      }
      if (session.order === 6 && chapter.order === 4) {
        MyComponent = components.QuizPensamentosEmoçoes
      }
      if (session.order === 7 && chapter.order === 4) {
        MyComponent = components.QuizPensamentoEmoçaoComportamento
      }
      if (session.order === 7 && chapter.order === 5) {
        MyComponent = components.QuizPan
      }
      if (session.order === 7 && chapter.order === 6) {
        MyComponent = components.QuizPensamentoRealista
      }
      if (session.order === 9 && chapter.order === 4) {
        MyComponent = components.QuizEscutaAtiva
      }
      if (session.order === 9 && chapter.order === 5) {
        MyComponent = components.QuizExpressarSentimentos
      }
    }

    if (MyComponent) {
      return (
        <MyComponent
          key={Math.random()}
          step={step}
          nextStep={nextStep}
          chapter={chapter}
          session={session}
          updateChapter={updateChapter}
          isOpen={stepName === 'step_educators'}
        />
      )
    }
    return null
  }

  render() {
    return this.renderContent()
  }
}

QuizComponent.propTypes = {
  step: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  session: PropTypes.object,
  updateChapter: PropTypes.bool,
  info: PropTypes.object.isRequired
}

QuizComponent.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  session: undefined,
  updateChapter: false
}

const mapStateToProps = (state) => ({
  info: state.info
})

const mapActionToProps = () => ({})

export default connect(mapStateToProps, mapActionToProps)(QuizComponent)

import React from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {QuestionText} from '../../styles/TextSizes'
import TextInput from '../inputs/TextInput'
import {OptionsContainer, OptionsSelect} from './QuestionStyles'

const QuestionSelect = ({
  activeLanguage,
  titleColor,
  textColor,
  question,
  translate,
  fieldName,
  disabled
}) => (
  <FormSpy subscription={{values: true}}>
    {({form}) => (
      <>
        <QuestionText color={titleColor}>
          {GetValueLanguage(question.title, activeLanguage?.code)}
        </QuestionText>
        <Margin size={24} />

        <Row>
          <OptionsContainer>
            <Field name={`${fieldName}.options`}>
              {props => (
                <>
                  {question.options.map((opt, i) => (
                    <OptionsSelect
                      key={i}
                      onClick={() =>
                        disabled
                          ? {}
                          : form.change(
                              `${fieldName}.options`,
                              props?.input?.value &&
                                props.input.value === opt._id
                                ? undefined
                                : opt._id
                            )
                      }
                      isSelected={opt._id === props?.input?.value}
                      color={textColor}
                    >
                      {GetValueLanguage(opt.title, activeLanguage?.code)}
                    </OptionsSelect>
                  ))}
                </>
              )}
            </Field>
          </OptionsContainer>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={8}>
            <Margin size={24} />
            <Field
              disabled={disabled}
              component={TextInput}
              name={`${fieldName}.other`}
              typeText='text'
              color={titleColor}
              label={translate('INTRODUZ_OUTRA_EMOÇAO')}
              placeholder={translate('EMOÇAO_QUE_SENTISTE')}
            />
          </Col>
        </Row>
      </>
    )}
  </FormSpy>
)

QuestionSelect.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  textColor: PropTypes.string,
  titleColor: PropTypes.string,
  question: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

QuestionSelect.defaultProps = {
  textColor: undefined,
  titleColor: undefined,
  disabled: false
}

export default withLocalize(withRouter(QuestionSelect))

import React from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {Title25} from '../../styles/TextSizes'
import {OptionsContainer, OptionText} from '../question/QuestionStyles'
import {EvaluationOption} from './EvaluationStyles'

const Question2 = ({
  question,
  fieldName,
  activeLanguage
}) => {
  const onSelectOption = (fields, idOption, form, name) => {
    const selected = fields || []
    const exist = selected.findIndex((id) => idOption === id)
    let changes = [...selected]
    if (exist >= 0) {
      changes = fields.filter((id) => idOption !== id)
    } else {
      changes.push(idOption)
    }
    form.change(name, changes)
  }

  const optionsArray = (input) => {
    const {options, form, fields} = input
    const currentOptions = fields.value || []

    return (
      <>
        {options.map((option, i) => (
          <Col xs={24} key={i} style={{marginBottom: 24, display: 'flex', alignItems: 'center'}}>
            <EvaluationOption
              onClick={() =>
                onSelectOption(currentOptions, option._id, form, fields.name)
              }
              isActive={currentOptions.findIndex((a) => option._id === a) >= 0}
            />
            <OptionText
              style={{textAlign: 'inherit', marginLeft: 8}}
            >
              {GetValueLanguage(option.title, activeLanguage?.code)}
            </OptionText>
          </Col>
        ))}
      </>
    )
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({form}) => (
        <>
          <Title25 style={{textAlign: 'center'}}>
            {GetValueLanguage(question.title, activeLanguage?.code)}
          </Title25>
          <Margin size={50} />
          <OptionsContainer gutter={[16, 16]} style={{margin: 0}}>
            <FieldArray
              name={`${fieldName}.options`}
              component={optionsArray}
              options={question.options}
              form={form}
            />
          </OptionsContainer>
        </>
      )}
    </FormSpy>
  )
}

Question2.propTypes = {
  question: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  title: PropTypes.string
}

Question2.defaultProps = {
  title: undefined
}

export default withLocalize(withRouter(Question2))

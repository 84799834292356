import React, {useState} from 'react'

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  MinusCircleTwoTone
} from '@ant-design/icons'
import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import Confetti from 'react-dom-confetti'
import {withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {UpdateGame} from '../../infra/requests/ProgramRequests'
import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {Margin} from '../../styles/BasicStyles'
import {
  SuccessBgColor,
  PrimaryColour,
  ErrorBgColor
} from '../../styles/Colours'
import {QuestionText, Body} from '../../styles/TextSizes'
import {IntroductionBlock} from '../jogo_espirais/JogoEspiraisStyles'
import {
  QuestionOptionChallenge,
  QuestionOptionSmall
} from '../question/QuestionStyles'

const ChallengesComponent = ({
  chapter,
  response,
  translate,
  activeLanguage,
  UpdateResponse,
  showMessage
}) => {
  const dispatch = useDispatch()
  const [animation, setAnimation] = useState(undefined)
  const [showConfetti, setConfetti] = useState(false)
  const week = response[`week${response?.currentWeek}`] || false

  const onSelectOption = async (value, option) => {
    setConfetti(false)
    setAnimation({value, option})
    try {
      const result = await UpdateGame({option, goal: value})

      if (result.success) {
        showMessage(value === 3 ? false : value)
        if (value === 1) {
          setConfetti(option)
        }
        UpdateResponse(result.data)
        dispatch(UpdateUserScore(result.data.points))
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const renderOptions = (option, isNew) => {
    if (!isNew) {
      let infoStep
      let infoOption
      let infoQuestion
      chapter.steps.forEach((step) => {
        step.questions.map((question) => {
          question.options.map((opt) => {
            if (opt._id === option) {
              infoStep = step
              infoQuestion = question
              infoOption = opt
            }
          })
        })
      })
      if (infoStep && infoQuestion && infoOption) {
        const done =
          week && week.find((w) => w.option === option)
            ? week.find((w) => w.option === option)?.goal
            : false
        return (
          <Row gutter={[16, {xs: 0, md: 16}]} style={{marginBottom: 16}}>
            <Col xs={24} md={12} lg={16}>
              <QuestionOptionChallenge>
                <Body>
                  {GetValueLanguage(infoStep?.title, activeLanguage?.code)}
                </Body>
                <Margin size={8} />
                <QuestionText style={{fontWeight: 600}}>
                  {GetValueLanguage(
                    infoOption?.title,
                    activeLanguage?.code
                  )}
                </QuestionText>
              </QuestionOptionChallenge>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <QuestionOptionSmall
                className={
                  animation?.option === option && animation?.value === 1
                    ? 'animated'
                    : ''
                }
                onClick={() => onSelectOption(1, option)}
                onAnimationEnd={() => setAnimation(undefined)}
                isActive={done === 1}
              >
                <Body>{translate('ATINGI_DESAFIO_TOTALIDADE')}</Body>
                <CheckCircleTwoTone
                  twoToneColor={SuccessBgColor}
                  style={{fontSize: 32}}
                />
              </QuestionOptionSmall>
              <Confetti active={showConfetti === option} />
              <QuestionOptionSmall
                className={
                  animation?.option === option && animation?.value === 2
                    ? 'animated'
                    : ''
                }
                onClick={() => onSelectOption(2, option)}
                onAnimationEnd={() => setAnimation(undefined)}
                isActive={done === 2}
              >
                <Body>{translate('ATINGI_DESAFIO_PARTE')}</Body>
                <MinusCircleTwoTone
                  twoToneColor={PrimaryColour}
                  style={{fontSize: 32}}
                />
              </QuestionOptionSmall>
              <QuestionOptionSmall
                className={
                  animation?.option === option && animation?.value === 3
                    ? 'animated'
                    : ''
                }
                onClick={() => onSelectOption(3, option)}
                onAnimationEnd={() => setAnimation(undefined)}
                isActive={done === 3}
              >
                <Body>{translate('NAO_ATINGI_DESAFIO')}</Body>
                <CloseCircleTwoTone
                  twoToneColor={ErrorBgColor}
                  style={{fontSize: 32}}
                />
              </QuestionOptionSmall>
            </Col>
          </Row>
        )
      }
      return null
    }
    const done =
      week && week.find((w) => w.option === option._id)
        ? week.find((w) => w.option === option._id)?.goal
        : false
    return (
      <Row gutter={[16, {xs: 0, md: 16}]} style={{marginBottom: 16}}>
        <Col xs={24} md={12} lg={16}>
          <QuestionOptionChallenge>
            <Body>{response.main_objective}</Body>
            <Margin size={8} />
            <QuestionText style={{fontWeight: 600}}>
              {option.name}
            </QuestionText>
          </QuestionOptionChallenge>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <QuestionOptionSmall
            className={
              animation?.option === option._id && animation?.value === 1
                ? 'animated'
                : ''
            }
            onClick={() => onSelectOption(1, option._id)}
            onAnimationEnd={() => setAnimation(undefined)}
            isActive={done === 1}
          >
            <Body>{translate('ATINGI_DESAFIO_TOTALIDADE')}</Body>
            <CheckCircleTwoTone
              twoToneColor={SuccessBgColor}
              style={{fontSize: 32}}
            />
          </QuestionOptionSmall>
          <Confetti active={showConfetti === option._id} />
          <QuestionOptionSmall
            className={
              animation?.option === option._id && animation?.value === 2
                ? 'animated'
                : ''
            }
            onClick={() => onSelectOption(2, option._id)}
            onAnimationEnd={() => setAnimation(undefined)}
            isActive={done === 2}
          >
            <Body>{translate('ATINGI_DESAFIO_PARTE')}</Body>
            <MinusCircleTwoTone
              twoToneColor={PrimaryColour}
              style={{fontSize: 32}}
            />
          </QuestionOptionSmall>
          <QuestionOptionSmall
            className={
              animation?.option === option._id && animation?.value === 3
                ? 'animated'
                : ''
            }
            onClick={() => onSelectOption(3, option._id)}
            onAnimationEnd={() => setAnimation(undefined)}
            isActive={done === 3}
          >
            <Body>{translate('NAO_ATINGI_DESAFIO')}</Body>
            <CloseCircleTwoTone
              twoToneColor={ErrorBgColor}
              style={{fontSize: 32}}
            />
          </QuestionOptionSmall>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    if (!response.options.length && !response.objectives.length) {
      return null
    }
    return (
      <>
        {response.options.map((o) => renderOptions(o))}
        {response.objectives.map((o) => renderOptions(o, true))}
      </>
    )
  }

  return (
    <Col xs={24} lg={20} xl={18}>
      <IntroductionBlock>
        <QuestionText isBold>
          {translate('JOGO_DOS_DESAFIOS_ASSINALAR_FEITOS')}
        </QuestionText>
      </IntroductionBlock>
      {renderContent()}
    </Col>
  )
}

ChallengesComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  chapter: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired,
  UpdateResponse: PropTypes.func.isRequired
}

export default withLocalize(withRouter(ChallengesComponent))

import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {
  MainContainer,
  MainContainerBackground,
  MainContainerScroll
} from '../../pages/step/StepStyles'
import BaseButton from '../buttons/BaseButton'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'
import {ButtonsContainer} from '../message/MessageStyles'

const Step2_5_1 = ({step, translate, history, nextStep, chapter}) => (
  <>
    <BasicHeaderComponent
      onClose={() => history.push('/program')}
      title={chapter.title}
      progress={(step.order / chapter.steps.length) * 100}
    />
    <MainContainerBackground>
      <MainContainerScroll>
        <MainContainer>
          {step.type === 'text' && (
            <>
              {step.descriptions.map((description, index) => (
                <MessageComponent
                  key={index}
                  text={description.title}
                  active={index === step.descriptions.length - 1}
                />
              ))}
            </>
          )}
          <MessageComponent isUser active>
            <ButtonsContainer>
              <BaseButton
                align='right'
                htmlType='button'
                type='primaryMedium'
                text={translate('VAMOS_LA')}
                auto
                onClick={() =>
                  nextStep
                    ? history.push(`/step/${nextStep._id}`)
                    : history.push('/program')
                }
              />
            </ButtonsContainer>
          </MessageComponent>
        </MainContainer>
      </MainContainerScroll>
    </MainContainerBackground>
    <BasicFooterComponent
      onNext={() =>
        nextStep
          ? history.push(`/step/${nextStep._id}`)
          : history.push('/program')
      }
    />
  </>
)

Step2_5_1.propTypes = {
  step: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object
}

Step2_5_1.defaultProps = {
  nextStep: undefined,
  chapter: undefined
}
export default withLocalize(withRouter(Step2_5_1))

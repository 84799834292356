import React, {useState} from 'react'

import {Menu, Dropdown, Select} from 'antd'
import {PropTypes} from 'prop-types'
import {Draggable, Droppable} from 'react-beautiful-dnd'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import Search from '../../assets/icons/search.svg'
import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {Body} from '../../styles/TextSizes'
import {
  MaterialInputBox,
  MaterialInput,
  MaterialIcon
} from '../inputs/MaterialStyles'
import {TagsContainer, Tag} from './AtividadesLazerStyles'

const {Option} = Select
const activities = {
  pt: [
    {title: 'Canto'},
    {title: 'Ler'},
    {title: 'Dança'},
    {title: 'Cozinhar'},
    {title: 'Ouvir música'},
    {title: 'Ir ao cinema'}
  ]
}

const TagsComponent = ({activeLanguage, translate}) => {
  const [value, setValue] = useState('')
  const [tags, setTags] = useState([])
  const children = []
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    )
  }
  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const onSelectItem = (v) => {
    if (
      !tags.find(
        (t) => t.toLowerCase().trim() === value.toLowerCase().trim()
      )
    ) {
      setTags([value, ...tags])
    }
    setTags([v, ...tags])
    setValue('')
  }

  const renderMenu = () => {
    const filteredActivities = GetValueLanguage(
      activities,
      activeLanguage?.code
    ).filter((a) => {
      const hasValue = a.title.indexOf(value) > -1
      const inTags = tags.find((t) => t === a.title)
      return hasValue && !inTags
    })

    return (
      <Menu style={{padding: 0}}>
        {filteredActivities.map((activity, i) => (
          <Menu.Item
            key={i}
            style={{
              padding: '8px 16px'
            }}
          >
            <div onClick={() => onSelectItem(activity.title)}>
              {activity.title.toLowerCase()}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (
        !tags.find(
          (t) => t.toLowerCase().trim() === value.toLowerCase().trim()
        )
      ) {
        setTags([value, ...tags])
      }
      setValue('')
    }
  }

  return (
    <Droppable droppableId='droppableTags'>
      {(provided) => (
        <div
          style={{width: '100%'}}
          {...provided.props}
          ref={provided.innerRef}
        >
          <Dropdown
            overlay={renderMenu}
            placement='bottomCenter'
            trigger={['click']}
          >
            <MaterialInputBox style={{margin: 0}}>
              <MaterialInput
                placeholder={translate('ADICIONA_ATIVIDADES')}
                type='text'
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                style={{padding: '9px 16px'}}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
              />
              <MaterialIcon>
                <img alt='search' src={Search} />
              </MaterialIcon>
            </MaterialInputBox>
          </Dropdown>
          <TagsContainer>
            {tags.map((tag, i) => (
              <Draggable key={i} draggableId={tag} index={i}>
                {(p) => (
                  <Tag
                    key={i}
                    ref={p.innerRef}
                    {...p.draggableProps}
                    {...p.dragHandleProps}
                  >
                    <Body>{tag.toLowerCase()}</Body>
                  </Tag>
                )}
              </Draggable>
            ))}
          </TagsContainer>
        </div>
      )}
    </Droppable>
  )
}

TagsComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(withRouter(TagsComponent))

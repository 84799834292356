export const menuItems = [
  {
    name: 'PROGRAM',
    url: '/program'
  },
  {
    name: 'ACTIVITIES',
    url: '/activities'
  },
  {
    name: 'FORUM',
    url: '/forum'
  }
]

export const settingsMenu = [
  {
    name: 'HELP',
    url: '/help'
  },
  {
    name: 'TERMS_CONDITIONS_WD',
    url: '/terms-and-conditions'
  },
  {
    name: 'PRIVACY_POLICY_WD',
    url: '/privacy-policy'
  }
]

export const PrimaryColour = '#FFBF00'
export const SecondaryColour = '#F38231'
export const ThirdColour = '#46329B'
export const FourthColour = '#F9EAD7'
export const PrimaryCTA = '#FFBF00'
export const SecondaryCTA = '#FFE188'
export const FocusColor = '#FFD127'
export const AlertColour = '#DE8F8F'
export const InputBorderColour = '#D7D7D7'
export const PrimaryBorderColor = '#F3F3F3'
export const SecondaryBorderColor = '#DECBB3'
export const MenuBorderColor = '#EDEDED'
export const HoverBorderColor = '#656565'
export const ActiveBorderColor = '#59B122'
export const TextColor = '#3E3E3E'
export const InactiveTextColor = '#DCDCDC'
export const Beige = '#FEF8F1'
export const Beige2 = '#FFE5C4'
export const Beige3 = '#FFF8EF'
export const White = '#FFFFFF'
export const Lilac = '#B1A1F5'
export const LightGrey = '#ADADAD'
export const ErrorColor = '#FF4D00'
export const Purple = '#1C0A6D'
export const Purple2 = '#36228C'
export const SuccessBgColor = '#cbe2b4'
export const ErrorBgColor = '#fac4b0'

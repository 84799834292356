import React from 'react'

import {Spin} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {TextColor, ErrorColor} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import {StyledOption, StyledQuestionSelectInput} from './InputStyles'
import {
  MaterialInputSection,
  QuestionInputBox,
  RequiredIcon
} from './MaterialStyles'

const filterOption = (inputValue, option) => {
  if (option?.props?.children) {
    return (
      option?.props?.children
        .toLowerCase()
        .indexOf(inputValue.toLowerCase()) > -1
    )
  }
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
}

const QuestionSelectInput = ({
  input,
  items,
  dataKey,
  placeholder,
  notFoundMessage,
  fetching,
  label,
  disabled,
  fetchData,
  loading,
  meta,
  allowClear,
  isRequired,
  requiredError
}) => {
  const {invalid, submitFailed} = meta
  const showError = invalid && submitFailed

  const formatValue = value => {
    if (!value) return undefined
    if (Array.isArray(value)) return value
    return value.toString()
  }

  const changeSelect = value => {
    input.onChange(value || '')
  }

  return (
    <MaterialInputSection>
      {label && (
        <QuestionText
          color={showError || requiredError ? ErrorColor : TextColor}
        >
          {label} {isRequired && <RequiredIcon />}
        </QuestionText>
      )}
      <QuestionInputBox>
        <StyledQuestionSelectInput
          disabled={disabled}
          placeholder={placeholder}
          notFoundContent={
            fetching ? <Spin size='small' /> : notFoundMessage
          }
          filterOption={filterOption}
          allowClear={allowClear}
          onSearch={fetchData}
          onChange={changeSelect}
          value={formatValue(input?.value)}
          loading={loading}
          error={showError ? 1 : 0}
          bordered={false}
        >
          {items.map((elem, index) => (
            <StyledOption
              key={dataKey ? elem[dataKey] : index}
              disabled={elem.disabled}
            >
              {elem.title}
            </StyledOption>
          ))}
        </StyledQuestionSelectInput>
      </QuestionInputBox>
    </MaterialInputSection>
  )
}

QuestionSelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  allowClear: PropTypes.bool,
  notFoundMessage: PropTypes.string,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  requiredError: PropTypes.bool
}

QuestionSelectInput.defaultProps = {
  allowClear: false,
  dataKey: undefined,
  label: undefined,
  placeholder: undefined,
  fetchData: () => {},
  loading: false,
  notFoundMessage: undefined,
  fetching: false,
  disabled: false,
  isRequired: false,
  requiredError: false
}

export default withLocalize(QuestionSelectInput)

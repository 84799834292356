import client from '../config/AxiosConfig'

export const Login = async data => client.post('/auth/login/fo', data)

export const Register = async data => client.post('/users_register', data)

export const RecoverAccount = async data =>
  client.post('/auth/recover', data)

export const ResetPassword = async data =>
  client.post('/auth/recover-confirmation', data)

export const ValidatePassword = async data =>
  client.post('/auth/define-password', data)

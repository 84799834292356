import React from 'react'

import moment from 'moment'
import {Droppable, Draggable} from 'react-beautiful-dnd'

import Close from '../../assets/icons/close.svg'
import {White, TextColor} from '../../styles/Colours'
import {QuestionText, Title25, Body} from '../../styles/TextSizes'
import {
  LineContainer,
  DayContainer,
  ActivitiesContainer,
  CountContainer,
  TagsContainer,
  Tag,
  TagRemove
} from './AtividadesLazerStyles'

const validateDate = (date, start, end) => {
  const startOf = moment.utc(start).startOf('isoWeek')
  const endOf = moment.utc(end).endOf('isoWeek')
  const current = moment.utc(date)

  if (current.isAfter(endOf) || current.isBefore(startOf)) {
    return true
  }
  return false
}

const DaysComponent = ({
  values,
  form,
  days,
  translate,
  validation,
  onClickTag,
  onRemoveTag
}) => {
  const currentWeek = !validateDate(
    moment.utc(),
    days[0]?.date,
    days[6]?.date
  )

  const RenderActivities = (input) => {
    const {day, activities} = input

    if (!activities || !activities.length) return null
    return (
      <>
        {activities?.map((tag, j) => (
          <Draggable
            isDragDisabled
            key={j}
            draggableId={`tag_${day.date}_${j}`}
            index={j}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Tag
                  key={`tag_${day.date}_${j}`}
                  onClick={
                    tag.completed
                      ? () => {}
                      : () => onClickTag(tag.title.toLowerCase(), day.date)
                  }
                  isActive={
                    validation &&
                    tag.title.toLowerCase() ===
                      validation.tag.toLowerCase() &&
                    day.date === validation.date
                  }
                  isValid={tag.completed}
                >
                  <Body color={tag.completed ? White : TextColor}>
                    {tag.title.toLowerCase()}
                  </Body>
                  {!tag.completed && currentWeek && (
                    <TagRemove
                      src={Close}
                      onClick={(e) => {
                        e.stopPropagation()
                        onRemoveTag(
                          tag.title.toLowerCase(),
                          day.date,
                          form,
                          values
                        )
                      }}
                    />
                  )}
                </Tag>
              </div>
            )}
          </Draggable>
        ))}
      </>
    )
  }

  return days.map((d, i) => {
    let day = d
    const exist = values.activities?.findIndex(
      (f) => moment.utc(f.date).format('YYYY-MM-DD') === d.date
    )
    if (exist > -1) day = {...values.activities[exist], ...day}
    return (
      <LineContainer key={Math.random()} isLast={i === days.length - 1}>
        <DayContainer xs={6}>
          <QuestionText>{translate(day.dayOfWeek)}</QuestionText>
          <Title25>{day.day}</Title25>
        </DayContainer>
        <ActivitiesContainer xs={12}>
          <Droppable droppableId={`laptop_${day.date}`}>
            {(provided) => (
              <div
                style={{
                  width: '100%',
                  height: '100%'
                }}
                {...provided.props}
                ref={provided.innerRef}
              >
                <TagsContainer style={{margin: 0}}>
                  <RenderActivities
                    day={day}
                    activities={
                      exist > -1
                        ? values.activities[exist]?.activities
                        : []
                    }
                  />
                </TagsContainer>
              </div>
            )}
          </Droppable>
        </ActivitiesContainer>
        <CountContainer xs={6}>
          <Title25>
            {exist > -1 ? values.activities[exist]?.activities.length : 0}
          </Title25>
        </CountContainer>
      </LineContainer>
    )
  })
}
export default DaysComponent

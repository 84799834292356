import {Col} from 'antd'
import styled from 'styled-components'

import {TextColor} from '../../styles/Colours'
import {device} from '../../styles/Responsive'

export const Footer = styled.div`
  width: 100%;
  height: 72px;
  margin: auto;
  position: relative;
  z-index: 99;
  border-top: 2px solid ${(p) => p.theme.menuBorderColor};
  background-color: #ffffff;

  @media ${device.laptop} {
    border-top: 2px solid ${(p) => p.theme.menuBorderColor};
  }

  @media ${device.desktopL} {
    height: 72px;
  }

  @media ${device.desktop} {
    height: 72px;
  }

  @media ${device.laptopL} {
    height: 72px;
  }

  @media ${device.mobileL} {
    height: 72px;
  }
`

export const BasicFooter = styled.div`
  width: 100%;
  height: 105px;
  margin: auto;
  position: relative;
  z-index: 99;
  border-top: 2px solid ${(p) => p.theme.menuBorderColor};
  background-color: #ffffff;

  @media ${device.laptop} {
    border-top: 2px solid ${(p) => p.theme.menuBorderColor};
  }

  @media ${device.desktopL} {
    height: 105px;
  }

  @media ${device.desktop} {
    height: 105px;
  }

  @media ${device.laptopL} {
    height: 105px;
  }

  @media ${device.mobileL} {
    height: 105px;
  }
`

export const FooterContent = styled.div`
  max-width: 1440px;
  height: 100%;
  padding: 0px 112px;
  margin: auto;
  display: flex;

  @media ${device.laptop} {
    padding: 0px 20px;
  }

  @media ${device.tablet} {
    padding: 0px 10px;
  }
`

export const BasicFooterContent = styled.div`
  max-width: 1440px;
  height: 100%;
  padding: 25px 112px;
  margin: auto;
  display: flex;
  align-items: center;

  @media ${device.laptop} {
    padding: 25px 20px;
  }

  @media ${device.tablet} {
    padding: 16px;
  }
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-right: ${(p) => (p.last ? 0 : 32)}px;
  overflow: hidden;
  color: ${TextColor};
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media ${device.laptopL} {
    margin-right: 15px;
  }

  @media ${device.laptop} {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 20px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const BasicFooterRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media ${device.tabletMin} {
    display: block;
  }
`

export const BasicFooterCol = styled(Col)`
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end;

  @media ${device.tabletMin} {
    align-items: center;

    button {
      width: 100% !important;
      margin-left: 0px !important;
      margin-right: 0px !important;
      height: 36px !important;
      padding: 0px 24px !important;
    }
  }
`

export const BasicFooterMessage = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tabletMin} {
    margin-bottom: 8px;
  }
`

export const FooterBottom = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  background-color: #ededed;
  padding: 8px 0px;
`

export const CodepointImg = styled.img`
  width: 308px;
  height: 21px;
  cursor: pointer;
`

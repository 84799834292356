import React, {useState, useEffect, useRef} from 'react'

import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Form, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'
import {withRouter, useLocation} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_quiz.svg'
import {SaveStepAnswer} from '../../infra/requests/ProgramRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {
  GetValueLanguage,
  UpdateUserProgram
} from '../../infra/utils/CommonFunctions'
import {
  MainContainerBackground,
  MainContainerScroll,
  MainContainer
} from '../../pages/step/StepStyles'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {PageForm} from '../../styles/BasicStyles'
import {ThirdColour, SecondaryColour} from '../../styles/Colours'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import FooterFeedbackComponent from '../question/FooterFeedbackComponent'
import QuestionImage from '../question/QuestionImage'
import {OptionText} from '../question/QuestionStyles'
import ScoreComponent from '../score/ScoreComponent'
import ScoreQuiz from './ScoreQuiz'
import TextComponent from './TextComponent'

const formRules = FormValidator.make({})

const QuizEmoçoes = ({
  step,
  chapter,
  nextStep,
  history,
  activeLanguage,
  translate,
  updateChapter
}) => {
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({steps: []})
  const [currentStep, setCurrentStep] = useState(step)
  const [points, setPoints] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const refScroll = useRef(null)
  const user = useSelector((state) => state.user)
  const isApplicator = user.type === 2
  const {state} = useLocation()
  const isProgram = !state?.noProgram

  useEffect(() => {
    const values = {
      steps: []
    }
    chapter.steps.map((s) => {
      const st = {_id: s._id, questions: []}
      s.questions.map((q) => {
        st.questions.push({
          _id: q._id,
          options: undefined
        })
      })
      values.steps.push(st)
    })
    setInitialValues(values)
  }, [chapter])

  const scrollTop = () => {
    if (refScroll?.current) {
      refScroll.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  const onSubmit = async (values) => {
    if (isApplicator) {
      scrollTop()
      setCurrentStep(chapter.steps[currentStep.order])
      return
    }

    try {
      setIsLoading(true)
      const fields = []
      values.steps.forEach((field) => {
        const newField = {
          user: undefined,
          chapter: chapter._id,
          step: field._id
        }
        field.questions.forEach((question) => {
          fields.push({
            ...newField,
            question: question._id,
            options: [question.options]
          })
        })
      })

      const result = await SaveStepAnswer(fields)

      if (result.success) {
        setCurrentStep(chapter.steps[currentStep.order])
        dispatch(UpdateUserScore(result.data.points))
        setPoints(result.data.points)
        scrollTop()
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.warn(e)
    }
  }

  const onNextStep = (values) => {
    if (chapter.steps[currentStep.order]) {
      if (currentStep.order + 1 === chapter.steps.length) {
        onSubmit(values)
      } else {
        setCurrentStep(chapter.steps[currentStep.order])
        scrollTop()
      }
    } else {
      if (isProgram && !isApplicator) {
        UpdateUserProgram(updateChapter, chapter._id, dispatch)
      }
      const next = state?.next || false
      if (next) {
        if (next === 'isBack') history.goBack()
        else history.push(next)
      } else if (nextStep) {
        history.push(`/step/${nextStep._id}`)
      } else history.push('/program')
    }
  }

  const onCloseStep = () => {
    if (state?.back) {
      if (state?.back === 'isBack') history.goBack()
      else history.push(state.back)
    } else history.push('/program')
  }

  const onBackStep = () => {
    if (chapter.steps[currentStep.order - 2]) {
      setCurrentStep(chapter.steps[currentStep.order - 2])
      scrollTop()
    } else history.goBack()
  }

  const renderFooterFeedback = (values) => {
    if (
      !values?.steps[currentStep.order - 1]?.questions[0]?.options ||
      !values?.steps[currentStep.order - 1]?.questions[0]?.options.length
    ) {
      return null
    }

    const question = currentStep.questions[0]
    const option = question.options.find(
      (q) =>
        q._id === values.steps[currentStep.order - 1].questions[0].options
    )
    if (option.isCorrect) {
      return (
        <FooterFeedbackComponent isCorrect>
          <OptionText style={{display: 'flex', alignItems: 'center'}}>
            {GetValueLanguage(question.correct, activeLanguage?.code)}
          </OptionText>
        </FooterFeedbackComponent>
      )
    }
    return (
      <FooterFeedbackComponent>
        <OptionText>
          {GetValueLanguage(question.wrong, activeLanguage?.code)}
        </OptionText>
      </FooterFeedbackComponent>
    )
  }

  const renderContent = () => {
    switch (currentStep.type) {
      case 'question':
        return <QuestionImage step={currentStep} chapterIndex='2.4' />
      default: {
        if (currentStep.order === chapter.steps.length) {
          const totalQuestions = chapter.steps.filter(
            (s) => s.type === 'question'
          ).length
          return (
            <FormSpy subscription={{values: true}}>
              {({values}) => {
                let correct = 0
                values.steps.map((s, i) => {
                  s.questions.map((q, j) => {
                    if (
                      q.options ===
                      chapter.steps[i].questions[j].options.find(
                        (o) => o.isCorrect
                      )._id
                    ) {
                      correct += 1
                    }
                  })
                })
                return (
                  <ScoreComponent step={currentStep}>
                    <ScoreQuiz points={points}>
                      {translate('ACERTASTE')} {correct}/{totalQuestions}
                    </ScoreQuiz>
                  </ScoreComponent>
                )
              }}
            </FormSpy>
          )
        }
        return (
          <TextComponent step={currentStep} onClickNext={onNextStep} />
        )
      }
    }
  }

  const validateNext = (values) => {
    if (currentStep.type === 'text') return false

    const stepValues = values.steps.find((s) => s._id === currentStep._id)
    if (
      stepValues?.questions?.length &&
      stepValues?.questions[0]?.options
    ) {
      return false
    }

    return true
  }

  return (
    <Form
      onSubmit={onNextStep}
      validate={formRules}
      mutators={{
        ...arrayMutators
      }}
      initialValues={initialValues}
    >
      {({handleSubmit, values}) => (
        <PageForm onSubmit={handleSubmit}>
          <BasicHeaderComponent
            onClose={onCloseStep}
            title={chapter.title}
            progress={(currentStep.order / chapter.steps.length) * 100}
            progressColor={ThirdColour}
          />
          <MainContainerBackground
            bgColor={SecondaryColour}
            style={{
              backgroundImage: `url(${Illustration})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center'
            }}
          >
            <MainContainerScroll>
              <MainContainer ref={refScroll}>
                <FieldArray name='steps' component={renderContent} />
              </MainContainer>
            </MainContainerScroll>
          </MainContainerBackground>
          <BasicFooterComponent
            leftContent={renderFooterFeedback(values)}
            onBack={onBackStep}
            nextHtmlType='submit'
            nextDisabled={validateNext(values)}
            isLoading={isLoading}
          />
        </PageForm>
      )}
    </Form>
  )
}

QuizEmoçoes.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  updateChapter: PropTypes.bool
}

QuizEmoçoes.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  updateChapter: false
}

export default withLocalize(withRouter(QuizEmoçoes))

import {Spin, Form as AntForm} from 'antd'
import styled, {createGlobalStyle} from 'styled-components'

import {StyledButton} from '../components/buttons/ButtonsStyles'
import {SecondaryColour, TextColor, White, PrimaryColour} from './Colours'
import {device, vertical} from './Responsive'
import 'cropperjs/dist/cropper.css'

export const transitionsDelay = '0.3s'

export const BasicStyles = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');

  @import 'cropperjs/dist/cropper.css';

  *:focus {
    outline: none;
  }

  body, button {
    font-family: 'Poppins', 'sans-serif' !important;
    overflow-x: hidden;
  }

  body {
    color: #000000;
    font-size: 20px;
    line-height: 31px;
    font-weight: 400;

    @media ${device.laptop} {
      font-size: 14px;
      line-height: 20px;
    }

    .ant-tooltip-arrow{
      width: 18px;
      height: 18px;
      ::before{
        background-color: ${SecondaryColour}
        width: 18px;
        height: 18px;
        top: -4px;
      }
    }
    .ant-tooltip-arrow-content {
      background-color: ${SecondaryColour}
    }
    
    .basic .ant-tooltip-inner {
      background-color: ${SecondaryColour};
      color: ${TextColor};
      font-size: 14px;
      font-weight: 400;
      border-radius: 0%;
      width: auto;
      height: auto;
      margin-bottom: 2px;
    }
    .ant-tooltip-inner{
      background-color: ${SecondaryColour}
      font-size: 25px;
      font-weight: 400;
      border-radius: 50%;
      width: 52px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0px;
      margin-bottom: -1px;
    }

    .ant-picker-week-panel-row-selected td, .ant-picker-week-panel-row-selected:hover td {
      background: #FFF0E4
      &:first-child {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
      &:last-child {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
      &.ant-picker-cell-week,  &.ant-picker-cell-week {
        color: rgba(0, 0, 0, 0.25);
      }
      .ant-picker-cell-inner, .ant-picker-cell-inner {
        color: ${TextColor}
      }
      &.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: ${SecondaryColour};
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      color: ${TextColor}
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-radius: 50%;
        border: 1px solid ${SecondaryColour};
        background: ${White};
        z-index: -1;
    }
    .ant-picker-week-panel-row:hover td{
      &:first-child {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
      &:last-child {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }

  p, ol, ul, dl {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
  }

  .ant-layout {
    background: #fff !important;
  }

  html {
    scroll-behavior: smooth;
  }

  textarea.ant-input {
    font-size: 16px;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-select-dropdown {
    & .ant-select-dropdown-menu-item {
      padding: 0;

      &:hover {
        background-color: #FAF9F9 !important;
      }
    }

    & .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #FAF9F9 !important;
    }
  }

  & .ant-select-selection-selected-value {
    & > div {
      padding: 0;
    }
  }

  .ant-notification {
    &&& {
      top: 118px !important;
    }
  }

  .ant-notification-notice {
    &&& {
      border-radius: 12px;
    }
  }

  .ant-notification-notice-message {
    &&& {
      color: #000000;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 29px;
    }
  }

  .ant-notification-notice-description {
    &&& {
      color: #000000;
      font-size: 20px;
      font-weight: 400;
      line-height: 31px;
    }
  }

  .successAlert {
    &&& {
      background-color: #DECBB3;
    }
  }

  .errorAlert {
    &&& {
      background-color: #DE8F8F;
    }
  }

  @media ${device.laptop} {
    .ant-notification-notice-message {
      &&& {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .ant-notification-notice-description {
      &&& {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  @media ${device.tablet} {
    .ant-notification {
      &&& {
        top: 90px !important;
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    .ant-notification-notice {
      &&& {
        padding: 10px 14px;
      }
    }

    .ant-notification-notice-message {
      &&& {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .ant-notification-notice-description {
      &&& {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .customSelect {
    height: 40px;

    & .ant-select-selection--single {
      height: 40px;

      & .ant-select-selection__rendered {
        line-height: 40px;
        height: 40px;

        & .ant-select-selection-selected-value {
          line-height: 40px;
          height: 40px;
          padding-right: 30px;

          & > div {
            height: 40px;
            padding-left: 0px;
            background-color: transparent;
            border-top: 0;
          }

          & .customSelectBox {
            display: none;
          }
        }

        & .ant-select-selection__placeholder {
          height: 40px !important;
          line-height: 40px !important;
          opacity: 0.3 !important;

          @media ${device.tablet} {
            font-size: 16px !important;
          }
        }
      }
    }
  }

  .recharts-tooltip-wrapper {
    z-index: 999;
  }
`

export const HeaderContainer = styled.div`
  width: calc(100% - 80px);
  display: flex;
  position: fixed;
  padding: 20px;
  line-height: 1;
  background-color: ${({theme}) => theme.primaryBackgroundColor};
  z-index: 50;
  border-bottom: 2px solid ${({theme}) => theme.secondaryBackgroundColor};
  align-items: center;
`

export const HeaderTitle = styled.div`
  width: calc(
    100% - ${({buttons}) => (buttons ? `${buttons} * 170px` : '0px')}
  );
  display: inline-block;
  text-align: left;
`

export const HeaderButtonsContainer = styled.div`
  width: calc(${({buttons}) => (buttons ? `${buttons} * 170px` : '0px')});
  float: right;

  ${StyledButton} {
    margin-left: 10px;
    float: right;
  }
`

export const PageTitle = styled.h1`
  font-size: ${({theme}) => theme.titleSize};
  color: ${({theme}) => theme.primaryColor};
  margin-bottom: 5px;
`

export const SectionTitle = styled.h2`
  font-size: ${({theme}) => theme.sectionSize};
  color: ${({theme}) => theme.thirdColor};
  margin-bottom: 10px;
  text-align: left;
  margin-top: ${({subsection}) => (subsection ? '50px' : 0)};
`

export const PageContainer = styled.div`
  width: 100%;
  margin-top: 105px;
  position: relative;
  display: inline-block;
  padding: 20px;
  line-height: 1;
  background-color: ${({theme}) => theme.primaryBackgroundColor};
`

export const TableButton = styled.div`
  display: inline-block;
  z-index: 999;
  padding: 5px;
  margin: 0 10px;

  &:hover {
    color: ${({theme, primary, error}) =>
      primary
        ? theme.primaryColor
        : error
        ? theme.inputErrorColor
        : 'inherit'};
  }
`

export const SpinLoading = styled(Spin)`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 50px !important;
  text-align: center;
  justify-content: center;
  align-items: center;
`

export const FormContainer = styled.div`
  width: calc(100% - 20px);
  max-width: ${({singleColumn}) => (singleColumn ? '600px' : '100%')};
  margin-top: 105px;
  text-align: left;
  position: relative;
  display: inline-block;
  padding: 20px 8px;
  line-height: 1;
  background-color: ${(p) => p.theme.primaryBackgroundColor};
  text-align: left;
`

export const PageForm = styled.form`
  margin: 0;
  width: 100%;
  display: inline-block;
`

export const BaseForm = styled(AntForm)`
  display: inline-block;
  width: 100%;
`

export const TableFilterSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`

export const TableImage = styled.div`
  display: inline-block;
  width: 200px;
`

export const DefaultLanguageTab = styled.div`
  border-bottom: 1px solid rgb(232, 232, 232);
  padding: 12px 16px;
  margin-top: -2px;
  margin-bottom: 16px;
`

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 990px;
  flex-direction: column;
  padding: 80px 20px;
  margin: auto;
  margin-bottom: 40px;
  position: relative;

  @media ${device.desktopL} {
    padding: 60px 20px;
  }

  @media ${device.laptop} {
    padding: 50px 20px;
  }

  @media ${device.tablet} {
    padding: 40px 20px;
  }

  @media ${device.mobileL} {
    padding: 30px 10px;
    margin-bottom: 60px;
  }
`

export const Margin = styled.div`
  margin-top: ${(p) => (p.size ? p.size : 0)}px;

  @media ${vertical.medium} {
    margin-top: ${(p) => (p.vertical ? p.vertical : p.size ? p.size : 0)}px;
  }

  @media ${device.tablet} {
    margin-top: ${(p) => (p.tablet ? p.tablet : p.size ? p.size : 0)}px;
  }

  @media ${device.mobileL} {
    margin-top: ${(p) => (p.mobile ? p.mobile : p.size ? p.size : 0)}px;
  }
`

export const MarginH = styled.div`
  margin-left: ${(p) => (p.size ? p.size : 0)}px;

  @media ${device.tabletMin} {
    margin-left: ${(p) => (p.tablet ? p.tablet : p.size ? p.size : 0)}px;
  }

  @media ${device.mobileL} {
    margin-left: ${(p) => (p.mobile ? p.mobile : p.size ? p.size : 0)}px;
  }
`

export const LoadingSpin = styled(Spin)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: ${({min}) => min || 'auto'};
  color: ${PrimaryColour};

  &&& {
    .ant-spin-dot-item {
      background-color: ${PrimaryColour};
    }
  }
`

export const EmptyLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 37px 0px;
  width: 100%;
  text-align: center;
`

export const PlayerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  video {
    max-width: 100%;
    max-height: calc(100vh - 270px);
  }

  #sample_video::cue {
    font-size: 20px;
  }
`

export const IconQuestion = styled.img`
  float: right;
  cursor: pointer;
  width: 32px;
  border-radius: 50%;

  &:hover,
  &:active {
    background-color: ${(p) => p.theme.Beige};
  }
`

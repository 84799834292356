import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {
  Footer,
  FooterContent,
  LinkContainer,
  FooterBottom,
  CodepointImg
} from './FooterStyles'

const {REACT_APP_FRONTEND} = process.env
const FooterComponent = ({translate}) => (
    <Footer>
      <FooterContent>
        <LinkContainer
          onClick={() =>
            window.open(`${REACT_APP_FRONTEND}terms-conditions`, '_blank')
          }
        >
          {translate('TERMS_CONDITIONS')}
        </LinkContainer>
        <LinkContainer
          onClick={() =>
            window.open(`${REACT_APP_FRONTEND}privacy-policy`, '_blank')
          }
        >
          {translate('PRIVACY_POLICY')}
        </LinkContainer>
        <LinkContainer
          onClick={() =>
            window.open(`${REACT_APP_FRONTEND}cookies-policy`, '_blank')
          }
        >
          {translate('COOKIES_POLICY')}
        </LinkContainer>
      </FooterContent>
      <FooterBottom>
        <CodepointImg
          src={require('../../assets/icons/codepoint.png')}
          onClick={() => window.open('https://codepoint.pt/', '_blank')}
        />
      </FooterBottom>
    </Footer>
  )

FooterComponent.propTypes = {
  translate: PropTypes.func.isRequired
}
export default withLocalize(withRouter(FooterComponent))

import EN from './en.svg';
import PT from './pt.svg';

export const GetAvailableLanguages = () => ['en', 'pt'];

export const Languages = [
  { code: 'en', name: 'English', icon: EN },
  { code: 'pt', name: 'Português', icon: PT }
];

export const GetTranslationDescription = code =>
  Languages.find(x => x.code === code)?.name;

export const TranslateFromObject = (object, code) => {
  if (!object) return '';
  if (object[code]) return object[code];
  if (object['pt']) return object['pt'];
  if (Object.keys(object).length) return object[Object.keys(object)[0]];
  return object;
};

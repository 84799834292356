import React from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {Form, Field} from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroller'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Close from '../../assets/icons/close.svg'
import Message from '../../assets/icons/message_grey.svg'
import Pencil from '../../assets/icons/pencil.svg'
import {BaseButton} from '../../components/buttons/BaseButton'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import TextInput from '../../components/inputs/TextInput'
import AddCommentComponent from '../../components/thread/AddCommentComponent'
import CommentsComponent from '../../components/thread/CommentsComponent'
import {
  MessageContainer,
  CommentsList,
  EditIcon,
  TitleEditable
} from '../../components/thread/ThreadStyles'
import {
  GetThread,
  EditThread,
  GetComments
} from '../../infra/requests/ForumRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {LoadingSpin, Margin, PageForm} from '../../styles/BasicStyles'
import {LightGrey, White, InputBorderColour} from '../../styles/Colours'
import {Title22, Body} from '../../styles/TextSizes'
import {DashboardContainer} from '../dashboard/DashboardStyles'
import {ForumContainer} from './ForumStyles'

const formRules = FormValidator.make({
  title: 'required',
  description: 'required'
})

const limit = 12
class ThreadPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      thread: undefined,
      editable: false,
      comments: [],
      page: 1,
      total: undefined
    }
  }

  componentDidMount = () => {
    this.getThread()

    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  getThread = async () => {
    const {isLoading} = this.state
    const {
      match: {params}
    } = this.props

    if (isLoading || !params?.id) return
    this.setState({isLoading: true})
    try {
      const result = await GetThread(params.id)
      if (result.success) {
        this.setState({
          isLoading: false,
          thread: result.data
        })
        this.getComments()
      }
    } catch (error) {
      console.warn(error)
    }
  }

  getComments = async () => {
    const {page, thread, comments, total} = this.state

    if (!thread || total <= comments.length) return
    try {
      const result = await GetComments(page, limit, thread._id)
      if (result.success) {
        const newComments = [...comments, ...result.data.items]
        this.setState({
          comments: newComments,
          page: page + 1,
          total: result.data.total
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }

  updateComments = (comment, isSub, isUpdate) => {
    const {comments} = this.state
    const {dispatch} = this.props
    if (isSub || isUpdate) {
      const cIndex = comments.findIndex((c) => c._id === comment._id)
      comments[cIndex] = comment
    } else {
      this.setState(
        {
          page: 1,
          total: undefined,
          comments: []
        },
        this.getComments
      )
    }
    if (comment.points) dispatch(UpdateUserScore(comment.points))

    this.setState({comments})
  }

  onClickEdit = () => {
    const {editable} = this.state
    this.setState({editable: !editable})
  }

  onSubmit = async (values) => {
    const {thread} = this.state
    try {
      const result = await EditThread(thread._id, values)
      if (result.success) {
        this.setState({editable: false, thread: result.data})
      }
    } catch (e) {
      console.warn(e)
    }
  }

  renderThreadContent = () => {
    const {user, translate} = this.props
    const {editable, thread} = this.state
    const isAuthor = user._id === thread?.author?._id

    if (!editable) {
      return (
        <>
          <TitleEditable>
            <Title22 style={{textAlign: 'left'}}>
              {thread.title}
              {thread.edited && (
                <Body
                  color={InputBorderColour}
                  weight='bold'
                  style={{marginLeft: 16}}
                >
                  ({translate('EDITADO')})
                </Body>
              )}
            </Title22>
            {isAuthor && (
              <EditIcon src={Pencil} onClick={this.onClickEdit} />
            )}
          </TitleEditable>
          <Margin size={16} />
          <Body>{thread.description}</Body>
        </>
      )
    }

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={formRules}
        initialValues={{
          title: thread.title,
          description: thread.description
        }}
      >
        {({handleSubmit, valid, submitting, pristine}) => (
          <PageForm onSubmit={handleSubmit}>
            <TitleEditable>
              <Field
                isSmall
                noMargin
                component={TextInput}
                name='title'
                type='text'
                placeholder={translate(
                  'TITULO_NOVA_DISCUSSAO_PLACEHOLDER'
                )}
              />
              <BaseButton
                disabled={!valid || submitting || pristine}
                style={{
                  minWidth: 'fit-content',
                  width: 'auto',
                  marginLeft: 8
                }}
                htmlType='submit'
                type='primaryMedium'
                text={translate('ATUALIZAR')}
              />
              <EditIcon
                style={{alignSelf: 'center'}}
                src={Close}
                onClick={() => this.setState({editable: false})}
              />
            </TitleEditable>
            <Margin size={16} />
            <Field
              noMargin
              isSmall
              component={TextAreaInput}
              name='description'
              type='text'
              minRows={1}
              maxRows={20}
              bgColor={White}
              placeholder={translate(
                'DESCRIÇAO_NOVA_DISCUSSAO_PLACEHOLDER'
              )}
            />
          </PageForm>
        )}
      </Form>
    )
  }

  render() {
    const {thread, isLoading, comments, total} = this.state
    const {translate, user} = this.props
    const totalComments = comments?.length || 0

    return (
      <ForumContainer>
        <DashboardContainer>
          <Row gutter={[24]}>
            {isLoading || !thread ? (
              <LoadingSpin />
            ) : (
              <>
                <Col xs={24} md={18}>
                  {this.renderThreadContent()}
                  <Margin size={24} />
                  <MessageContainer>
                    <img
                      alt='message'
                      src={Message}
                      style={{marginRight: 8}}
                    />
                    <Body color={LightGrey} weight={600}>
                      {totalComments}{' '}
                      {translate(
                        totalComments === 1 ? 'COMENTARIO' : 'COMENTARIOS'
                      )}
                    </Body>
                  </MessageContainer>
                  <AddCommentComponent
                    thread={thread}
                    onChangeComment={this.updateComments}
                    isEducator={user.type === 3}
                  />
                  <Margin size={16} />
                  <CommentsList>
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={this.getComments}
                      hasMore={total > comments.length}
                      loader={<LoadingSpin />}
                    >
                      <CommentsComponent
                        comments={comments}
                        onChangeComment={this.updateComments}
                      />
                    </InfiniteScroll>
                  </CommentsList>
                </Col>
                <Col xs={24} md={6} />
              </>
            )}
          </Row>
        </DashboardContainer>
      </ForumContainer>
    )
  }
}

ThreadPage.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  withRouter(connect(mapStateToProps, mapActionToProps)(ThreadPage))
)

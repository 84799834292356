import React, {useEffect} from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Cup from '../../assets/icons/Taça.svg'
import {BaseButton} from '../../components/buttons/BaseButton'
import CompleteComponent from '../../components/dashboard/CompleteComponent'
import MeditateComponent from '../../components/dashboard/MeditateComponent'
import NotificationsComponent from '../../components/dashboard/NotificationsComponent'
import YourAreaComponent from '../../components/dashboard/YourAreaComponent'
import {Margin} from '../../styles/BasicStyles'
import {QuestionText} from '../../styles/TextSizes'
import {
  DashboardContainer,
  TitleDashboard,
  DashboardBoxSmall,
  DashboardBoxCol
} from './DashboardStyles'

const DashboardPage = ({translate, history}) => {
  const {
    user,
    info: {program, surveys}
  } = useSelector((state) => state)
  const isApplicator = user.type === 2
  const isEducator = user.type === 3
  const isStudent = user.type === 4
  const isFinished = isApplicator ? false : program.completed || user.programCompleted
  const hasSurveys = !isApplicator && surveys && !surveys.answered
  const activeAndCompleted =
    program?.sessions.filter((s) => s.enabled && s.completed) || false
  const lastActiveAndCompleted =
    program?.sessions[activeAndCompleted.length - 1]
  const nextWeek = program?.sessions[lastActiveAndCompleted?.order]
    ? !program?.sessions[lastActiveAndCompleted.order].enabled
    : false

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <DashboardContainer>
      <DashboardBoxSmall bgColor='#FFFFFF'>
        <Col xs={24} md={12} lg={16}>
          <TitleDashboard>
            {translate('HELLO_AGAIN')} <span>{user.name}</span>
          </TitleDashboard>
          {isFinished && isStudent && (
            <QuestionText isBold>
              {translate(
                isEducator
                  ? 'PARABENS_TERMINOU_PROGRAMA_EDUCADOR'
                  : 'PARABENS_TERMINOU_PROGRAMA'
              )}
            </QuestionText>
          )}
        </Col>
        {!isApplicator && !isFinished && !isEducator && (
          <DashboardBoxCol xs={24} md={12} lg={8}>
            <BaseButton
              disabled={nextWeek}
              auto
              variant='raised'
              htmlType='button'
              type='primaryMedium'
              text={translate('NEXT_SESSION')}
              onClick={() => history.push('/program', {nextSession: true})}
            />
          </DashboardBoxCol>
        )}
        {isFinished && isStudent && (
          <DashboardBoxCol xs={24} md={12} lg={8}>
            <img src={Cup} alt='cup' style={{width: '35%'}} />
          </DashboardBoxCol>
        )}
      </DashboardBoxSmall>
      {!isApplicator && (
        <>
          {(hasSurveys || nextWeek) && (
            <>
              <Margin size={32} />
              <NotificationsComponent
                Answer={hasSurveys ? surveys : undefined}
                isInfo={
                  nextWeek
                    ? translate('NEXT_SESSION_WARNING_NEXT_WEEK')
                    : false
                }
              />
            </>
          )}
          {!isEducator &&
            <>
              <Margin size={32} />
              <CompleteComponent />
            </>
          }
          <Margin size={32} />
          <MeditateComponent />
          <Margin size={32} />
          <YourAreaComponent />
        </>
      )}
    </DashboardContainer>
  )
}

DashboardPage.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withLocalize(withRouter(DashboardPage))

import React from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Eye from '../../assets/icons/eye_white.svg'
import Lock from '../../assets/icons/lock.svg'
import Illustration from '../../assets/illustrations/illustration_infoteca.svg'
import {
  RowMeditate,
  ColMeditate
} from '../../components/dashboard/DashboardComponentStyles'
import {
  MeditatecaContainer,
  MeditatecaScrollBox,
  AudioLineComponent,
  MeditatecaShadow
} from '../../components/diario_meditaçao/DiarioMeditaçaoStyles'
import {
  GetInfosFromProgram,
  GetStepName,
  GetValueLanguage
} from '../../infra/utils/CommonFunctions'
import {
  PrimaryColour,
  TextColor,
  InputBorderColour
} from '../../styles/Colours'
import {TitleH3, QuestionText} from '../../styles/TextSizes'
import {DashboardBox, LockIcon} from '../dashboard/DashboardStyles'

const InfotecaComponent = ({translate, history, activeLanguage}) => {
  const {
    user,
    info: {program}
  } = useSelector((state) => state)
  const stepName = GetStepName(program)
  const isEducator = user.type === 3
  const videos = GetInfosFromProgram(program, isEducator)

  const onClickVideo = (video) => {
    history.push(
      `/${stepName}/${video.steps[0]._id}`,
      {
        next: 'isBack',
        back: 'isBack',
        isVideoteca: true
      }
    )
  }

  return (
    <DashboardBox
      column
      bgColor='#FFFFFF'
      image={`url(${require('../../assets/backgrounds/background_videoteca.svg')})`}
    >
      <Row style={{flex: 1}}>
        <Col xs={12} style={{textAlign: 'left'}}>
          <TitleH3 weight={700}>
            {translate('INFOTECA')}
          </TitleH3>
        </Col>
      </Row>
      <RowMeditate>
        <Col md={24} lg={12} style={{flex: 1}}>
          <MeditatecaContainer
            style={{marginTop: 21}}
            height={400}
            bgColor={PrimaryColour}
          >
            <MeditatecaScrollBox>
              {videos.map((video, index) => {
                const active = video.enabled
                return (
                  <AudioLineComponent
                    key={index}
                    isLast={index === videos.length - 1}
                    item={video}
                    active={active}
                  >
                    {!active && (
                      <LockIcon top='-10px' right='-10px'>
                        <img alt='lock' src={Lock} />
                      </LockIcon>
                    )}
                    <QuestionText
                      style={{textAlign: 'left'}}
                      isBold
                      color={active ? TextColor : InputBorderColour}
                    >
                      {GetValueLanguage(video.title, activeLanguage?.code)}
                    </QuestionText>
                    <img
                      src={Eye}
                      alt='play'
                      style={{
                        marginLeft: 8,
                        cursor: active ? 'pointer' : 'no-drop'
                      }}
                      onClick={
                        !active ? () => {} : () => onClickVideo(video)
                      }
                    />
                  </AudioLineComponent>
                )
              })}
            </MeditatecaScrollBox>
            <MeditatecaShadow isYellow />
          </MeditatecaContainer>
        </Col>
        <ColMeditate xs={0} lg={12}>
          <img
            style={{width: '100%', padding: '0px 50px 0px 65px'}}
            src={Illustration}
          />
        </ColMeditate>
      </RowMeditate>
    </DashboardBox>
  )
}

InfotecaComponent.propTypes = {
  history: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(withRouter(InfotecaComponent))

import React, {useState, useRef} from 'react'

import {Progress} from 'antd'
import PropTypes from 'prop-types'
import ReactAudioPlayer from 'react-audio-player'
import {withRouter} from 'react-router-dom'

import Pause from '../../assets/icons/pause.svg'
import Play from '../../assets/icons/play.svg'
import {White} from '../../styles/Colours'

let currentTimeInterval

const AudioPlayer = ({file, onEnd}) => {
  const [duration, setDuration] = useState(0)
  const [play, setPlay] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const audio = useRef(null)
  const onLoadedMetadata = (e) => {
    setDuration(e.target.duration)
  }

  const onPlay = () => {
    if (!play) {
      audio.current.audioEl.current.play()
      currentTimeInterval = setInterval(() => {
        if (
          !audio?.current?.audioEl ||
          audio.current.audioEl.current.currentTime >= duration
        ) {
          clearInterval(currentTimeInterval)
          if (onEnd) onEnd()
        } else setCurrentTime(audio.current.audioEl.current.currentTime)
      }, 500)
    } else {
      audio.current.audioEl.current.pause()
      clearInterval(currentTimeInterval)
    }
    setPlay(!play)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <Progress
        type='circle'
        strokeColor={White}
        trailColor='rgba(189, 179, 234, 0.6)'
        percent={(currentTime * 100) / duration}
        width={212}
        strokeWidth={11}
        format={() =>
          play ? (
            <img
              src={Pause}
              onClick={onPlay}
              style={{width: 168, cursor: 'pointer'}}
            />
          ) : (
            <img
              src={Play}
              onClick={onPlay}
              style={{width: 168, cursor: 'pointer'}}
            />
          )
        }
      />
      <ReactAudioPlayer
        ref={audio}
        src={file}
        onLoadedMetadata={onLoadedMetadata}
      />
    </div>
  )
}

AudioPlayer.propTypes = {
  file: PropTypes.string,
  onEnd: PropTypes.func
}

AudioPlayer.defaultProps = {
  file: undefined,
  onEnd: undefined
}

export default withRouter(AudioPlayer)

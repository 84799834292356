import React from 'react'

import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Form, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import Illustration from '../../assets/backgrounds/background_diario_meditacao.png'
import {SaveStepAnswer} from '../../infra/requests/ProgramRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {
  GetValueLanguage,
  UpdateUserProgram,
  getImage,
  GetStepName,
  GetProgramName
} from '../../infra/utils/CommonFunctions'
import {
  MainContainerBackground,
  MainContainerScroll2,
  MainContainerFlex2,
  MainContainerScroll,
  MainContainer,
  RegisterContainer
} from '../../pages/step/StepStyles'
import {UpdateUserScore} from '../../redux/User/user.actions'
import {Margin, PageForm} from '../../styles/BasicStyles'
import {
  White,
  ThirdColour,
  PrimaryColour,
  Purple
} from '../../styles/Colours'
import {QuestionText, Title25, Body, Title18} from '../../styles/TextSizes'
import BasicFooterComponent from '../footer/BasicFooterComponent'
import BasicHeaderComponent from '../header/BasicHeaderComponent'
import MessageComponent from '../message/MessageComponent'
import QuestionMultiSelect from '../question/QuestionMultiSelect'
import QuestionSlide from '../question/QuestionSlide'
import AudioPlayer from './AudioPlayer'
import {
  AudioPlayerContainer,
  TableRow,
  TableCol,
  AudioTextContainer
} from './AudioStyles'

const formRules = FormValidator.make({})
class AudioPage extends React.Component {
  constructor(props) {
    super(props)
    this.scrollComponent = null
    const isApplicator = props.user.type === 2
    const stepsA = isApplicator
      ? props.history?.location?.pathname.indexOf('step_educators') !== -1
        ? props.audio.filter((s) => s.user_type === 3)
        : props.audio.filter((s) => s.user_type === 4)
      : props.audio

    this.state = {
      stepsAudio: stepsA,
      activeStep: stepsA[0],
      activeStepIndex: 0,
      endAudio: false,
      initialValues: {questions: []},
      showText: false,
      isLoading: false
    }
  }

  componentDidMount = () => {
    const {stepsAudio} = this.state
    const values = {
      questions: []
    }
    stepsAudio.map((q) => {
      values.questions.push({
        _id: q._id,
        options: undefined
      })
    })
    this.setState({initialValues: values})
  }

  changeActiveStep = (back) => {
    const {stepsAudio, activeStepIndex} = this.state
    this.setState({
      activeStep: stepsAudio[activeStepIndex + (back ? -1 : 1)],
      activeStepIndex: activeStepIndex + (back ? -1 : 1)
    })
  }

  renderContent = ({fields}) => {
    const {activeStep, activeStepIndex, showText} = this.state
    const {step, activeLanguage, translate, user} = this.props
    const isEducator = user.type === 3
    switch (activeStep.type) {
      case 'select':
        return (
          <FormSpy subscription={{values: true}}>
            {() => (
              <MainContainerScroll>
                <MainContainer>
                  {activeStep.descriptions.map((description, index) => (
                    <MessageComponent
                      key={index}
                      text={description.title}
                      active={index === activeStep.descriptions.length - 1}
                    />
                  ))}
                  <RegisterContainer bgColor={Purple} borderColor={Purple}>
                    <QuestionMultiSelect
                      fieldName={`${fields.name}[${activeStepIndex}]`}
                      textColor={ThirdColour}
                      titleColor={White}
                      question={activeStep}
                    />
                  </RegisterContainer>
                </MainContainer>
              </MainContainerScroll>
            )}
          </FormSpy>
        )

      case 'slide':
        return (
          <MainContainerScroll>
            <MainContainer>
              {activeStep.descriptions.map((description, index) => (
                <MessageComponent
                  key={index}
                  text={description.title}
                  active={index === activeStep.descriptions.length - 1}
                />
              ))}
              <RegisterContainer bgColor={Purple} borderColor={Purple}>
                <QuestionSlide
                  fieldName={`${fields.name}[${activeStepIndex}]`}
                  question={activeStep}
                  titleColor={White}
                  onChangeValue={() => {
                    // setAnswered(true)
                  }}
                />
              </RegisterContainer>
            </MainContainer>
          </MainContainerScroll>
        )

      case 'media':
        return (
          <MainContainerScroll2>
            <MainContainerFlex2>
              <Title18 color={White} style={{marginBottom: 32}}>
                {isEducator ? translate('TEXTO_INTRO_AUDIO_EDUCADOR') : translate('TEXTO_INTRO_AUDIO')}
              </Title18>
              <Body
                onClick={() => this.setState({showText: !showText})}
                style={{
                  textDecorationLine: 'underline',
                  color: showText ? PrimaryColour : White,
                  cursor: 'pointer'
                }}
              >
                {translate('LER_A_MEDITAÇAO')}
              </Body>
              <Margin size={24} />
              <TableRow gutter={[16, 16]}>
                {showText && (
                  <TableCol sm={24} md={12}>
                    <AudioTextContainer>
                      <QuestionText
                        color={White}
                        dangerouslySetInnerHTML={{
                          __html: GetValueLanguage(
                            step.descriptions[0]?.title,
                            activeLanguage?.code
                          )
                        }}
                      />
                    </AudioTextContainer>
                  </TableCol>
                )}
                <TableCol sm={24} md={showText ? 12 : 24}>
                  <AudioPlayerContainer>
                    <Title25 color={White}>
                      {GetValueLanguage(step.title, activeLanguage?.code)}
                    </Title25>
                    <Margin size={24} />
                    <AudioPlayer
                      file={getImage(step[`audio_${activeLanguage.code}`])}
                      onEnd={() => {
                        this.setState({endAudio: true})
                      }}
                    />
                  </AudioPlayerContainer>
                </TableCol>
              </TableRow>
            </MainContainerFlex2>
          </MainContainerScroll2>
        )
      case 'text':
        return (
          <MainContainerScroll>
            <MainContainer>
              {activeStep.descriptions.map((description, index) => (
                <MessageComponent
                  key={index}
                  text={description.title}
                  active={index === activeStep.descriptions.length - 1}
                />
              ))}
            </MainContainer>
          </MainContainerScroll>
        )

      default:
        return <MainContainerScroll />
    }
  }

  onSubmit = async (values) => {
    const {stepsAudio} = this.state
    const {step, user, dispatch, chapter} = this.props

    const isApplicator = user.type === 2

    if (isApplicator) return this.changeActiveStep()
    try {
      this.setState({isLoading: true})
      const fields = []

      values.questions.forEach((question) => {
        const audioType = stepsAudio.find((a) => a._id === question._id)
          ?.type
        if (audioType === 'slide' || audioType === 'select') {
          const object = {
            user: undefined,
            chapter: chapter._id,
            step: step._id,
            audio: question._id
          }
          if (Number.isInteger(question.options)) {
            object.sliderValue = question.options
          } else {
            if (question.other) object.answer = [question.other]
            if (question.options && question.options.length) {
              object.options = [...question.options]
            }
          }
          fields.push(object)
        }
      })

      const result = await SaveStepAnswer(fields)

      if (result.success) {
        this.changeActiveStep()
        dispatch(UpdateUserScore(result.data.points))
      }
      this.setState({isLoading: false})
    } catch (e) {
      this.setState({isLoading: false})
      console.warn(e)
    }
  }

  onNext = (values) => {
    const {activeStepIndex, stepsAudio} = this.state
    const {
      updateChapter,
      user,
      info,
      dispatch,
      chapter,
      history,
      nextStep,
      location
    } = this.props
    const isProgram = !location.state?.noProgram
    const isDiarioMeditacao = location.state?.isDiarioMeditacao
    const isBiblioteca = location.state?.isBiblioteca
    const isApplicator = user.type === 2
    const stepName = GetStepName(info.program)
    const programName = GetProgramName(info.program)

    if (activeStepIndex + 1 !== stepsAudio.length) {
      if (activeStepIndex + 2 === stepsAudio.length) {
        this.onSubmit(values)
      } else {
        this.changeActiveStep()
      }
    } else {
      if (isProgram && !isBiblioteca && !isApplicator) {
        UpdateUserProgram(
          updateChapter,
          isDiarioMeditacao ? isDiarioMeditacao._id : chapter._id,
          dispatch
        )
      }

      if (location.state?.next) {
        if (location.state?.next === 'isBack') history.goBack()
        else history.push(location.state?.next)
      } else if (nextStep) {
        history.push(
          `/${stepName}/${nextStep._id}`
        )
      } else history.push(`/${programName}`)
    }
  }

  verifyForm = (values) => {
    const {activeStep, endAudio} = this.state
    const {user} = this.props
    const isStudent = user.type === 4

    if (activeStep.type === 'text') {
      return false
    }
    if (
      activeStep.type === 'media' &&
      isStudent &&
      !endAudio
    ) {
      return true
    }
    if (
      activeStep.type !== 'media' &&
      !values?.questions[activeStep.order - 1]?.options &&
      values?.questions[activeStep.order - 1]?.options !== 0 &&
      !values?.questions[activeStep.order - 1]?.other
    ) {
      return true
    }
    return false
  }

  render() {
    const {
      activeStepIndex,
      initialValues,
      activeStep,
      stepsAudio,
      isLoading
    } = this.state
    const {chapter, history, location, info} = this.props
    const progress = location.state?.progress
      ? ((location.state?.progress.number + activeStep.order) /
          location.state.progress.total) *
        100
      : (activeStep.order / stepsAudio.length) * 100

    const programName = GetProgramName(info.program)

    return (
      <Form
        onSubmit={this.onNext}
        validate={formRules}
        mutators={{
          ...arrayMutators
        }}
        initialValues={initialValues}
      >
        {({handleSubmit, values}) => (
          <PageForm onSubmit={handleSubmit}>
            <BasicHeaderComponent
              onClose={() =>
                location.state?.back
                  ? location.state?.back === 'isBack'
                    ? history.goBack()
                    : history.push(location.state.back)
                  : history.push(`/${programName}`)
              }
              title={chapter.title}
              progress={progress}
            />
            <MainContainerBackground
              bgColor={ThirdColour}
              style={{
                backgroundImage: `url(${Illustration})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center center'
              }}
            >
              <FieldArray
                name='questions'
                component={this.renderContent}
              />
            </MainContainerBackground>
            <BasicFooterComponent
              onBack={() =>
                stepsAudio[activeStepIndex - 1]
                  ? this.changeActiveStep(true)
                  : history.goBack()
              }
              nextHtmlType='submit'
              nextDisabled={this.verifyForm(values)}
              isLoading={isLoading}
            />
          </PageForm>
        )}
      </Form>
    )
  }
}
AudioPage.propTypes = {
  step: PropTypes.object,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  updateChapter: PropTypes.bool,
  audio: PropTypes.array,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired
}

AudioPage.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  step: undefined,
  updateChapter: false,
  audio: undefined
}

const mapStateToProps = (state) => ({
  user: state.user,
  info: state.info
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  withRouter(connect(mapStateToProps, mapActionToProps)(AudioPage))
)

import React from 'react'

import {PropTypes} from 'prop-types'
import {withRouter} from 'react-router-dom'

import AtividadesLazer from '../atividades_lazer/AtividadesLazer'
import RegistoObjetivos from '../atividades_lazer/RegistoObjetivos'
import QuizEmoçoesAtividades from '../quiz/QuizEmoçoesAtividades'
import QuizPensamentosEmoçoes from '../quiz/QuizPensamentosEmoçoes'

const components = {
  AtividadesLazer,
  RegistoObjetivos,
  QuizEmoçoesAtividades,
  QuizPensamentosEmoçoes
}

const QuestionComponent = ({
  step,
  nextStep,
  chapter,
  session,
  updateChapter
}) => {
  const renderContent = () => {
    let MyComponent = false
    if (session.order === 4 && chapter.order === 4) {
      MyComponent = components.AtividadesLazer
    }
    if (session.order === 5 && chapter.order === 3) {
      MyComponent = components.RegistoObjetivos
    }
    // if (session.order === 5 && chapter.order === 6) {
    //   MyComponent = components.QuizEmoçoesAtividades
    // }
    // if (session.order === 6 && chapter.order === 5) {
    //   MyComponent = components.QuizPensamentosEmoçoes
    // }

    if (MyComponent) {
      return (
        <MyComponent
          key={Math.random()}
          step={step}
          nextStep={nextStep}
          chapter={chapter}
          session={session}
          updateChapter={updateChapter}
        />
      )
    }
    return null
  }

  return renderContent()
}

QuestionComponent.propTypes = {
  step: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.object,
  chapter: PropTypes.object,
  session: PropTypes.object,
  updateChapter: PropTypes.bool
}

QuestionComponent.defaultProps = {
  nextStep: undefined,
  chapter: undefined,
  session: undefined,
  updateChapter: false
}

export default withRouter(QuestionComponent)

import React from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {
  GetRecordsFromProgram,
  GetValueLanguage,
  isEnabledInProgram
} from '../../infra/utils/CommonFunctions'
import {diarioHumor} from '../../infra/utils/PagesNames'
import {
  DashboardBox,
  SeeAllButton,
  DashboardBoxCol,
  TitleDashboard,
  DashboardBoxSmall
} from '../../pages/dashboard/DashboardStyles'
import {Margin} from '../../styles/BasicStyles'
import {SecondaryColour, White} from '../../styles/Colours'
import {TitleH3} from '../../styles/TextSizes'
import {BaseButton} from '../buttons/BaseButton'
import CompleteBoxComponent from './CompleteBoxComponent'

const CompleteComponent = ({
  isActivities,
  translate,
  activeLanguage,
  history
}) => {
  const {
    user,
    info: {program}
  } = useSelector((state) => state)
  const isStudent = user.type === 4

  const records = GetRecordsFromProgram(program, isActivities)

  return (
    <>
      {isStudent ? (
        <DashboardBox
          column
          bgColor={SecondaryColour}
          image={`url(${require('../../assets/backgrounds/background_complete.png')})`}
        >
          <Row>
            <Col xs={12} style={{textAlign: 'left'}}>
              <TitleH3 weight={700} color='#FFFFFF'>
                {translate(isActivities ? 'REGISTOS' : 'COMPLETE')}
              </TitleH3>
            </Col>
            {!isActivities && (
              <Col xs={12} style={{textAlign: 'right'}}>
                <SeeAllButton
                  color='#FFFFFF'
                  onClick={() => history.push('/activities')}
                >
                  {translate('SEE_ALL')}
                </SeeAllButton>
              </Col>
            )}
          </Row>
          <Margin size={21} />
          <Row gutter={[16, 16]}>
            {records.map((record, i) => (
              <Col xs={24} md={isActivities ? 12 : 8} key={i}>
                <CompleteBoxComponent
                  translate={translate}
                  inactive={
                    !record.enabled ||
                    record.steps.find((s) => s.completedWeek)
                  }
                  title={GetValueLanguage(
                    record.title,
                    activeLanguage?.code
                  )}
                  starNumber={4}
                  onClick={() =>
                    history.push(
                      `/step/${
                        record.steps.find((s) => s.type === 'question')._id
                      }`,
                      {
                        next: 'isBack',
                        back: 'isBack',
                        noProgram: true
                      }
                    )
                  }
                />
              </Col>
            ))}
          </Row>
        </DashboardBox>
      ) : (
        <DashboardBoxSmall
          bgColor={SecondaryColour}
          image={`url(${require('../../assets/backgrounds/background_complete.png')})`}
        >
          <Col xs={24} md={12} lg={16}>
            <TitleDashboard color={White}>
              {translate('COMO_TE_SENTES_HOJE_EDUCADOR')}
            </TitleDashboard>
          </Col>
          <DashboardBoxCol xs={24} md={12} lg={8}>
            <BaseButton
              auto
              variant='raised'
              htmlType='button'
              type='primaryMedium'
              text={translate('CONTINUE')}
              onClick={() =>
                history.push(`/step_educator/${diarioHumor}`, {
                  next: 'isBack',
                  back: 'isBack',
                  noProgram: true
                })
              }
              disabled={!isEnabledInProgram(program, diarioHumor)}
            />
          </DashboardBoxCol>
        </DashboardBoxSmall>
      )}
    </>
  )
}

CompleteComponent.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  isActivities: PropTypes.bool,
  history: PropTypes.object.isRequired
}

CompleteComponent.defaultProps = {
  isActivities: false
}

export default withLocalize(withRouter(CompleteComponent))

import React from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {FormSpy, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import {GetValueLanguage} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {LightGrey} from '../../styles/Colours'
import {QuestionText} from '../../styles/TextSizes'
import TextAreaInput from '../inputs/TextAreaInput'

const QuestionTextArea = ({step, showValue, disabled, activeLanguage}) => {
  const title = GetValueLanguage(step.title, activeLanguage?.code)
  return (
    <FormSpy subscription={{values: true}}>
      {({values}) => (
        <>
          {showValue && (
            <>
              {step.descriptions.map((d, i) => (
                <>
                  <QuestionText
                    dangerouslySetInnerHTML={{
                      __html: GetValueLanguage(
                        d.title,
                        activeLanguage && activeLanguage.code
                      )
                    }}
                  />
                  <Margin size={8} />
                </>
              ))}
            </>
          )}
          {title && <QuestionText>{title}</QuestionText>}
          <FieldArray name={`steps[${step.order - 1}].questions`}>
            {({fields}) =>
              step.questions.map((question, i) => (
                <div key={i}>
                  <QuestionText color={LightGrey}>
                    {GetValueLanguage(
                      question.title,
                      activeLanguage?.code
                    )}
                  </QuestionText>
                  {showValue ? (
                    <>
                      <Margin size={24} />
                      <Row gutter={[16, 16]}>
                        <Col sm={24} md={12}>
                          <TextAreaInput
                            disabled={disabled}
                            typeText='text'
                            input={{
                              value: values.steps[3].questions[0].answer,
                              onChange: () => {}
                            }}
                            meta={{}}
                            label={GetValueLanguage(
                              question.options[0].title,
                              activeLanguage?.code
                            )}
                            readOnly
                          />
                        </Col>
                        <Col sm={24} md={12}>
                          <Field
                            disabled={disabled}
                            typeText='text'
                            name={`${fields.name}[${i}].answer`}
                            component={TextAreaInput}
                            label={GetValueLanguage(
                              question.options[1].title,
                              activeLanguage?.code
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Field
                      disabled={disabled}
                      typeText='text'
                      name={`${fields.name}[${i}].answer`}
                      component={TextAreaInput}
                      label={GetValueLanguage(
                        question.options[0].title,
                        activeLanguage?.code
                      )}
                    />
                  )}
                </div>
              ))
            }
          </FieldArray>
        </>
      )}
    </FormSpy>
  )
}

QuestionTextArea.propTypes = {
  step: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  showValue: PropTypes.bool,
  disabled: PropTypes.bool
}

QuestionTextArea.defaultProps = {
  showValue: false,
  disabled: false
}

export default withLocalize(withRouter(QuestionTextArea))

import React, {useEffect, useState} from 'react'

import {DownloadOutlined} from '@ant-design/icons'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import ReactPlayer from 'react-player'
import {withRouter, useParams} from 'react-router-dom'

import IconFile from '../../assets/icons/file-text.svg'
import AudioPlayer from '../../components/audio/AudioPlayer'
import {GetBoosterSession} from '../../infra/requests/ProgramRequests'
import {
  GetValueLanguage,
  getImage
} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {Beige3} from '../../styles/Colours'
import {TitleH3, Body} from '../../styles/TextSizes'
import {DashboardContainer} from '../dashboard/DashboardStyles'
import {
  BoosterSessionContainer,
  ThemeDescription,
  ThemeImage,
  FileContainer
} from './BoosterSessionStyles'

const BoosterSessionPage = ({translate, activeLanguage}) => {
  const [session, setSession] = useState(undefined)
  const {id} = useParams()

  useEffect(() => {
    async function getSession() {
      const result = await GetBoosterSession(id)
      if (result.success) {
        setSession(result.data)
      }
    }
    if (id) getSession()
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [id])

  const Download = (e, href, title) => {
    fetch(href, {
      method: 'GET',
      headers: {}
    })
      .then((response) => {
        response.arrayBuffer().then((buffer) => {
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', title)
          document.body.appendChild(link)
          link.click()
        })
      })
      .catch((err) => {
        console.warn(err)
      })
  }

  if (!session) return <BoosterSessionContainer />

  return (
    <BoosterSessionContainer>
      <DashboardContainer>
        <TitleH3 isBold style={{textAlign: 'left'}}>
          {GetValueLanguage(session?.title, activeLanguage?.code)}
        </TitleH3>
        <Margin size={32} />
        {session?.blocks.map((block, i) =>
          block.type === 1 ? (
            <ThemeDescription
              key={i}
              dangerouslySetInnerHTML={{
                __html: block?.description[activeLanguage?.code]
              }}
            />
          ) : block.type === 2 ? (
            <div style={{position: 'relative'}} key={i}>
              <ThemeImage src={block?.image?.url} />
              <a
                download
                onClick={(e) => Download(e, block?.image?.url, 'image.jpg')}
              >
                <DownloadOutlined
                  style={{
                    fontSize: 32,
                    position: 'absolute',
                    bottom: 36,
                    right: 16,
                    background: Beige3,
                    borderRadius: '50%',
                    padding: 8
                  }}
                />
              </a>
            </div>
          ) : block.type === 3 ? (
            <ReactPlayer
              key={i}
              url={block?.video}
              height={360}
              width='100%'
              controls
            />
          ) : block.type === 4 ? (
            <AudioPlayer
              key={i}
              file={`${process.env.NEXT_PUBLIC_REACT_APP_IMAGES_URL}/${block.file._id}`}
            />
          ) : block.type === 5 && block.file ? (
            <FileContainer key={i}>
              <img src={IconFile} alt='file' />
              <Body>{block.file?.filename}</Body>
              <a
                download
                onClick={(e) =>
                  Download(
                    e,
                    getImage(block?.file?._id),
                    block.file?.filename
                  )
                }
                style={{
                  marginLeft: 'auto'
                }}
              >
                <DownloadOutlined
                  style={{
                    fontSize: 32,
                    background: Beige3,
                    borderRadius: '50%',
                    padding: 8
                  }}
                />
              </a>
            </FileContainer>
          ) : null
        )}
      </DashboardContainer>
    </BoosterSessionContainer>
  )
}

BoosterSessionPage.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(withRouter(BoosterSessionPage))

import React from 'react'

import Routes from './routes/routes'
import {BasicStyles} from './styles/BasicStyles'

const App = () => (
  <>
    <Routes />
    <BasicStyles />
  </>
)

export default App

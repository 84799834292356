import {Row, Col} from 'antd'
import styled from 'styled-components'

import {device} from '../../styles/Responsive'

export const RowMeditate = styled(Row)`
  display: flex !important;

  @media ${device.laptop} {
    display: block !important;
  }
`

export const ColMeditate = styled(Col)`
  display: flex !important;
  flex: 1;
  justify-content: center;

  @media ${device.laptop} {
    display: none !important;
  }
`

export const TitleVideoteca = styled.div`
  display: none;
  text-align: left;
  margin-bottom: 34px;

  @media ${device.laptop} {
    display: block;
  }
`

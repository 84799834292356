import React from 'react'

import {PropTypes} from 'prop-types'

import Illustration from '../../assets/illustrations/step2_6_3.png'
import {RegisterContainer} from '../../pages/step/StepStyles'
import MessageComponent from '../message/MessageComponent'

const TextComponent = ({step, editable}) => (
  <RegisterContainer
    style={{
      backgroundImage: `url(${Illustration})`,
      backgroundPosition: 'right bottom',
      backgroundRepeat: 'no-repeat'
    }}
  >
    {step.type === 'text' && (
      <>
        {step.descriptions.map((description, index) => (
          <>
            {((editable && index === 0) || index > 0) && (
              <MessageComponent
                key={index}
                text={description.title}
                active={index === step.descriptions.length - 1}
              />
            )}
          </>
        ))}
      </>
    )}
  </RegisterContainer>
)

TextComponent.propTypes = {
  step: PropTypes.object.isRequired,
  editable: PropTypes.bool
}

TextComponent.defaultProps = {
  editable: false
}

export default TextComponent

import React from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router-dom'

import BackIcon from '../../assets/icons/arrow-left.svg'
import NextIcon from '../../assets/icons/arrow-right.svg'
import RetryIcon from '../../assets/icons/retry.svg'
import {MarginH} from '../../styles/BasicStyles'
import {BaseButton} from '../buttons/BaseButton'
import {
  BasicFooterContent,
  BasicFooter,
  BasicFooterRow,
  BasicFooterCol
} from './FooterStyles'

const BasicFooterComponent = ({
  onRetry,
  onBack,
  onNext,
  leftContent,
  nextDisabled,
  onNextText,
  nextHtmlType,
  isLoading
}) => (
  <BasicFooter>
    <BasicFooterContent>
      <BasicFooterRow>
        <Col xs={24} md={16}>
          {leftContent}
        </Col>
        <BasicFooterCol xs={24} md={8}>
          {onRetry && (
            <BaseButton
              style={{padding: 0}}
              align='right'
              htmlType='button'
              type='default'
              icon={<img alt='retry' src={RetryIcon} />}
              onClick={onRetry}
            />
          )}
          {onBack && (
            <BaseButton
              style={{marginLeft: 16, width: 72}}
              align='right'
              htmlType='button'
              type='default'
              icon={<img alt='back' src={BackIcon} />}
              onClick={onBack}
            />
          )}
          <MarginH size={0} tablet={16} mobile={16} />
          {(nextHtmlType || onNext) && (
            <BaseButton
              loading={isLoading}
              disabled={nextDisabled}
              style={{marginLeft: 16, width: onNextText ? 'auto' : 72}}
              align='right'
              htmlType={nextHtmlType || 'button'}
              type='primaryMedium'
              icon={
                !onNextText ? <img alt='next' src={NextIcon} /> : undefined
              }
              onClick={onNext}
              text={onNextText}
            />
          )}
        </BasicFooterCol>
      </BasicFooterRow>
    </BasicFooterContent>
  </BasicFooter>
)

BasicFooterComponent.propTypes = {
  onRetry: PropTypes.func,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  leftContent: PropTypes.object,
  nextDisabled: PropTypes.bool,
  onNextText: PropTypes.string,
  nextHtmlType: PropTypes.string,
  isLoading: PropTypes.bool
}

BasicFooterComponent.defaultProps = {
  onRetry: undefined,
  onBack: undefined,
  onNext: undefined,
  leftContent: undefined,
  nextDisabled: false,
  onNextText: undefined,
  nextHtmlType: undefined,
  isLoading: false
}

export default withLocalize(withRouter(BasicFooterComponent))

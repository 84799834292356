import {
  Input,
  Select,
  InputNumber,
  Checkbox,
  DatePicker,
  Icon,
  Collapse,
  Radio
} from 'antd'
import styled from 'styled-components'

import {
  MenuBorderColor,
  TextColor,
  ErrorColor,
  White
} from '../../styles/Colours'
import {device} from '../../styles/Responsive'
import {titleH4} from '../../styles/TextSizes'

const {TextArea} = Input
const {Panel} = Collapse
const {Option} = Select

const defineBorderColor = (props) => {
  if (props.error) return props.theme.inputErrorColor
  if (props.active) return props.theme.primaryColor
  return props.theme.inputBorderColor
}

export const InputDiv = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: ${(p) => (p.first ? '0px' : p.isAuth ? '16px' : '30px')};

  @media ${device.tablet} {
    margin-top: ${(p) => (p.first ? '0px' : p.isAuth ? '10px' : '20px')};
  }
`

export const SampleButtonSpace = styled.div`
  display: inline-block;
  width: 100%;
  height: 32px;
`

export const SampleInputSpace = styled.div`
  width: 100%;
  margin-top: ${(p) => (p.first ? '0px' : '10px')};
  height: 64px;
`

export const InputLabelDiv = styled.div`
  display: inline-block;
  font-size: 16px;
  width: 100%;
  ${({error}) => error && 'color: red'};
  margin-top: ${({sublabel}) => (sublabel ? '10px' : 0)};
`

export const InputField = styled(Input)`
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${(p) => p.theme.inputFocusColor};
    }
    .ant-input {
      border: 2px solid ${(p) => p.theme.inputFocusColor};
    }
  }

  ${(p) =>
    p.error &&
    `
      border: 2px solid ${p.theme.inputErrorColor} !important;
      .ant-input-prefix, .ant-input-suffix{
        color: ${p.theme.inputErrorColor};
      }
  `}
`

export const InputNumberField = styled(InputNumber)`
  width: 100% !important;

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${(p) => p.theme.inputFocusColor};
    }
    .ant-input-number-input {
      border: 2px solid ${(p) => p.theme.inputFocusColor};
    }
  }

  ${(p) =>
    p.error &&
    `.ant-input-number-input{
        border: 2px solid ${p.theme.inputErrorColor};
      }
  `}
`

export const LanguagePill = styled.div`
  display: inline-block;
  margin: 10px 10px 0 0;
  padding: 6px 10px;
  border: 2px solid ${(props) => defineBorderColor(props)};
  color: ${({active}) => (active ? 'white' : 'rgba(0, 0, 0, 0.65)')};
  background-color: ${({active, theme}) =>
    active ? theme.primaryColor : 'transparent'};
  cursor: pointer;
  border-radius: 3px;
`

export const Flag = styled.img`
  width: 20px;
  margin-right: 10px;
`

export const InputBox = styled.div`
  width: 100%;
  height: 52px;
  background: #ffffff;
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  display: flex;
  margin-top: ${(p) => (p.isAuth ? '8px' : '0px')};
}
`

export const DateInputField = styled(DatePicker)`
  width: 100%;

  .ant-input {
    height: 52px;
  }

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({theme}) => theme.inputFocusColor};
    }
    .ant-input {
      border: 2px solid ${({theme}) => theme.inputFocusColor};
    }
  }

  ${({error, theme}) =>
    error &&
    `.ant-calendar-picker-input{
        border: 2px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`

export const RatingInputBox = styled.div`
  display: inline-block;
  width: 100%;
`

export const RateImage = styled.img`
  margin: 5px;
  width: 30px;
  cursor: pointer;
`

export const ImageButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.5s linear;
`

export const ImageInputSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 250px;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${(p) => p.theme.inputBorderColor};
  cursor: pointer;
  ${({filled}) => (filled ? 'border: none' : '')}

  &:hover {
    ${ImageButton} {
      opacity: 1;
    }
  }
`

export const ImageMessage = styled.div`
  color: ${({error, theme}) =>
    error ? theme.inputErrorColor : '#000000'};
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  opacity: 0.2;
  margin-top: 25px;
  padding: 0px 5px;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const ImageIcon = styled.img`
  width: 63px;
`

export const SelectButton = styled.div`
  margin-top: 25px;

  & button {
    @media ${device.tablet} {
      padding: 12px 80px !important;
    }

    @media ${device.mobileL} {
      padding: 12px 50px !important;
    }

    @media ${device.mobileS} {
      padding: 12px 10px !important;
    }
  }
`

export const LanguagesComponent = styled.div`
  display: inline-block;
  width: 100%;
  margin: 20px 0;
`

export const AddLangButton = styled.div`
  display: inline-block;
  width: 300px;
  margin-top: 10px;
`

export const DeleteLangIcon = styled.div`
  display: inline-block;
  position: relative;
`

export const FormCollapse = styled(Collapse)`
  &&.ant-collapse-header {
    color: red;
  }
`

export const FormPanel = styled(Panel)`
  .ant-collapse-header {
    color: ${({error}) => (error ? 'red !important' : '')};
  }
`

export const ImageSlideComponent = styled.div`
  display: inline-block;
  width: 100%;

  .ant-carousel {
    margin-top: 10px;
    .slick-slide {
      position: relative;
    }
  }
`

export const ImageSlideButton = styled.div`
  display: inline-block;
  width: 100%;
  cursor: pointer;
  margin-top: 12px;
`

export const RemoveImage = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: white;
`

export const AddIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  font-size: 50px;
`

export const EmptySlide = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 66%;
  border: 2px solid
    ${({showError, theme}) =>
      showError ? theme.inputErrorColor : theme.inputBorderColor};

  ${AddIcon} {
    color: ${({showError, theme}) =>
      showError ? theme.inputErrorColor : theme.inputBorderColor};
  }
`

export const MapSearchInput = styled(Input)`
  &&& {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 350px;
  }
`

export const PreviewVideo = styled.div`
  display: inline-block;
  width: 100%;
  height: 400px;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
`

export const FileSection = styled.div`
  margin-top: 3px;
  background-color: ${(p) => p.theme.secondaryColor};
  padding: 5px;
  text-transform: uppercase;
  position: relative;

  & i {
    position: absolute;
    right: 1%;
    top: 25%;
    cursor: pointer;
  }
`

export const FileDropSection = styled.div`
  display: flex;
  width: 100%;
  color: ${({theme}) => theme.inputBorderColor};
  border: 2px solid ${({theme}) => theme.inputBorderColor};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 18px;

  i {
    font-size: 50px;
  }
`

export const DownloadFileButton = styled.div`
  background-color: ${(p) => p.theme.primaryCTA};
  border-radius: 6px;
  width: auto;
  height: auto;
  padding: 10px 15px;
  font-size: 16px;
  color: #ffffff;
  pointer-events: auto !important;

  &:hover {
    background-color: ${(p) => p.theme.secondaryCTA};
  }
`

/** ***************************** CHECKBOX INPUT ****************************** */

export const CheckboxField = styled(Checkbox)`
  & .ant-checkbox {
    width: 24px;
    height: 24px;

    & + span {
      padding-left: 0px;
      padding-right: 0px;
    }

    & .ant-checkbox-input {
      width: 24px;
      height: 24px;
    }

    & .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border-radius: ${(p) => (p.checkboxType === 'square' ? '0px' : '50%')};

      &:after {
        top: ${(p) => (p.checkboxType === 'square' ? '42%' : '43%')};
        left: ${(p) => (p.checkboxType === 'square' ? '14%' : '16%')};
        width: ${(p) => (p.checkboxType === 'square' ? '9px' : '8px')};
        height: ${(p) => (p.checkboxType === 'square' ? '20px' : '17px')};
      }
    }

    &.ant-checkbox-checked {
      &:after {
        border: 0px;
      }

      & .ant-checkbox-inner {
        background-color: ${(p) => p.theme.primaryColor};
        border-color: ${(p) => p.theme.primaryColor};
      }
    }

    &:hover {
      & .ant-checkbox-inner {
        border-color: ${(p) => p.theme.primaryColor};
      }
    }
  }

  &.ant-checkbox-wrapper {
    display: flex;
    align-items: flex-end;

    &:hover {
      & .ant-checkbox-inner {
        border-color: ${(p) => p.theme.primaryColor};
      }
    }
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(p) => p.theme.primaryColor};
  }
`

/** ***************************** RADIO INPUT ****************************** */

export const RadioGroup = styled(Radio.Group)`
  &.ant-radio-group {
    & .ant-radio-wrapper {
      margin-right: 0px;

      & .ant-radio-input {
        width: 22px;
        height: 22px;
      }

      & .ant-radio-inner {
        width: 24px;
        height: 24px;
      }

      & .ant-radio + span {
        display: none;
      }
    }
  }
`

/** ***************************** TEXT AREA INPUT ****************************** */

export const TextAreaDiv = styled.div`
  position: relative;
  align-items: center;
  display: ${({isSmall}) => (isSmall ? 'flex' : 'inline-block')};
  width: 100%;
  border-radius: 8px;
  border: 2px solid
    ${({showError}) => (showError ? ErrorColor : MenuBorderColor)};
  padding: ${({isSmall}) => (isSmall ? '8px 16px' : '16px 24px')};
  background: ${({bgColor}) => bgColor || MenuBorderColor};
`

export const CheckboxContainer = styled.div`
  & div {
    display: block;
    margin-top: 0px;
  }
`

export const TextAreaInputField = styled(TextArea)`
  &.ant-input {
    width: 100%;
    opacity: ${({isSmall}) => (isSmall ? '1' : '0.6')};
    padding: 0px;
    font-size: ${({isSmall}) => (isSmall ? '14px' : '16px')};
    color: ${TextColor};
    line-height: 21px;

    &::placeholder {
      color: #d7d7d7;
    }

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
`

/** ***************************** QUANTITY INPUT ****************************** */

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ButtonSubtract = styled.span`
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 10px;
  background-color: ${(p) => p.theme.secondaryColor};
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  transition: background-color 0.5s linear;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${(p) => p.theme.secondaryCTA};
  }
`

export const QuantityInput = styled(Input)`
  max-width: 30px;

  &.ant-input {
    background-color: ${(p) => p.theme.secondaryColor};
    border: 0;
    border-radius: 0px;
    height: 34px;
    color: #000000;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0px 1px;

    &:focus {
      box-shadow: none;
    }
  }
`

export const ButtonAdd = styled.span`
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 10px;
  background-color: ${(p) => p.theme.secondaryColor};
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  transition: background-color 0.5s linear;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${(p) => p.theme.secondaryCTA};
  }
`

/** ***************************** SELECT INPUT ****************************** */

export const SelectDiv = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: 0px;
`

export const SelectArrow = styled.span`
  position: absolute;
  bottom: 5px;
  right: 0;
  cursor: pointer;
  pointer-events: none;

  &.customSelectArrow {
    bottom: 8px;
  }

  & img {
    height: 12px;
  }
`

export const CloseArrow = styled.span`
  position: absolute;
  bottom: 5px;
  right: 0;
  cursor: pointer;
  z-index: 1;

  & img {
    height: 16px;
  }
`

export const ArrowImg = styled.img``

export const StyledSelectInput = styled(Select)`
  width: 100%;

  .ant-select-selector {
    width: 100%;
    outline: none;
    border-radius: 8px !important;
    border: 2px solid
      ${({showError}) => (showError ? 'red' : MenuBorderColor)} !important;
    font-size: 14px;
    color: ${TextColor};
    line-height: 21px;
    font-weight: 400;
    background: ${White};
    opacity: ${(p) => (p.tag === 'GUESTS' ? 1 : p.disabled ? 0.6 : 1)};
    min-height: 53px;
    align-items: center;
    text-align: left;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &::placeholder {
      color: #d7d7d7;
    }

    &:hover,
    &:active,
    &:focus {
      outline: none;
    }

    @media ${device.laptop} {
      font-size: 14px;
    }

    @media ${device.tablet} {
      font-size: 13px;
    }

    @media ${device.tablet} {
      font-size: 12px;
    }

    & .ant-select-selector__rendered {
      margin-right: 0px;
      margin-left: 0px;
      line-height: 31px;

      & .ant-select-selector__placeholder {
        color: #000000;
        opacity: 1;
        font-size: 20px;
        font-weight: 400;
        line-height: 31px;
        height: 31px;
        top: 0px;
        right: 0px;
        margin-top: 0px;
      }

      & .ant-select-selector-selected-value {
        color: #000000;
        font-size: 20px;
        font-weight: 400;
        line-height: 31px;
        height: 31px;
      }
    }
  }

  .ant-select-open .ant-select-selector,
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active {
    border-color: ${(p) => p.theme.inputFocusColor};
    box-shadow: none;
  }

  ${(p) =>
    p.error &&
    `.ant-select-selector {
        border: 0;
        border-bottom: 2px solid ${p.theme.inputErrorColor} !important;
      }
     
  `}
`

export const StyledQuestionSelectInput = styled(Select)`
  width: 100%;
  border-bottom: 2px solid
    ${({showError}) => (showError ? 'red' : MenuBorderColor)};

  .ant-select-selector {
    display: inline-block;
    width: 100%;
    border: none;
    outline: none;
    color: ${TextColor};
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    background: transparent;
  }

  @media ${device.tablet} {
    .ant-select-selector {
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }
`

export const StyledOption = styled(Option)``

export const StyledOptionContent = styled.div`
  padding: 5px 12px;
`

/** ***************************** CUSTOM SELECT INPUT ****************************** */

export const OptionContainer = styled(Option)``

export const OptionContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 20px;
  cursor: pointer;
  background-color: ${(p) =>
    p.selected ? p.theme.secondaryColor : 'transparent'};

  &:hover {
    & span {
      opacity: 0.6;
    }
  }

  @media ${device.tabletS} {
    padding: 7px 10px;
  }
`

export const Box = styled.div`
  background-color: ${(p) =>
    p.selected ? p.theme.secondaryCTA : p.theme.primaryCTA};
  width: 24px;
  height: 24px;
  margin-right: 15px;
  flex-shrink: 0;

  @media ${device.mobileM} {
    margin-right: 5px;
  }
`

export const OptionText = styled.span`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  opacity: ${(p) => (p.selected ? '1' : '0.3')};
  text-align: left;
  transition: opacity 0.5s linear;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${device.tablet} {
    font-size: 16px;
  }
`

export const SelectErrorLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  top: ${({filled}) => (filled ? '0' : '10px')};
  left: 0;
  transition: all 0.2s ease;
  transition: color 0;
  color: #de8f8f;
  pointer-events: none;
  text-align: left;
`

/** ***************************** CUSTOM SELECT INPUT "OUR DAY" ****************************** */

export const OptionContentOurDay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  background-color: ${(p) =>
    p.selected ? p.theme.secondaryColor : 'transparent'};
  border-top: ${(p) => (p.hasBorder ? '2px' : '0px')} solid
    ${(p) => p.theme.primaryCTA};

  &:hover {
    & span {
      opacity: 0.6;
    }
  }

  @media ${device.tabletS} {
    padding: 7px 10px;
  }
`

export const BoxOurDay = styled.div`
  background-color: ${(p) =>
    p.selected ? p.theme.secondaryCTA : p.theme.secondaryColor};
  width: 24px;
  height: 24px;
  margin-right: 15px;
  flex-shrink: 0;

  @media ${device.mobileM} {
    margin-right: 5px;
  }
`

export const OptionTextOurDay = styled.span`
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  opacity: ${(p) => (p.selected ? '1' : '0.3')};
  text-align: left;
  transition: opacity 0.5s linear;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${device.tablet} {
    font-size: 16px;
  }
`

export const CustomSelectLabel = styled(titleH4)`
  line-height: 29px;
  padding-bottom: 15px;
  text-align: left;
`

/** ***************************** TIME INPUT ****************************** */

export const TimeInputSection = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  background: transparent;
`

export const TimeInputLabel = styled(titleH4)`
  line-height: 29px;
  padding-bottom: 15px;
  text-align: left;
`

export const TimeInputError = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  top: ${({filled}) => (filled ? '0' : '10px')};
  left: 0;
  transition: all 0.2s ease;
  transition: color 0;
  color: #de8f8f;
  pointer-events: none;
  text-align: left;
`

export const TimeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  height: 45px;
  background-color: #ffffff;
  border: 2px solid ${(p) => p.theme.primaryCTA};
  border-radius: 12px;

  @media ${device.tablet} {
    height: 40px;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
`

export const TimeInput = styled.input`
  width: 100%;
  border: 0;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  opacity: ${(p) => (p.filled ? '1' : '0.3')};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  border-left: 2px solid ${(p) => p.theme.primaryCTA};
`

export const ArrowUp = styled.img`
  transform: rotateZ(180deg);
  padding-top: 4px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  @media ${device.tablet} {
    padding-top: 3px;
  }
`

export const ArrowDown = styled.img`
  padding-top: 4px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  @media ${device.tablet} {
    padding-top: 3px;
  }
`

export const SelectLabelInput = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: black;
  opacity: 0.2;
  margin-top: 30px;
  text-align: left;
`
